import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSizes, Header4, muiInputFormsCSS, StyledIconButton, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { TextField, useTheme } from "@mui/material";

import React, { forwardRef, useState, useTransition, useRef, useImperativeHandle, ReactNode } from "react";
import { SimpleTooltip } from "./tooltips";

export const FormWizardEditableTitle = forwardRef<
    { updateNameExternally(name: string | null): void },
    { entityName: string; updateNameOnEntity: (name: string) => void; disableNameEdits: boolean; icon?: ReactNode; className?: string; fallbackTitle: string }
>(({ entityName, updateNameOnEntity, disableNameEdits, icon, className, fallbackTitle }, ref) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();

    const [editingName, setEditingName] = useState(false);
    const [, startTransition] = useTransition();

    const initialName = useRef<string>(entityName);
    const [name, setName] = useState(entityName);

    const updateName = (e: { target: { value: string } }) => {
        setName(e.target.value);
        startTransition(() => {
            // Use start transition so that the text editor is handled immediately in local (to this component) state. Without this it feels laggy
            updateNameOnEntity(e.target.value);
        });
    };

    const handleSubmit = () => {
        initialName.current = name;
        setEditingName(false);
    };

    useImperativeHandle(ref, () => ({
        updateNameExternally: (name) => {
            setName(name);
            initialName.current = name;
        },
    }));

    return (
        <StyledTitle className={className}>
            {icon ?? null}
            {entityName == null && <span>{fallbackTitle}</span>}
            {editingName ? (
                <TextField
                    data-testid="edit-layer-name-input"
                    inputProps={{ style: { fontSize: fontSizes.large, fontWeight: 600, lineHeight: 1.33, padding: "0", height: "auto" } }}
                    variant="standard"
                    value={name}
                    onChange={updateName}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
            ) : (
                // Margin to accomodate the textbox while editing (so the form doesn't jump around when flicking between edit and non-edit)
                <Header4 style={{ margin: "1px 0" }} data-testid="layer-name-title">
                    {name}
                </Header4>
            )}
            {entityName && !disableNameEdits && (
                <>
                    {editingName ? (
                        <>
                            <TitleButton
                                onClick={() => {
                                    updateName({ target: { value: initialName.current } });
                                    setEditingName(false);
                                }}
                            >
                                <FontAwesomeIcon color={theme.secondaryColors.cancel} icon={["far", "xmark"]} />
                                <span className="hidden">{translate(Content.common.buttons.cancel)}</span>
                            </TitleButton>
                            <TitleButton onClick={handleSubmit}>
                                <FontAwesomeIcon color={theme.secondaryColors.confirm} icon={["far", "check"]} />
                                <span className="hidden">{translate(Content.common.buttons.confirm)}</span>
                            </TitleButton>
                        </>
                    ) : (
                        <SimpleTooltip text={translate(Content.common.buttons.edit_name)} placement="right">
                            <TitleButton onClick={() => setEditingName(true)} data-testid="edit-layer-name-button">
                                <FontAwesomeIcon icon={["far", "pencil"]} />
                                <span className="hidden">{translate(Content.common.buttons.edit_name)}</span>
                            </TitleButton>
                        </SimpleTooltip>
                    )}
                </>
            )}
        </StyledTitle>
    );
});

const TitleButton = styled(StyledIconButton)`
    height: 100%;
`;

const StyledTitle = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    align-items: center;
`;
