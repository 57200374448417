/* eslint-disable no-underscore-dangle */
import { createContext, useContext } from "react";
import { interpret, Interpreter } from "xstate";
import { xstateDevTools } from "@iventis/utilities/src/environment-variables";
import { BehaviorSubject } from "rxjs";
import { useObservableValue } from "@iventis/utilities";
import { RemoteUploadEvents, remoteUploadMachine, RemoteUploadState } from "./remote-upload-machine";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoteUploadGroup = Map<string, Interpreter<RemoteUploadState, any, RemoteUploadEvents, any, any>>;

export type RemoteUploader = (context: RemoteUploadState) => (send: (event: RemoteUploadEvents) => void) => void;

export class RemoteUpload {
    private _uploads$ = new BehaviorSubject<RemoteUploadGroup>(new Map());

    get uploads$() {
        return this._uploads$;
    }

    get uploads() {
        return this.uploads$.getValue();
    }

    constructor(private uploader: RemoteUploader = defaultUploader) {}

    public upload(id: string, url: string, file: File, successCallback: (file: File) => void) {
        const machine = interpret(
            remoteUploadMachine(id)
                .withContext({
                    id,
                    url,
                    file,
                    progress: null,
                })
                .withConfig({
                    services: {
                        successCallback: async (context, event) => {
                            await successCallback(event.response);
                        },
                        uploadFile: this.uploader,
                    },
                }),
            { devTools: xstateDevTools }
        );
        machine.start();
        this.uploads.set(id, machine);
        this.uploads$.next(this.uploads);
    }

    public remove(id: string) {
        this.uploads.delete(id);
        this.uploads$.next(this.uploads);
    }
}

export const RemoteUploadContext = createContext<RemoteUpload>(null);

export const useRemoteUpload = () => {
    const context = useContext(RemoteUploadContext);
    const uploads = useObservableValue(context.uploads$, context.uploads$.value);
    return { uploads, context };
};

export const defaultUploader: RemoteUploader = (context) => (send) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
            const percentage = Math.round((event.loaded * 100) / event.total);
            send({ type: "UPDATE_PROGRESS", percentage });
        }
    });

    xhr.open("PUT", context.url, true);
    xhr.setRequestHeader("Content-Type", "application/zip");
    xhr.onload = () => {
        if (xhr.status === 200) {
            send({ type: "UPLOAD_SUCCESS", ...xhr });
        } else {
            send({ type: "UPLOAD_FAILED", request: xhr });
        }
    };

    xhr.onerror = (error) => {
        send({ type: "NETWORK_ERROR", error });
    };

    xhr.send(context.file);
};
