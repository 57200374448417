import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { Model } from "@iventis/domain-model/model/model";
import { ModelLodFile } from "@iventis/domain-model/model/modelLodFile";
import { EMPTY_GUID, isUrlBase64 } from "@iventis/utilities";
import { UploadBackgroundData } from "@iventis/components";
import { UploadModelData, UploadIconData, UploadModelImageData } from "@iventis/types/model-types";
import { AssetsPatchRequest, AssetsPostRequest, AssetsPutRequest } from "./api-helpers";
import { ModelAsset } from "./model-types";

export const convertModelDataToThumbnailAssetPostData = (data: UploadModelData): AssetsPostRequest => {
    const asset: AssetsPostRequest = {
        name: data.name,
        type: AssetType.Model,
        tags: data.categories ?? [],
        dataUrl: data.thumbnail.imageUrl,
        metaData: constructModelAssetMetadata(data),
    };
    return asset;
};

export const convertModelDataToThumbnailAssetPutData = (data: UploadModelData, existingModel: ModelAsset): AssetsPutRequest => ({
    id: existingModel.thumbnailAssetId,
    name: data.name,
    type: AssetType.Model,
    tags: data.categories ?? [],
    dataUrl: isUrlBase64(data.thumbnail.imageUrl) ? data.thumbnail.imageUrl : null,
    metaData: constructModelAssetMetadata(data),
});

export const convertModelDataToLodFileAssetPostData = (data: UploadModelData): AssetsPostRequest => {
    const asset: AssetsPostRequest = {
        name: `model lod file`,
        type: AssetType.Model,
        tags: data.categories ?? [],
        dataUrl: data.model.modelUrl,
    };
    return asset;
};

export const convertModelDataToLodFileAssetPutData = (data: UploadModelData, existingFile: ModelLodFile): AssetsPutRequest => ({
    // Only support
    id: existingFile.assetId,
    name: `model lod file`,
    type: AssetType.Model,
    tags: data.categories ?? [],
    dataUrl: data.model.modelUrl,
});

export const convertUploadIconDataToAssetPostData = (data: UploadIconData, assetType = AssetType.MapIcon): AssetsPostRequest => ({
    name: data.name,
    dataUrl: data.image.imageUrl,
    type: assetType,
    tags: data.categories,
    metaData: data.metaData,
});

export const convertUploadModelImageDataToAssetPostData = (data: UploadModelImageData): AssetsPostRequest => ({
    name: data.name,
    dataUrl: data.image.imageUrl,
    type: AssetType.ModelImage,
    tags: [],
    metaData: [],
});

export const convertUploadIconDataToAssetPatchData = (data: UploadIconData, existingAsset: Asset): AssetsPatchRequest => ({
    id: existingAsset.id,
    name: existingAsset.name !== data.name ? data.name : undefined,
    dataUrl: data.image.imageUrl.startsWith("data:") ? data.image.imageUrl : undefined,
    type: AssetType.MapIcon,
    tags: existingAsset.tags !== data.categories ? data.categories : undefined,
    metaData: existingAsset.metaData !== data.metaData ? data.metaData : undefined,
});

export const convertUploadBackgroundDataToAssetPostData = (data: UploadBackgroundData): AssetsPostRequest => ({
    name: data.name,
    dataUrl: data.json.dataUrl,
    thumbnailDataUrl: data.thumbnail.imageUrl,
    type: AssetType.MapBackground,
    tags: [],
    metaData: [],
});

export const convertUploadIconDataToAssetPutData = (data: UploadIconData, existingAsset: Asset): AssetsPutRequest => ({
    ...existingAsset,
    name: data.name,
    dataUrl: data.image.imageUrl,
    thumbnailDataUrl: data.image.imageUrl,
    type: AssetType.MapIcon,
    tags: data.categories,
    metaData: data.metaData,
});

export const convertUploadBackgroundDataToAssetPutData = (data: UploadBackgroundData, existingAsset: Asset): AssetsPutRequest => ({
    ...existingAsset,
    name: data.name,
    dataUrl: data.json.dataUrl,
    thumbnailDataUrl: data.thumbnail.imageUrl,
    type: AssetType.MapBackground,
    tags: [],
    metaData: [],
});

export const convertUploadModelDataToModelPostData = (data: UploadModelData, thumbnailAssetId: string, lodFileAssetId: string): Model => ({
    name: data.name,
    thumbnailAssetId,
    categories: data.categories,
    id: EMPTY_GUID,
    height: data.size?.height,
    width: data.size?.width,
    length: data.size?.length,
    imageAspectRatioHeight: data.aspectRatio?.height,
    imageAspectRatioWidth: data.aspectRatio?.width,
    lods: [
        {
            lod: 1,
            id: EMPTY_GUID,
            modelId: EMPTY_GUID,
            files: [{ id: EMPTY_GUID, lodId: EMPTY_GUID, type: "glb", assetId: lodFileAssetId }],
        },
    ],
});

export const convertUploadModelDataToModelPutData = (data: UploadModelData, existingModel: ModelAsset): Model => ({
    name: data.name,
    thumbnailAssetId: existingModel.thumbnailAssetId,
    categories: data.categories,
    id: existingModel.id,
    height: data.size?.height,
    width: data.size?.width,
    length: data.size?.length,
    imageAspectRatioHeight: data.aspectRatio?.height,
    imageAspectRatioWidth: data.aspectRatio?.width,
    lods: [
        {
            lod: 1,
            id: existingModel.lods[0].id,
            modelId: existingModel.lods[0].modelId,
            files: [
                {
                    id: existingModel.lods[0].files[0].id,
                    lodId: existingModel.lods[0].files[0].lodId,
                    type: "glb",
                    assetId: existingModel.lods[0].files[0].assetId,
                },
            ],
        },
    ],
});

export const convertAssetToUploadAssetData = (asset: Asset, imageUrlGetter: (url: string, signature: string) => string): UploadIconData =>
    asset == null
        ? undefined
        : {
              categories: asset.tags,
              image: { fileName: asset.name, imageUrl: imageUrlGetter(asset.assetUrl, asset.authoritySignature) },
              name: asset.name,
              metaData: asset.metaData,
          };

export const convertModelToUploadModelData = (model: ModelAsset, modelLodFileAsset: Asset, imageUrlGetter: (url: string, signature: string) => string): UploadModelData => {
    const { height, width, length } = model;
    return model == null
        ? undefined
        : {
              categories: model.categories ?? model.thumbnail.tags ?? [],
              model: { fileName: model.name, modelUrl: imageUrlGetter(modelLodFileAsset.assetUrl, modelLodFileAsset.authoritySignature) },
              thumbnail: { fileName: model.thumbnail.name, imageUrl: imageUrlGetter(model.thumbnail.assetUrl, model.thumbnail.authoritySignature) },
              name: model.name,
              variableSizeModel: height != null && width != null && length != null,
              size: { height, width, length },
              customImage: model.thumbnail.metaData?.customImage ?? false,
              aspectRatio: { width: model.imageAspectRatioWidth, height: model.imageAspectRatioHeight },
              customColour: model.thumbnail.metaData?.customColour ?? false,
          };
};

// eslint-disable-next-line no-unused-vars
export const convertAssetToUploadBackgroundData = (asset: Asset): UploadBackgroundData =>
    asset == null
        ? undefined
        : {
              json: { fileName: asset.name, dataUrl: "" },
              thumbnail: { fileName: asset.name, imageUrl: asset.thumbnailUrl },
              name: asset.name,
          };

// Should return null if no properties apply, or an object with customImage and variableSizeModel properties
export const constructModelAssetMetadata = (model: Partial<UploadModelData>, original?: UploadModelData): Partial<UploadModelData> => {
    const metadata: Partial<UploadModelData> = {};

    if (model.customImage != null) {
        metadata.customImage = model.customImage;
    } else if (original?.customImage != null) {
        metadata.customImage = original.customImage;
    }

    if (model.customColour != null) {
        metadata.customColour = model.customColour;
    }

    if (model.variableSizeModel != null) {
        metadata.variableSizeModel = model.variableSizeModel;
    } else if (original?.variableSizeModel != null) {
        metadata.variableSizeModel = original.variableSizeModel;
    }

    return Object.keys(metadata).length > 0 ? metadata : undefined;
};
