/* eslint-disable camelcase */
export const table = {
    timeValidationError: "Minute should be 00 to 59",
    startEndTimeValidationError: "End time should be after start time",
    Eq: "Equals",
    Gt: "Greater than",
    Gte: "Greater than or equal to",
    Lt: "Less than",
    Lte: "Less than or equal to",
    Ct: "Contains",
    deleteRow: "Delete row",
    addRow: "Add new row",
    addColumn: "Add column",
    deleteAll: "Delete all",
    uploadData: "Upload data",
    deleteExisting: "Delete existing items",
    keepExisting: "Keep existing items",
    downloadData: "Download data",
    schedule: {
        day: "Day",
        date: "Date",
        start: "Start",
        end: "End",
        duration: "Duration (minutes)",
        venue: "Venue",
        space: "Space",
        type: "Type",
        Discipline: "Discipline",
        zero: "Zero",
        description: "Description",
    },
    toasts: {
        edit_success: "Successfully edited cell",
        edit_error: "Error while editing cell, consider refreshing cells",
        insert_success: "Successfully added {{ type }}",
        insert_error: "Error while inserting row, consider refreshing cells",
        delete_success: "Successfully deleted row entity",
        delete_error: "Error while deleting row, consider refreshing cells",
        refresh_cells: "Refresh cells",
    },
    dataTable: {
        filter: {
            equals: "Equals",
            greaterThan: "Greater than",
            greaterThanOrEqualTo: "Greater than or equal to",
            lessThan: "Less than",
            lessThanOrEqualTo: "Less than or equal to",
            contains: "Contains",
            select_all: "Select all",
        },
    },
    type_something: "Type {{ name }}",
    group: "Group",
    count_column_header: "(Count)",
    none_group: "None",
    "Export selected rows only": "Export selected rows only",
    "Export all": "Export all",
    from: "From",
    to: "To",
} as const;
