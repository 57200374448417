import { FormWizardTemplate, FormWizardTitle } from "@iventis/components";
import { FormLabel, formGap, inputHeight, muiInputFormsCSS, sectionalMargin, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { MenuItem, TextField } from "@mui/material";
import React, { FunctionComponent, useState } from "react";

import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItemProperty } from "@iventis/domain-model/model/dataFieldListItemProperty";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { DataFieldListItemRelationship } from "@iventis/domain-model/model/dataFieldListItemRelationship";
import { useQuery } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { ListItemPropertyTypeSelector } from "./list-item-editor-components";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";

/**
 * Form that will add a property to the list item properties of the given attribute
 */
enum ColumnType {
    Property,
    Relationship,
}

export const EditListItemPropertyForm: FunctionComponent<{ attribute: DataField; onConfirm: (attribute: DataField) => void; onClose: () => void }> = ({
    attribute,
    onConfirm,
    onClose,
}) => {
    const translate = useIventisTranslate();

    const { dataFieldsService } = useDataFieldServices();
    const { resourceId } = useDataFieldConfig();

    const [columnType, setColumnType] = useState<ColumnType>(ColumnType.Property);
    const [relatedDataFieldId, setRelatedDataFieldId] = useState<string>(null);
    const [propertyName, setPropertyName] = useState<{ value: string; valid: boolean }>({ value: "", valid: false });
    const [propertyType, setPropertyType] = useState<DataFieldListItemPropertyType>(DataFieldListItemPropertyType.Text);
    const [status, setStatus] = useState<"unsaved" | "saving" | "saved">("unsaved");

    const { data: resourceDataFields } = useQuery(["data-fields", resourceId], async () => dataFieldsService.getDataFieldsForResource(resourceId), {
        refetchOnWindowFocus: false,
        enabled: resourceId != null,
    });

    const save = async () => {
        setStatus("saving");
        if (columnType === ColumnType.Property) {
            const newListItemProperty: DataFieldListItemProperty = {
                name: propertyName.value,
                type: propertyType,
                id: uuid(),
                dataFieldId: attribute.id,
            };
            const newProps = [...(attribute.listItemProperties ?? []), newListItemProperty];
            await dataFieldsService.putDataField({ ...attribute, id: attribute.id, listItemProperties: newProps }, resourceId);
            onConfirm({ ...attribute, listItemProperties: newProps });
        }
        if (columnType === ColumnType.Relationship) {
            const newRelationship: DataFieldListItemRelationship = {
                id: uuid(),
                dataFieldId: attribute.id,
                relatedToDataFieldId: relatedDataFieldId,
            };
            const dataFieldRelationships = [...(attribute.listItemRelationships ?? []), newRelationship];
            await dataFieldsService.putDataField({ ...attribute, id: attribute.id, listItemRelationships: dataFieldRelationships }, resourceId);
            onConfirm({ ...attribute, listItemRelationships: dataFieldRelationships });
        }
    };

    const handlePropertyNameChange = (updatedName: string) => {
        // Don't allow for property names which are an empty string or already exist
        const propertyNameExistsAlready = attribute.listItemProperties?.some((property) => property.name.toLowerCase() === updatedName.toLowerCase());
        const isValid = updatedName !== "" && !propertyNameExistsAlready;
        setPropertyName({ valid: isValid, value: updatedName });
    };

    return (
        <FormWizardTemplate
            currentStage={0}
            title={<FormWizardTitle header={translate(Content.map6.add_list_item_attribute.subtitle)} title={translate(Content.map6.add_list_item_attribute.title)} />}
            stages={[
                {
                    primaryButtonText: translate(Content.common.buttons.save),
                    primaryButtonCallback: save,
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.back), onButtonPressed: onClose, dataCy: "close-attribute-editor" }],
                    isValid: (propertyName.valid && columnType === ColumnType.Property) || (ColumnType.Relationship && relatedDataFieldId != null),
                    isSubmitted: status === "saved",
                    showLoadingSpinner: status === "saving",
                    submittingText: translate(Content.common.saving),
                    submittedText: translate(Content.common.saved),
                    submitButtonDataCy: "attribute-editor-submit",
                },
            ]}
        >
            <FormContainer>
                <FormItemContainer>
                    <FormLabel className="input-label space-within-content">{translate(Content.map6.add_list_item_attribute.column_type)}</FormLabel>
                    <TextField
                        value={columnType}
                        onChange={(e) => setColumnType((e.target.value as unknown) as ColumnType)}
                        select // tell TextField to render select
                        data-testid="list-item-column-type"
                    >
                        <MenuItem data-testid="list-item-property-option-property" key={1} value={ColumnType.Property}>
                            Property
                        </MenuItem>
                        <MenuItem data-testid="list-item-relationship-option-relationship" key={2} value={ColumnType.Relationship}>
                            Relationship
                        </MenuItem>
                    </TextField>

                    {columnType === ColumnType.Relationship && (
                        <>
                            <FormLabel className="input-label space-within-content gap-above">Data field</FormLabel>
                            <TextField
                                value={relatedDataFieldId}
                                onChange={(e) => setRelatedDataFieldId(e.target.value)}
                                select // tell TextField to render select
                                data-testid="attribute-relationship-select"
                            >
                                {resourceDataFields != null &&
                                    resourceDataFields
                                        .filter(
                                            (d) =>
                                                d.id !== attribute.id &&
                                                d.type === DataFieldType.List &&
                                                !attribute?.listItemRelationships?.find((r) => r.relatedToDataFieldId === d.id)
                                        )
                                        .map((d) => (
                                            <MenuItem key={d.id} value={d.id} data-testid={`attribute-${d.name}`}>
                                                {d.name}
                                            </MenuItem>
                                        ))}
                            </TextField>
                        </>
                    )}

                    {columnType === ColumnType.Property && (
                        <>
                            <FormLabel className="input-label space-within-content gap-above">{translate(Content.map6.add_list_item_attribute.column_name)}</FormLabel>
                            <TextField
                                value={propertyName.value}
                                onChange={(event) => handlePropertyNameChange(event.target.value)}
                                data-testid="list-item-property-name-textfield"
                            />
                            <ListItemPropertyTypeSelector value={propertyType} onChange={(value) => setPropertyType(value)} />
                        </>
                    )}
                </FormItemContainer>
            </FormContainer>
        </FormWizardTemplate>
    );
};

export const FormContainer = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    flex-direction: column;
    gap: ${sectionalMargin};
    margin-top: ${sectionalMargin};
    .MuiOutlinedInput-input {
        line-height: ${inputHeight};
    }
    .gap-above {
        margin-top: ${formGap};
    }
`;

export const FormItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
