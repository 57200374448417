import type { Map, LineLayerSpecification, ModelLayerSpecification, LayerSpecification } from "@iventis/mapbox-gl";
import type { Feature, Geometry } from "geojson";
import { createMapboxLevelFilter } from "../mapbox/filters/engine-mapbox-level-filter-helpers";
import { removeLayerFilterByType, updateLayerFilters } from "../mapbox/filters/engine-mapbox-filter-helpers";
import { createDateFilterExpressionForLocalLayer } from "../mapbox/filters/engine-mapbox-date-filter-helpers";
import { FilterType } from "../mapbox/filters/engine-mapbox-filter-types";

type SupportedMapboxLayers = LineLayerSpecification | ModelLayerSpecification;

/** Basic Mapbox layer which adds functionality which most Mapbox layers will need */
export class MapboxLayer<TSource extends Feature<Geometry>[], TLayer extends SupportedMapboxLayers> {
    private readonly map: Map;

    private readonly layerId: string;

    private readonly sourceId: string;

    constructor(map: Map, layerId: string, sourceId: string) {
        this.map = map;
        this.layerId = layerId;
        this.sourceId = sourceId;
    }

    public addLayer(mapboxLayer: TLayer, aboveLayerId?: string) {
        if (!this.map.getLayer(mapboxLayer.id)) {
            // Ensure the layer has the source
            if (!this.map.getSource(mapboxLayer.source)) {
                this.addSource();
            }

            this.map.addLayer(mapboxLayer, aboveLayerId);
        }
    }

    public removeLayer() {
        this.map.removeLayer(this.layerId);
    }

    public addSource(): void {
        if (!this.map.getSource(this.sourceId)) {
            this.map.addSource(this.sourceId, { type: "geojson", data: { type: "FeatureCollection", features: [] } });
        }
    }

    public updateSource(source: TSource) {
        let layerSource = this.map.getSource(this.sourceId);

        if (layerSource == null) {
            this.addSource();
            layerSource = this.map.getSource(this.sourceId);
        }

        if (layerSource != null && layerSource.type === "geojson") {
            layerSource.setData({ type: "FeatureCollection", features: source });
        }
    }

    public removeSource(): void {
        this.map.removeSource(this.sourceId);
    }

    public hideLayer(): void {
        this.map.setLayoutProperty(this.layerId, "visibility", "none");
    }

    public showLayer(): void {
        this.map.setLayoutProperty(this.layerId, "visibility", "visible");
    }

    public updateMapOrder(aboveLayerId: string): void {
        this.map.moveLayer(this.layerId, aboveLayerId);
    }

    public updateMapLevel(level: number) {
        const levelFilter = createMapboxLevelFilter(level);
        this.updateLayerFilter(levelFilter);
    }

    public addDateFilter() {
        const dateFilter = createDateFilterExpressionForLocalLayer();
        this.updateLayerFilter(dateFilter);
    }

    public removeDateFilter() {
        this.removeLayerFilter(FilterType.Date);
    }

    private updateLayerFilter(newFilter: LayerSpecification["filter"]) {
        const currentFilter = this.map.getFilter(this.layerId);
        const updatedFilter = updateLayerFilters(currentFilter, newFilter);
        this.map.setFilter(this.layerId, updatedFilter);
    }

    private removeLayerFilter(filterToRemove: FilterType) {
        const currentFilter = this.map.getFilter(this.layerId);
        const updatedFilter = removeLayerFilterByType(currentFilter, filterToRemove);
        this.map.setFilter(this.layerId, updatedFilter);
    }
}
