import { getDataFieldListItemPropertyForModelDimension } from "@iventis/datafields/src/datafield-property-based-styling";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { createMappedPropertyStyleValue, createMappedStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { ModelLayerStyle } from "@iventis/map-engine/src/types/models";
import { ModelWithThumbnail } from "./model-edit-style";

/** Gets all the list items currently in use in the width, height and length style properties' mapped values */
export const getListItemsUsedInStylesDimensions = (style: ModelLayerStyle) =>
    Object.keys({ ...style.height.mappedValues, ...style.width.mappedValues, ...style.length.mappedValues });

export type ListItemToModelDimension = {
    [listItemId: string]: ModelDimensions;
};

export type ModelDimensions = { thumbnailUrl?: string; modelName?: string; modelId?: string; dimensions?: boolean; height: number; length: number; width: number };

export const STATIC_LIST_ITEM_KEY = "static";

const getModelDimension = (styleValue: StyleValue<number>, listItemId: string) => styleValue?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(styleValue);

const getModelDimensionFromProperty = (styleValue: StyleValue<number>, listItem: DataFieldListItem, fallbackValue: number) => {
    const propertyValue = listItem.propertyValues.find((value) => value.propertyId === styleValue.dataFieldListItemPropertyId)?.number;
    // If property value can't be found use fallback (in this case is the model dimension)
    if (propertyValue == null) {
        return fallbackValue;
    }
    return propertyValue;
};

/** Converts a datafield and style values to a map where the list item id is the key and the model dimensions are the value */
export const convertListItemsToModelDimensions = (
    style: ModelLayerStyle,
    modelsWithThumbnails: ModelWithThumbnail[],
    attributeBasedModel: boolean,
    propertyBasedStyling: boolean,
    listItems: DataFieldListItem[]
) => {
    const map: ListItemToModelDimension = {};

    listItems.forEach((listItem) => {
        const listItemId = listItem.id;
        // Get which model is being used, so we can use it for fallback value if property based styling is used and the property value is null
        const modelId = style.model?.mappedValues?.[listItemId]?.staticValue ?? getStaticStyleValueFromMapped(style.model);
        const model = modelsWithThumbnails.find((model) => model.id === modelId);
        const height = propertyBasedStyling ? getModelDimensionFromProperty(style.height, listItem, model?.height) : getModelDimension(style.height, listItemId);
        const width = propertyBasedStyling ? getModelDimensionFromProperty(style.width, listItem, model?.width) : getModelDimension(style.width, listItemId);
        const length = propertyBasedStyling ? getModelDimensionFromProperty(style.length, listItem, model?.length) : getModelDimension(style.length, listItemId);

        // If we model is not attribute based then we only using attribute for dimensions
        if (!attributeBasedModel) {
            map[listItemId] = {
                height,
                width,
                length,
                modelId,
            };
        } else {
            map[listItemId] = {
                height,
                width,
                length,
                modelId: model.id,
                modelName: model.name,
                thumbnailUrl: model.thumbnailUrl,
                dimensions: !(model?.height == null || model?.height === 0),
            };
        }
    });
    map.static = getStaticModelValues(style, modelsWithThumbnails, attributeBasedModel);
    return map;
};

export const getStaticModelValues = (style: ModelLayerStyle, modelsWithThumbnails: ModelWithThumbnail[], attributeBasedModel: boolean): ModelDimensions => {
    const height = getStaticStyleValueFromMapped(style.height);
    const width = getStaticStyleValueFromMapped(style.width);
    const length = getStaticStyleValueFromMapped(style.length);
    // If we model is not attribute based then we only using attribute for dimensions
    if (!attributeBasedModel) {
        return {
            height,
            length,
            width,
        };
    }

    const modelId = getStaticStyleValueFromMapped(style.model);
    const model = modelsWithThumbnails.find((model) => model.id === modelId);
    return {
        height,
        width,
        length,
        modelId: model.id,
        modelName: model.name,
        thumbnailUrl: model.thumbnailUrl,
        dimensions: !(model?.height == null || model?.height === 0),
    };
};

/** Converts a map of list items and dimensions to StyleValues */
export const convertModelDimensionsToStyleValues = (
    dataFieldIdToDimensionMap: ListItemToModelDimension,
    selectedDataField: DataField,
    attributeBasedModel: boolean,
    propertyBasedStyling: boolean
): { height: StyleValue<number>; length: StyleValue<number>; width: StyleValue<number>; model?: StyleValue<string> } => {
    if (!propertyBasedStyling) {
        const height: { [listItemId: string]: number } = {};
        const width: { [listItemId: string]: number } = {};
        const length: { [listItemId: string]: number } = {};
        const model: { [listItemId: string]: string } = {};
        Object.entries(dataFieldIdToDimensionMap).forEach(([listItemId, value]) => {
            if (listItemId !== STATIC_LIST_ITEM_KEY) {
                const defaultValues = dataFieldIdToDimensionMap.static;
                const isDifferent = Object.entries(value).some(([key]) => defaultValues[key] !== value[key]);
                // If the value is different to the default value, we can add a mapped value for the list item
                if (isDifferent) {
                    height[listItemId] = value.height;
                    width[listItemId] = value.width;
                    length[listItemId] = value.length;
                    model[listItemId] = value?.modelId;
                }
            }
        });
        return {
            height: createMappedStyleValue(dataFieldIdToDimensionMap.static.height, height, selectedDataField.id),
            length: createMappedStyleValue(dataFieldIdToDimensionMap.static.length, length, selectedDataField.id),
            width: createMappedStyleValue(dataFieldIdToDimensionMap.static.width, width, selectedDataField.id),
            model: attributeBasedModel ? createMappedStyleValue(dataFieldIdToDimensionMap.static.modelId, model, selectedDataField.id) : null,
        };
    }

    const heightProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "height");
    const lengthProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "length");
    const widthProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "width");

    // Attribute based model
    const model: { [listItemId: string]: string } = {};
    if (attributeBasedModel) {
        Object.entries(dataFieldIdToDimensionMap).forEach(([listItemId, value]) => {
            if (listItemId !== STATIC_LIST_ITEM_KEY) {
                model[listItemId] = value?.modelId;
            }
        });
    }

    // Sets what list items are being style by a property
    const listItemIds = Object.keys(dataFieldIdToDimensionMap).filter((key) => key !== STATIC_LIST_ITEM_KEY);

    return {
        height: createMappedPropertyStyleValue(dataFieldIdToDimensionMap.static?.height ?? 1, selectedDataField.id, heightProperty.id, listItemIds),
        length: createMappedPropertyStyleValue(dataFieldIdToDimensionMap.static?.length ?? 1, selectedDataField.id, lengthProperty.id, listItemIds),
        width: createMappedPropertyStyleValue(dataFieldIdToDimensionMap.static?.width ?? 1, selectedDataField.id, widthProperty.id, listItemIds),
        model: attributeBasedModel ? createMappedStyleValue(dataFieldIdToDimensionMap.static.modelId, model, selectedDataField.id) : null,
    };
};
