import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { NODE_HEIGHT_PX, NodeComponent, getIndentPx, useTreeBrowser, LibraryOverflowPopover } from "@iventis/tree-browser";
import { format } from "date-fns";
import { styled, Body2, Header5, InteractiveElement, iconButtonSize, inlineTextIconMargin, skeletonShimmer, StyledIconButton } from "@iventis/styles";
import { useTheme } from "@mui/material";

import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Status } from "@iventis/domain-model/model/status";
import { ConfirmationDialogComponent } from "@iventis/components/src/confirmation-dialog";
import { StatusIcon } from "@iventis/components/src/thumbnails/status";
import { SiteMapNodeTypes, SitemapNode, SitemapTreeBrowserContext, SitemapVersionLevelNode, SitemapVersionNode } from "./sitemap-types";
import { TagComponent } from "./tag";
import { SitemapFormWizard, SitemapModal, SitemapFormRef } from "./sitemap-wizard";
import { convertSitemapsToSitemapNodes } from "./sitemap-helpers";

const SitemapNodeComponent: NodeComponent<SitemapNode, undefined> = ({ node }) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const [{ expandedNodeIds, isLoadingTree }, treeBrowserContext] = useTreeBrowser(SitemapTreeBrowserContext, node.id, "expandedNodeIds", "isLoadingTree", "tree");

    const expanded = useMemo(() => expandedNodeIds.includes(node.id), [expandedNodeIds]);

    const overflowButtonRef = useRef<HTMLDivElement>();
    const sitemapFormWizardRef = useRef<SitemapFormRef>();
    const [overflowOpen, setOverflowOpen] = useState(false);
    const [modal, setModal] = useState<"edit-sitemap" | "delete-sitemap" | null>(null);

    const closeSitemapEditor = () => {
        if (sitemapFormWizardRef.current.isUploadingTiles()) {
            return;
        }
        const sitemapVersions = sitemapFormWizardRef.current.getSitemapVersions();
        const latestSitemapNode = convertSitemapsToSitemapNodes([{ ...node.sitemap, versions: sitemapVersions }], node.parentId)[0];
        treeBrowserContext.updateNodeLocally(latestSitemapNode, undefined, true, true);
        setModal(null);
    };

    return (
        <SitemapNodeComponentContainer className="sitemap-node">
            <ColumnSection>
                <InteractiveElement
                    onClick={(e) => {
                        e.stopPropagation();
                        treeBrowserContext.toggleExpandNode(node);
                    }}
                    style={{ width: iconButtonSize, height: iconButtonSize, color: theme.shades.two }}
                >
                    <FontAwesomeIcon icon={isLoadingTree ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="lg" spin={isLoadingTree} />
                </InteractiveElement>
                <TagComponent tag={node.sitemap.tag} backgroundColour={node.sitemap.colour} />
                <Header5>{node.name}</Header5>
            </ColumnSection>
            {(window.location.hostname.includes("admin-dashboard") || window.location.hostname.includes("localhost")) && (
                <StyledIconButton onClick={() => setOverflowOpen(true)}>
                    <span ref={overflowButtonRef}>
                        <FontAwesomeIcon size="xs" icon="ellipsis" />
                    </span>
                    <span className="hidden">{translate(Content.settings3.sitemaps.click_to_open_sitemap_options)}</span>
                </StyledIconButton>
            )}
            <LibraryOverflowPopover
                node={node}
                overflowButtonRef={overflowButtonRef}
                overflowOpen={overflowOpen}
                setOverflowOpen={setOverflowOpen}
                overflowOptions={[
                    { label: translate(Content.settings3.sitemaps.edit_sitemap), selected: () => setModal("edit-sitemap") },
                    { label: translate(Content.settings3.sitemaps.delete_sitemap), selected: () => setModal("delete-sitemap") },
                ]}
            />
            <SitemapModal open={modal === "edit-sitemap"} onClose={closeSitemapEditor}>
                <SitemapFormWizard
                    ref={sitemapFormWizardRef}
                    sitemap={node.sitemap}
                    saveAndClose={(s) => {
                        treeBrowserContext.updateNodeLocally(convertSitemapsToSitemapNodes([s], node.parentId)[0], undefined, true, true);
                        setModal(null);
                    }}
                    close={closeSitemapEditor}
                />
            </SitemapModal>
            <ConfirmationDialogComponent
                show={modal === "delete-sitemap"}
                handleConfirm={() => {
                    treeBrowserContext.deleteNodes([node]);
                    setModal(null);
                }}
                handleCancel={() => setModal(null)}
                title={translate(Content.settings3.sitemaps.delete_sitemap)}
                confirmText={translate(Content.common.buttons.confirm)}
                cancelText={translate(Content.common.buttons.cancel)}
                message={translate(Content.common.delete_confirmation_message, { entity: node.name })}
            />
        </SitemapNodeComponentContainer>
    );
};

const VersionNodeComponent: NodeComponent<SitemapVersionNode, undefined> = ({ node }) => {
    const [{ expandedNodeIds, isLoadingTree }, treeBrowserContext] = useTreeBrowser(SitemapTreeBrowserContext, node.id, "expandedNodeIds", "isLoadingTree", "tree");
    const expanded = useMemo(() => expandedNodeIds.includes(node.id), [expandedNodeIds]);
    const theme = useTheme<Theme>();
    return (
        <NodeComponentContainer style={{ paddingLeft: `${getIndentPx(1)}px` }} className="version-node">
            <ColumnSection>
                <InteractiveElement
                    data-cy={`expand-node-id-${node.id}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        treeBrowserContext.toggleExpandNode(node);
                    }}
                    style={{ width: iconButtonSize, height: iconButtonSize, color: theme.shades.two }}
                >
                    <FontAwesomeIcon icon={isLoadingTree ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="lg" spin={isLoadingTree} />
                </InteractiveElement>
                <Body2>{node.name}</Body2>
            </ColumnSection>
            <ColumnSection>
                <Body2>{format(node.version.date, "d MMMM yyyy")}</Body2>
            </ColumnSection>
        </NodeComponentContainer>
    );
};

const LevelNodeComponent: NodeComponent<SitemapVersionLevelNode, undefined> = ({ node }) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();
    return (
        <NodeComponentContainer style={{ paddingLeft: `${getIndentPx(1)}px` }} className="level-node">
            <ColumnSection>
                <span style={{ width: iconButtonSize, height: iconButtonSize }} />
                <Body2>{`${node.name} (${node.level.levelAbbreviation})`}</Body2>
            </ColumnSection>
            <StatusIcon
                height={25}
                width={65}
                fill={
                    node.level.status === Status.Pending ? theme.toastColours.info : node.level.status === Status.Active ? theme.secondaryColors.accept : theme.toastColours.error
                }
                label={translate(Content.sitemaps.sitemapVersion[node.level.status])}
            />
        </NodeComponentContainer>
    );
};

export const SkeletonNode: FunctionComponent<{ index: number; total: number }> = ({ index, total }) => (
    <SkeletonNodeContainer style={{ opacity: 1 - index / total }}>
        <div style={{ width: "15em", height: "1.5em", marginLeft: "20px" }}>
            <SkeletonPlaceholder />
        </div>
    </SkeletonNodeContainer>
);

const ColumnSection = styled.div`
    flex: 1 1;
    display: flex;
    align-items: center;
`;

const SkeletonNodeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${inlineTextIconMargin};
    border-top: solid 1px ${({ theme }: { theme: Theme }) => theme.shades.lightBorder};
    height: ${NODE_HEIGHT_PX}px;
    margin-left: 20px;
`;

const SkeletonPlaceholder = styled.span`
    display: block;
    ${skeletonShimmer({ sequence: 0 })}
    transform: scale(1, 0.9);
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const SitemapNodeComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    height: ${NODE_HEIGHT_PX}px;
`;

const NodeComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px ${({ theme }: { theme: Theme }) => theme.shades.lightBorder};
    height: ${NODE_HEIGHT_PX}px;
    margin-left: 20px;
    padding-right: 20px;
`;

export const sitemapNodeComponents = {
    [SiteMapNodeTypes.Level]: LevelNodeComponent,
    [SiteMapNodeTypes.Version]: VersionNodeComponent,
    [SiteMapNodeTypes.Sitemap]: SitemapNodeComponent,
    [SiteMapNodeTypes.Root]: undefined,
};
