import React, { useMemo, FunctionComponent, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTreeBrowser } from "@iventis/tree-browser/state/tree-browser-hooks";
import { findNode, findAllParentIds } from "@iventis/tree-browser/tree-operations";
import { StyledExpansionContainer } from "@iventis/tree-browser/implementations/library/styles/library-list-node.styles";
import { BaseNode } from "@iventis/tree-browser/implementations/admin/components/base-node";
import { Body2, iconButtonSize, InteractiveElement, styled } from "@iventis/styles";
import { LibraryOverflowPopover, NODE_HEIGHT_PX } from "@iventis/tree-browser";
import { AddNewPeopleComponent, createPersonNode } from "@iventis/people";

import { ExtractRefFromComponent } from "@iventis/types/useful.types";
import { toast } from "@iventis/toasts/src/toast";
import { ProjectUser } from "@iventis/domain-model/model/projectUser";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { CustomDialog, ProjectStatusIcon } from "@iventis/components";
import { useQuery } from "@tanstack/react-query";
import { SsoConfig } from "@iventis/domain-model/model/ssoConfig";
import { useNavigate } from "react-router";
import { AdminProjectNode } from "./tree-browser-types";
import { getProjectSsoConfig, postProjectUsers, syncAssetsOnProject, updateProjectSubscription } from "./project-api-functions";
import { ProjectModalComponent } from "./project-modal";
import { ProjectCommittedUntil } from "./project-committed-until";
import { TreeBrowserProjectsContext } from "./project.helpers";

export interface ProjectNodeProps {
    node: AdminProjectNode;
}

enum ProjectNodeModal {
    People,
    Edit,
}

type AddNewPeopleRef = ExtractRefFromComponent<typeof AddNewPeopleComponent>;

export const ProjectNode: FunctionComponent = ({ node }: ProjectNodeProps) => {
    const nav = useNavigate();
    const [{ tree, expandedNodeIds, loadingNodeIds, mainNodeId }, treeBrowserContext] = useTreeBrowser(
        TreeBrowserProjectsContext,
        node.id,
        "tree",
        "loadingNodeIds",
        "expandedNodeIds",
        "mainNodeId"
    );

    const loading = loadingNodeIds.includes(node.id);
    const indentLevel = useMemo(() => findAllParentIds(findNode([tree], mainNodeId), node.id).length - 2, [tree, mainNodeId]);
    const expanded = expandedNodeIds.includes(node.id);

    const addNewPeopleRef = useRef<AddNewPeopleRef>(null);

    const overflowButtonRef = useRef(null);

    const [overFlowOpen, setOverFlowOpen] = useState(false);

    const [modal, setModal] = useState<ProjectNodeModal | null>(null);

    const { data: ssoConfig } = useQuery<SsoConfig>(["sso-config", node.id], () => getProjectSsoConfig(tree.id, node.id), { enabled: modal === ProjectNodeModal.People });

    return (
        <StyledBaseNode className="project-node" node={node} treeContext={TreeBrowserProjectsContext}>
            <>
                {/* Makes sure we have consistent indent level */}
                <StyledExpansionContainer indentLevel={indentLevel}>
                    <InteractiveElement
                        onClick={(e) => {
                            e.stopPropagation();
                            treeBrowserContext.toggleExpandNode(node);
                        }}
                    >
                        <FontAwesomeIcon icon={loading ? "circle-notch" : expanded ? "caret-down" : "caret-right"} size="lg" spin={loading} />
                    </InteractiveElement>
                </StyledExpansionContainer>
                <FontAwesomeIcon icon={{ prefix: "far", iconName: "chart-network" }} className="icon" size="lg" />
                <Body2 className="name">{node.name}</Body2>
                <div className="project-info">
                    <ProjectStatusIcon projectStatus={node.status} />
                    <ProjectCommittedUntil committedUntil={node.projectSubscription?.committedUntil} />
                </div>
                <InteractiveElement ref={overflowButtonRef} className="ellipsis" onClick={() => setOverFlowOpen((isOpen) => !isOpen)}>
                    <span className="hidden">Click to open options for {node.name}</span>
                    <FontAwesomeIcon icon={["fas", "ellipsis"]} />
                </InteractiveElement>
                <LibraryOverflowPopover
                    overflowButtonRef={overflowButtonRef}
                    overflowOpen={overFlowOpen}
                    setOverflowOpen={setOverFlowOpen}
                    overflowOptions={[
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon={{ prefix: "far", iconName: "pencil" }} style={{ ...iconLabelStyle }} />
                                    <span>Edit</span>
                                </>
                            ),
                            selected: () => setModal(ProjectNodeModal.Edit),
                            key: "edit",
                        },
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon={{ prefix: "far", iconName: "user-plus" }} style={{ ...iconLabelStyle }} />
                                    <span>Add people</span>
                                </>
                            ),
                            selected: () => setModal(ProjectNodeModal.People),
                            key: "add-people",
                        },
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon={{ prefix: "far", iconName: "arrows-rotate" }} style={{ ...iconLabelStyle }} />
                                    <span>Sync assets</span>
                                </>
                            ),
                            selected: syncAssetsOnProject,
                            key: "sync",
                        },
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon="map" style={{ ...iconLabelStyle }} />
                                    <span>Sitemaps</span>
                                </>
                            ),
                            selected: () =>
                                nav({
                                    pathname: "/resources/sitemaps",
                                    search: new URLSearchParams({ projectId: node.id, projectName: node.name, instanceName: node.parentId }).toString(),
                                }),
                            key: "sitemaps",
                        },
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon="mountains" style={{ ...iconLabelStyle }} />
                                    <span>Terrains</span>
                                </>
                            ),
                            selected: () =>
                                nav({
                                    pathname: "/resources/terrains",
                                    search: new URLSearchParams({ projectId: node.id, projectName: node.name, instanceName: node.parentId }).toString(),
                                }),
                            key: "terrains",
                        },
                    ]}
                    node={node}
                    withIcons
                />
                <CustomDialog
                    open={modal === ProjectNodeModal.People}
                    onClose={() => {
                        addNewPeopleRef.current.cancel();
                        setModal(null);
                    }}
                    maxWidth="lg"
                >
                    <AddNewPeopleComponent
                        onClose={() => setModal(null)}
                        project={node.project}
                        /** Teams not supported yet in admin dashboard */
                        getTeams={async () => Promise.resolve([])}
                        toast={toast}
                        postNewUserAndGroups={async (req) => {
                            const res = await postProjectUsers(tree.id, node.id, req);
                            if (res) {
                                treeBrowserContext.addNodesLocally(res.map((user) => createPersonNode<ProjectUser, never>(user, node.id)));
                            }
                            return res;
                        }}
                        ref={addNewPeopleRef}
                        specifyProjectAdminStatus
                        specifyUserSso={ssoConfig?.enabled}
                    />
                </CustomDialog>
                <ProjectModalComponent
                    open={modal === ProjectNodeModal.Edit}
                    subtitle={node.project.name}
                    project={node.project}
                    onClose={() => setModal(null)}
                    onConfirm={async (_name, _apps, _billing, plan, price, maxUsers, unlimitedUsers, _, committedUntil) => {
                        setModal(null);
                        const updateSubscription: ProjectSubscription = {
                            subscriptionPlanPriceId: price.id,
                            subscriptionPlanId: plan.id,
                            id: node.project.projectSubscriptionId,
                            billingProvider: undefined,
                            status: undefined,
                            projectId: undefined,
                            maximumUsers: unlimitedUsers ? null : maxUsers,
                            committedUntil,
                            cancelAtCommittedUntil: false,
                        };
                        await updateProjectSubscription(tree.id, node.id, updateSubscription);
                        treeBrowserContext.updateNodeLocally({ ...node, projectSubscription: updateSubscription });
                    }}
                    instanceName={tree.id}
                />
            </>
        </StyledBaseNode>
    );
};

export const StyledBaseNode = styled(BaseNode)`
    display: grid;
    grid-template-columns: auto auto auto 1fr 1fr auto;
    grid-template-rows: ${`${NODE_HEIGHT_PX}px`};
    row-gap: 0;
    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ellipsis {
        height: 100%;
        width: ${iconButtonSize};
    }

    .project-info {
        display: flex;
        gap: 30px;
        justify-items: flex-start;
        align-items: center;
    }
`;

const iconLabelStyle = { width: "20px" };
