/* eslint-disable @typescript-eslint/no-empty-function */
import { Body2, Header4, Header5, inlineTextIconMargin, sectionalMargin, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { DataField } from "@iventis/domain-model/model/dataField";
import { useLocalDataWithMutations } from "@iventis/utilities";
import { Divider } from "@mui/material";
import React, { Fragment, FunctionComponent, useMemo } from "react";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { Toasts } from "@iventis/toasts/src/toast.types";
import { FlatListBrowser } from "@iventis/components";
import { ChipsErrorBoundary, DataFieldsChips, getProjectDataFieldsQueryKey } from "./data-fields-chips";
import ProjectAttributeRow, { ProjectAttributeRowSkeleton } from "./project-attribute-row";
import { DataFieldConfigContext, DataFieldServicesContext, IDataFieldListItemService, IDataFieldServices } from "./data-fields-services";

interface ProjectAttributeProps {
    projectAttributeApiFunctions: {
        get: () => Promise<DataField[]>;
        post?: (datafield: DataField) => Promise<DataField>;
        patch?: (dataField: Partial<DataField>) => Promise<DataField>;
        put?: (dataField: DataField) => Promise<DataField>;
        delete?: (dataFieldId: string) => Promise<void>;
        isDataFieldBeingUsed?: (dataFieldId: string, resourceId: string) => Promise<boolean>;
    };
    listItemService?: IDataFieldListItemService;
    navigateToProjectSettings?: () => void;
    projectId?: string;
    toast: Toasts;
}

export const ProjectAttributes: FunctionComponent<ProjectAttributeProps> = ({ projectAttributeApiFunctions, listItemService, projectId, toast }) => {
    const translate = useIventisTranslate();

    const { data: projectAttributes, isLoading, patch, post, put, delete: deleteFn } = useLocalDataWithMutations(getProjectDataFieldsQueryKey, {
        getFn: projectAttributeApiFunctions.get,
        postFn: projectAttributeApiFunctions.post,
        patchFn: projectAttributeApiFunctions.patch,
        putFn: projectAttributeApiFunctions.put,
        deleteFn: projectAttributeApiFunctions.delete,
    });

    const dataFieldServices: IDataFieldServices = useMemo(
        () => ({
            dataFieldsService: {
                postDataField: async (attribute) => post(attribute),
                putDataField: async (attribute) => (projectAttributeApiFunctions?.put ? put(attribute) : patch(attribute)),
                deleteDataField: async (dataFieldId: string) => deleteFn(dataFieldId),
                getDataFieldsForResource: async () => projectAttributeApiFunctions.get(),
                isDataFieldBeingUsed: projectAttributeApiFunctions.isDataFieldBeingUsed,
            },
            dataFieldListItemsService: {
                ...(listItemService == null
                    ? {
                          // If list item service is null, we know the server in-use manages list items through the data field routes
                          getDataFieldListItems: async (attributeId) => projectAttributes.find((pa) => pa.id === attributeId).listValues,
                          getDataFieldListItem: async (attributeId, listItemId) =>
                              projectAttributes.find((pa) => pa.id === attributeId).listValues.find((lv) => lv.id === listItemId),
                          postDataFieldListItem: async (listItem, attributeId) => {
                              const attribute = projectAttributes.find((pa) => pa.id === attributeId);
                              await (projectAttributeApiFunctions.put ? put : patch)({ ...attribute, listValues: [...attribute.listValues, listItem] });
                          },
                          putDataFieldListItem: async (listItem, attributeId) => {
                              const attribute = projectAttributes.find((pa) => pa.id === attributeId);
                              await (projectAttributeApiFunctions.put ? put : patch)({ ...attribute, listValues: [...attribute.listValues, listItem] });
                          },
                          deleteDataFieldListItem: listItemService?.deleteDataFieldListItem,
                      }
                    : listItemService),
            },
        }),
        [post, put, patch, projectAttributes]
    );

    return (
        <DataFieldConfigContext.Provider value={{ resourceId: projectId, allowSetDefaultValue: false, validateListItem: () => true }}>
            <DataFieldServicesContext.Provider value={dataFieldServices}>
                <Container>
                    <div>
                        <Header4>{translate(Content.settings2.projectAttributes.title)}</Header4>
                        <Body2>{translate(Content.settings2.projectAttributes.subtitle)}</Body2>
                    </div>
                    <Divider />
                    <HeaderContainer>
                        <div style={{ marginRight: inlineTextIconMargin }}>
                            <Header5>{translate(Content.settings2.projectAttributes.predefined_title)}</Header5>
                            <Body2>{translate(Content.settings2.projectAttributes.predefined_text)}</Body2>
                        </div>
                        <ChipsErrorBoundary toast={toast}>
                            <DataFieldsChips dataFields={[]} getProjectDataFields={projectAttributeApiFunctions.get} isProjectAttributesOnly />
                        </ChipsErrorBoundary>
                    </HeaderContainer>
                    <IventisErrorBoundaryWithToast toast={toast}>
                        <AttributeBrowser projectAttributes={projectAttributes} isLoading={isLoading} />
                    </IventisErrorBoundaryWithToast>
                </Container>
            </DataFieldServicesContext.Provider>
        </DataFieldConfigContext.Provider>
    );
};

export default ProjectAttributes;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: ${sectionalMargin};
    box-sizing: border-box;
    gap: ${sectionalMargin};
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const AttributeBrowser: FunctionComponent<{ projectAttributes; isLoading }> = ({ projectAttributes, isLoading }) => {
    const translate = useIventisTranslate();

    // Sorts the project datafields into which ones can be edited
    const editableProjectDataFields = useMemo(
        () => (projectAttributes ?? []).filter((dataField) => dataField.systemDataFieldName == null && dataField.type !== DataFieldType.What3Words),
        [projectAttributes]
    );
    const attributes = useMemo(() => {
        if (isLoading) {
            return <CenteredText data-testid="loading-project-attributes">{translate(Content.common["loading..."])}</CenteredText>;
        }

        if (editableProjectDataFields != null && editableProjectDataFields.length > 0) {
            return editableProjectDataFields.map((attribute) => (
                <Fragment key={attribute.id}>
                    <ProjectAttributeRow attribute={attribute} />
                    <Divider />
                </Fragment>
            ));
        }
        return (
            <CenteredText>
                <Body2>{translate(Content.settings2.projectAttributes.no_attributes)}</Body2>
            </CenteredText>
        );
    }, [editableProjectDataFields]);

    return (
        <FlatListBrowser skeleton={<ProjectAttributeRowSkeleton />} loading={isLoading}>
            {attributes}
        </FlatListBrowser>
    );
};

const CenteredText = styled.div`
    display: flex;
    padding: ${sectionalMargin};
    justify-content: center;
    width: 100%;
`;
