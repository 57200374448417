import { createContext } from "react";
import { Terrain } from "@iventis/domain-model/model/terrain";
import { TerrainCreateRequest } from "@iventis/domain-model/model/terrainCreateRequest";
import { TerrainCreateResponse } from "@iventis/domain-model/model/terrainCreateResponse";

export interface ITerrainContext {
    create: (terrain: TerrainCreateRequest) => Promise<TerrainCreateResponse>;
    update: (terrain: Partial<Terrain>) => Promise<string>;
    delete: (terrainId: string) => Promise<void>;
    list: () => Promise<Terrain[]>;
    get: (id: string) => Promise<Terrain>;
}

export const TerrainContext = createContext<ITerrainContext>(null);
