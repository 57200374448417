import {
    FillLayerSpecification,
    FillExtrusionLayerSpecification,
    LineLayerSpecification,
    SymbolLayerSpecification,
    CircleLayerSpecification,
    LayerSpecification,
    ModelLayerSpecification,
} from "@iventis/mapbox-gl";

type IventisMapboxLayer =
    | FillLayerSpecification
    | FillExtrusionLayerSpecification
    | LineLayerSpecification
    | SymbolLayerSpecification
    | CircleLayerSpecification
    | ModelLayerSpecification;

interface MapboxLayerMetaData {
    name?: string;
    type?: "base" | "arrows" | "text" | "outline" | "highlight" | null;
}

export type IventisMapboxTestLayer = IventisMapboxLayer & { metadata?: MapboxLayerMetaData };

export function isIventisTestLayer(layer: LayerSpecification): layer is IventisMapboxLayer & { metadata: MapboxLayerMetaData } {
    if (layer == null || layer.metadata == null || typeof layer.metadata !== "object") {
        return false;
    }

    const metadata = layer.metadata as Record<string, unknown>;
    const hasName = typeof metadata.name === "string";
    return hasName;
}
