import { StyleType } from "@iventis/domain-model/model/styleType";
import { CustomLayerInterface, LayerSpecification } from "@iventis/mapbox-gl";
import { MapModuleLayer } from "../../../types/store-schema";

export type StyleTypeOrder = StyleType | ExtendedStyleType;

export enum ExtendedStyleType {
    Area3D = "Area3D",
}

/** User layer order with StyleType */
export const styleTypeOrder: StyleTypeOrder[] = [StyleType.Area, StyleType.Line, StyleType.LineModel, StyleType.Model, ExtendedStyleType.Area3D, StyleType.Icon, StyleType.Point];

/** User layer order with mapbox layer type */
export const mapboxLayerOrder: (LayerSpecification | CustomLayerInterface)["type"][] = ["fill", "line", "custom", "fill-extrusion", "symbol", "circle"];

export enum SubLayerPlacement {
    Above = "Above",
    Below = "Below",
    IsBaseLayer = "IsBaseLayer",
}

export enum LayerMovementAction {
    MoveToTop = "MoveToTop",
    MoveToBottom = "MoveToBottom",
}

export type LayerMapOrderUpdate = Pick<MapModuleLayer, "id" | "mapOrder">;
