import { useDataFieldServices, LIST_ITEMS_QUERY_KEY } from "@iventis/datafield-editor";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

// If the number of list items for the data field is above this number, we should use the item search functionality.
// When above the number, we use an auto complete search bar for items.
// When above the number, we do not need to display and generate default values for each list item.
export const MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED = 10;

interface SpecificDataField<DType extends DataFieldType> extends DataField {
    type: DType;
}

// Given a data field id, a default value and list of data field items, create a data driven style value.
export function createDataDrivenStyleValue<TStyleValue extends StyleValue<unknown>, DType extends DataFieldType>(
    dataField: SpecificDataField<DType>,
    defaultValue: TStyleValue["staticValue"],
    listItems: DType extends DataFieldType.List ? DataFieldListItem[] : null
): TStyleValue {
    const styleValue = {
        staticValue: defaultValue,
        dataFieldId: dataField.id,
        extractionMethod: StyleValueExtractionMethod.Static,
        mappedValues: null,
    };

    switch (dataField.type) {
        case DataFieldType.List:
            styleValue.extractionMethod = StyleValueExtractionMethod.Mapped;
            styleValue.mappedValues = listItems?.reduce((cum, listItem) => ({ ...cum, [listItem.id]: styleValue.staticValue }), {});
            break;
        case DataFieldType.TimeRange:
        case DataFieldType.Time:
        case DataFieldType.Text:
        case DataFieldType.Number:
        case DataFieldType.What3Words:
            styleValue.extractionMethod = StyleValueExtractionMethod.Literal;
            break;
        default:
            throw new Error(`Data driven styling cannot be applied to data fields of type: ${dataField.type}`);
    }
    return styleValue as TStyleValue;
}

export const useListItemsForFormula = (
    selectedDataField: DataField,
    listItemsChangedCallback: (listItems: DataFieldListItem[]) => void,
    maxListItemsBeforeCallback = MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED
) => {
    const { dataFieldListItemsService } = useDataFieldServices();

    const query = useQuery(
        [LIST_ITEMS_QUERY_KEY, selectedDataField],
        async () => (selectedDataField == null ? ([] as DataFieldListItem[]) : dataFieldListItemsService.getDataFieldListItems(selectedDataField.id)),
        {
            refetchOnWindowFocus: false,
        }
    );
    const { data: listItems } = query;

    useEffect(() => {
        // if the list items are above the given parameter (default 10), we don't need to worry about generating each list item with static values as it uses the item search method instead and we can just return.
        if (selectedDataField == null || listItems == null || listItems.length > maxListItemsBeforeCallback) {
            return;
        }
        listItemsChangedCallback(listItems);
    }, [listItems]);

    return query;
};
