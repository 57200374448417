import type { Feature, LineString } from "geojson";
import type { MapObjectProperties } from "@iventis/map-types";
import type { BehaviorSubject } from "rxjs";
import { Status } from "@iventis/domain-model/model/status";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { LayerStorageScope, MapboxEngineData, MapState } from "../../types/store-schema";
import type { DomainLayer } from "../../state/map.state.types";
import { Layer } from "./layer";
import { ViewByStatus } from "../../types/layer-view-by";
import { defaultLineStyle } from "../../utilities/default-style-values";

export abstract class LineModelGuideLineLayer extends Layer<Feature<LineString, MapObjectProperties>[], LineStyle> {
    protected readonly layerId: string;

    protected readonly sourceId: string;

    protected readonly guideLineLayer: DomainLayer;

    constructor(state$: BehaviorSubject<MapState<MapboxEngineData>>, lineModelLayerId: string) {
        super(state$);
        this.layerId = `${lineModelLayerId}-guide`;
        this.sourceId = this.layerId;
        this.guideLineLayer = this.createGuideLineLayer();
    }

    private createGuideLineLayer(): DomainLayer {
        return {
            id: this.layerId,
            status: Status.Active,
            mapId: "",
            visible: true,
            locked: false,
            imported: false,
            name: "line model guide",
            styleType: StyleType.Line,
            areaStyle: undefined,
            lineStyle: {
                ...defaultLineStyle,
                colour: createStaticStyleValue("black"),
            },
            pointStyle: undefined,
            iconStyle: undefined,
            modelStyle: undefined,
            lineModelStyle: undefined,
            previewIconUrl: "",
            dataFields: [],
            tooltipDataFieldIds: [],
            createdByUserId: "",
            createdByUserName: "",
            createdAt: undefined,
            lastUpdatedByUserId: "",
            lastUpdatedByUserName: "",
            lastUpdatedAt: undefined,
            viewBy: ViewByStatus.OBJECT,
            source: this.sourceId,
            stamp: "",
            selected: false,
            storageScope: LayerStorageScope.LocalOnly,
            remote: false,
        };
    }
}
