export enum AssetType {
    MapBackground = "MapBackground",
    SiteMap = "SiteMap",
    Image = "Image",
    ExportToUrlPayload = "ExportToUrlPayload",
    MapIcon = "MapIcon",
    LayerPreview = "LayerPreview",
    Model = "Model",
    MapThumbnail = "MapThumbnail",
    LayerTemplate = "LayerTemplate",
    ExcelExport = "ExcelExport",
    ModelLodFile = "ModelLodFile",
    MapJsonExport = "MapJsonExport",
    MapObjectImage = "MapObjectImage",
    TagIcon = "TagIcon",
    ProjectImage = "ProjectImage",
    ProjectLogo = "ProjectLogo",
    ProfileImage = "ProfileImage",
    CommentImage = "CommentImage",
    ModelImage = "ModelImage",
    CustomMapExportLogoImage = "CustomMapExportLogoImage",
    Terrain = "Terrain"
}