import GeoJSON from "geojson";
import { Event, ErrorEvent } from "@iventis/mapbox-gl";
import { ValidationError } from "../errors";

export enum InvocationEventType {
    MOUSE_MOVE = "MOUSE_MOVE",
    MOUSE_OUT = "MOUSE_OUT",
    PAN_START = "PAN_START",
    VALIDATION_ERROR = "VALIDATIONERROR",
    MAP_LOADED = "MAP_LOADED",
    MAP_ERRORED = "MAP_ERRORED",
    DRAW_OUTSIDE_CAD = "DRAW_OUTSIDE_CAD",
}

export interface MouseMoveEvent {
    type: InvocationEventType.MOUSE_MOVE;
    body: {
        features: GeoJSON.Feature[];
        x: number;
        y: number;
    };
}

export interface MouseOutEvent {
    type: InvocationEventType.MOUSE_OUT;
    body: {
        x: number;
        y: number;
    };
}

export interface PanStartEvent {
    type: InvocationEventType.PAN_START;
    body: undefined;
}

export interface ValidationErrorEvent {
    type: InvocationEventType.VALIDATION_ERROR;
    body: {
        icon?;
        message: ValidationError;
    };
}

export interface MapLoadCompleteEvent {
    type: InvocationEventType.MAP_LOADED;
}

export interface DrawOutsideCadEvent {
    type: InvocationEventType.DRAW_OUTSIDE_CAD;
}

export type MapErrorBodyType = ErrorEvent & Event;

export interface MapErrorEvent {
    type: InvocationEventType.MAP_ERRORED;
    body: MapErrorBodyType;
}

export type InvocationEvent = MouseMoveEvent | MouseOutEvent | PanStartEvent | ValidationErrorEvent | MapLoadCompleteEvent | MapErrorEvent | DrawOutsideCadEvent;
