import React from "react";
import { Body2, CheckedCheckBox, InteractiveElement, UncheckedCheckBox } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { CUSTOM_COLOUR_MODEL_SUPPORT_URL, useFunctionality } from "@iventis/utilities";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useTheme } from "@mui/material";
import { UploadModelData, CustomColourValue } from "@iventis/types/model-types";
import { Checkbox, ModelVariableSizeContainer } from "./model-input-shared-styles";

export const UploadModelCustomColourComponent = ({
    onChange,
    value,
}: {
    onChange: <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => void;
    value: CustomColourValue;
}) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();
    const functionality = useFunctionality();

    const handleCustomColourToggle = () => {
        const updatedValue = !value.customColour;
        onChange("customColour", updatedValue);
    };

    return (
        <ModelVariableSizeContainer>
            <div className="checkbox-container">
                {functionality?.customColourOnModels ? (
                    <Checkbox
                        icon={<UncheckedCheckBox size={15} borderStyle="1px solid rgba(0,0,0,0.23)" backgroundColour={theme.secondaryColors.blank} />}
                        checkedIcon={<CheckedCheckBox size={15} tickColour="white" borderStyle="1px solid transparent" backgroundColour={theme.primaryColors.focus} />}
                        checked={value.customColour}
                        onChange={() => handleCustomColourToggle()}
                        size="small"
                        data-testid="custom-colour-toggle"
                    />
                ) : (
                    <StyledInteractiveElement className="upgrade-button" onClick={() => functionality.open?.("customColourOnModels")}>
                        <FontAwesomeIcon icon={["far", "circle-up"]} color={theme.primaryColors.focus} className="icon" />
                    </StyledInteractiveElement>
                )}
                <Body2>
                    {translate(Content.map5.styles.customColour)}{" "}
                    <a href={CUSTOM_COLOUR_MODEL_SUPPORT_URL} target="_blank" rel="noreferrer" style={{ fontWeight: "bold", color: theme.primaryColors.focus }}>
                        {translate(Content.common.learnMore)}
                    </a>
                    .
                </Body2>
            </div>
        </ModelVariableSizeContainer>
    );
};

const StyledInteractiveElement = styled(InteractiveElement)`
    padding: 0px 10px 0px 0px;
`;
