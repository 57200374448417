import { toast } from "@iventis/toasts/src/toast";
import { Content } from "@iventis/translations";
import { basicTranslator as translate } from "@iventis/translations/use-iventis-translate";
import { AxiosResponse, AxiosError } from "axios";
import { isAuthenticated$ } from "../app";
import { api } from "./api";

const problemWithOurServersErrorToast = () => {
    toast.error({
        title: translate(Content.errors.error),
        message: translate(Content.errors.problem_with_our_servers),
        overridingToastIcon: "triangle-exclamation",
    });
};

(() => {
    api.interceptors.response.use(
        (next: AxiosResponse) => {
            if (isAuthenticated$.value === false) {
                isAuthenticated$.next(true);
            }
            return Promise.resolve(next);
        },
        ({ response, name }: AxiosError<string>) => {
            if (response.status === 401) {
                if (isAuthenticated$.value === true) {
                    isAuthenticated$.next(false);
                }
            } else if (isAuthenticated$.value === false) {
                isAuthenticated$.next(true);
            }
            if (response.config.suppress404 && response.status === 404) {
                return Promise.reject(response);
            }
            switch (response.status) {
                case 400:
                    toast.error({
                        title: "Error",
                        message: translate(Content.errors.problem_with_data_submitted),
                    });
                    break;
                case 401:
                    break;
                case 500:
                    toast.error({
                        title: "Error",
                        message: translate(Content.errors.problem_with_our_servers_code, {
                            code: response.data,
                        }),
                        button: { label: translate(Content.errors.copy_code) },
                        onClick: () => {
                            navigator.clipboard.writeText(response.data);
                        },
                    });
                    break;
                case 503:
                    toast.error({
                        title: "Error",
                        message: translate(Content.errors.connecting_with_our_servers),
                    });
                    break;
                default:
                    if (name === "TimeoutError") {
                        toast.error({
                            title: "Error",
                            message: translate(Content.errors.timeout_connecting_servers),
                        });
                    } else {
                        // the error code has no be caught, show generic message
                        problemWithOurServersErrorToast();
                    }
            }
            // eslint-disable-next-line consistent-return
            return Promise.reject(response);
        }
    );
})();
