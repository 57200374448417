import React, { useMemo, useState } from "react";
import { TextButton } from "@iventis/styles";
import { isUrlBase64, useAssetSignatureCache } from "@iventis/utilities";
import { AssetBrowserComponent, LoadingComponent, UploadProjectImageData, UploadProjectImageForm } from "@iventis/components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FilterFormat } from "@iventis/types";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { uploadFileModalDimensions } from "@iventis/api-helpers";
import { Asset } from "@iventis/domain-model/model/asset";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { UploadAssetModal } from "@iventis/layer-styles/src/upload-model";
import { AssetsRepoTemplate } from "./assets-repo-template";
import { ASSET_CARD_SIZE } from "./constants";
import { createImage, getAsset, getAssets, updateImage } from "./assets-api-helpers";

type Action = { type: "upload"; target?: null } | { type: "replace"; target: Asset } | { type: null; target?: null };
const filter: FilterFormat[] = [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.Image }];

export const ProjectImagesRepository: React.FunctionComponent = () => {
    const imageGetter = useAssetSignatureCache();
    const [action, setAction] = useState<Action>({ type: null });
    const [selection, setSelection] = useState<string[]>([]);

    const queryClient = useQueryClient();

    const { status, data: assets, error } = useQuery(["images", filter], async () => getAssets({ filter }));

    const selectedImageSingle = useMemo(() => (selection?.length === 1 ? assets?.find((a) => selection?.[0] === a.id) : undefined), [selection, assets]);

    const mutation = useMutation(
        async (data: UploadProjectImageData) => {
            if (action?.type === "upload") {
                // If uploading, we create a new asset
                return createImage(data);
            }
            if (action?.type === "replace") {
                // If replacing, we update an existing asset
                const newData = data;
                // If the url is not base64, we know a new image has not been uploaded, and can set it to null.
                if (!isUrlBase64(data.image.imageUrl)) {
                    newData.image.imageUrl = null;
                }
                return updateImage(newData, action.target);
            }
            throw new Error("Cannot mutate data without specifying an action");
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["images"]);
            },
        }
    );

    const getExistingImageInUploadFormFormat: (assetId: string) => Promise<UploadProjectImageData> = async (assetId) => {
        const existingImage = await getAsset(assetId);
        return { id: assetId, image: { fileName: existingImage.name, imageUrl: imageGetter(existingImage.assetUrl, existingImage.authoritySignature) } };
    };

    return (
        <>
            <AssetsRepoTemplate
                repoTitle="Project Images"
                repoSubtitle="Default project images"
                buttons={[
                    <TextButton key="upload" type="button" onClick={() => setAction({ type: "upload" })}>
                        Upload new project image
                    </TextButton>,
                    <TextButton key="replace" type="button" onClick={() => setAction({ type: "replace", target: selectedImageSingle })} disabled={selection?.length !== 1}>
                        Edit project image
                    </TextButton>,
                ]}
                browserComponent={
                    status === "loading" ? (
                        <LoadingComponent />
                    ) : status === "error" ? (
                        <>
                            <p>Error!</p>
                            <p>{JSON.stringify(error, null, 4)}</p>
                        </>
                    ) : (
                        <AssetBrowserComponent
                            imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
                            assets={assets}
                            fetchNextPage={() => null}
                            isFetchingNextPage={false}
                            cardHeight={ASSET_CARD_SIZE}
                            cardMinWidth={ASSET_CARD_SIZE}
                            cardWidth="100%"
                            onSelectionChange={setSelection}
                            onEditClicked={(id) => setAction({ type: "replace", target: assets.find((a) => a.id === id) })}
                        />
                    )
                }
            />

            <UploadAssetModal open={action?.type === "upload"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadProjectImageForm onClose={() => setAction({ type: null })} uploadRemotely={mutation.mutateAsync} setSelectedId={(id) => setSelection([id])} />
            </UploadAssetModal>

            <UploadAssetModal open={action?.type === "replace"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadProjectImageForm
                    onClose={() => setAction({ type: null })}
                    existingId={action?.target?.id}
                    uploadRemotely={mutation.mutateAsync}
                    getExistingData={getExistingImageInUploadFormFormat}
                    setSelectedId={(id) => setSelection([id])}
                />
            </UploadAssetModal>
        </>
    );
};
