/* eslint-disable no-param-reassign */
import { DoubleSide, Mesh } from "three";
import { loadImageAsTexture, convertThreeJSModelToBlob, parseModelArrayBuffer } from "./customise-model-helpers";
import { CUSTOM_IMAGE_MATERIAL_SLOT_NAME } from "./model-material-name-constants";

/** Given a model and an image url will add the image to the model */
export async function addImageToModel(modelBlob: Blob, imageUrl: string) {
    const modelArrayBuffer = await modelBlob.arrayBuffer();
    const threeJSModel = await parseModelArrayBuffer(modelArrayBuffer);

    if (threeJSModel == null) {
        throw new Error("Failed to load model from array buffer using threejs");
    }

    const imageAsTexture = await loadImageAsTexture(imageUrl);

    threeJSModel.scene.traverse((child) => {
        if (child instanceof Mesh) {
            if (child.material.name === CUSTOM_IMAGE_MATERIAL_SLOT_NAME) {
                child.material.side = DoubleSide;
                child.material.map = imageAsTexture;
                child.material.needsUpdate = true;
                child.material.transparent = true;
            }
        }
    });

    return convertThreeJSModelToBlob(threeJSModel);
}
