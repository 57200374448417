import { Map } from "@iventis/mapbox-gl";

export class MapboxCustomControl {
    constructor(buttonTitle: string, onClick: () => void, svgPath: string, svgViewbox?: string) {
        this.buttonTitle = buttonTitle;
        this.onClick = onClick;
        this.svgPath = svgPath;
        this.svgViewbox = svgViewbox ?? "0 0 512 512";
    }

    private buttonTitle: string;

    private onClick: () => void;

    private svgPath: string;

    private svgViewbox: string;

    map: Map | undefined;

    _container: HTMLDivElement;

    _btn: HTMLButtonElement;

    onAdd(map: Map) {
        this.map = map;
        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon";
        this._btn.type = "button";
        this._btn.title = this.buttonTitle;
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("viewBox", this.svgViewbox);
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svg.setAttribute("width", "22");
        svg.setAttribute("height", "22");
        svg.style.padding = "3.5px";
        svg.innerHTML = this.svgPath;
        this._btn.appendChild(svg);
        this._btn.onclick = () => {
            this.onClick();
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container?.parentNode?.removeChild(this._container);
        this.map = undefined;
    }
}
