import { TextButton } from "@iventis/styles";
import React, { FunctionComponent, useMemo, useState } from "react";
import { LoadingComponent, AssetBrowserComponent, UploadIconForm, ConfirmationDialogComponent } from "@iventis/components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { Asset } from "@iventis/domain-model/model/asset";
import { UploadAssetModal } from "@iventis/layer-styles/src/upload-model";
import { UploadIconData } from "@iventis/types/model-types";
import { AxiosResponse } from "axios";
import { isUrlBase64, useAssetSignatureCache } from "@iventis/utilities";
import {
    convertAssetToUploadAssetData,
    convertUploadIconDataToAssetPostData,
    convertUploadIconDataToAssetPutData,
    AssetsPostRequest,
    AssetsPutRequest,
    uploadFileModalDimensions,
} from "@iventis/api-helpers";
import { FilterFormat } from "@iventis/types/api.types";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { AssetsRepoTemplate } from "./assets-repo-template";
import { deleteAsset, getAsset, getAssets } from "./assets-api-helpers";
import { ASSET_CARD_SIZE } from "./constants";
import { api } from "../api/api";
import { getCategories } from "./category-services";

type Action =
    | { type: "upload"; target?: null }
    | { type: "replace"; target: Asset }
    | { type: "delete"; target: Asset; deleting: boolean }
    | { type: null; target?: null; deleting?: null };

export const IconRepositoryComponent: FunctionComponent = () => {
    const imageGetter = useAssetSignatureCache();

    const [action, setAction] = useState<Action>({ type: null });

    const [selection, setSelection] = useState<string[]>([]);

    const filter: FilterFormat[] = useMemo(() => [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.MapIcon }], []);

    // Access the client
    const queryClient = useQueryClient();

    const { status, data: assets, error, refetch } = useQuery(["icons", filter], async () => getAssets({ filter }));
    const { data: categories } = useQuery(["categories", AssetType.MapIcon], () => getCategories(AssetType.MapIcon));
    const iconCategories = useMemo(() => categories?.map((c) => c.name) ?? [], [categories]);

    /** Selected icon (undefined if more than one asset is selected) */
    const selectedIconSingle = useMemo(() => (selection?.length === 1 ? assets?.find((a) => selection?.[0] === a.id) : undefined), [selection, assets]);

    const mutation = useMutation(
        async (data: UploadIconData) => {
            const params = { params: { height: data?.image?.height, width: data?.image?.width } };
            if (action?.type === "upload") {
                // If uploading, we create a new asset
                return api.post<Asset, AxiosResponse<Asset>, AssetsPostRequest>("assets", convertUploadIconDataToAssetPostData(data), params).then((res) => res.data);
            }
            if (action?.type === "replace") {
                // If replacing, we update an existing asset
                const newData = data;
                // If the url is not base64, we know a new image has not been uploaded, and can set it to null.
                if (!isUrlBase64(data.image.imageUrl)) {
                    newData.image.imageUrl = null;
                }
                return api
                    .put<Asset, AxiosResponse<Asset>, AssetsPutRequest>(`assets/${action.target.id}`, convertUploadIconDataToAssetPutData(newData, action.target), params)
                    .then((res) => res.data);
            }
            throw new Error("Cannot mutate data without specifying an action");
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["icons"]);
            },
        }
    );

    const getExistingIconInUploadFormFormat: (assetId: string) => Promise<UploadIconData> = async (assetId) => {
        const existingIcon = await getAsset(assetId);
        return convertAssetToUploadAssetData(existingIcon, imageGetter);
    };

    const handleDeleteIcon = async () => {
        setAction({ ...action, deleting: true });
        await deleteAsset(selectedIconSingle.id);
        await refetch();
        setAction({ type: null });
    };

    return (
        <>
            <AssetsRepoTemplate
                repoTitle="Icons"
                repoSubtitle="Icon repository"
                buttons={[
                    <TextButton key="upload" type="button" onClick={() => setAction({ type: "upload" })}>
                        Upload new icon
                    </TextButton>,
                    <TextButton key="replace" type="button" onClick={() => setAction({ type: "replace", target: selectedIconSingle })} disabled={selection?.length !== 1}>
                        Edit icon
                    </TextButton>,
                    <TextButton
                        key="delete"
                        type="button"
                        onClick={() => setAction({ type: "delete", target: selectedIconSingle, deleting: false })}
                        disabled={selection?.length !== 1}
                    >
                        Delete icon
                    </TextButton>,
                ]}
                browserComponent={
                    status === "loading" ? (
                        <LoadingComponent />
                    ) : status === "error" ? (
                        <>
                            <p>Error!</p>
                            <p>{JSON.stringify(error, null, 4)}</p>
                        </>
                    ) : (
                        <AssetBrowserComponent
                            imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
                            assets={assets}
                            fetchNextPage={() => null}
                            isFetchingNextPage={false}
                            cardHeight={ASSET_CARD_SIZE}
                            cardMinWidth={ASSET_CARD_SIZE}
                            cardWidth="100%"
                            onSelectionChange={setSelection}
                            onEditClicked={(id) => setAction({ type: "replace", target: assets.find((a) => a.id === id) })}
                        />
                    )
                }
            />
            <UploadAssetModal open={action?.type === "upload"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadIconForm
                    close={() => setAction({ type: null })}
                    categories={iconCategories}
                    uploadRemotely={mutation.mutateAsync}
                    setSelectedId={(id) => setSelection([id])}
                />
            </UploadAssetModal>
            <UploadAssetModal open={action?.type === "replace"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadIconForm
                    close={() => setAction({ type: null })}
                    categories={iconCategories}
                    uploadRemotely={mutation.mutateAsync}
                    getExistingData={getExistingIconInUploadFormFormat}
                    existingId={action.target?.id}
                    setSelectedId={(id) => setSelection([id])}
                />
            </UploadAssetModal>
            {action.type === "delete" && (
                <ConfirmationDialogComponent
                    handleConfirm={handleDeleteIcon}
                    handleCancel={() => setAction({ type: null })}
                    title={`Delete ${action?.target?.name} icon`}
                    message="Are you sure you want to delete this icon?"
                    confirmText="Confirm"
                    cancelText="Cancel"
                    isSubmitting={action.deleting}
                    show
                />
            )}
        </>
    );
};
