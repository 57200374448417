import React, { useRef, useState } from "react";
import { NODE_HEIGHT_PX, NodeComponent, useTreeBrowser, LibraryOverflowPopover } from "@iventis/tree-browser";
import { styled, Header5, StyledIconButton } from "@iventis/styles";
import { useTheme } from "@mui/material";

import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Status } from "@iventis/domain-model/model/status";
import { Terrain } from "@iventis/domain-model/model/terrain";
import { ConfirmationDialogComponent } from "@iventis/components/src/confirmation-dialog";
import { StatusIcon } from "@iventis/components/src/thumbnails/status";
import { TerrainNode, TerrainNodeTypes, TerrainTreeBrowserContext } from "./terrain-types";
import { TerrainModal, TerrainWizard, TerrainWizardRef } from "./terrain-wizard";
import { tileUploader } from "./terrain-tile-uploader";

export const TerrainNodeComponent: NodeComponent<TerrainNode, undefined> = ({ node }) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const [{ tree }, treeBrowserContext] = useTreeBrowser(TerrainTreeBrowserContext, node.id, "expandedNodeIds", "isLoadingTree", "tree");

    const terrainWizardRef = useRef<TerrainWizardRef>();
    const overflowButtonRef = useRef<HTMLDivElement>();
    const [overflowOpen, setOverflowOpen] = useState(false);
    const [modal, setModal] = useState<"edit-terrain" | "delete-terrain" | null>(null);

    const closeWizard = (terrain: Terrain) => {
        if (terrain != null) {
            treeBrowserContext.updateNodeLocally(terrainToNode(terrain, tree.id), undefined, true, true);
            setModal(null);
        }
        if (!terrainWizardRef.current.isSaving()) {
            setModal(null);
        }
    };

    return (
        <TerrainNodeComponentContainer className="terrain-node">
            <Header5>{node.terrain.name}</Header5>
            <StyledEndOfRow>
                <StatusIcon
                    height={25}
                    width={65}
                    fill={
                        node.terrain.status === Status.Pending
                            ? theme.toastColours.info
                            : node.terrain.status === Status.Active
                            ? theme.secondaryColors.accept
                            : theme.toastColours.error
                    }
                    label={translate(Content.terrain[node.terrain.status])}
                />

                <StyledIconButton onClick={() => setOverflowOpen(true)}>
                    <span ref={overflowButtonRef}>
                        <FontAwesomeIcon size="xs" icon="ellipsis" />
                    </span>
                    <span className="hidden">{translate(Content.terrain.terrain_options)}</span>
                </StyledIconButton>
            </StyledEndOfRow>
            <LibraryOverflowPopover
                node={node}
                overflowButtonRef={overflowButtonRef}
                overflowOpen={overflowOpen}
                setOverflowOpen={setOverflowOpen}
                overflowOptions={[
                    { label: translate(Content.terrain.edit), selected: () => setModal("edit-terrain") },
                    { label: translate(Content.terrain.delete), selected: () => setModal("delete-terrain") },
                ]}
            />

            <TerrainModal open={modal === "edit-terrain"} onClose={() => closeWizard(null)}>
                <TerrainWizard tileUploader={tileUploader} onClose={closeWizard} ref={terrainWizardRef} terrain={node.terrain} />
            </TerrainModal>
            <ConfirmationDialogComponent
                show={modal === "delete-terrain"}
                handleConfirm={() => {
                    treeBrowserContext.deleteNodes([node]);
                    setModal(null);
                }}
                handleCancel={() => setModal(null)}
                title={translate(Content.terrain.delete)}
                confirmText={translate(Content.common.buttons.confirm)}
                cancelText={translate(Content.common.buttons.cancel)}
                message={translate(Content.common.delete_confirmation_message, { entity: node.terrain.name })}
            />
        </TerrainNodeComponentContainer>
    );
};

export const terrainToNode = (terrain: Terrain, rootNodeId: string): TerrainNode => ({
    id: terrain.id,
    parentId: rootNodeId,
    name: "",
    type: TerrainNodeTypes.Terrain,
    terrain,
    childNodes: [],
    childCount: 0,
    lastUpdatedAt: new Date(terrain.lastUpdatedAt),
});

const TerrainNodeComponentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    height: ${NODE_HEIGHT_PX}px;
`;

const StyledEndOfRow = styled.div`
    display: flex;
    align-items: center;
`;

export const terrainNodeComponents = {
    [TerrainNodeTypes.Terrain]: TerrainNodeComponent,
    [TerrainNodeTypes.Root]: undefined,
};
