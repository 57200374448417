import { styled } from "@iventis/styles";
import React, { FunctionComponent } from "react";

export const StyledImagePreview = styled.div<{ url: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: center / contain no-repeat url(${(props) => props.url});
    border: solid 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-clip: content-box;
    background-origin: content-box;
    }
`;

export const ModelThumbnailPreview: FunctionComponent<{ fileThumbnailUrl: string }> = ({ fileThumbnailUrl }) => (
    <StyledImagePreview url={fileThumbnailUrl}>
        <div className="thumbnail" />
    </StyledImagePreview>
);
