import {
    Anchor,
    CircleLayerSpecification,
    ExpressionSpecification,
    FillExtrusionLayerSpecification,
    FillLayerSpecification,
    LineLayerSpecification,
    MapOptions,
    ModelLayerSpecification,
    RequestTransformFunction,
    SymbolLayerSpecification,
} from "@iventis/mapbox-gl";
import { TextStyle } from "@iventis/domain-model/model/textStyle";
import { EngineInterpreterOptions } from "../../types/internal";
import { PitchOptions } from "../../types/store-schema";

export interface MapboxEngineOptions extends EngineInterpreterOptions {
    mbxAccessToken: string;
    maxZoom: number;
    minZoom: number;
    bustTileCache?: TileCacheBuster;
    transformRequest?: RequestTransformFunction;
    preserveDrawingBuffer?: boolean;
    fontStackUrl: string;
    pitchOptions?: PitchOptions;
    exportOptions?: ExportOptions;
    useMapObjectBounds?: boolean;
    cypressWindowName?: string;
}

export type ExportOptions = { bearing: number; bounds: number[][]; scale?: number; pitch: number };

export const transparentColour = "rgba(0,0,0,0)";

export type LineEndString = "butt" | "round" | "square";
export type LineJoinString = "bevel" | "round" | "miter";

export type TileCacheBuster = (tileUrls: string[]) => string[];

export type AnySupportedMapboxLayer =
    | LineLayerSpecification
    | FillLayerSpecification
    | CircleLayerSpecification
    | SymbolLayerSpecification
    | ModelLayerSpecification
    | FillExtrusionLayerSpecification;

/* Font Stacks for mapbox text layers */
/** Used when the font is bold */
export const boldFontStack = ["Open Sans Bold", "Arial Unicode MS Bold"];
/** Used when the font is regular */
export const regularFontStack = ["Open Sans Regular", "Arial Unicode MS Regular"];
/** Text anchor positions for when the text position is automatic */
export const automaticTextAnchor: Anchor[] = ["center", "bottom", "top", "right", "left"];

/** A key value pair, where the key is Iventis style property, value is mapbox equivalent style property */
export const iventisToMapboxTextStyleProperty: { [key in keyof TextStyle]: keyof SymbolLayerSpecification["layout"] | keyof SymbolLayerSpecification["paint"] | undefined } = {
    text: "text-field",
    textContent: "text-field",
    textColour: "text-color",
    textSize: "text-size",
    textOverlap: "text-allow-overlap",
    textBold: "text-font",
    textItalic: undefined,
    textUnderlined: undefined,
    textOutlineWidth: "text-halo-width",
    textOutlineColour: "text-halo-color",
    textOpacity: "text-opacity",
    textPosition: "text-variable-anchor",
    textOffset: "text-radial-offset",
    styleType: undefined,
    objectOrder: undefined,
} as const;

export const maxDefaultPitch = 85;
export const minDefaultPitch = 0;

export type MapboxInitialPosition = Pick<MapOptions, "pitch" | "bearing" | "zoom" | "center" | "bounds" | "fitBoundsOptions">;

export const locationHashString = "location";

export type ApplyListItemTextFunction = (expression: ExpressionSpecification, textLayerId: string, iventisLayerId: string) => Promise<void>;

export const googleMapBackgroundAssetTag = "Google Maps";

export const mapTilerTerrainUrl = "https://api.maptiler.com/tiles/terrain-rgb-v2/tiles.json?key=WG7Rlgw2Z5z7PnRrpjEG";

// The tile url in full looks like this https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.png?key=apikey
export const mapTilerTerrainTileUrlStartsWith = "https://api.maptiler.com/tiles/terrain-rgb-v2/";
