/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { FormLabel, Header4, StyledFieldLabel, formGap, labelMargin, muiInputFormsCSS, styled } from "@iventis/styles";
import React, { FunctionComponent, useState } from "react";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { Switch, Divider, TextField, LinearProgress } from "@mui/material";
import { AnalysisType } from "@iventis/domain-model/model/analysisType";
import { SubscriptionPlanFunctionality } from "@iventis/domain-model/model/subscriptionPlanFunctionality";
import { convertPascalToSentence, useDelayedState } from "@iventis/utilities";
import { MultipleValueTextInput, FormWizardTemplate, CustomDialog } from "@iventis/components";
import { useQuery } from "@tanstack/react-query";
import { PriceEditor } from "./price-editor";
import { getPricesForSubscriptionPlan } from "./subscription-plans-api-helpers";
import { SUBSCRIPTION_PRICE_QUERY_KEY } from "./constants";

const defaultPlan: SubscriptionPlan = {
    name: "Plan",
    functionality: {
        maxIndividualMapsPerUser: 0,
        maxTeamMaps: 0,
        sharedLinks: false,
        sharedWorkspace: false,
        iconAndModelUploads: false,
        layerCopying: false,
        plansCopying: false,
        importingLayers: false,
        pdfExports: false,
        layer3DTypes: false,
        customLogo: false,
        siteMaps: false,
        sso: false,
        customLayer: false,
        analysisTypes: [],
        attributeDataExport: false,
        digitalTwin: false,
        listItemProperties: false,
        unlinkingLayers: false,
        imagesOnModels: false,
        customColourOnModels: false,
    },
    subscriptionPlanPrices: [],
    trialLength: 0,
    id: undefined,
    stripeId: "",
};

export const SubscriptionPlanModal: FunctionComponent<{
    open: boolean;
    initialPlan?: SubscriptionPlan;
    onSave: (plan: SubscriptionPlan) => void;
    onClose: () => void;
}> = ({ open, initialPlan = defaultPlan, onSave, onClose }) => {
    const [name, setName] = useState(initialPlan.name);
    const [functionality, setFunctionality] = useState(initialPlan.functionality);
    const [trialLength, setTrialLength] = useState(initialPlan.trialLength);

    const { data: existingPrices, isLoading: _isLoadingPrices } = useQuery([SUBSCRIPTION_PRICE_QUERY_KEY, initialPlan?.id], () => getPricesForSubscriptionPlan(initialPlan.id), {
        enabled: initialPlan?.id != null,
    });

    const [prices, setPrices, isLoadingPrices] = useDelayedState(initialPlan.id == null ? false : _isLoadingPrices, existingPrices ?? initialPlan?.subscriptionPlanPrices ?? []);

    const updatePlan = <PropetyType extends keyof SubscriptionPlanFunctionality>(value: SubscriptionPlanFunctionality[PropetyType], key: PropetyType) => {
        const newFunctionality = { ...functionality };
        newFunctionality[key] = value;
        setFunctionality(newFunctionality);
    };

    return (
        <CustomDialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <FormWizardTemplate
                title={initialPlan.id === defaultPlan.id ? "Create Plan" : "Edit plan"}
                currentStage={0}
                stages={[
                    {
                        isValid: prices?.length > 0,
                        primaryButtonCallback: () => {
                            const plan: SubscriptionPlan = {
                                name,
                                functionality,
                                subscriptionPlanPrices: prices,
                                trialLength,
                                id: initialPlan.id,
                                stripeId: "",
                            };
                            onSave(plan);
                        },
                        primaryButtonText: "Save",
                        secondaryButtons: [{ buttonText: "Cancel", onButtonPressed: onClose }],
                    },
                ]}
            >
                <StyledFormContainer>
                    {/* Name */}
                    <div>
                        <StyledFieldLabel>
                            <FormLabel>Name of plan</FormLabel>
                        </StyledFieldLabel>
                        <TextField style={{ width: "100%" }} value={name} onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div>
                        <StyledFieldLabel>
                            <FormLabel>Trial Length (Days)</FormLabel>
                        </StyledFieldLabel>
                        <TextField style={{ width: "100%" }} type="number" value={trialLength.toFixed(0)} onChange={(event) => setTrialLength(Number(event.target.value))} />
                    </div>
                    <Divider />
                    {/* Functionality */}
                    <Header4>Functionality</Header4>

                    {Object.keys(defaultPlan.functionality).map((key: keyof SubscriptionPlanFunctionality) => {
                        switch (typeof defaultPlan.functionality[key]) {
                            case "boolean":
                                return (
                                    <SwitchInput
                                        key={key}
                                        label={convertPascalToSentence(key)}
                                        value={Boolean(functionality[key])}
                                        onChange={(value) => {
                                            updatePlan(value, key);
                                        }}
                                    />
                                );
                            case "number":
                                return (
                                    <NumberInput
                                        key={key}
                                        label={convertPascalToSentence(key)}
                                        value={functionality[key] == null ? null : Number(functionality[key])}
                                        onChange={(value) => {
                                            updatePlan(value, key);
                                        }}
                                    />
                                );
                            default:
                                break;
                        }
                    })}
                    <StyledFieldLabel>
                        <FormLabel>Analysis Types</FormLabel>
                    </StyledFieldLabel>
                    <MultipleValueTextInput
                        selectedValues={functionality.analysisTypes}
                        values={Object.values(AnalysisType)}
                        onChange={(values) => {
                            setFunctionality({ ...functionality, analysisTypes: values });
                        }}
                        getLabel={(analysisType) => convertPascalToSentence(analysisType)}
                        sectionalMargin={false}
                    />
                    <Divider />
                    {/* Pricing */}
                    <Header4>Pricing</Header4>
                    {isLoadingPrices ? <LinearProgress /> : <PriceEditor onChange={(prices) => setPrices(prices)} pricePlans={prices} subscriptionPlanId={initialPlan?.id} />}
                </StyledFormContainer>
            </FormWizardTemplate>
        </CustomDialog>
    );
};

const SwitchInput: FunctionComponent<{ label: string; value: boolean; onChange: (value: boolean) => void }> = ({ label, value, onChange }) => (
    <div key={label} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <Switch checked={value} onChange={(event) => onChange(event.target.checked)} />
    </div>
);

const NumberInput: FunctionComponent<{ label: string; value: number; onChange: (value: number) => void }> = ({ label, value, onChange }) => (
    <StyledNumberInput key={label}>
        <StyledFieldLabel>{label}</StyledFieldLabel>
        <div className="number-input">
            {value == null && <em>unlimited</em>}
            <TextField
                sx={{ width: "60px" }}
                type="number"
                value={value?.toFixed(0) ?? null}
                onChange={(event) => onChange(event.target.value === "" ? null : Number(event.target.value))}
            />
        </div>
    </StyledNumberInput>
);

const StyledNumberInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${labelMargin};
    .number-input {
        display: flex;
        em {
            margin: auto;
            margin-right: 10px;
        }
    }
`;

const StyledFormContainer = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    flex-direction: column;
    gap: ${formGap};
`;
