import { Mesh } from "three";
import { loadModel } from "./customise-model-helpers";
import { CUSTOM_COLOUR_MATERIAL_SLOT_NAME, CUSTOM_IMAGE_MATERIAL_SLOT_NAME } from "./model-material-name-constants";

export async function validateModelCanHaveCustomisation(modelUrl: string, customisation: "image" | "colour"): Promise<boolean> {
    const model = await loadModel(modelUrl);

    if (model == null) {
        return false;
    }

    let valid = false;

    model.scene.traverse((child) => {
        if (child instanceof Mesh && valid === false) {
            if (customisation === "image" && child.material.name === CUSTOM_IMAGE_MATERIAL_SLOT_NAME) {
                valid = true;
            }

            if (customisation === "colour" && child.material.name === CUSTOM_COLOUR_MATERIAL_SLOT_NAME) {
                valid = true;
            }
        }
    });

    return valid;
}
