import { v4 as uuid } from "uuid";
import { DataField } from "@iventis/domain-model/model/dataField";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { replaceImportMapLayerAndGroupIds } from "./map-json-import-layers-and-groups";
import { replaceSavedMapViewIds } from "./map-json-saved-map-views";
import { ImportIventisMap, ImportMapApiFunctions } from "./map-json-types";
import { MissingAsset } from "./map-json-import-assets";

/** Takes an export map and replaces all the relevant data to be imported */
export async function createMapJsonImport(
    importedMap: ImportIventisMap,
    missingAssets: MissingAsset[],
    projectDataFields: DataField[],
    selectedMapBackgroundId: string,
    sitemaps: Sitemap[],
    selectedSitemapVersions: Record<string, string>,
    apiFunctions: ImportMapApiFunctions
): Promise<ImportIventisMap> {
    // Create a new map id
    const mapId = uuid();
    // Parse the layers and groups
    const { layers, groups } = await replaceImportMapLayerAndGroupIds(importedMap, mapId, missingAssets, projectDataFields, sitemaps, selectedSitemapVersions, apiFunctions);
    // Parse the saved map views
    const { updatedDefaultSavedMapViewId, updatedSavedMapViews } = replaceSavedMapViewIds(importedMap.savedMapViews, importedMap.defaultSavedMapViewId);

    return {
        id: mapId,
        globeEnabled: importedMap.globeEnabled,
        name: importedMap.name,
        terrainEnabled: importedMap.terrainEnabled,
        backgroundName: importedMap.backgroundName,
        groups,
        layers,
        savedMapViews: updatedSavedMapViews,
        defaultSavedMapViewId: updatedDefaultSavedMapViewId,
        backgroundId: selectedMapBackgroundId,
        buildings3DEnabled: importedMap.buildings3DEnabled,
        streetNamesEnabled: importedMap.streetNamesEnabled,
    };
}
