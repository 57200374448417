/* eslint-disable func-call-spacing */
/* eslint-disable no-spaced-func */
import "@iventis/styles/src/fonts/index.css";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { styled } from "@iventis/styles";
import { ModelRenderStatus } from "@iventis/types/model-types";
import { MapboxModelPreview } from "./mapbox/mapbox-model-preview";

export type ModelPreviewRef = {
    renderModel: (modelUrl: string) => Promise<ModelRenderStatus>;
    renderThumbnail: () => Promise<string>;
};

export const ModelPreviewComponent = forwardRef<ModelPreviewRef, { onCameraClicked: (dataUrl: string) => void }>((props, ref) => {
    const renderModel = (modelUrl: string): Promise<ModelRenderStatus> => mapboxInstanceRef?.current?.renderModel(modelUrl) ?? Promise.resolve(ModelRenderStatus.Error);

    const renderThumbnail = (): Promise<string> => mapboxInstanceRef.current?.generateThumbnail() as Promise<string>;

    useImperativeHandle(ref, () => ({
        renderModel: (modelUrl: string): Promise<ModelRenderStatus> => renderModel(modelUrl),
        renderThumbnail: (): Promise<string> => renderThumbnail(),
    }));

    const mapboxInstanceRef = useRef<MapboxModelPreview | null>(null);
    const handleMapContainerRendered = async (element: HTMLDivElement) => {
        // If mapbox has already been created, do nothing
        if (mapboxInstanceRef.current != null) {
            return;
        }

        const mapboxInstance = new MapboxModelPreview(props.onCameraClicked);
        mapboxInstanceRef.current = mapboxInstance;

        // Create mapbox map
        await mapboxInstance.createMap(element);
    };

    return (
        <Container>
            <MapContainer>
                <MapElement ref={handleMapContainerRendered} />
            </MapContainer>
        </Container>
    );
});

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const MapContainer = styled.div`
    position: relative;
    flex-shrink: 1;
    flex-grow: 1;
`;

const MapElement = styled.div`
    width: 100%;
    height: 100%;
`;
