import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChipType, LoadingComponent, StyledChip } from "@iventis/components";
import { terrainNodeComponents, terrainToNode } from "@iventis/terrains/src/terrain-nodes";
import { Header3, sectionalMargin, Subtitle, styled } from "@iventis/styles";
import { createInitialState, TreeBrowserComponent, useTreeBrowserInitialise } from "@iventis/tree-browser";
import { useConstant } from "@iventis/utilities";
import { Button } from "@mui/material";
import { Terrain } from "@iventis/domain-model/model/terrain";
import React, { useState, FunctionComponent, useRef } from "react";
import { tileUploader } from "@iventis/terrains/src/terrain-tile-uploader";
import { TerrainRootNode, TerrainTreeBrowserContext } from "@iventis/terrains/src/terrain-types";
import { TerrainModal, TerrainWizard, TerrainWizardRef } from "@iventis/terrains/src/terrain-wizard";
import { ITerrainContext, TerrainContext } from "@iventis/terrains/src/terrain-context";
import { createRootIdFromProjectIdAndInstanceName, createTerrainContext, terrainServices } from "./terrain-api-functions";
import { RepoContainer } from "./assets-repo-template";
import { useProjectSelector } from "./project-selector";

export const TerrainRepository = () => {
    const { embeddedSelector, instanceName, isProjectSelected, projectId, projectName, switchProjectCallback } = useProjectSelector();

    return (
        <RepoContainer>
            <div className="header-row">
                <StyledHeader>
                    <span>Terrain</span> {isProjectSelected && <span>({projectName})</span>}
                </StyledHeader>
                {isProjectSelected && (
                    <Button onClick={switchProjectCallback} variant="text">
                        Switch project
                    </Button>
                )}
            </div>
            <StyledContainer>
                {!isProjectSelected && embeddedSelector}
                {isProjectSelected && <TerrainTree projectId={projectId} instanceName={instanceName} />}
            </StyledContainer>
        </RepoContainer>
    );
};

const TerrainTree: FunctionComponent<{ projectId: string; instanceName: string }> = ({ projectId, instanceName }) => {
    const treeId = useConstant(() => createRootIdFromProjectIdAndInstanceName(projectId, instanceName));
    const [{ tree, expandedNodeIds, isLoadingTree }, terrainTreeBrowserContext] = useTreeBrowserInitialise(
        createInitialState<TerrainRootNode>({
            mainNodeId: treeId,
            expandAllNodesOnLoad: true,
            treeId,
        }),
        terrainServices,
        null
    );
    const terrainContextValue = useConstant<ITerrainContext>(() => createTerrainContext(instanceName, projectId));
    const terrainWizardRef = useRef<TerrainWizardRef>();

    const [modal, setModal] = useState<"create-terrain" | null>(null);
    return (
        <>
            <TerrainContext.Provider value={terrainContextValue}>
                <div className="subtitle-controls-row">
                    <Subtitle>Terrain repository</Subtitle>
                    <StyledChip
                        label={
                            <>
                                <FontAwesomeIcon icon={["far", "plus"]} /> Add terrain
                            </>
                        }
                        chipType={ChipType.PrimaryButton}
                        onClick={() => setModal("create-terrain")}
                    />
                </div>
                <StyledTreeContainer>
                    {isLoadingTree ? (
                        <LoadingComponent />
                    ) : (
                        <TerrainTreeBrowserContext.Provider value={terrainTreeBrowserContext}>
                            <TreeBrowserComponent<TerrainRootNode> node={tree} openNodes={expandedNodeIds} components={terrainNodeComponents} />
                        </TerrainTreeBrowserContext.Provider>
                    )}
                </StyledTreeContainer>
                <TerrainModal open={modal === "create-terrain"} onClose={() => (terrainWizardRef.current.isSaving() ? null : setModal(null))}>
                    <TerrainWizard
                        tileUploader={tileUploader}
                        onClose={(terrain: Terrain) => {
                            if (terrain != null) {
                                // Terrain has been saved, so we can update the tree and close
                                terrainTreeBrowserContext.addNodesLocally([terrainToNode(terrain, treeId)]);
                                setModal(null);
                            }
                            if (!terrainWizardRef.current.isSaving()) {
                                setModal(null);
                            }
                        }}
                        terrain={null}
                        ref={terrainWizardRef}
                    />
                </TerrainModal>
            </TerrainContext.Provider>
        </>
    );
};

const StyledHeader = styled(Header3)`
    align-self: start;
`;

const StyledContainer = styled.div`
    margin-top: ${sectionalMargin};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const StyledTreeContainer = styled.div`
    overflow-y: auto;
    flex: 1 1 0;
`;
