import { RGBColor, color, merge } from "d3";

/**
 * Converts a Hex value into a filter for feColorMatrix
 *
 * https://observablehq.com/@pstuffa/hex-code-to-fecolormatrix-filter
 */
export function hexToFeColorMatrix(hex: string): string | undefined {
    const rgb = hexToRgb(hex);

    if (rgb == null) {
        return undefined;
    }

    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;

    // Create matrix for filter
    const matrix = [
        [-0.7, 0, 0, 0, r],
        [-0.7, 0, 0, 0, g],
        [-0.7, 0, 0, 0, b],
        [0, 0, 0, 1, 0],
    ];
    // Convert matrix into a string, each element seperated by a space
    return merge(matrix).join(" ");
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } | undefined {
    const rgb = color(hex) as RGBColor;

    if (rgb == null || rgb.r == null || rgb.b == null || rgb.g == null) {
        return undefined;
    }

    return { r: rgb.r, g: rgb.g, b: rgb.b };
}
