import { TextButton } from "@iventis/styles";
import React, { FunctionComponent, useState, useMemo } from "react";
import { UploadMapBackgroundForm, LoadingComponent, AssetBrowserComponent, UploadBackgroundData } from "@iventis/components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AssetType } from "@iventis/domain-model/model/assetType";
import {
    AssetsPostRequest,
    AssetsPutRequest,
    convertAssetToUploadBackgroundData,
    convertUploadBackgroundDataToAssetPostData,
    convertUploadBackgroundDataToAssetPutData,
    uploadFileModalDimensions,
} from "@iventis/api-helpers";
import { FilterFormat } from "@iventis/types";
import { Asset } from "@iventis/domain-model/model/asset";
import { AxiosResponse } from "axios";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { UploadAssetModal } from "@iventis/layer-styles/src/upload-model";
import { api } from "../api/api";
import { AssetsRepoTemplate } from "./assets-repo-template";
import { ASSET_CARD_SIZE } from "./constants";
import { getAsset, getAssets } from "./assets-api-helpers";

type Action = { type: "upload"; target?: null } | { type: "replace"; target: Asset } | { type: null; target?: null };

export const MapBackgroundsRepositoryComponent: FunctionComponent = () => {
    const [action, setAction] = useState<Action>({ type: null });

    const [selection, setSelection] = useState<string[]>([]);

    const queryClient = useQueryClient();

    const filter: FilterFormat[] = useMemo(() => [{ fieldName: "assetType", operator: IventisFilterOperator.Eq, value: AssetType.MapBackground }], []);

    const { status, data: assets, error } = useQuery(["map-backgrounds", filter], async () => getAssets({ filter }));

    /** Selected background (undefined if more than one asset is selected) */
    const selectedBackgroundSingle = useMemo(() => (selection?.length === 1 ? assets?.find((a) => selection?.[0] === a.id) : undefined), [selection, assets]);

    const mutation = useMutation(
        async (data: UploadBackgroundData) => {
            if (action?.type === "upload") {
                // If uploading, we create a new asset
                return api.post<Asset, AxiosResponse<Asset>, AssetsPostRequest>("assets", convertUploadBackgroundDataToAssetPostData(data)).then((res) => res.data);
            }
            if (action?.type === "replace") {
                // If replacing, we update an existing asset
                const newData = data;
                const assetPutData = convertUploadBackgroundDataToAssetPutData(newData, action.target);
                // If the thumbnail has not changed, set thumbnailDataUrl to null
                if (!assetPutData.thumbnailDataUrl.startsWith("data:image")) {
                    assetPutData.thumbnailDataUrl = null;
                }
                return api.put<Asset, AxiosResponse<Asset>, AssetsPutRequest>(`assets/${action.target.id}`, assetPutData).then((res) => res.data);
            }
            throw new Error("Cannot mutate data without specifying an action");
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["map-backgrounds"]);
            },
        }
    );

    const getExistingBackgroundInUploadFormFormat: (assetId: string) => Promise<UploadBackgroundData> = async (assetId) => {
        const existingBackground = await getAsset(assetId);
        return convertAssetToUploadBackgroundData(existingBackground);
    };

    return (
        <>
            <AssetsRepoTemplate
                repoTitle="Map Backgrounds"
                repoSubtitle="Map Background repository"
                buttons={[
                    <TextButton key="upload" type="button" onClick={() => setAction({ type: "upload" })}>
                        Upload new map background
                    </TextButton>,
                    <TextButton key="replace" type="button" onClick={() => setAction({ type: "replace", target: selectedBackgroundSingle })} disabled={selection?.length !== 1}>
                        Edit map background
                    </TextButton>,
                ]}
                browserComponent={
                    status === "loading" ? (
                        <LoadingComponent />
                    ) : status === "error" ? (
                        <>
                            <p>Error!</p>
                            <p>{JSON.stringify(error, null, 4)}</p>
                        </>
                    ) : (
                        <AssetBrowserComponent
                            imageUrlGetter={(asset) => asset.thumbnailUrl}
                            assets={assets}
                            fetchNextPage={() => null}
                            isFetchingNextPage={false}
                            cardHeight={ASSET_CARD_SIZE}
                            cardMinWidth={ASSET_CARD_SIZE}
                            cardWidth="100%"
                            onSelectionChange={setSelection}
                            onEditClicked={(id) => setAction({ type: "replace", target: assets.find((a) => a.id === id) })}
                        />
                    )
                }
            />
            <UploadAssetModal open={action?.type === "upload"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadMapBackgroundForm close={() => setAction({ type: null })} uploadRemotely={mutation.mutateAsync} />
            </UploadAssetModal>
            <UploadAssetModal open={action?.type === "replace"} close={() => setAction({ type: null })} minModalDimensions={uploadFileModalDimensions}>
                <UploadMapBackgroundForm
                    close={() => setAction({ type: null })}
                    uploadRemotely={mutation.mutateAsync}
                    getExistingData={getExistingBackgroundInUploadFormFormat}
                    existingId={action.target?.id}
                />
            </UploadAssetModal>
        </>
    );
};
