/* eslint-disable consistent-return */
import { BottomUpHierarchy } from "@iventis/domain-model/model/bottomUpHierarchy";
import { replaceItemInArray } from "@iventis/utilities";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuid } from "uuid";
import { TreeBrowserNode, UnionOfNodes } from "./types/data-types";

/**
 * This function receives an array of nodes and finds the node matching the given id.
 * If you are searching a tree not an array, simply pass [tree] as the array.
 *
 * @param {TreeBrowserNode[]} arr - An array of nodes
 * @param {string} nodeId - id of the node you wish to find
 * @return {TreeBrowserNode} The node matching the passed id
 *
 */

export const findNode = <N extends TreeBrowserNode = TreeBrowserNode>(arr: N[], nodeId: string): UnionOfNodes<N> => {
    if (nodeId === undefined) return undefined;
    if (arr.length > 0) {
        return (
            (arr.find((node: N) => node?.id === nodeId) ||
                main.findNode<N>(
                    arr.flatMap((node: N) => (node?.childNodes as N[]) || []),
                    nodeId
                )) ??
            // If you can't find by id, try by source id before retunring undefined
            findNodeByProperty(arr, "sourceId", nodeId)
        );
    }
};

export const findNodeBySourceId = <N extends TreeBrowserNode = TreeBrowserNode>(arr: N[], sourceId: string): UnionOfNodes<N> => findNodeByProperty(arr, "sourceId", sourceId);

/**
 * Recursively searches a node array until a desired value is found
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findNodeByProperty = <N extends TreeBrowserNode = TreeBrowserNode, PropertyType extends keyof N = any>(
    arr: N[],
    property: PropertyType,
    value: N[PropertyType]
): UnionOfNodes<N> => {
    if (value === undefined) return undefined;
    if (arr.length > 0) {
        return (
            arr.find((node: N) => node?.[property] === value) ||
            main.findNodeBySourceId<N>(
                arr.flatMap((node: N) => (node?.childNodes as N[]) || []),
                property,
                value
            )
        );
    }
};

/**
 * This function receives an array of nodes and finds the nodes matching the given id.
 * If you are searching a tree not an array, simply pass [tree] as the array.
 *
 * @param {TreeBrowserNode[]} arr - An array of nodes
 * @param {string} nodeIds - id of the node you wish to find
 * @return {TreeBrowserNode[]} The nodes matching the passed ids
 *
 */

export const findNodes = <N extends TreeBrowserNode>(arr: N[], nodeIds: string[], errorIfNotFound = true): UnionOfNodes<N>[] => {
    const nodesFound: N[] = [];
    nodeIds.forEach((nodeId) => {
        const nodeFound = findNode(arr, nodeId);
        if (nodeFound == null) {
            if (errorIfNotFound) {
                throw new Error(`Failed to find node with id ${nodeId}`);
            }
            return;
        }
        nodesFound.push(nodeFound);
    });
    return nodesFound;
};

/** Refines a union of tree browser nodes by the "type" property. I.e. RefineType<RootNode | PersonNode, NodeType.Person> = PersonNode */
type RefineType<T extends TreeBrowserNode, TType extends T["type"]> = T extends { type: TType } ? T : never;

/** Returns all the nodes in the given tree of the given type */
export const findNodesByType = <N extends TreeBrowserNode, NType extends N["type"]>(
    arr: N[],
    type: NType
): (RefineType<UnionOfNodes<N>, NType> extends never ? UnionOfNodes<N> & { type: NType } : RefineType<UnionOfNodes<N>, NType>)[] => {
    if (arr?.length > 0) {
        const filtered = arr.filter((n) => n.type === type);
        return [
            ...filtered,
            ...findNodesByType(
                arr.flatMap((n) => n?.childNodes ?? []),
                type
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any; // Casting to any due to complexity of return type. This function is unit tested so should catch mistakes
    }
    return [];
};

/**
 * This function returns an exact copy of a given tree of nodes.
 *
 * @param {TreeBrowserNode} tree - The node you wish to copy
 * @return {TreeBrowserNode} An exact copy of the node passed
 *
 */
// cloneDeep generates a deep copy, maintaining dates and objects
export const copyTree = <N extends TreeBrowserNode, T extends N | N[]>(tree: T): T => cloneDeep(tree);

/** A function to check what update operation needs to happen (moving node or updating node properties) */
export const updateNode = <N extends TreeBrowserNode>(
    tree: N,
    node: N,
    expandedNodeIds: string[],
    previousNodeParentId?: string,
    sort?: (nodes: N[]) => N[],
    skipExpandedCheck = false,
    replaceChildren = false
): N => {
    if (previousNodeParentId != null && node.parentId !== previousNodeParentId) {
        return moveNode(tree, node.id, node.parentId, sort);
    }
    if (expandedNodeIds.includes(node.id) && !skipExpandedCheck) {
        // Update all non-hierarchy properties
        return updateNodes(tree, [node], (oldNode, newNode) => ({
            ...newNode,
            parentId: oldNode.parentId,
            childNodes: replaceChildren ? newNode.childNodes : oldNode.childNodes,
            childCount: replaceChildren ? newNode.childCount : oldNode.childCount,
        }));
    }
    return updateNodeProperties(tree, node, replaceChildren);
};

/**
 * This function moves a given node from one parent's childNodes to another given node, and returns the resulting tree.
 *
 * @param {TreeBrowserNode} tree - A tree of nodes
 * @param {string} id - id of the node you wish to move
 * @param {string} moveToId - id of the node you wish to place the moving node inside
 * @return {TreeBrowserNode} The resulting tree of Nodes
 *
 */
export const moveNode = <N extends TreeBrowserNode>(tree: N, id: string, moveToId: string, sort?: (nodes: N[]) => N[]): N => {
    const newTree = copyTree(tree);

    if (id == null || moveToId == null) {
        throw new Error("Must pass valid ids into moveNode function");
    }

    // if (newTree.type === NodeType.MapObject) {
    //     throw new Error("Map Object has no children so cannot act as a tree");
    // }

    const moveToNode = findNode([newTree], moveToId);
    const movingNode = findNode(newTree.childNodes as N[], id);

    if (moveToNode == null || movingNode == null) {
        throw new Error("Must pass valid ids into moveNode function");
    }

    const movingOutNode = findNode([newTree], movingNode.parentId);
    if (movingOutNode.id === moveToNode.id) {
        return;
    }
    // Add node to new parent
    moveToNode.childNodes = sort == null ? [...moveToNode.childNodes, movingNode] : sort([...(moveToNode.childNodes as N[]), movingNode]);
    moveToNode.childCount += 1;
    // Update node's parent id
    movingNode.parentId = moveToNode.id;
    // Add node to from old parent
    movingOutNode.childNodes = movingOutNode.childNodes.filter((node) => node.id !== id);
    movingOutNode.childCount -= 1;
    return newTree;
};

/** Moves multiple nodes within the given tree to a new parent by the given parent id, and returns the resulting up to date tree */
export const moveNodes = <N extends TreeBrowserNode>(nodes: N[], _tree: N, newParentId: string, sort?: (nodes: N[]) => N[]) => {
    const tree = copyTree(_tree);
    const newParent = findNode([tree], newParentId);

    if (newParent == null) {
        throw new Error(`New parent with id: ${newParentId} does not exist in the tree, so you cannot move anything into it`);
    }

    // Apply mapping so we only have to find the old parents once
    const mapParentToNodes = nodes.reduce((cum, node) => {
        // Throw error if any of the nodes are the parent
        if (node.id === newParentId) {
            throw new Error("Cannot move node into itself");
        }

        if (cum[node.parentId] === undefined) {
            // eslint-disable-next-line no-param-reassign
            cum[node.parentId] = [node];
        } else {
            cum[node.parentId].push(node);
        }

        return cum;
    }, {} as Record<string, N[]>);

    Object.entries(mapParentToNodes).forEach(([parentId, nodes]) => {
        const parent = findNode([tree], parentId);
        if (parent == null) {
            return;
        }
        parent.childCount -= nodes.length;
        parent.childNodes = parent.childNodes.filter((node) => !nodes.some((n) => node.id === n.id));
    });

    newParent.childCount += nodes.length;
    const newParentChildren = [...newParent.childNodes, ...nodes.map((node) => ({ ...node, parentId: newParent.id }))] as N[];
    newParent.childNodes = sort == null ? newParentChildren : sort(newParentChildren);

    return tree;
};

/** Updates the lastUpdated properties on the provided nodes */
export const updateNodesLastUpdatedAt = <N extends TreeBrowserNode>(nodes: N[], _tree: N, newParentId: string) => {
    const newTree = copyTree(_tree);

    nodes.forEach((node) => {
        const foundNode = findNode([newTree], node.id);
        foundNode.lastUpdatedAt = new Date();
    });

    if (newParentId != null) {
        // update parent too if new
        const foundParentNode = findNode([newTree], newParentId);
        if (foundParentNode != null) {
            foundParentNode.lastUpdatedAt = new Date();
        }
    }

    return newTree;
};

/**
 * Updates an array of nodes in a given tree. Takes a function to determine what the new updated node should be. Useful if you want to only update some properties
 * @param tree the tree that the nodes belong in
 * @param updatedNodes an array of updated nodes
 * @param func the function to determine the new node to be inserted into the given tree
 * @returns A new tree with nodes updated
 */
export const updateNodes = <N extends TreeBrowserNode>(tree: N, updatedNodes: N[], func: <N extends TreeBrowserNode>(existingNode: N, updatedNode: N) => N) => {
    const newTree = copyTree(tree);

    updatedNodes.forEach((updatedNode) => {
        const parent = findNode([newTree], updatedNode.parentId);
        const index = parent.childNodes.findIndex((n) => n.id === updatedNode.id);
        const oldNode = parent.childNodes[index];

        parent.childNodes[index] = func(oldNode, updatedNode);
    });

    return newTree;
};

/**
 * This function updates a node in the tree and return the full tree.
 *
 * @param {TreeBrowserNode} tree - A tree of nodes
 * @param {TreeBrowserNode} updatedNode - The new node (must have the same id as the previous node)
 * @return {TreeBrowserNode} The resulting tree of Nodes
 *
 */
export const updateNodeProperties = <N extends TreeBrowserNode>(tree: N, updatedNode: N, replaceChildren?: boolean, sort?: (nodes: N[]) => N[]) => {
    const newTree = copyTree(tree);

    // Find the parent and index of the node updating. If it doesn't exist, we're at the root, so just return the tree
    const parent = findNode([newTree], updatedNode.parentId);
    if (parent) {
        const index = parent.childNodes.findIndex((n) => n.id === updatedNode.id);
        const oldNode = parent.childNodes[index];

        if (replaceChildren) {
            // Replace item with new updated node
            parent.childNodes[index] = updatedNode;
        } else {
            // Concat our new node's childNodes with our existing node's, incase we've moved data onto it
            const updatedNodeWithChildren = { ...updatedNode, childNodes: [...updatedNode.childNodes, ...oldNode.childNodes] };
            // Remove dupes by filtering out nodes with an id already in the array
            updatedNodeWithChildren.childNodes = updatedNodeWithChildren.childNodes.filter(
                (node, index, self) => index === self.findIndex((nodeInSelf) => nodeInSelf.id === node.id)
            );
            // Replace item with new updated node
            parent.childNodes[index] = updatedNodeWithChildren;
        }

        // Retain any breadcrums hierarchies from the old node if the exist.
        // Required for static libraires otherwise the breadcrum state and last viewed at will be lost when a node is opened.
        if (oldNode?.bottomUpHierarchy != null) {
            parent.childNodes[index].bottomUpHierarchy = oldNode.bottomUpHierarchy;
            parent.childNodes[index].lastViewedAt = oldNode.lastViewedAt;
        }

        if (sort != null) {
            parent.childNodes = sort(parent.childNodes as N[]);
        }
    }

    // If parent can't be found and the updated node is the root of the tree, update the root of the tree
    if (parent == null && newTree.id === updatedNode.id) {
        return { ...newTree, ...updatedNode };
    }

    return newTree;
};

export const updateNodesProperties = <N extends TreeBrowserNode>(tree: N, updateNodes: N[], sort?: (nodes: N[]) => N[]) =>
    updateNodes.reduce((sortedTree, node) => updateNodeProperties(sortedTree, node, undefined, sort), tree);

/**
 * This function deletes a given node from a given tree and returns the reulting tree of nodes.
 *
 * @param {TreeBrowserNode} tree - A tree of nodes
 * @param {string} id - id of the node you wish to delete
 * @return {TreeBrowserNode} The resulting tree of Nodes
 *
 */
export const deleteNode = <N extends TreeBrowserNode>(tree: N, id: string): N => {
    const newTree = copyTree(tree);

    if (id == null) {
        throw new Error("Must pass valid id into deleteNode");
    }

    const deletedNode = findNode([newTree], id);

    if (deletedNode == null) {
        throw new Error(`No node exists with id: ${id}`);
    }

    const parentNode = findNode([newTree], deletedNode.parentId);
    if (!parentNode) {
        return;
    }

    parentNode.childNodes = parentNode.childNodes.filter((node) => node.id !== id);
    // We might not have all the childNodes locally, so we can't use childNodes.length, instead just decrement the child count.
    parentNode.childCount -= 1;

    return newTree;
};

/**
 * This function deletes nodes from a given tree and returns the reulting tree of nodes.
 *
 * @param {TreeBrowserNode} tree - A tree of nodes
 * @param {string} ids - ids of the node you wish to delete
 * @return {TreeBrowserNode} The resulting tree of Nodes
 *
 */
export const deleteNodes = <N extends TreeBrowserNode>(tree: N, ids: string[]): UnionOfNodes<N> => {
    let newTree = tree;
    ids.forEach((id) => {
        try {
            newTree = deleteNode(newTree, id);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`${id} could not be found in tree while attempting to delete.`);
        }
    });
    return newTree;
};

/**
 * This function adds a new node to a given tree and inserts it inside the childNodes of a given parent node.
 *
 */
export const addNode = <N extends TreeBrowserNode>(
    tree: N,
    parentId: string,
    type: N["type"],
    id: string,
    name: string,
    sort?: (nodes: N[]) => N[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extraProps?: Partial<N>
): N => {
    const newTree = copyTree(tree);
    const parentNode = findNode([newTree], parentId);
    if (parentNode == null) {
        throw new Error("Must pass valid parentId into addNode");
    }
    if (findNode([newTree], id)) {
        // eslint-disable-next-line no-console
        console.warn(`Node already exists with id: ${id}`);
        return tree;
    }
    const newNode = {
        id,
        name,
        type,
        parentId,
        childNodes: [],
        childCount: 0,
        ...extraProps,
    } as N;
    parentNode.childNodes.push(newNode);
    if (sort != null) {
        parentNode.childNodes = sort(parentNode.childNodes as N[]);
    }
    parentNode.childCount += 1;
    return newTree;
};

/** Adds an array of nodes with a shared parent to a given tree */
export const addNodes = <N extends TreeBrowserNode>(tree: N, nodes: UnionOfNodes<N>[], sort?: (nodes: N["childNodes"]) => N["childNodes"]) => {
    const newTree = copyTree(tree);
    const uniqueParents = findNodes(
        [newTree],
        nodes.reduce<string[]>((acc, { parentId }) => (acc.includes(parentId) ? acc : [...acc, parentId]), [])
    );
    uniqueParents.forEach((parentNode) => {
        let newChildren = 0;
        nodes.forEach((node) => {
            // If the node doesn't belong to the parent then return early
            if (!(node.parentId === parentNode.sourceId || node.parentId === parentNode.id)) {
                return;
            }
            if (parentNode.sourceId && parentNode.sourceId !== parentNode.id) {
                // If the parent node has a source id different to its' id, then the childs parentId property must point to that instead
                // eslint-disable-next-line no-param-reassign
                node.parentId = parentNode.sourceId;
            }
            // If the node does not exist within its' parents children, then append, else, replace
            const existing = parentNode.childNodes.find((child) => child.id === node.id);
            if (existing == null) {
                parentNode.childNodes.push(node);
                newChildren += 1;
            } else {
                // eslint-disable-next-line no-param-reassign
                parentNode.childNodes = replaceItemInArray(parentNode.childNodes, { ...node, ...existing });
            }
        });
        if (sort != null) {
            // eslint-disable-next-line no-param-reassign
            parentNode.childNodes = sort(parentNode.childNodes);
        }
        // eslint-disable-next-line no-param-reassign
        parentNode.childCount += newChildren;
    });
    return newTree;
};

/**
 * This function finds all the parent id's of a given node inside a given tree of nodes.
 *
 * @param {TreeBrowserNode} tree - A tree of nodes
 * @param {string} id - id of the nested node
 * @return {string[]} an array of uuids for all parents of a nested node
 *
 *
 */
export const findAllParentIds = <N extends TreeBrowserNode>(tree: N, node: string | N): string[] => {
    const nodes = [];
    const fullNode = typeof node === "string" ? findNode([tree], node) : node;
    if (fullNode == null) {
        return [];
    }
    nodes.push(fullNode);
    const findParent = (_tree: N, child: N) => {
        const parentNode = findNode([_tree], child.parentId);
        if (parentNode) {
            nodes.push(parentNode);
            findParent(_tree, parentNode);
        }
    };
    findParent(tree, fullNode);
    return nodes.map((n) => n.id);
};

export const flattenNodeTree = <N extends TreeBrowserNode>(tree: N | N[], filterAllow?: (node: N) => boolean): UnionOfNodes<N>[] => {
    if (Array.isArray(tree)) {
        return tree.flatMap((node) => flattenNodeTree(node, filterAllow));
    }
    const childNodes = filterAllow ? tree.childNodes?.filter(filterAllow) : tree.childNodes;
    return [tree, ...(childNodes?.flatMap<N>((child: N) => flattenNodeTree(child, filterAllow)) ?? [])];
};

export const isAllNodesSelected = <N extends TreeBrowserNode>(tree: N, selectedNodeIds: string[]): boolean => {
    const childNodeIds = tree?.childNodes?.map(({ id }) => id);
    return childNodeIds != null && childNodeIds.length > 0 ? childNodeIds.every((id) => selectedNodeIds.includes(id)) : false;
};

/**
 * Searches the parents of a node until specified type is found
 */
export const findParentNodeByType = <N extends TreeBrowserNode, NType extends N["type"]>(
    tree: N | N[],
    nodeType: NType,
    node: UnionOfNodes<N>,
    throwError = true
): RefineType<UnionOfNodes<N>, NType> extends never ? UnionOfNodes<N> & { type: NType } : RefineType<UnionOfNodes<N>, NType> => {
    const treeArray = Array.isArray(tree) ? tree : [tree];
    const foundNode = findNode(treeArray, node.parentId);

    if (foundNode == null) {
        if (throwError) {
            throw new Error(`No node exists up the tree with type: ${nodeType}`);
        } else {
            return undefined;
        }
    }

    if (foundNode.type === nodeType) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return foundNode as any; // Casting to any due to complexity of return type. This function is unit tested so should catch mistakes
    }
    return findParentNodeByType(tree, nodeType, foundNode, throwError);
};

/** Get nodes which are being displayed in the tree browser */
export const getNodesBeingDisplayed = <N extends TreeBrowserNode>(tree: N, expandedNodeIds: string[]) => {
    const displayedNodes: N[] = [];
    getChildNodesBeingDisplayed(tree, expandedNodeIds, displayedNodes);
    return displayedNodes.map((node) => node.id);
};

const getChildNodesBeingDisplayed = <N extends TreeBrowserNode>(node: N, expandedNodeIds: string[], displayedNodes: N[] = []) => {
    const children = node.childNodes as N[];
    const displayedChildren = children.filter((child) => expandedNodeIds.includes(child.id));
    displayedNodes.push(...children);
    displayedChildren.forEach((child) => getChildNodesBeingDisplayed(child, expandedNodeIds, displayedNodes));
};

/** Finds the nodes from a given list which are descendants of the given node id */
export const findNodesThatAreDescendants = <N extends TreeBrowserNode>(tree: N, nodeId: string, nodesToCheck: string[]) => {
    const nodesToCollapse = nodesToCheck.filter((id) => {
        const nodeParentIds = findAllParentIds(tree, id);
        return nodeParentIds.includes(nodeId);
    });
    return nodesToCollapse;
};

/** Finds the nodes from a given list which are ancestors of the given nodes */
export const findNodesThatAreAncestors = <N extends TreeBrowserNode>(tree: N, nodeIds: string[], nodesToCheck: string[]) => {
    const nodesToCollapse = nodesToCheck.filter((id) => {
        const node = findNode([tree], id);
        if (node == null) {
            return [];
        }
        const nodeDescedants = listIdsOfAllDescendants(node);
        return nodeDescedants.some((d) => nodeIds.includes(d));
    });
    return nodesToCollapse;
};

export const createDefaultNode = (): TreeBrowserNode => ({
    id: null,
    childNodes: [],
    parentId: null,
    type: null,
    name: null,
    favourite: false,
});

/**
 * Favourites a node within a tree of nodes. Returns the resulting tree
 * @param tree - the tree the node resides in
 * @param id - the id of the node to be favourited
 * @param favourite - the value we are setting favourite, defaults to true
 */
export const toggleFavourite = <TNode extends TreeBrowserNode>(tree: TNode, id: string, favourite = true): TNode => {
    const newTree = copyTree(tree);

    const node = findNode([newTree], id);
    if (node == null) {
        throw new Error("Could not find node to be favourited");
    }
    node.favourite = favourite;

    return newTree;
};

export const forEachRecursiveChild = <TNode extends TreeBrowserNode>(node: TNode, callback: (n: TNode) => void) => {
    node?.childNodes?.forEach((child) => {
        callback(child as TNode);
        forEachRecursiveChild(child, callback);
    });
};

/** Recursively lists all descendants of the given node */
export const listIdsOfAllDescendants = <TNode extends TreeBrowserNode>(node: TNode) => {
    if (node == null) {
        return [];
    }
    const children: string[] = [];
    // Select all children when you select the parent
    forEachRecursiveChild(node, (child) => {
        children.push(child.id);
    });
    return children;
};

/** Recursively lists all descendants of the given node */
export const listOfAllDescendants = <TNode extends TreeBrowserNode>(node: TNode) => {
    if (node == null) {
        return [];
    }
    const children: TNode[] = [];
    // Select all children when you select the parent
    forEachRecursiveChild(node, (child) => {
        children.push(child);
    });
    return children;
};

export const getAllAncestorsIdsToSelect = <TNode extends TreeBrowserNode>(tree: TNode, node: TNode, selectedNodeIds: string[]) => {
    const ancestorsToSelect: string[] = [];
    const recursive = (tree: TNode, child: TNode) => {
        if (selectedNodeIds.includes(child.parentId) || tree.id === child.parentId) {
            return;
        }
        const parent = findNode([tree], child.parentId);
        const hasParentGotAllChildrenSelected = parent && parent.childNodes.every((c) => selectedNodeIds.includes(c.id) || child.id === c.id);
        if (hasParentGotAllChildrenSelected) {
            ancestorsToSelect.push(parent.id);
            recursive(tree, parent);
        }
    };
    recursive(tree, node);
    return ancestorsToSelect;
};

export const getAllAncestorsToSelect = <TNode extends TreeBrowserNode>(tree: TNode, node: TNode, selectedNodeIds: string[]) => {
    const ancestorsToSelect: TNode[] = [];
    const recursive = (tree: TNode, child: TNode) => {
        if (selectedNodeIds.includes(child.parentId) || tree.id === child.parentId || tree.sourceId === child.parentId) {
            return;
        }
        const parent = findNode([tree], child.parentId);
        const hasParentGotAllChildrenSelected = parent && parent.childNodes.every((c) => selectedNodeIds.includes(c.id) || child.id === c.id);
        if (hasParentGotAllChildrenSelected) {
            ancestorsToSelect.push(parent);
            recursive(tree, parent);
        }
    };
    recursive(tree, node);
    return ancestorsToSelect;
};

/**
 * Travels down the given tree and appends the first nodes in their "sub-trees" to the resulting array based on the given selection
 *
 * Scenario: When we select a node, typically everything below it is appended to the selection array.
 *           But sometimes we are only interested in that node we selected, not all the children,
 *           so this function will return all the nodes who are selected, but their parent isn't selected
 */
export const recursivelyAppendTopSelection = <TNode extends TreeBrowserNode>(node: TNode, selection: string[], accumulation: TNode[] = []) => {
    node.childNodes.forEach((node) => {
        // If the node is selected, append to the accumulation and move on (DO NOT CHECK CHILDREN)
        if (selection.includes(node.id)) {
            accumulation.push(node as TNode);
        } else {
            // If the node is not selected, check their children
            main.recursivelyAppendTopSelection(node, selection, accumulation);
        }
    });

    return accumulation;
};

/**
 * @summary Is the given id the only child in the current selection
 *
 * @description
 * For example:
 *   - folder (selected)
 *     - folder (selected)
 *       - layer (selected)
 *
 * the layer would be the only child, but:
 *   - folder (selected)
 *     - folder (selected)
 *       - layer (selected)
 *     - layer (selected)
 *
 * the layer would not be the only child
 * */
export const isOnlyChildInSelection = <TNode extends TreeBrowserNode>(node: TNode, selection: string[], accumulation: TNode[], idOfChild: string) => {
    // If multiple children have been detected, return
    if (accumulation?.length > 1) {
        return false;
    }
    // If child is not selected, it's not in the selection, so return false
    if (!selection.includes(idOfChild)) {
        return false;
    }
    let childHere = false;
    if (node.childNodes?.some((child) => child.id === idOfChild)) {
        childHere = true;
    }
    node.childNodes.forEach((node) => {
        if (selection.includes(node.id)) {
            // If they have no selected children they are the bottom most selection, so append and return
            // Equally, if the child is within these childNodes, we don't care about their selected children, so append and return
            if (!node.childNodes?.some((child) => selection.includes(child.id)) || childHere) {
                accumulation.push(node as TNode);
                return;
            }
            if (node.childNodes?.length > 1) {
                const selectedChildren = node.childNodes.filter((n) => selection.includes(n.id));
                if (selectedChildren.length > 1) {
                    // If he selected node has more than one child, we can assume they are all selected too, so append them all
                    accumulation.push(...(node.childNodes as TNode[]));
                    return;
                }
            }
        }
        if (!childHere) {
            // If the node is not selected, check their children
            main.isOnlyChildInSelection(node, selection, accumulation, idOfChild);
        }
    });

    return !(accumulation?.length > 1);
};

/**
 * Loops through all the nodes in the given tree and runs the callback on each node
 */
export const forEachNode = async <N extends TreeBrowserNode>(nodes: N[], callback: (n: N) => Promise<void>) => {
    for (let i = 0; i < nodes.length; i += 1) {
        const node = nodes[i];
        await callback(node);
        await forEachNode(node.childNodes as N[], callback);
    }
};

/**
 * Immutably replaces the given nodes in the tree (but keeping the existing parent id in order to maintain the structure of the tree)
 * @returns Updated tree
 * */
export const replaceNodes = <N extends TreeBrowserNode>(
    tree: N[],
    replacementNodes: N[],
    matcher = (oldNode: N, replacementNode: N) => oldNode.sourceId === replacementNode.sourceId
) => {
    const updatedNodes: N[] = [];
    tree?.forEach((node) => {
        const matchedNode = replacementNodes.find((replacementNode) => matcher(node, replacementNode));
        if (matchedNode == null) {
            updatedNodes.push({ ...node, childNodes: replaceNodes(node.childNodes, replacementNodes, matcher) } as N);
        } else {
            updatedNodes.push({ ...matchedNode, parentId: node.parentId, childNodes: replaceNodes(node.childNodes as N[], replacementNodes, matcher) });
        }
    });
    return updatedNodes;
};

/**
 * M utates the given list of nodes by updating the ids of nodes of the given type
 * @returns Updated tree
 * */
export const replaceIdsInTreeByType = <N extends TreeBrowserNode>(tree: N[], type: N["type"], parentId?: string) => {
    const updatedNodes: N[] = [];
    tree?.forEach((node) => {
        /* eslint-disable no-param-reassign */
        node.parentId = parentId ?? node.parentId;
        if (node.type === type) {
            node.id = uuid();
            node.sourceId = node.id;
            /* eslint-enable no-param-reassign */
        }
        replaceIdsInTreeByType(node.childNodes as N[], type, node.id);
    });
    return updatedNodes;
};

/** Works its way up the bottom up hierarchy to find the first parent of the given type */
export const findFirstParentByType = <N extends TreeBrowserNode>(bottomUpTree: BottomUpHierarchy<N>, type: N["type"], throwErrorIfNotFound = true): N => {
    const { parent } = bottomUpTree;
    if (throwErrorIfNotFound && parent?.item == null) {
        throw new Error(`No parent found with type: ${type}`);
    }
    if (parent.item.type === type) {
        return parent.item;
    }
    return findFirstParentByType(parent, type);
};

/** Finds how many levels deep the given node is within the given tree hieracrhy */
export const getNodeNestedLevel = <N extends TreeBrowserNode>(mapNode: N, node: UnionOfNodes<N>, level = 0): number => {
    const parent = findNode([mapNode], node.parentId);
    if (parent == null) {
        return level;
    }
    if (parent.id === mapNode.id) {
        return level;
    }
    return getNodeNestedLevel(mapNode, parent, level + 1);
};

export const filterTree = <N extends TreeBrowserNode>(tree: N, filter: (node: N) => boolean): N => {
    const allNodesFiltered = flattenNodeTree(tree).filter(filter);
    return recursivelyFilterTree(tree, allNodesFiltered, { ...copyTree(tree), childNodes: [] }).filteredTree;
};

export const recursivelyFilterTree = <TNode extends TreeBrowserNode>(tree: TNode, filteredNodes: TNode[], parent: TNode) => {
    let found = false;
    const unfilteredParent = findNode([tree], parent.id);
    unfilteredParent.childNodes.forEach((node) => {
        if (filteredNodes.some((n) => n.id === node.id)) {
            found = true;
            parent.childNodes.push(node as TNode);
        } else {
            const { found: childFound, filteredTree: nodeWithFilteredChildren } = main.recursivelyFilterTree(tree, filteredNodes, { ...copyTree(node), childNodes: [] } as TNode);
            if (childFound) {
                found = true;
                parent.childNodes.push(nodeWithFilteredChildren as TNode);
            }
        }
    });

    return { filteredTree: parent, found } as const;
};

/** Travels down the new tree and adds any nodes that do not exist in the existing tree to the accumulated array */
export const findMissingNodes = <N extends TreeBrowserNode>(existingTree: N, newTree: N, acc: N[] = []) => {
    newTree.childNodes.forEach((child) => {
        const existingChild = existingTree.childNodes?.find((x) => x.id === child.id);
        if (!existingChild) {
            acc.push(child as N);
            return;
        }
        findMissingNodes(existingChild, child, acc);
    });
    return acc;
};

/**
 * Creates a new breadcrumb element array to the new root node
 */
export function createBreadcrumb<T extends TreeBrowserNode>(node: string | T, tree: T, excludeTypes: T["type"][] = [], options = { includeSelf: true }) {
    if (node == null || tree == null) {
        return [];
    }
    const fullNode = typeof node === "string" ? findNode([tree], node) : node;
    if (fullNode == null) {
        return [];
    }
    return recursivelyAppendParentsToBreadcrumb(fullNode, tree, options.includeSelf ? [fullNode] : [], excludeTypes);
}

/**
 * Finds the parent node in the tree and adds to breadcrumb array
 */
function recursivelyAppendParentsToBreadcrumb<T extends TreeBrowserNode>(node: T, tree: T, breadCrumb: T[], excludeTypes: T["type"][]): T[] {
    const parentNode = findNode([tree], node.parentId);
    // Nothing else to search in the tree
    if (parentNode == null || excludeTypes.includes(parentNode.type)) {
        return breadCrumb;
    }
    const updatedBreadCrumb = [parentNode, ...breadCrumb];
    return recursivelyAppendParentsToBreadcrumb(parentNode, tree, updatedBreadCrumb, excludeTypes);
}

export const main = {
    findNode,
    copyTree,
    moveNode,
    moveNodes,
    addNode,
    deleteNode,
    findAllParentIds,
    updateNode,
    createDefaultNode,
    findParentNodeByType,
    findNodeBySourceId: findNodeByProperty,
    recursivelyAppendTopSelection,
    isOnlyChildInSelection,
    recursivelyFilterTree,
};

export default main;
