export enum ModelRenderStatus {
    Proccessing = "Processing", // Processing the model
    Visible = "Visible", // Model has been loaded and can be displayed
    Error = "Error", // Error loading the model
    None = "None", // No model to display
}

export type UploadModelData = {
    modelId?: string;
    thumbnailId?: string;
    name: string;
    thumbnail: { fileName: string; imageUrl: string; changed?: boolean };
    model: { fileName: string; modelUrl: string | null; error?: string; modelRenderStatus?: ModelRenderStatus };
    categories: string[];
} & ModelVariableSize &
    CustomColourValue &
    CustomImageValue;

export interface ModelVariableSize {
    variableSizeModel: boolean;
    size?: { height: number; width: number; length: number };
}

export interface CustomColourValue {
    customColour: boolean;
}

export type UploadModelImageData = {
    name: string;
    image: { fileName: string; imageUrl: string; height?: number; width?: number };
};

export type UploadIconData = {
    name: string;
    image: { fileName: string; imageUrl: string; height?: number; width?: number };
    categories: string[];
    metaData: { sdf: boolean };
};

export interface CustomImageValue {
    customImage: boolean;
    aspectRatio?: { width: number; height: number };
}
