export const terrain = {
    title: {
        create: "Create terrain",
        edit: "Edit terrain",
    },
    name: "Name",
    bounds: "Bounds",
    tiles: "Tiles",
    remove_tiles: "Remove tiles",
    Pending: "Pending",
    Active: "Active",
    Deleted: "Deleted",
    terrain_options: "Click to open terrain options",
    edit: "Edit terrain",
    delete: "Delete terrain",
    uploading_tiles: "Uploading tiles",
} as const;
