import React, { useMemo, useState, ReactNode } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Body2, Body3, Header4, InteractiveElement, inlineTextIconMargin, media, muiInputFormsCSS, styled } from "@iventis/styles";
import { FormWizardTitle } from "@iventis/components";
import { Theme } from "@emotion/react";

import { v4 as uuid } from "uuid";
import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DataField } from "@iventis/domain-model/model/dataField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { hasDataFieldChangedShallow, isValidUuid, useConstant } from "@iventis/utilities";
import { useMutation } from "@tanstack/react-query";
import ExpandableRadioOption from "@iventis/components/src/expandable-radio-option";
import { FormWizardTemplate, Stage } from "@iventis/components/src/form-wizard-template";
import { WHAT3WORDS_ATTRIBUTE_NAME } from "@iventis/what3words";
import { dataFieldTypes, emptyDataField } from "./data-fields-types.constants";
import { AdditionalAttributeDataFormComponent } from "./attribute-editor";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";
import { AttributeTypeSelector, AttributeNameEditor } from "./attribute-editor-components";
import { AttributeDefaultValueComponent } from "./attribute-default-value";

enum AddAttributeOptions {
    predefined = "Predefined",
    custom = "Custom",
}

enum AddAttributeStages {
    predefinedSelector = 0,
    createAttribute = 1,
}

interface AddAttributeFormProps {
    projectAttributes: DataField[];
    onClose: () => void;
    onSave: (newDatafield: DataField, predefined?: boolean) => void;
    AdditionalAttributeDataForm: AdditionalAttributeDataFormComponent;
    navigateToProjectSettings?: () => void;
    savedDataField: DataField;
    filterDataFieldTypes?: DataFieldType[];
}

export const AddAttributeForm: React.FunctionComponent<AddAttributeFormProps> = ({
    projectAttributes,
    savedDataField,
    onClose,
    onSave,
    AdditionalAttributeDataForm,
    navigateToProjectSettings,
    filterDataFieldTypes = [],
}) => {
    const translate = useIventisTranslate();
    const { dataFieldsService } = useDataFieldServices();
    const { resourceId } = useDataFieldConfig();
    const dataFieldId = useConstant<string>(uuid);

    const hasPredefinedAttributes = projectAttributes && projectAttributes.length > 0;

    const [currentStage, setCurrentStage] = useState<AddAttributeStages>(0);
    const [attributeSection, setAttributeSection] = useState<AddAttributeOptions>(hasPredefinedAttributes ? AddAttributeOptions.predefined : AddAttributeOptions.custom);
    const [predefinedAttributeSelection, setPredefinedAttributeSelection] = useState<string>(null);
    const [newDatafield, setNewDatafield] = useState<DataField>(emptyDataField);

    const creationIsValid = useMemo(() => {
        const isDefined = newDatafield.type !== undefined && newDatafield.name !== undefined && newDatafield.name !== "";
        return isDefined;
    }, [newDatafield]);

    const { isLoading: isSavingDataField, mutateAsync: saveDataField } = useMutation(async (df: DataField) => {
        if (creationIsValid || predefinedAttributeSelection === df.id) {
            // If the id already exists in the project attrs, that means the user is trying to add a project attr to a layer
            const isLinkingProjectAttribute = projectAttributes.some(({ id }) => id === df.id) && resourceId !== df.projectId;
            const shouldPost = !isValidUuid(df.id) || isLinkingProjectAttribute;
            if (shouldPost) {
                // If linking a project attribute, use the same id
                // Otherwise use a newly generated ID
                // eslint-disable-next-line no-param-reassign
                if (!isLinkingProjectAttribute) df.id = dataFieldId;

                await dataFieldsService.postDataField(df, resourceId);
            } else {
                await dataFieldsService.putDataField(df, resourceId);
            }
            setNewDatafield((d) => ({ ...d, id: dataFieldId }));
            onSave(df, attributeSection === AddAttributeOptions.predefined);
        }
    });

    const stages: Stage[] = [
        {
            primaryButtonText: attributeSection === AddAttributeOptions.predefined ? translate(Content.map.data_fields.add) : translate(Content.common.buttons.continue),
            primaryButtonCallback: () => {
                if (attributeSection === AddAttributeOptions.predefined) {
                    // Add the selected predefined attribute to the thing

                    // Get the attribute based on the selected id
                    const attribute = projectAttributes.find(({ id }) => id === predefinedAttributeSelection);
                    saveDataField(attribute);
                } else {
                    // Go to the next stage to create the attribute
                    setCurrentStage(AddAttributeStages.createAttribute);
                }
            },
            submitButtonDataCy: "attribute-editor-submit",
            secondaryButtons: [
                {
                    buttonText: translate(Content.common.buttons.cancel),
                    buttonDisabled: isSavingDataField,
                    onButtonPressed: () => onClose(),
                },
            ],
            isValid:
                (attributeSection === AddAttributeOptions.predefined && predefinedAttributeSelection != null) ||
                (attributeSection === AddAttributeOptions.custom && newDatafield?.type != null),
        },
        {
            primaryButtonText: translate(Content.map.data_fields.add),
            submittingText: translate(Content.common.saving),
            submittedText: translate(Content.common.saved),
            isSubmitted: !hasDataFieldChangedShallow(savedDataField, newDatafield),
            showLoadingSpinner: isSavingDataField,
            submitButtonDataCy: "attribute-editor-submit",
            primaryButtonCallback: () => saveDataField(newDatafield),
            secondaryButtons: [
                {
                    buttonText: translate(Content.common.buttons.back),
                    onButtonPressed: () => {
                        setCurrentStage(AddAttributeStages.predefinedSelector);
                    },
                    buttonDisabled: isSavingDataField,
                },
            ],
            isValid: creationIsValid,
        },
    ];

    // Selects the first 3 items in project attributes and uses them as examples in the description for predefined attributes
    // Example could be: "(e.g. "Cost", "Quantity", "Client group")"
    const predefinedExamples =
        projectAttributes && projectAttributes.length > 0
            ? ` (e.g. ${projectAttributes
                  .slice(0, 3)
                  .map(({ name }) => `"${name}"`)
                  .join(", ")})`
            : ".";

    const handleTypeChange = (value: DataFieldType) => {
        switch (value) {
            case DataFieldType.List:
                setNewDatafield({ ...newDatafield, type: value, listValues: [] });
                break;
            case DataFieldType.What3Words:
                setNewDatafield({ ...newDatafield, type: value, listValues: [], name: WHAT3WORDS_ATTRIBUTE_NAME });
                break;
            default:
                setNewDatafield({ ...newDatafield, type: value, listValues: undefined });
        }
    };

    const filteredProjectAttributes = useMemo(() => projectAttributes.filter(({ type }) => !filterDataFieldTypes.includes(type)), [projectAttributes]);

    return (
        <FormWizardTemplate
            stages={stages}
            currentStage={currentStage}
            title={
                <FormWizardTitle
                    footer={translate(Content.map4.attributes.add_attribute_subtitle)}
                    title={
                        <TextIconContainer>
                            <FontAwesomeIcon icon={["fas", "tag"]} />
                            <Header4>{translate(Content.map.data_fields.add)}</Header4>
                        </TextIconContainer>
                    }
                />
            }
        >
            <FormContainer>
                {currentStage === AddAttributeStages.predefinedSelector && (
                    <StyledRadioGroup value={attributeSection} onChange={(_, value: AddAttributeOptions) => setAttributeSection(value)}>
                        <ExpandableRadioOption
                            title={translate(Content.map.data_fields.project_data_fields)}
                            subtitle={`${translate(Content.map4.attributes.predefined_subtitle)}${predefinedExamples}`}
                            selected={attributeSection === AddAttributeOptions.predefined}
                            value={AddAttributeOptions.predefined}
                            infoPopup={{
                                title: translate(Content.map4.attributes.predefined_info_title),
                                text: (
                                    <>
                                        <Body2>{translate(Content.map4.attributes.predefined_info_text)}</Body2>
                                        {navigateToProjectSettings != null && (
                                            <InteractiveElement
                                                onClick={() => {
                                                    navigateToProjectSettings();
                                                }}
                                            >
                                                <Body2 style={{ textDecoration: "underline" }}>{translate(Content.map4.attributes.predefined_info_link)}</Body2>
                                            </InteractiveElement>
                                        )}
                                    </>
                                ),
                                placement: "top",
                            }}
                            disabled={!hasPredefinedAttributes}
                            dataCy="predefined-attribute"
                        >
                            {hasPredefinedAttributes && (
                                <StyledGridRadioGroup value={predefinedAttributeSelection} onChange={(_, value) => setPredefinedAttributeSelection(value)}>
                                    {filteredProjectAttributes.map((attribute) => (
                                        <PredefinedAttributeOption attribute={attribute} key={attribute.id} />
                                    ))}
                                </StyledGridRadioGroup>
                            )}
                        </ExpandableRadioOption>
                        <Divider />
                        <ExpandableRadioOption
                            title={translate(Content.map.data_fields.custom_data_field)}
                            subtitle={translate(Content.map4.attributes.custom_subtitle)}
                            selected={attributeSection === AddAttributeOptions.custom}
                            value={AddAttributeOptions.custom}
                            dataCy="custom-attribute"
                        >
                            <AttributeTypeSelector value={newDatafield.type} onChange={handleTypeChange} />
                        </ExpandableRadioOption>
                    </StyledRadioGroup>
                )}
                {currentStage === AddAttributeStages.createAttribute && (
                    <div style={{ padding: "30px 0" }}>
                        <AttributeNameEditor attribute={newDatafield} updateDataField={setNewDatafield} />
                        {AdditionalAttributeDataForm && <AdditionalAttributeDataForm attribute={newDatafield} updateAttribute={setNewDatafield} />}
                        {newDatafield.type !== DataFieldType.List && newDatafield.type !== DataFieldType.Image && (
                            <AttributeDefaultValueComponent attribute={newDatafield} setDefaultValue={(defaultValue) => setNewDatafield({ ...newDatafield, defaultValue })} />
                        )}
                    </div>
                )}
            </FormContainer>
        </FormWizardTemplate>
    );
};

export const PredefinedAttributeOption: React.FunctionComponent<{ attribute: DataField }> = ({ attribute }) => {
    const translate = useIventisTranslate();

    const icon = useMemo(() => {
        const { icon } = dataFieldTypes.find(({ type }) => type === attribute.type);
        if (icon === undefined) {
            return <></>;
        }
        if (Array.isArray(icon)) {
            return <FontAwesomeIcon icon={icon} style={{ marginRight: "5px" }} size="xs" />;
        }
        return <CustomIconContainer>{icon as ReactNode}</CustomIconContainer>;
    }, [attribute.type]);

    const attributeType = useMemo(() => dataFieldTypes.find(({ type }) => type === attribute.type).name, [attribute.type]);

    return (
        <div>
            <FormControlLabel
                control={<Radio size="small" data-testid={`project-datafield-${attribute.name}`} />}
                label={
                    <div style={{ marginTop: "1em" }} data-testid={`predefined-attribute-${attribute.name}`}>
                        <StyledBody2>{attribute.name}</StyledBody2>
                        <PredefinedAttributeTypeContainer>
                            {icon}
                            <Body3>{translate(attributeType)}</Body3>
                        </PredefinedAttributeTypeContainer>
                    </div>
                }
                value={attribute.id}
            />
        </div>
    );
};

const StyledBody2 = styled(Body2)`
    font-weight: 600;
`;

const PredefinedAttributeTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
`;

const StyledRadioGroup = styled(RadioGroup)`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const FormContainer = styled.div`
    max-height: 100%;
    ${muiInputFormsCSS}

    .full-width {
        width: 100%;
    }
`;

const StyledGridRadioGroup = styled(RadioGroup)`
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 75%;

    ${media.extraSmall} {
        width: 100%;
    }
`;

export const TextIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${inlineTextIconMargin};
`;

const CustomIconContainer = styled.div`
    margin-right: 5px;
`;
export default AddAttributeForm;
