/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
import React, { forwardRef, FunctionComponent, ReactNode, useContext, useEffect, useImperativeHandle, useState } from "react";
import GeoJSON from "geojson";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FormWizardTemplate } from "@iventis/components/src/form-wizard-template";
import { Terrain } from "@iventis/domain-model/model/terrain";
import { CustomDialog } from "@iventis/components";
import { TerrainCreateRequest } from "@iventis/domain-model/model/terrainCreateRequest";
import { Status } from "@iventis/domain-model/model/status";
import { TerrainForm } from "./terrain-form";
import { TerrainContext } from "./terrain-context";
import { TerrainFromValues } from "./terrain-form-values";

export type TerrainWizardRef = { isSaving(): boolean };

/** Create or edit a terrain */
export const TerrainWizard = forwardRef<
    TerrainWizardRef,
    {
        terrain?: Terrain;
        tileUploader: (setTileUploadProgress: (progress: number) => void, url: string, file: File) => Promise<void>;
        onClose: (terrain?: Terrain) => void; // If terrain is provided on close, it has been saved.
    }
>(({ terrain, onClose, tileUploader }, ref) => {
    const translate = useIventisTranslate();
    const terrainContext = useContext(TerrainContext);
    const stage = 0;
    const [isSaving, setSaving] = useState(false);

    useImperativeHandle(ref, () => ({
        isSaving() {
            return isSaving;
        },
    }));

    const [isValid, setValid] = useState(false);
    const [terrainFormValues, setTerrainFormValues] = useState<TerrainFromValues>({
        name: terrain?.name ?? "",
        bounds: terrain?.bounds != null ? JSON.stringify(terrain.bounds) : "",
        tileFile: null,
        tileFileName: terrain?.name ?? "",
    });

    useEffect(() => {
        if (terrainFormValues != null) {
            try {
                if (terrainFormValues.name.length === 0 || terrainFormValues.tileFileName.length === 0) {
                    setValid(false);
                    return;
                }
                const boundsParsed: GeoJSON.Polygon = JSON.parse(terrainFormValues.bounds);
                if (boundsParsed.coordinates[0].length !== 5 || boundsParsed.type !== "Polygon") {
                    setValid(false);
                    return;
                }
                setValid(true);
            } catch (error) {
                setValid(false);
            }
        }
    }, [terrainFormValues]);

    const createTerrain: () => Promise<Terrain> = async () => {
        const newTerrain: TerrainCreateRequest = { name: terrainFormValues.name, bounds: JSON.parse(terrainFormValues.bounds) as GeoJSON.Polygon };
        const terrainResponse = await terrainContext.create(newTerrain);
        await tileUploader((progress) => setTileUploadProgress(progress), terrainResponse.tileUploadUrl, terrainFormValues.tileFile);
        await terrainContext.update({ id: terrainResponse.id, status: Status.Active });
        return terrainResponse;
    };

    const updateTerrain: () => Promise<Terrain> = async () => {
        const updatedTerrain: Partial<Terrain> = { id: terrain.id, name: terrainFormValues.name, bounds: JSON.parse(terrainFormValues.bounds) as GeoJSON.Polygon };
        if (terrainFormValues.tileFile != null) {
            updatedTerrain.status = Status.Pending;
        }
        const tileUrl = await terrainContext.update(updatedTerrain);
        if (tileUrl != null && terrainFormValues.tileFile != null) {
            await tileUploader((progress) => setTileUploadProgress(progress), tileUrl, terrainFormValues.tileFile);
            await terrainContext.update({ id: terrain.id, status: Status.Active });
        }
        return { ...terrain, ...updatedTerrain };
    };

    const terrainSubmited = async () => {
        setSaving(true);
        if (terrain == null) {
            try {
                const creation = await createTerrain();
                setSaving(false);
                onClose(creation);
            } catch (error) {
                setSaving(false);
                throw error;
            }
        } else {
            try {
                const updatedTerrain = await updateTerrain();
                setSaving(false);
                onClose(updatedTerrain);
            } catch (error) {
                setSaving(false);
                throw error;
            }
        }
    };

    const [tileUploadProgress, setTileUploadProgress] = useState<number>(null);
    const close = () => {
        if (!isSaving) {
            onClose();
        }
    };
    return (
        <FormWizardTemplate
            currentStage={stage}
            stages={[
                {
                    isValid: isValid && terrain?.status !== Status.Pending,
                    primaryButtonText: translate(Content.common.buttons.saveAndClose),
                    primaryButtonCallback: terrainSubmited,
                    showLoadingSpinner: isSaving,
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.close), onButtonPressed: close, buttonDisabled: () => isSaving }],
                    Component: (
                        <TerrainForm
                            disabled={isSaving || terrain?.status === Status.Pending}
                            terrainFormValues={terrainFormValues}
                            terrainUploadProgress={tileUploadProgress}
                            setTerrainFormValues={(values) => {
                                setTerrainFormValues(values);
                            }}
                        />
                    ),
                    title: terrain == null ? translate(Content.terrain.title.create) : translate(Content.terrain.title.edit),
                },
            ]}
        />
    );
});

/** Standard modal with consistent style for sitemap form. Pass the sitemap form as a child */
export const TerrainModal: FunctionComponent<{ open: boolean; onClose: () => void; children: ReactNode }> = ({ open, onClose, children }) => (
    <CustomDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        {children}
    </CustomDialog>
);
