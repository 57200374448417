import type { Feature, LineString } from "geojson";
import type { MapObjectProperties } from "@iventis/map-types";
import type { BehaviorSubject } from "rxjs";
import type { ExpressionSpecification, LineLayerSpecification, Map } from "@iventis/mapbox-gl";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { MapboxEngineData, MapState } from "../../types/store-schema";
import { LineModelGuideLineLayer } from "../generic-layers/line-model-guide-line-layer";
import { iventisLineToAggregateLayer } from "../mapbox/engine-mapbox-helpers";
import { MapboxLayer } from "./mapbox-layer";

export class MapboxLineModelGuideLineLayer extends LineModelGuideLineLayer {
    private readonly map: Map;

    private readonly lineModelId: string;

    private readonly mapboxLayer: MapboxLayer<Feature<LineString, MapObjectProperties>[], LineLayerSpecification>;

    constructor(state$: BehaviorSubject<MapState<MapboxEngineData>>, lineModelLayerId: string, map: Map) {
        super(state$, lineModelLayerId);
        this.map = map;
        this.lineModelId = lineModelLayerId;
        this.mapboxLayer = new MapboxLayer(map, this.layerId, this.sourceId);
        this.addSource();
        this.addLayer();
        const features = this.getGeoJson();
        if (features != null) {
            this.updateSource(features);
        }
    }

    public showGuideLinesFor(objectIds: string[]) {
        if (this.map.getLayer(this.layerId)) {
            this.map.setFilter(this.layerId, this.createFilter(objectIds));
        }
    }

    public removeGuideLines() {
        if (this.map.getLayer(this.layerId)) {
            this.map.setFilter(this.layerId, this.createFilter());
        }
    }

    public updateMapOrder() {
        this.mapboxLayer.updateMapOrder(this.lineModelId);
    }

    protected addLayer(): void {
        const layers = iventisLineToAggregateLayer(this.guideLineLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, this.createFilter());
        layers.forEach(({ layer }) => {
            // Check for type safety
            if (layer.type === "line") {
                this.mapboxLayer.addLayer(layer, this.lineModelId);
            }
        });
    }

    protected removeLayer(): void {
        this.mapboxLayer.removeLayer();
    }

    protected addSource(): void {
        this.mapboxLayer.addSource();
    }

    public updateSource(source: Feature<LineString, MapObjectProperties>[]): void {
        this.mapboxLayer.updateSource(source);
    }

    protected removeSource(): void {
        this.mapboxLayer.removeSource();
    }

    protected updateStyle(): void {
        throw new Error("Method not implemented.");
    }

    protected updateMapLevel(): void {
        this.removeGuideLines();
    }

    protected hideLayer(): void {
        this.mapboxLayer.hideLayer();
    }

    protected showLayer(): void {
        this.mapboxLayer.showLayer();
    }

    private createFilter(objectIds: string[] = []): ExpressionSpecification {
        return ["in", "id", ...objectIds];
    }
}
