import { NodeServices } from "@iventis/tree-browser";
import { TerrainNode, TerrainNodeTypes, TerrainRootNode } from "@iventis/terrains/src/terrain-types";
import { ITerrainContext } from "@iventis/terrains/src/terrain-context";
import { TerrainCreateResponse } from "@iventis/domain-model/model/terrainCreateResponse";
import { Terrain } from "@iventis/domain-model/model/terrain";
import { terrainToNode } from "@iventis/terrains/src/terrain-nodes";
import { api } from "../api/api";

/** The root id has to be made up of both the project id and the instance name because we need both in our requests to the admin dashboard */
export const getProjectIdAndInstanceNameFromRootId = (id: string) => id.split("|");
export const createRootIdFromProjectIdAndInstanceName = (projectId: string, instanceName) => `${projectId}|${instanceName}`;

export const terrainServices: NodeServices<TerrainRootNode> = {
    getTree: async (id) => {
        const [projectId, instanceName] = getProjectIdAndInstanceNameFromRootId(id);

        const terrains = await listTerrains(instanceName, projectId);

        // Convert sitemaps into a tree browser node implementation of sitemaps
        const nodeData: TerrainNode[] = terrains.map((terrain) => terrainToNode(terrain, id));

        // Wrap all the sitemaps around a blank root node
        return {
            id,
            name: undefined,
            type: TerrainNodeTypes.Root,
            childNodes: nodeData,
            childCount: nodeData.length,
        } as TerrainRootNode;
    },
    addNode: async () => {
        throw new Error(`Sitemap tree browser only adds nodes locally. Please update on the server first, then call "addNodesLocally()"`);
    },
    updateNode: async () => {
        throw new Error(`Sitemap tree browser only updates nodes locally. Please update on the server first, then call "updateNodeLocally()"`);
    },
    deleteNodes: async (nodes, tree) => {
        const [projectId, instanceName] = getProjectIdAndInstanceNameFromRootId(tree.id);
        const requests = nodes.map(({ id }) => api.delete(`/instances/${instanceName}/projects/${projectId}/terrains/${id}`));
        await Promise.all(requests);
        return nodes.map(({ id }) => id);
    },
    getNode: async () => null,
    moveNodes: async () => null,
    copyNodes: async () => null,
    getParents: async () => null,
};

export const createTerrainContext = (instanceName: string, projectId: string): ITerrainContext => ({
    async create(terrainCreateRequest): Promise<TerrainCreateResponse> {
        const { data } = await api.post<TerrainCreateResponse>(`/instances/${instanceName}/projects/${projectId}/terrains`, terrainCreateRequest);
        return data;
    },
    async update(terrain): Promise<string> {
        const result = await updateTerainWith404Retry(instanceName, projectId, terrain);
        return result;
    },
    async delete(terrainId): Promise<void> {
        await api.delete(`/instances/${instanceName}/projects/${projectId}/terrains/${terrainId}`);
    },
    async list(): Promise<Terrain[]> {
        const { data } = await api.get<Terrain[]>(`/instances/${instanceName}/projects/${projectId}/terrains`);
        return data;
    },
    async get(terrainId: string): Promise<Terrain> {
        const { data } = await api.get<Terrain>(`/instances/${instanceName}/projects/${projectId}/terrains/${terrainId}`);
        return data;
    },
});

export const listTerrains = async (instanceName: string, projectId: string): Promise<Terrain[]> => {
    const { data } = await api.get<Terrain[]>(`/instances/${instanceName}/projects/${projectId}/terrains`);
    return data;
};

export const updateTerainWith404Retry = async (instanceName: string, projectId: string, terrain: Partial<Terrain>): Promise<string> => {
    let response: string;

    const update = async (suppress404: boolean) =>
        new Promise<string>((resolve, reject) => {
            api.patch<string>(`/instances/${instanceName}/projects/${projectId}/terrains/${terrain.id}`, terrain, { suppress404 })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.status);
                });
        });
    for (let i = 0; i < 3; i++) {
        try {
            response = await update(i !== 2);
            break;
        } catch (e) {
            if (!(e === 404 && i < 2)) {
                throw e;
            }
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }
    return response;
};
