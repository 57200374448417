import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { Status } from "@iventis/domain-model/model/status";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { Feature, Position, Point } from "geojson";
import { DomainLayer } from "../../state/map.state.types";
import { ViewByStatus } from "../../types/layer-view-by";
import { LayerStorageScope } from "../../types/store-schema";
import { defaultPointStyle } from "../../utilities/default-style-values";
import { Layer } from "./layer";

/** Generic layer which shows where a map object is going to snap to when drawing or editing */
export abstract class SnapIndicatorLayer extends Layer<Position | undefined, PointStyle> {
    protected source: Feature<Point>[] | undefined = undefined;

    protected readonly layerId = "snap-indicator-layer";

    protected readonly sourceId = this.layerId;

    protected readonly snapIndicatorLayer: DomainLayer = {
        id: this.layerId,
        status: Status.Active,
        mapId: "",
        visible: true,
        locked: false,
        imported: false,
        name: "snap indicator layer",
        styleType: StyleType.Point,
        areaStyle: undefined,
        lineStyle: undefined,
        pointStyle: {
            ...defaultPointStyle,
            opacity: createStaticStyleValue(0.5),
            colour: createStaticStyleValue("#ff0000"),
            radius: createStaticStyleValue(15),
        },
        iconStyle: undefined,
        modelStyle: undefined,
        lineModelStyle: undefined,
        previewIconUrl: "",
        dataFields: [],
        tooltipDataFieldIds: [],
        createdByUserId: "",
        createdByUserName: "",
        createdAt: undefined,
        lastUpdatedByUserId: "",
        lastUpdatedByUserName: "",
        lastUpdatedAt: undefined,
        viewBy: ViewByStatus.OBJECT,
        source: this.layerId,
        stamp: this.sourceId,
        selected: false,
        storageScope: LayerStorageScope.LocalOnly,
        remote: false,
    };

    public updateSource(updatedPoint: Position | undefined): void {
        this.source = updatedPoint ? [{ type: "Feature", geometry: { type: "Point", coordinates: updatedPoint }, properties: {} }] : [];
    }
}
