/* eslint-disable no-case-declarations */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { Subject, Subscription } from "rxjs";
import { v4 as uuid } from "uuid";
import { takeUntil } from "rxjs/operators";
import { Optional, Cacher, SVG_NAMESPACE, isValidUuid, EventStream, getKeyIn, CtrlListener } from "@iventis/utilities";
import { featureCollection, FeatureCollection, point, Feature, lineString, polygon, BBox } from "@turf/helpers";
import nearestPoint from "@turf/nearest-point";
import center from "@turf/center";
import centroid from "@turf/centroid";
import GeoJSON, { Geometry } from "geojson";
import bbox from "@turf/bbox";
import booleanContains from "@turf/boolean-contains";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { ActorRef, interpret, Interpreter } from "xstate";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { GeometryType } from "@iventis/domain-model/model/geometryType";
import { AnySupportedGeometry, MapObjectProperties, SelectedMapObject, RouteWaypoint, MeasurementScope } from "@iventis/map-types";
import { createStaticStyleValue, getStaticStyleValue } from "@iventis/layer-style-helpers";
import { getMapObjectRouteTimeSystemDataField } from "@iventis/datafields";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import {
    CompositionMapObject,
    ClickEvent,
    Listener,
    MapCursor,
    MoveEvent,
    StylePropertyToValueMap,
    CompositionReturn,
    AssetOptions,
    EngineInterpreterOptions,
    ReflectLocalObjectsOptions,
    FixedShapeSupportedGeometry,
    TypedFeature,
    CompositionRemainingSource,
} from "../types/internal";
import { convertImageIdToLocalPath, groupCompositionMapObjectByLayer } from "../utilities/converters";
import {
    DrawingMachineEvents,
    DrawingState,
    DrawingStates,
    machineEventTypes,
    MapMode,
    ModeContext,
    ModeEvents,
    modeMachineEvents,
    ModeMachineEventsWithDrawingCallbacks,
    SelectableComment,
} from "../machines/map-machines.types";
import { ObjectDrag } from "../utilities/object-drag";
import { MapStoreEvent, MapStoreEventType, ObjectUpdate } from "../types/events-store";
import { InvocationEvent, InvocationEventType, MapErrorBodyType } from "../types/events-invocation";
import {
    StoredPosition,
    MapStore,
    MapState,
    DrawingObject,
    MapModuleLayer,
    Source,
    LayerStorageScope,
    LayerDrawingControl,
    UnionOfStyles,
    SelectedMapComment,
    StoredBounds,
    DrawingModifier,
    MappingEngine,
} from "../types/store-schema";
import {
    addMidpointCoordinate,
    deleteCoordinate,
    geometryToPointGeometry,
    getAreaInAppropriateUnits,
    getClosestVectorMidpoint,
    getClosestVectorMidpoints,
    getCoordinateList,
    getOneDirectionalCursor,
    getLengthInAppropriateUnits,
    getLineGeometryForMidpoints,
    getRelativePosition,
    minimumNumberOfPointsToShowMeasurement,
    numbersEqualWithin,
    styleTypeToGeoJSONGeometryType,
    ensure360Bearing,
    getMidPointOfLineString,
    isCompositionValid,
} from "../utilities/geojson-helpers";
import {
    localSuffix,
    coordinateDeleteLayerId,
    coordinateDeleteSourceId,
    coordinateDeleteSpriteName,
    coordinateHandleLayerId,
    coordinateHandleSourceId,
    coordinateHandleSpriteName,
    internalMapLayerIds,
    rotateHandleLayerId,
    rotateHandleSourceId,
    rotateHandleSpriteName,
    highlightInfix,
    traceLineSourceId,
    midpointHandleSourceId,
    midpointHandleLayerId,
    continueDrawingLayerId,
    continueDrawingSourceId,
    continueDrawingSpriteName,
    textboxSpriteName,
    measurementSourceId,
    measurementLayerId,
    MEASUREMENT_PADDING,
    analysisLineLayerId,
    analysisLayerIds,
    analysisPolygonLayerId,
    triangleSprintName,
    longLastingMeasurementSourceId,
    longLastingMeasurementLayerId,
    midpointHandleSpriteName,
    sdfDisabledSuffix,
    FROM_MAP_ENGINE,
    waypointSourceId,
    waypointSpriteName,
    waypointLayerId,
    analysisMeasuringLabelLayerName,
    commentsLayerId,
    DEFAULT_COMMENT_ICON_VALUE,
    selectedLayerAreaSelectId,
} from "./constants";
import coordinateHandlePNG from "../assets/coordinate-handle.png";
import routeHandlePNG from "../assets/route-waypoint-handle.png";
import rotateHandlePNG from "../assets/rotate-handle.png";
import removeButtonPNG from "../assets/remove-button.png";
import textboxPNG from "../assets/textbox.png";
import trianglePNG from "../assets/triangle.png";
import continueDrawingButtonPNG from "../assets/continue-drawing-button.png";
import { Rotator, RotatorEvent } from "../utilities/rotator";
import { CoordinateDelete, CoordinateDeleteEvent } from "../utilities/coordinate-delete";
import { ContinueDrawing, ContinueDrawingEvent } from "../utilities/continue-drawing";
import { Composition, CompositionEvent } from "../utilities/composition";
import { CoordinateHandleEvent, CoordinateHandles, getCoordinateGeometry } from "../utilities/coordinate-handles";
import { Midpoint, MidpointHandleEvent, MidpointHandles } from "../utilities/midpoint-handles";
import { defaultLineStyle, defaultPointStyle } from "../utilities/default-style-values";
import { modeMachine } from "../machines/mode-machine";
import { EventMessageMap, MapEvents, MapEventTypes, RefreshMapTileSourceEvent } from "../types/map-stream.types";
import { checkAnyModeIsPresent, checkModeIsPresent, machineModeOutputToString } from "../machines/mode-machine.helpers";
import {
    addSystemDataFieldsToMapObject,
    getLocalLayerID,
    mergeCompositionMapObjectProperties,
    tileLayerToLocalLayer,
    getDefaultAttributeValues,
    isSingleRoute,
    defaultCursor,
    alphabet,
} from "./engine-generic-helpers";
import { findDifferingStyleProperties, getHighlightCircleRadius, modifyStyleValueFundamentalValues } from "../utilities/style-helpers";
import { isAnalysisLayer, isMeasurableLayer, measurableGeometries } from "../utilities/state-helpers";
import { DRAWING_MACHINE_ID } from "../machines/drawing-machine";
import { coordinatesEqual } from "../utilities/vectors";
import { CompositionFixedShape, FixedShapeEvent } from "../utilities/composition-fixed-shape";
import { FixedShapeMidpointHandleEvent, FixedShapeMidpointHandles } from "../utilities/fixed-shape-midpoints-handles";
import { RouteControlListeners, RouteControls, RouteControlsEvent } from "../utilities/route-controls";
import { createBasicRouteWaypoint } from "../utilities/route-helpers";
import { CommentDrawingEvent, CommentsDrawing } from "../utilities/comments-drawing";
import { CommentIcon } from "../utilities/comment-icon";
import { LayerMovementAction } from "./mapbox/layer-ordering/layer-ordering-types-and-constants";
import { findLayerWithOrderingChange } from "./mapbox/layer-ordering/layer-ordering-state-helpers";
import { orderDomainLayers } from "./mapbox/layer-ordering/layer-ordering-generic-helpers";
import { ObjectSnapping, ObjectSnappingFunctions } from "../utilities/objects-snapping";
import { CompositionUndoRedo } from "../utilities/composition-undo-redo";

/* eslint-disable @typescript-eslint/no-empty-interface */

/**
 * 🛠 Generic interpreter for underlying mapping technology. All higher-level functionality is
 * abstracted at this level. The goal is to have the majority of the implementation in this class
 * as this can be re-used whereas the underlying connection to a specific map engine cannot be
 * re-used.
 */

export abstract class EngineInterpreter {
    protected store: MapStore;

    protected eventStream: EventStream<EventMessageMap>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public machine: Interpreter<ModeContext, any, ModeMachineEventsWithDrawingCallbacks, any, any>;

    protected onDestroy = new Subject();

    private lastStateRecieved: MapState;

    protected mapCursorState: MapCursor;

    public storeEvents = new Subject<MapStoreEvent>();

    public invocationEvents = new Subject<InvocationEvent>();

    protected keysPressed: string[] = [];

    protected container: HTMLElement;

    protected assetOptions: AssetOptions;

    protected modifierKeys: KeyboardEvent["key"][];

    private svgContainer: SVGElement;

    protected getAttributeListItems: EngineInterpreterOptions["getAttributeListItems"];

    protected user: EngineInterpreterOptions["user"];

    public readonly isExternalUser: boolean;

    private compositionUndoRedo: CompositionUndoRedo;

    /*
        OBJECT MANIPULATORS
    */

    private rotator: Rotator;

    private composition: Composition;

    private fixedShape: CompositionFixedShape;

    private fixedShapeMidpointHandles: FixedShapeMidpointHandles;

    private coordinateHandles: CoordinateHandles;

    private midpointHandles: MidpointHandles;

    private continueDrawingHandler: ContinueDrawing;

    private coordinateDelete: CoordinateDelete;

    private objectDrag: ObjectDrag;

    private routeControls: RouteControls;

    private commentsHandler: CommentsDrawing;

    /*
        LISTENERS
    */

    private readMouseMoveListener: Listener;

    private lastMouseListener: Listener;

    private readClickListener: Listener;

    private cameraMoveEndListener: Listener;

    private objectDragListeners: Listener[] = [];

    protected lastMouse: { lng: number; lat: number; x: number; y: number };

    protected ctrlListener: CtrlListener;

    /*
        EPHEMERAL STORAGE
    */

    /*  The feature currently being composed by the user;
        Features that are actively being drawn, or dragged */
    private currentComposition: CompositionMapObject[];

    private initialComposition: CompositionMapObject[] | undefined;

    /** Features that share the same layers as those in currentComposition, but are not being composed */
    private compositionLayersRemainingSource: CompositionRemainingSource[] = [];

    private currentCoordinateDelete: { coordinate: GeoJSON.Position } = { coordinate: undefined };

    private currentContinueDrawing: { coordinate: GeoJSON.Position } = { coordinate: undefined };

    protected preview: boolean;

    private longLastingMeasurementLabels: FeatureCollection<AnySupportedGeometry, { mapObjectId: string; text: string }> = { type: "FeatureCollection", features: [] };

    public imagesLoading: string[] = [];

    public routeApiFunctions: EngineInterpreterOptions["routeApiFunctions"];

    private objectSnappingFunctions: ObjectSnappingFunctions<TypedFeature> = {
        queryRenderedFeatures: (position, radius) => this.queryMapObjectsForSnapping(position, radius),
        getGeometry: (layerId, objectId) => this.getLocalGeometry(objectId, layerId, false),
        requestMapObjectGeometries: (objects) =>
            this.requestMapObjectGeometries(
                objects.map((object) => ({
                    layerId: object.properties.layerid,
                    objectId: object.properties.id,
                    geojson: object,
                    level: object.properties.level,
                }))
            ),
        getCanvasCoordinates: (position) => this.project(position),
        hasFeatureAlreadyBeenRequested: (layerId, objectId) => this.isLocalGeometryBeingRequested(layerId, objectId),
        onSnapToCoordinate: (position) => this.setSnapIndicatorSource(position),
    };

    public abstract getMappingEngine(): MappingEngine;

    public abstract getRotation(): number;

    public abstract getPosition(): Promise<StoredPosition>;

    protected abstract getBounds(): Promise<StoredBounds>;

    protected abstract getUserLocation(): void;

    protected abstract onImageRequired(callback: (e: { id: string }) => void): Listener;

    protected abstract onMoveEnd(callback: () => void): Listener;

    protected abstract onMouseMove(callback: (event: MoveEvent) => void, options?: { queryRadius?: number; layerId?: string; disableQuerying?: boolean }): Listener;

    protected abstract onMouseUp(callback: (event: MoveEvent) => void): Listener;

    protected abstract onFocus(callback: (event: FocusEvent) => void): Listener;

    protected abstract onFocusOut(callback: (event: FocusEvent) => void): Listener;

    protected abstract onClick(callback: (event: ClickEvent) => void, layerId?: string): Listener;

    protected abstract onMouseDown(callback: (event: ClickEvent) => void, layerIds?: string[]): Listener;

    public abstract onMapIdle(callback: (event: MoveEvent) => void, options: { disableMapQuerying: boolean; listenOnce: boolean }): Listener;

    protected abstract onDoubleClick(callback: (event: ClickEvent) => void): Listener;

    protected abstract onRightClick(callback: (event: ClickEvent) => void): Listener;

    protected abstract onMouseOut(callback: (event: MoveEvent) => void): Listener;

    protected abstract onMouseOver(callback: (event: MoveEvent) => void): Listener;

    protected onKeyDown(callback: (event: KeyboardEvent) => void) {
        document.addEventListener("keydown", callback);
        return {
            remove: () => document.removeEventListener("keydown", callback),
        };
    }

    protected onKeyUp(callback: (event: KeyboardEvent) => void) {
        document.addEventListener("keyup", callback);
        return {
            remove: () => document.removeEventListener("keyup", callback),
        };
    }

    protected onKeyCopy(callback: (event: KeyboardEvent) => void) {
        const copyListener = this.onKeyDown((e) => {
            if (this.ctrlListener.ctrlDown && this.ctrlListener.active) {
                if (e.key.toLowerCase() === "c") {
                    callback(e);
                }
            }
        });
        return copyListener;
    }

    protected onKeyPaste(callback: (event: ClickEvent) => void) {
        const eventListener = (e: KeyboardEvent) => {
            if (this.ctrlListener.ctrlDown && this.ctrlListener.active) {
                if (e.key.toLowerCase() === "v") {
                    callback({ ...this.lastMouse, objects: [] });
                }
            }
        };
        document.addEventListener("keydown", eventListener);
        return {
            remove: () => document.removeEventListener("keydown", eventListener),
        };
    }

    protected abstract OnMapMoveStart(callback: () => void): Listener;

    protected abstract OnMapMoveEnd(callback: () => void): Listener;

    protected abstract onPanStart(callback: () => void): Listener;

    protected abstract onPanEnd(callback: () => void): Listener;

    protected abstract onZoomStart(callback: () => void): Listener;

    protected abstract onZoomEnd(callback: () => void): Listener;

    protected abstract onMapRotateStart(callback: () => void): Listener;

    protected abstract onMapRotateEnd(callback: (bearing: number) => void): Listener;

    protected abstract onFirstRender(callback: () => void): void;

    protected abstract removeOnMapLoadedListener(): void;

    protected abstract removeMouseDownListener(layerId?: string): void;

    protected abstract reflectLocalObjects(options: ReflectLocalObjectsOptions): void;

    protected abstract hideRemoteObjects(): void;

    protected abstract hideLocalObjects(layerId: string, objectIds: string[]): void;

    protected abstract unhideLocalObjects(layerId: string, objectIds: string[]): void;

    protected abstract ensureLayerInMap(layer: MapModuleLayer): void;

    protected abstract queryMapObjects(x: number, y: number, radius?: number): GeoJSON.Feature[];

    protected abstract queryMapObjectsByLayer(position: [number, number], layerIds: string[], radius: number): TypedFeature[];

    protected abstract queryMapObjectsForSnapping(position: [number, number], radius: number): TypedFeature[];

    protected abstract getMapCentre(cb: (pos: GeoJSON.Position) => void, isMoving?: boolean): void;

    protected abstract addSnapIndicatorLayer(): void;

    protected abstract removeSnapIndicatorLayer(): void;

    protected abstract setSnapIndicatorSource(position: GeoJSON.Position | undefined): void;

    /** CAD Bounds */

    public abstract areCompositionObjectsOutsideCadBounds(objects: CompositionMapObject[]): boolean;

    public abstract areGeometriesOutsideCadBounds(objects: Geometry[]): boolean;

    public userHasDrawnOutsideCad() {
        this.invocationEvents.next({ type: InvocationEventType.DRAW_OUTSIDE_CAD });
    }

    /* CACHING */

    protected queryMapObjectsCached = new Cacher<GeoJSON.Feature[]>();

    protected queryMapObjectsWithCaching(x: number, y: number, radius?: number): GeoJSON.Feature[] {
        const hit = this.queryMapObjectsCached.getEntry(x, y, radius);

        if (hit !== null) {
            return hit;
        }

        const manualGet = this.queryMapObjects(x, y, radius);
        this.queryMapObjectsCached.setEntry(manualGet, x, y, radius);

        return manualGet;
    }

    private cacheClearingListeners: Listener;

    private enableCacheClearing() {
        this.cacheClearingListeners = this.onMapIdle(
            () => {
                this.queryMapObjectsCached.clear();
            },
            { disableMapQuerying: false, listenOnce: false }
        );

        this.onDestroy.subscribe(() => {
            this.cacheClearingListeners.remove();
        });
    }

    protected abstract reflectSource(sourceId: string, value: GeoJSON.FeatureCollection<GeoJSON.Geometry>): void; // Make sure the source is set for the source id provided

    protected abstract reflectAdditionalSources(
        layer: MapModuleLayer,
        values: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>[],
        allowTextOnSelectedObjects: boolean
    ): void;

    protected abstract addImage(image: string | HTMLImageElement, id: string, options?: { sdf?: boolean }): Promise<void>;

    protected abstract removeImage(id: string): void;

    protected abstract reloadLayersByAssetId(id: string): void;

    protected abstract isImageAdded(id: string): boolean;

    protected abstract addCoordinateHandleLayer(): void;

    protected abstract removeCoordinateHandleLayer(): void;

    protected abstract addWaypointsLayer(): void;

    protected abstract removeWaypointLayer(): void;

    protected abstract addMidpointHandleLayer(): void;

    protected abstract removeMidpointHandleLayer(): void;

    protected abstract addCoordinateDeleteLayer(): void;

    protected abstract addContinueDrawingLayer(): void;

    protected abstract setContinueDrawingButtonRotation(angle: number): void;

    protected abstract addRotateHandleLayer();

    protected abstract removeCoordinateDeleteLayer(): void;

    protected abstract addTraceLineLayer(): void;

    protected abstract addTextboxLayer(layerId: string, sourceId?: string, removeOtherTextBoxes?: boolean, name?: string): void;

    protected abstract disableDragPan();

    public abstract enableDragPan();

    protected abstract disableDoubleClickZoom();

    protected abstract enableDoubleClickZoom();

    protected abstract disableMapRotation();

    protected abstract enableMapRotation();

    public abstract setCursor(cursorState: MapCursor): void;

    public abstract project(coordinate: GeoJSON.Position): [number, number];

    protected abstract unproject(coordinate: [number, number]): GeoJSON.Position;

    protected abstract addLayer(layer: MapModuleLayer, refreshMap?: boolean): Promise<void>;

    protected abstract hideLayer(id: string): void;

    protected abstract showLayer(id: string): void;

    protected abstract removeLayer(id: string): void;

    /**
     * Remove text labels by setting the layer source and omitting the provided objects.
     * These text labels will re-appear when reflectSource is called in future, since we're not removing the layer or using filters.
     * @param removals The objects for which to remove the text labels
     */
    protected abstract removeTextLabels(removals: { objectId: string; layerId: string }[]): void;

    protected abstract updateStyle<TStyle extends UnionOfStyles = UnionOfStyles>(layerId: string, styleType: StyleType, styleChanges: StylePropertyToValueMap<TStyle>[]): void;

    protected abstract checkIfLayerExists(layerId: string): boolean;

    protected abstract localSourceIsAdded(sourceId: string, localOnlyLayer: boolean): boolean;

    protected abstract addHighlightLayer(layer: MapModuleLayer): void;

    protected abstract removeHighlightLayer(layerId: string[]): void;

    protected abstract highlightMapObjects(remoteMapObjectIds: string[], localMapObjectIds: string[], selectedLayerIds: string[]): void;

    protected abstract refreshTileSources(sourceNames: string[]): void;

    protected abstract configureTerrain3D(enabled: boolean, exaggeration: number): void;

    protected abstract configureGlobeProjection(enabled: boolean): void;

    public abstract setPosition(lat: number, lng: number, bearing: number, pitch: number, zoom: number, smooth: boolean): void;

    public abstract setBounds(bounds: number[][][], duration?: number): void;

    public abstract setBearing(bearing: number): void;

    /** Sets the bounds of the map using a native bounds type */
    public abstract setBoundsWithBounds(bounds: [number, number, number, number], duration?: number, maxZoom?: number, padding?: number): void;

    public abstract onResize(): void;

    public abstract getSnapshotDataUrl(): Promise<string>;

    public abstract setMapLock(locked: boolean): void;

    public abstract getExportPosition(): { bounds: number[][]; bearing: number; pitch: number };

    public abstract updateBoundsForSource(sourceId: string, bounds: BBox): void;

    public abstract addDateFilterToLayer(layer: MapModuleLayer, dateFilter: { day: number; time: number }): void;

    public abstract removeDateFilterFromLayer(layerId: MapModuleLayer): void;

    protected abstract moveLayerToTopOfMap(layerId: string): void;

    protected abstract moveLayerToBottomOfMap(layerId: string): void;

    /**
     * @param store The data store the map will reflect the data from
     * @param container The HTML element in which to render the map
     * @param eventStream The event stream for directly triggering stateless map events
     * @param preview Whether this map is a preview map, otherwise requiring full functionality
     * @param modifierKeys Keys which when pressed modify click behaviour (e.g shift)
     * @param bustTileCache Transforms network requests to bust caches for tiles
     * @param routeApiFunctions object containing async functions such as generating routes and getting waypoints for the object etc
     */
    constructor({
        store,
        eventStream,
        container,
        preview,
        assetOptions,
        modifierKeys,
        devTools,
        getAttributeListItems,
        routeApiFunctions,
        user,
        isExternalUser,
    }: EngineInterpreterOptions) {
        if (store === undefined) {
            throw new Error("A store is required");
        }
        this.store = store;
        this.eventStream = eventStream;
        this.container = container;
        this.preview = preview;
        this.assetOptions = assetOptions;
        this.isExternalUser = isExternalUser;
        this.modifierKeys = modifierKeys;
        this.getAttributeListItems = getAttributeListItems.bind(this);
        this.routeApiFunctions = routeApiFunctions;
        this.user = user;
        this.initialiseCompositionUndoRedo();
        this.addSvgOverlay(container);
        this.subscribeToEventStream();
        this.subscribeToMachineTransitions(devTools);
        this.machine.start();
    }

    /*
        EVENT STREAM
    */

    protected subscribeToEventStream() {
        // Add all events inside this listen function call
        this.eventStream
            .listen(Object.values(modeMachineEvents))
            .pipe(takeUntil(this.onDestroy))
            .subscribe((message) => {
                this.machine.send(message as ModeEvents);
            });
        this.eventStream.listen(Object.values(MapEventTypes)).pipe(takeUntil(this.onDestroy)).subscribe(this.onStreamEvent.bind(this));
    }

    /*
        MACHINE TRANSITION
    */

    protected subscribeToMachineTransitions(devTools: boolean) {
        this.machine = interpret(
            modeMachine(this).withConfig({
                // Promise.resolve() is a placeholder until we actually store analysis objects on the backend
                services: { getAnalysisObjects: async () => Promise.resolve() },
            }),
            { devTools }
        ).onTransition(({ value, changed }) => {
            if (!changed) {
                return;
            }
            const parsedValue = machineModeOutputToString(value);

            this.storeEvents.next({ eventType: MapStoreEventType.SET_MODE, payload: { mode: parsedValue as MapMode } });
        });
    }

    protected getDrawingMachineState(): DrawingState {
        const drawingMachine = this.getDrawingMachine();
        if (drawingMachine) {
            return machineModeOutputToString<DrawingStates>(drawingMachine.getSnapshot().value);
        }
        return null;
    }

    protected getDrawingMachine(): ActorRef<DrawingMachineEvents> {
        return this.machine.children.get(DRAWING_MACHINE_ID);
    }

    protected addSvgOverlay(container: HTMLElement) {
        const svgElement = document.createElementNS(SVG_NAMESPACE, "svg");
        svgElement.style.position = "absolute";
        svgElement.style.left = "0";
        svgElement.style.top = "0";
        svgElement.style.width = "100%";
        svgElement.style.height = "100%";
        svgElement.style.pointerEvents = "none";
        svgElement.style.zIndex = "10";
        container.appendChild(svgElement);
        this.svgContainer = svgElement;
    }

    private initialiseCompositionUndoRedo() {
        this.compositionUndoRedo = new CompositionUndoRedo({
            updateGeometry: (change) => {
                // Update the geometry of the composition
                this.machine.send({ type: "UPDATE_GEOMETRY", payload: { updateStore: true, features: change.map((object) => object.geojson) } });

                // ToDo: Handle route waypoints somehow
            },
            cancel: () => {
                this.machine.send({ type: "CANCEL_DRAWING", payload: { updateStore: true } });
            },
        });
    }

    /*
        GETTERS
    */

    public getCurrentState() {
        return this.store.change.getValue();
    }

    protected getMode() {
        return this.getCurrentState().mode.value;
    }

    public getCompositionUndoRedo() {
        return this.compositionUndoRedo;
    }

    protected getMapObjectRemoteId(localId: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return this.getCurrentState().localToRemoteMapObjectIdsMap.value[localId] as string;
    }

    protected getMapObjectlocalId(remoteId: string) {
        return getKeyIn(this.getCurrentState().localToRemoteMapObjectIdsMap.value, remoteId);
    }

    protected getSelectedMapObjectsIds() {
        return this.getCurrentState().mapObjectsSelected.value;
    }

    protected getMapLayers() {
        return this.getCurrentState().layers.value;
    }

    protected getLocalRemoteObjectIdMap() {
        return this.getCurrentState().localToRemoteMapObjectIdsMap.value;
    }

    protected saveLastState(state: MapState) {
        this.lastStateRecieved = state;
    }

    protected getProjectDataFields() {
        return this.getCurrentState().projectDataFields;
    }

    public getStoredLayer(layerId: string) {
        return this.getCurrentState().layers.value.find((layer) => layer.id === layerId);
    }

    protected getLayerControlValue(layerId: string, controlProperty: LayerDrawingControl) {
        const layer = this.getStoredLayer(layerId);
        return layer.drawingControls[controlProperty];
    }

    protected getLocalLayerGeometry(layerId: string, throwIfNotFound = false) {
        const localLayer = this.getCurrentState().geoJSON.value[layerId];
        if (throwIfNotFound && localLayer == null) {
            throw new Error(`GeoJson could not be found for ${layerId}`);
        }
        return localLayer;
    }

    private getLocalObject(objectId: string, layerId: string, throwIfNotFound = true) {
        const state = this.getCurrentState();
        const layerGeometry = state.geoJSON.value[layerId];
        if (!Array.isArray(layerGeometry)) {
            if (throwIfNotFound) {
                throw new Error("Could not find local geometry for provided layer ID");
            } else {
                return undefined;
            }
        }

        const object = layerGeometry.find((object) => object.objectId === objectId);

        if (object === undefined) {
            if (throwIfNotFound) {
                throw new Error(`Could not find object with ID ${objectId} in local geometry`);
            } else {
                return undefined;
            }
        }

        return object;
    }

    private getLocalGeometry(objectId: string, layerId: string, throwIfNotFound = true): TypedFeature {
        const localObject = this.getLocalObject(objectId, layerId, throwIfNotFound);
        return localObject?.feature;
    }

    /** Gets map features fo the line and polygon analysis layers */
    private getAnalysisLayerGeometry(throwIfNotFound = true) {
        const polygonAnalysisLayers = this.getLocalLayerGeometry(analysisPolygonLayerId, throwIfNotFound) ?? [];
        const lineAnalysisLayers = this.getLocalLayerGeometry(analysisLineLayerId, throwIfNotFound) ?? [];
        return [...polygonAnalysisLayers, ...lineAnalysisLayers];
    }

    public isCompositionMode(): boolean {
        return checkModeIsPresent(machineModeOutputToString(this.machine.getSnapshot().value), "composition");
    }

    /**
     * Get the current composition as either single (for convenience) or multiple objects
     * @param requireSingle Whether we're expecting a single object for composition. An error is thrown if this contradicts the current composition state
     * @returns Either an array of, or single map object
     */
    public getCurrentComposition<T extends boolean>(requireSingle: T): CompositionReturn<T> {
        if (requireSingle) {
            if (!this.currentComposition) {
                return undefined;
            }
            if (this.currentComposition.length > 1) {
                throw new Error("Attempted to get single current composition, but multiple existed");
            }
            return this.currentComposition[0] as CompositionReturn<T>;
        }

        return this.currentComposition as CompositionReturn<T>;
    }

    protected localGeometryExists(layerId: string, objectId: string): boolean {
        const currentState = this.getCurrentState();
        const geometryExists = currentState.geoJSON.value?.[layerId]?.some(({ objectId: storedObjectId }) => storedObjectId === objectId);
        return geometryExists === true;
    }

    public getSelectedLayers(): MapModuleLayer[] {
        return this.getMapLayers().filter((layer) => layer.selected);
    }

    protected isModifierPressed(): boolean {
        return this.keysPressed.some((key) => this.modifierKeys.includes(key));
    }

    protected getUnitOfMeasurement(): UnitOfMeasurement {
        return this.getCurrentState().unitOfMeasurement.value;
    }

    /*
        SETTERS
    */

    protected requestMapObjectGeometries(objects: DrawingObject[]) {
        this.storeEvents.next({
            eventType: MapStoreEventType.REQUEST_GEOMETRY,
            payload: {
                objects,
            },
        });
    }

    protected ensureGeometryExistsLocally(objects: DrawingObject[]) {
        return new Promise<void>((resolve) => {
            const objectsMissing = objects.filter(({ layerId, objectId }) => !this.localGeometryExists(layerId, objectId));

            if (objectsMissing.length > 0) {
                this.waitForStoreChange((previousState, currentState) => currentState.geoJSON.stamp !== previousState.geoJSON.stamp).then(() => resolve(undefined));
                this.storeEvents.next({
                    eventType: MapStoreEventType.REQUEST_GEOMETRY,
                    payload: {
                        objects: objectsMissing,
                    },
                });
            } else {
                resolve(undefined);
            }
        });
    }

    protected setGeometryInStore(objects: ObjectUpdate[]) {
        this.storeEvents.next({
            eventType: MapStoreEventType.SET_OBJECTS,
            payload: objects,
        });
    }

    protected setModeInStore(mode: MapMode) {
        return new Promise<void>((resolve) => {
            if (this.getMode() === mode) {
                resolve(undefined);
                return;
            }
            this.waitForStoreChange((previous, current) => previous.mode.stamp !== current.mode.stamp).then(resolve);
            this.sendEventToStore({
                eventType: MapStoreEventType.SET_MODE,
                payload: {
                    mode,
                },
            });
        });
    }

    public setSelectedMapObjectsInStore(mapObjects: SelectedMapObject[], from: string | undefined) {
        return new Promise<void>((resolve) => {
            this.waitForStoreChange((previous, current) => previous.mapObjectsSelected.stamp !== current.mapObjectsSelected.stamp).then(resolve);
            this.sendEventToStore({
                eventType: MapStoreEventType.SET_MAP_OBJECTS_SELECTED,
                payload: {
                    mapObjects,
                    from,
                },
            });
        });
    }

    public deselectLayers() {
        return new Promise<void>((resolve) => {
            this.waitForStoreChange((previous, current) => previous.mapObjectsSelected.stamp !== current.mapObjectsSelected.stamp).then(resolve);
            this.sendEventToStore({
                eventType: MapStoreEventType.DESELECT_LAYERS,
                payload: undefined,
            });
        });
    }

    public selectAnalysisLayer(layerId: string, from: string) {
        return new Promise<void>((resolve) => {
            this.waitForStoreChange((previous, current) => previous.layers.stamp !== current.layers.stamp).then(resolve);
            this.sendEventToStore({
                eventType: MapStoreEventType.SELECT_ANALYSIS_LAYER,
                layerId,
                from,
            });
        });
    }

    protected sendEventToStore(event: MapStoreEvent) {
        this.storeEvents.next(event);
    }

    protected async addInternalImages() {
        await Promise.all([
            this.addImage(removeButtonPNG, coordinateDeleteSpriteName),
            this.addImage(coordinateHandlePNG, coordinateHandleSpriteName),
            this.addImage(routeHandlePNG, waypointSpriteName),
            this.addImage(rotateHandlePNG, rotateHandleSpriteName),
            this.addImage(continueDrawingButtonPNG, continueDrawingSpriteName),
            this.addImage(textboxPNG, textboxSpriteName),
            this.addImage(trianglePNG, triangleSprintName, { sdf: true }),
        ]);
    }

    /*
        INTERACTIVITY/ ACTION
    */

    protected listenToStore() {
        this.store.change.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            const state = this.getCurrentState();
            const { lastStateRecieved: previousState } = this;
            this.saveLastState(state);
            this.onStoreChanged(state, previousState);
        });
    }

    protected async waitForStoreChange(hasChangeHappened: (previousState: MapState, newState: MapState) => boolean) {
        const currentState = this.getCurrentState();
        return new Promise<void>((resolve) => {
            const subscription: Subscription = this.store.change.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                const newState = this.getCurrentState();
                if (hasChangeHappened(currentState, newState)) {
                    subscription.unsubscribe();
                    resolve(undefined);
                }
            });
        });
    }

    protected async onStoreChanged(state: MapState, previousState: MapState) {
        // Position
        if (state.position.value.source === Source.HOST && state.position.stamp !== previousState.position.stamp) {
            const { lat, lng, bearing, pitch, zoom, smooth } = state.position.value;
            const setPosition = () => this.setPosition(lat, lng, bearing, pitch, zoom, smooth ?? false);
            // If we are updating the source at the same time as updating the position, we must wait for the source to be updated before setting the position
            if (state.geoJSON.stamp !== previousState.geoJSON.stamp) {
                if (this.preview) {
                    // When in preview mode don't wait for idle as it messes up timing for snapshots
                    setPosition();
                } else {
                    this.onMapIdle(() => setPosition(), { listenOnce: true, disableMapQuerying: false });
                }
            } else {
                setPosition();
            }
        }

        // Bounds
        if (state.bounds.value.source === Source.HOST && state.bounds.stamp !== previousState.bounds.stamp) {
            this.setBounds(state.bounds.value.bounds, state.bounds.value.duration);
        }

        // Layers changed
        if (state.layers.stamp !== previousState.layers.stamp) {
            const layerChanges = findDifferingLayers(state.layers.value, previousState.layers.value);
            layerChanges.removedIds.forEach((layerIdToRemove) => this.removeLayer(layerIdToRemove));
            this.implementStyleChanges(previousState.layers.value, state.layers.value);
            if (!this.preview) {
                await this.importLayers(previousState.layers.value, state.layers.value);
            }
            this.updateLayerVisibility(previousState.layers.value, state.layers.value);

            if (state.layers.value.some((layer) => layer.selected && layer.locked)) {
                this.machine.send({ type: machineEventTypes.enterRead });
            }

            // Check for ordering change
            const orderingLayerChange = findLayerWithOrderingChange(state.layers.value, previousState.layers.value);
            if (orderingLayerChange?.action === LayerMovementAction.MoveToTop) {
                this.moveLayerToTopOfMap(orderingLayerChange.layer.id);
            } else if (orderingLayerChange?.action === LayerMovementAction.MoveToBottom) {
                this.moveLayerToBottomOfMap(orderingLayerChange.layer.id);
            }
        }

        // Local Objects Changed
        if (state.geoJSON.stamp !== previousState.geoJSON.stamp) {
            // While we're dragging an object, we do not want to be listening to geojson store changes that might be behind the latest source
            const skip = this.objectDrag != null;
            if (!skip) {
                const currentComposition = this.getCurrentComposition(false);
                // Any of the objects being drawn are not kept in the map store
                if (currentComposition?.some((object) => state.geoJSON.value[object.layerId] != null)) {
                    const someCompositionsLostGeometry = currentComposition?.some(
                        (composition) => !state.geoJSON.value[composition.layerId].some((localObject) => localObject.objectId === composition.objectId)
                    );
                    if (someCompositionsLostGeometry) {
                        // If some compositions lost their geometry, it means they have been removed. Therefore we should no longer show their drawing controls.
                        this.removeDrawingControls();
                    }
                    // Make sure the name of the objects in the current compositions are up to date. (The object name is updated here when the Create Map Object request is returned)
                    currentComposition?.forEach((object) => {
                        if (object?.geojson?.properties) {
                            // Find the object in the local GeoJson Object
                            const localGeoJsonObject = state.geoJSON.value[object.layerId].find((f) => f.objectId === object.objectId);
                            // eslint-disable-next-line no-param-reassign
                            object.geojson.properties = localGeoJsonObject?.feature?.properties ?? object.geojson.properties;
                        }

                        // If there are any analysis objects we need to update their measurement labels
                        if (isAnalysisLayer(object.layerId)) {
                            this.setLongLastingMeasurementLabels(object.geojson);
                        }
                    });
                    // Get all layer ids in the current composition
                    const layerIds = Array.from(new Set(currentComposition.map((composition) => composition.layerId)));
                    // Update each layer inside the compositionLayersRemainingSource with their new local map objects
                    this.compositionLayersRemainingSource = layerIds.reduce<CompositionRemainingSource[]>((updatedCompositionLayers, layerId) => {
                        // Get all composition objects belonging to that layer
                        const compositionObjects = currentComposition.filter((object) => object.layerId === layerId);
                        // Filter out the objects which are in composition
                        const layerObjects = state.geoJSON.value[layerId].filter((localObject) => !compositionObjects.some((object) => object.objectId === localObject.objectId));
                        updatedCompositionLayers.push({ layerId, geojson: layerObjects.map((o) => o.feature) });
                        return updatedCompositionLayers;
                    }, []);
                }
                this.reflectLocalObjects({ allowTextOnSelectedObjects: this.isCompositionMode() });
                this.hideRemoteObjects();
            }
        }

        // Source Changed
        if (state.tileSources.stamp !== previousState.tileSources.stamp) {
            const state = this.getCurrentState();
            const sourcesToRefresh: string[] = [];
            Object.values(state.tileSources.value).forEach((tileSource) => {
                // Bounds is an empty string when there are no map objects for that source
                if (tileSource.bounds !== "" && tileSource.bounds != null) {
                    tileSource.tiles.forEach((tiles) => {
                        this.updateBoundsForSource(tiles.name, bbox(tileSource.bounds));
                        sourcesToRefresh.push(tiles.name);
                    });
                }
            });
            this.refreshTileSources(sourcesToRefresh);
        }

        // Map resized
        if (state.onResize !== previousState.onResize) {
            this.onResize();
        }

        // 3D terrain changed
        if (state.terrain3D.stamp !== previousState.terrain3D.stamp) {
            this.configureTerrain3D(state.terrain3D.value.enabled, state.terrain3D.value.exaggeration);
        }

        if (state.globe.stamp !== previousState.globe.stamp) {
            this.configureGlobeProjection(state.globe.value);
        }

        if (state.unitOfMeasurement.stamp !== previousState.unitOfMeasurement.stamp) {
            this.unitOfMeasurementChange();
        }

        if (state.isCameraMovementLocked.stamp !== previousState.isCameraMovementLocked.stamp) {
            this.setMapLock(state.isCameraMovementLocked.value);
        }

        if (state.overrideCursor.stamp !== previousState.overrideCursor.stamp) {
            this.setCursor(state.overrideCursor.value);
        }

        if (state.currentLevel !== previousState.currentLevel) {
            // Update object drag
            this.objectDrag?.updateLevel(state.currentLevel);
            this.storeEvents.next({ eventType: MapStoreEventType.LEVEL_CHANGED, payload: { level: state.currentLevel } });
        }

        if (state.hideComments !== previousState.hideComments) {
            if (state.hideComments) {
                this.hideLayer(commentsLayerId);
            } else {
                this.showLayer(commentsLayerId);
            }
        }

        if (state.datesFilter.stamp !== previousState.datesFilter.stamp) {
            // If any of current composition object is being filtered by the same date filter, remove the composition
            if (this.currentComposition?.length > 0 && this.currentComposition.some((object) => object.geojson.properties.dateFiltered)) {
                this.removeCompositionBehaviour();
                this.removeDrawingControls();
                this.removeComposition();
            }

            const { filter, day, time } = state.datesFilter.value;
            const layersWithTimeRanges = state.layers.value.filter((layer) => layer.dataFields?.some((df) => df.type === DataFieldType.RepeatedTimeRanges));
            if (filter) {
                layersWithTimeRanges.forEach((layer) => {
                    this.addDateFilterToLayer(layer, { day, time });
                });
            } else {
                layersWithTimeRanges.forEach((layer) => {
                    this.removeDateFilterFromLayer(layer);
                });
            }
        }
    }

    protected async onStreamEvent(event: MapEvents) {
        switch (event.type) {
            case MapEventTypes.GET_USER_LOCATION:
                this.getUserLocation();
                break;
            case MapEventTypes.REFRESH_SOURCE_TILES:
                this.refreshTileSources((event as RefreshMapTileSourceEvent).payload.sourceNames);
                break;
            case MapEventTypes.REFRESH_LOCAL_OBJECTS:
                this.reflectLocalObjects({ allowTextOnSelectedObjects: this.isCompositionMode() });
                break;
            case MapEventTypes.REFRESH_ASSET:
                if (event.payload.type === AssetType.MapIcon) {
                    this.removeImage(event.payload.assetId);
                } else if (event.payload.type === AssetType.Model) {
                    this.reloadLayersByAssetId(event.payload.assetId);
                }
                break;
            default:
                break;
        }
    }

    /*
        Function called when the last render has finished in the map.
        If you wish to do more map things, use onFirstRender, otherwise you're making us uncertain as to when the map is finished loading
    */
    protected onLoadComplete() {
        this.invocationEvents.next({ type: InvocationEventType.MAP_LOADED });
    }

    protected onMapError(error: MapErrorBodyType) {
        this.invocationEvents.next({ type: InvocationEventType.MAP_ERRORED, body: error });
    }

    protected initialiseListeners(): void {
        this.onFirstRender(async () => {
            this.listenToStore();
            this.addInternalImages();
            this.machine.send({ type: machineEventTypes.loaded });
            this.removeOnMapLoadedListener();
            this.enableCacheClearing();

            updatePosition(false);
        });

        const imageRequiredListener = this.onImageRequired(async (e) => {
            const id = e?.id;
            const idNoSuffix = id.replace(`-${sdfDisabledSuffix}`, "");

            if (!this.imagesLoading.some((imageId) => imageId === id)) {
                this.imagesLoading.push(id);
                let imageUrl = convertImageIdToLocalPath(idNoSuffix);

                // If the image isn't an internal image, use image options to get the correct path/url
                if (imageUrl == null) {
                    // If a url getter exists, use this to add the image
                    if (typeof this.assetOptions.assetUrlGetter === "function") {
                        imageUrl = await this.assetOptions.assetUrlGetter(idNoSuffix);
                    }

                    // If neither attempt has produced an image url, throw an error
                    if (imageUrl == null) {
                        this.imagesLoading = this.imagesLoading.filter((imageLoadingId) => imageLoadingId !== id);
                        return;
                    }
                }

                if (imageUrl.includes(AssetType.ProfileImage.toString())) {
                    const commentIcon = new CommentIcon(imageUrl);
                    const image = await commentIcon.createIcon();
                    this.addImage(image, id);
                } else {
                    const sdf = id.includes(sdfDisabledSuffix) ? false : isValidUuid(idNoSuffix) ? await this.assetOptions.isAssetSdf(idNoSuffix) : false;
                    // Else, add the image
                    await this.addImage(imageUrl, id, { sdf });
                }

                this.imagesLoading = this.imagesLoading.filter((imageLoadingId) => imageLoadingId !== id);
            }
        });

        this.ctrlListener = new CtrlListener(this.onKeyUp.bind(this), this.onKeyDown.bind(this));

        const mouseOutListener = this.onMouseOut((event) => {
            const [x, y] = this.project([event.lng, event.lat]);
            this.invocationEvents.next({
                type: InvocationEventType.MOUSE_OUT,
                body: {
                    x,
                    y,
                },
            });
            this.getDrawingMachine()?.send({ type: "MOVE_AWAY" });
            this.ctrlListener.destroy();

            // If the mouse leaves the canvas we do not want to try and delete the object (i.e. the user is editing the map object name in object details)
        });

        const mouseOverListener = this.onMouseOver(() => {
            if (!this.ctrlListener.active) {
                this.ctrlListener = new CtrlListener(this.onKeyUp.bind(this), this.onKeyDown.bind(this));
            }
        });

        const panStartListener = this.onPanStart(() => {
            this.invocationEvents.next({
                type: InvocationEventType.PAN_START,
                body: undefined,
            });
        });

        const keyDownListener = this.onKeyDown((event) => {
            this.keysPressed = [...this.keysPressed, event.key];
        });

        const keyUpListener = this.onKeyUp((event) => {
            this.keysPressed = [...this.keysPressed.filter((key) => key !== event.key)];
        });

        const updatePosition = async (getSiteMaps = true) => {
            const position = await this.getPosition();
            const bounds = await this.getBounds();
            const commentPosition = this.getSelectedCommentPosition();

            // If the selected comment is now out of bounds, centre the comment on the map
            if (commentPosition?.canvas.some((coord) => coord < 0)) {
                this.onMapIdle(() => this.setPosition(commentPosition.map[1], commentPosition.map[0], position.bearing, position.pitch, position.zoom, true), {
                    listenOnce: true,
                    disableMapQuerying: false,
                });
                return;
            }

            this.sendEventToStore({
                eventType: MapStoreEventType.SET_POSITION,
                payload: {
                    mapPostion: {
                        position,
                        bounds,
                    },
                    getSiteMaps,
                    commentCanvasPosition: commentPosition?.canvas,
                },
            });
        };

        const positionMoveListener = this.onMoveEnd(() => updatePosition());

        this.OnMapMoveStart(() => {
            this.sendEventToStore({
                eventType: MapStoreEventType.MAP_MOVE_START,
                payload: undefined,
            });
        });

        this.OnMapMoveEnd(async () => {
            const position = await this.getPosition();
            this.sendEventToStore({
                eventType: MapStoreEventType.MAP_MOVE_END,
                payload: position,
            });
        });

        const copyObjectsListener = this.onKeyCopy(() => {
            if (this.getSelectedObjects()?.length > 0) {
                this.machine.send({ type: machineEventTypes.copyObjects });
            }
        });

        this.onDestroy.subscribe(() => {
            mouseOutListener.remove();
            mouseOverListener.remove();
            panStartListener.remove();
            positionMoveListener.remove();
            keyDownListener.remove();
            keyUpListener.remove();
            imageRequiredListener.remove();
            this.ctrlListener.destroy();
            copyObjectsListener.remove();
        });
    }

    protected getCompositionForMapObjects(objects: (Optional<CompositionMapObject, "geojson" | "waypoints"> | CompositionMapObject)[]): CompositionMapObject[] {
        return objects.map((object) => {
            let geometry: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
            let waypoints;
            // TODO: Fix read-only store objects problem in a broader way (for now JSON.parse(JSON.stringify()))
            if (object.geojson !== undefined) {
                geometry = JSON.parse(JSON.stringify(object.geojson));
            } else {
                geometry = JSON.parse(JSON.stringify(this.getLocalGeometry(object.objectId, object.layerId)));
            }
            if (object.waypoints !== undefined) {
                waypoints = JSON.parse(JSON.stringify(object.waypoints));
            } else {
                const localObject = this.getLocalObject(object.objectId, object.layerId, false);
                waypoints = localObject?.waypoints ? JSON.parse(JSON.stringify(localObject.waypoints)) : undefined;
            }

            const currentComposition = {
                ...object,
                geojson: geometry,
                waypoints,
            };
            return currentComposition;
        });
    }

    /**
     * Creates a short-lived map object that we can use for drawing/ manipulating geometry.
     * Sets the class members currentComposition and compositionLayerRemainingSource; used in ephemeral high-rate updates to map objects
     * This is required so that our local layer does not end up with duplicated features rendered on the map.
     * This wouldn't be an issue if we just updated objects in the store as the objects changed, but the update rate is far too frequent for that to be sensible
     * @param objects The object we are editing. May contain an explicit starting object (via the geojson property) for new objects
     * @param withGeoJson Do the objects already come with geojson?
     */
    protected createEphemeralObjects(
        objects: Optional<CompositionMapObject, "geojson" | "waypoints">[],
        objectsWithGeoJson: CompositionMapObject[] = this.getCompositionForMapObjects(objects)
    ) {
        // Set the current composition as the objects we've recieved in this function
        this.setCurrentComposition(objectsWithGeoJson);

        const state = this.getCurrentState();

        this.compositionLayersRemainingSource = [];
        // Set the current composition remaining source
        Object.entries(groupCompositionMapObjectByLayer(objects)).forEach(([layerId]) => {
            const compositionObjectIds = this.currentComposition.map((comp) => comp.objectId);
            if (state.geoJSON.value[layerId] !== undefined) {
                // If we already have some local geometry, make sure that is used during drawing
                this.compositionLayersRemainingSource.push({
                    layerId,
                    geojson: this.getCurrentState()
                        .geoJSON.value[layerId].filter((object) => {
                            // Remove the current drawing objects from the "remaining" source so they don't appear twice
                            const isCompositionObject = compositionObjectIds.some((compositionId) => compositionId === object.objectId);
                            // Check if we are also filtering by date
                            const dateFilter = state.datesFilter.value.filter;
                            if (dateFilter) {
                                return !isCompositionObject && !object.feature.properties.dateFiltered;
                            }
                            return !isCompositionObject;
                        })
                        .map((obj) => obj.feature),
                });
            } else {
                // If we do not have some local geometry, we don't need to push it to the remaining source, since it will exist in the remote layer and remains present
                this.compositionLayersRemainingSource.push({
                    layerId,
                    geojson: [],
                });
            }
        });

        this.setEphemeralGeometry(this.currentComposition);
    }

    /**
     * Set the geometry for the short-lived feature in the current composition. Supports multiple layers simultaneously.
     * Uses compositionLayersRemainingSource member for the rest of the layer excluding the ephemeral objects.
     * @param object The feature to display
     */
    protected setEphemeralGeometry(objectOrObjects: CompositionMapObject | CompositionMapObject[]) {
        let objects: CompositionMapObject[];
        if (!Array.isArray(objectOrObjects)) {
            objects = [objectOrObjects];
        } else {
            objects = objectOrObjects;
        }
        const combinedSourceByLayerId: { [layerId: string]: Feature<AnySupportedGeometry>[] } = {};

        this.compositionLayersRemainingSource.forEach(({ layerId, geojson }) => {
            combinedSourceByLayerId[layerId] = combinedSourceByLayerId[layerId] ? [...combinedSourceByLayerId[layerId], ...geojson] : geojson;
        });

        Object.entries(combinedSourceByLayerId).forEach(([layerId, source]) => {
            const layer = this.getStoredLayer(layerId);
            const ephemeralGeometryInLayer = objects.filter((object) => object.layerId === layerId && object.geojson.geometry.coordinates.length !== 0).map((comp) => comp.geojson);
            const combinedSource = featureCollection([...source, ...ephemeralGeometryInLayer]);
            this.reflectSource(layer.storageScope === LayerStorageScope.LocalOnly ? layer.source : getLocalLayerID(layerId), combinedSource);
        });
    }

    /*
        READ BEHAVIOUR
    */

    /**
     * Set up event listeners and state ready for read operation
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public addReadBehaviour(enableClickZoom = true) {
        this.removeReadBehaviour();
        this.readClickListener = this.onClick((event) => {
            // Find the first one with an object ID, if it exists.
            const mapObjectClicked = event.objects.find((object) => object.objectId !== undefined);
            const internalMapObjectUnderClick = event.objects.find((object) => internalMapLayerIds.includes(object.layerId));

            if (internalMapObjectUnderClick) {
                // Do not perform any behaviours here if an internal map layer object was clicked
                // E.g clicking the node deletion button or rotation handle should not do anything here
                // Any events on these objects are handled by their respecitve behaviour classes
                return;
            }

            if (mapObjectClicked === undefined) {
                // Do nothing if an id-less feature was clicked
                this.clickOnEmptySpace();
                this.removeDrawingControls();
                this.removeHighlights();
                return;
            }

            switch (mapObjectClicked.layerId) {
                case commentsLayerId: {
                    if (mapObjectClicked.geometry.type !== "Point") return;
                    this.clickOnComment({ commentId: mapObjectClicked.objectId, userId: mapObjectClicked.properties.userId, position: mapObjectClicked.geometry.coordinates });
                    break;
                }
                default: {
                    this.clickOnMapObject({ objectId: mapObjectClicked.objectId, layerId: removeLayerIdSuffixes(removeAdditionalLayerSuffixes(mapObjectClicked.layerId)) });
                }
            }
        });

        this.addReadMouseMove();
    }

    public addReadMouseMove() {
        // Clear any current read mouse move to prevent multiple listeners
        this.readMouseMoveListener?.remove();
        this.readMouseMoveListener = this.onMouseMove((event) => this.readMouseMove(event));
    }

    public removeReadMouseMove() {
        this.readMouseMoveListener?.remove();
    }

    protected readMouseMove(event: MoveEvent) {
        // Our objects use a uuid, ensure the id is a uuid
        const validMapObjects = event.objects.filter((objects) => isValidUuid(objects.id)) as GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>[]; // Filter to only Map Object
        const internalMapObjects = event.objects.filter((object) => internalMapLayerIds.includes(object.properties.layerid)); // Check for internal map objects
        if (internalMapObjects.length > 0) {
            return;
        }

        const mode = this.getMode();

        if (checkAnyModeIsPresent<DrawingStates>(this.getDrawingMachineState(), ["nodeDragging", "objectDragging"])) {
            return;
        }

        if (validMapObjects?.length !== 0) {
            const topObject = validMapObjects[0];
            const isSelected = this.getSelectedMapObjectsIds()?.some(({ objectId }) => objectId === topObject.id);
            if (checkModeIsPresent(mode, "addComment") || (this.commentsHandler != null && topObject.properties.layerid === commentsLayerId)) {
                this.setCursor(this.commentsHandler?.getCursor(topObject.properties.layerid, topObject.properties.id));
                return;
            }
            if (isSelected && isSingleRoute([{ geojson: topObject }])) {
                return;
            }
            if (isSelected && checkModeIsPresent(mode, "edit")) {
                this.setCursor(MapCursor.MOVE); // While editing an object with a geometry type of "Point", we can click and move, so set the cursor to reflect that
            } else {
                this.setCursor(MapCursor.POINTING); // Pointing at an object
            }
        } else {
            this.setCursor(this.getDefaultCursor());
        }

        const [x, y] = this.project([event.lng, event.lat]);

        this.invocationEvents.next({
            type: InvocationEventType.MOUSE_MOVE,
            body: {
                features: event.objects,
                x,
                y,
            },
        });
    }

    /**
     * Clean up after the read state
     */
    public removeReadBehaviour() {
        this.readClickListener?.remove();
        this.removeReadMouseMove();
    }
    /*
        EDIT BEHAVIOUR - BASED UPON READ BEHAVIOUR. USED WHEN EDITING OBJECTS, BUT NOT COMPOSING
    */

    public async addEditBehaviour(selectObjects = true) {
        this.addReadBehaviour();
        this.addLastMouseRetention();

        this.addObjectDragListeners();
        this.showRotationHandleOnMove();
        if (selectObjects) {
            const selectedMapObjectIds = this.getSelectedMapObjectsIds();
            // Do not allow toggle because this isn't normal selection, we are just entering edit mode with existing selection
            this.selectMapObjectsInEditMode(selectedMapObjectIds, { updateStore: false, isFromDrag: false, allowToggle: false, from: FROM_MAP_ENGINE });
        }
    }

    public async addLastMouseRetention() {
        // Add last mouse position listener
        this.lastMouseListener = this.onMouseMove(
            (event) => {
                const [x, y] = this.project([event.lng, event.lat]);
                this.lastMouse = { x, y, lng: event.lng, lat: event.lat };
            },
            { disableQuerying: true }
        );
    }

    public async removeLastMouseRetention() {
        this.lastMouseListener?.remove();
    }

    /**
     * Clean up after the edit state
     */
    public removeEditBehaviour() {
        this.readClickListener?.remove();
        this.removeLastMouseRetention();
        this.readMouseMoveListener?.remove();
        this.cameraMoveEndListener?.remove();
        this.removeDrawingControls();
        this.removeObjectDragListeners();
    }

    /**
     * Add the listener to update the rotate handle
     */
    public showRotationHandleOnMove() {
        this.cameraMoveEndListener = this.onMoveEnd(() => {
            const selectedObjects = this.getSelectedObjects();
            if (this.getMode() !== "read") {
                this.showRotationHandle(selectedObjects);
            }
        });
    }

    public getSelectedObjects() {
        const selectedObjects = this.getSelectedMapObjectIdsWithLayerIds();
        const objects = this.getLocalGeometries(selectedObjects.map(({ objectId, layerId }) => ({ objectId, layerId })));
        return objects;
    }

    /**
     * Add the listeners ready to initialise dragging behaviour, should it be required
     */
    public addObjectDragListeners() {
        this.removeObjectDragListeners();
        let overSelectedObject = false;

        const listeners: Listener[] = [];

        listeners.push(
            this.onMouseMove((event) => {
                const selectedObjectsOver = event.objects.filter((object) => this.getSelectedMapObjectsIds().some((selectedId) => selectedId.objectId === object.id));
                if (selectedObjectsOver.length > 0 && !overSelectedObject) {
                    // If we move over a selected object, inform the machine
                    this.getDrawingMachine().send({ type: machineEventTypes.overSelectedObject });
                    overSelectedObject = true;
                }

                if (selectedObjectsOver.length === 0 && overSelectedObject) {
                    // If we are no longer over a selected object, inform the machine
                    this.getDrawingMachine().send({ type: machineEventTypes.leaveSelectedObject });
                    overSelectedObject = false;
                }
            })
        );

        listeners.push(
            this.onMouseDown((event) => {
                // Internal layers should prevent any events directly on the domain map objects
                if (event.objects.find((object) => internalMapLayerIds.includes(object.layerId))) {
                    return;
                }
                const selectedObjectsOver = event.objects.filter((object) => this.getSelectedMapObjectsIds().some((selectedId) => selectedId.objectId === object.objectId));

                if (selectedObjectsOver.length === 0) {
                    return;
                }

                this.disableDragPan();
                const mouseMoveListener = this.onMouseMove(() => {
                    // Only begin the drag behaviour once the mouse starts moving, otherwise it's just a click
                    this.getDrawingMachine().send({ type: machineEventTypes.objectDragStart, payload: { lng: event.lng, lat: event.lat } });
                    this.removeObjectDragListeners();
                    mouseMoveListener.remove();
                });

                const mouseUpListener = this.onMouseUp(() => {
                    mouseMoveListener.remove();
                    mouseUpListener.remove();
                    this.enableDragPan();
                });
            })
        );

        this.objectDragListeners = listeners;
    }

    removeObjectDragListeners() {
        this.objectDragListeners.forEach((listener) => listener.remove());
    }

    renderHighlights(selectedMapObjects: SelectedMapObject[]) {
        // make sure we remove previous highlights
        this.removeHighlights();
        const selectedLayers = this.getSelectedLayers();

        // for each selected layer
        selectedLayers.forEach((layer) => {
            // check if the layer has a highlight layer
            if (doesRequireHighlight(layer) && !this.checkForHighlightLayer(layer)) {
                // create highlight layer
                const highlightLayer = this.createHighlightLayer(layer);
                // add to map
                this.addHighlightLayer(highlightLayer);
            }
        });

        // get local and remote map objects
        const { localMapObjectsSelected, remoteMapObjectsSelected } = this.getLocalAndRemoteSelectedMapObjects(selectedMapObjects);

        // highlight map objects
        this.highlightMapObjects(
            remoteMapObjectsSelected,
            localMapObjectsSelected,
            selectedLayers.map((layer) => layer.id)
        );
    }

    removeHighlights() {
        this.removeHighlightLayer(this.getMapLayers().map((layer) => layer.id));
    }

    checkForHighlightLayer(layer: MapModuleLayer): boolean {
        if (this.localSourceIsAdded(layer.id, layer.storageScope === LayerStorageScope.LocalOnly)) {
            return this.checkIfLayerExists(`${layer.id}_${highlightInfix}`) && this.checkIfLayerExists(`${layer.id}_${highlightInfix}_${localSuffix}`);
        }

        return this.checkIfLayerExists(`${layer.id}_${highlightInfix}`);
    }

    /**
     * Read the current state to decide where to place controls for object manipulation; Coordinate handles, rotation handle, midpoints.
     * @param mapObjectId The map object ID for which we are showing the controls
     * @param isComposing Whether we are currently composing. This affects the placement of mid nodes such that one isn't added for the line following the cursor.
     */
    renderDrawingControls(mapObjectId: string, isComposing = false) {
        let objectRequiringControls: CompositionMapObject;

        const state = this.getCurrentState();

        // We do not yet have selection, so just display the controls for the object we have locally
        const localObjects = Object.entries(state.geoJSON.value).reduce<CompositionMapObject[]>(
            (cum, [layerId, object]) => [...cum, ...object.map((c) => ({ layerId, objectId: c.objectId, geojson: c.feature, waypoints: c.waypoints }))],
            []
        );

        const currentComposition = this.getCurrentComposition(true);
        const localMapObject = localObjects.find((obj) => obj.objectId === mapObjectId);
        if (localMapObject === undefined || (currentComposition && currentComposition.objectId === localMapObject.objectId)) {
            objectRequiringControls = currentComposition;
        } else {
            objectRequiringControls = {
                geojson: localMapObject.geojson,
                layerId: localMapObject.layerId,
                objectId: localMapObject.objectId,
                waypoints: localMapObject.waypoints,
            };
        }

        // We dont want to show drawing controls if object is on a different level
        if (objectRequiringControls.geojson.properties.level !== this.getCurrentState().currentLevel) {
            return;
        }

        if (objectRequiringControls) {
            if (isSingleRoute([objectRequiringControls])) {
                if (checkModeIsPresent(this.getMode(), "composition") || objectRequiringControls.waypoints != null) {
                    this.showRouteControls(objectRequiringControls, objectRequiringControls.waypoints ?? []);
                } else {
                    this.routeApiFunctions
                        .getWaypoints(objectRequiringControls.objectId)
                        .then((waypoints) =>
                            this.showRouteControls(
                                objectRequiringControls,
                                waypoints ?? objectRequiringControls.geojson.geometry.coordinates.map((coords) => createBasicRouteWaypoint(coords))
                            )
                        );
                }
            } else {
                this.showCoordinateHandles(objectRequiringControls.geojson, objectRequiringControls.layerId, objectRequiringControls.objectId);
                this.showMidpointHandles(objectRequiringControls.geojson, objectRequiringControls.layerId, isComposing);
            }
        }
    }

    protected removeDrawingControls() {
        this.removeCoordinateHandles();
        this.removeMidpointHandles();
        this.removeRotationHandle();
        this.removeCoordinateDelete();
        this.removeContinueDrawingHandle();
        this.removeRouteControls();
    }

    /*
        COORDINATE CONTINUE DRAWING BEHAVIOUR
    */
    public showContinueDrawingHandle(coordinate: GeoJSON.Position, object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>) {
        if (this.continueDrawingHandler && numbersEqualWithin(this.currentContinueDrawing.coordinate, coordinate)) {
            return;
        }

        this.removeContinueDrawingHandle();
        this.currentContinueDrawing = { coordinate };

        this.continueDrawingHandler = new ContinueDrawing(coordinate, object, this.svgContainer, {
            project: this.project.bind(this),
            unproject: this.unproject.bind(this),
            onContinueDrawingClicked: (callback: () => void) => this.onClick(callback, continueDrawingLayerId),
            onContinueDrawingButtonMouseMove: (callback: () => void) => this.onMouseMove(callback, { layerId: continueDrawingLayerId }),
            onMapMoveStarted: (callback: () => void) => this.OnMapMoveStart(callback),
            onMouseMove: (callback: (event: MoveEvent) => void) => this.onMouseMove(callback),
            setGeometry: (position, angle) => this.setContinueDrawingButtonPosition(position, angle),
            getMapRotation: () => this.getRotation(),
        })
            .on(ContinueDrawingEvent.DIRECTION_SELECTED, (direction) => {
                this.machine.send({
                    type: machineEventTypes.continueDrawing,
                    payload: {
                        objects: [{ geojson: object, objectId: object.properties.id, layerId: object.properties.layerid }],
                        fromCoordinate: coordinate,
                        direction,
                    },
                });
            })
            .on(ContinueDrawingEvent.MOUSE_MOVE_BUTTON, () => {
                this.getDrawingMachine().send({ type: machineEventTypes.overContinueDrawing });
            });
    }

    protected setContinueDrawingButtonPosition(position: GeoJSON.FeatureCollection<GeoJSON.Point>, angle: number) {
        this.reflectSource(continueDrawingSourceId, position);
        this.addContinueDrawingLayer();
        this.setContinueDrawingButtonRotation(angle);
    }

    public removeContinueDrawingHandle() {
        this.continueDrawingHandler?.destroy();
        this.currentContinueDrawing = { coordinate: undefined };
        this.continueDrawingHandler = undefined;
    }

    /*
        COORDINATE DELETE BEHAVIOUR
    */

    /**
     * Display the coordinate deletion button for a specified coordinate within an object
     * @param coordinate The coordinate within the object at which to display the button
     * @param object The object in question
     * Will NOT display for points
     */
    public showCoordinateDelete(coordinate: GeoJSON.Position, object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>) {
        const previousCoordinate = this.currentCoordinateDelete?.coordinate;
        if (
            // This one is already here
            (previousCoordinate && numbersEqualWithin(coordinate, previousCoordinate)) ||
            // Or type is point
            object.geometry.type === "Point"
        ) {
            return;
        }

        if (previousCoordinate) {
            this.removeCoordinateDelete();
        }

        this.currentCoordinateDelete = { coordinate };
        this.coordinateDelete = new CoordinateDelete(coordinate, getCoordinateGeometry(object), {
            onDeleteMouseMove: (callback: () => void) => this.onMouseMove(callback, { layerId: coordinateDeleteLayerId }),
            onContentUnderCursorChanged: (callback: (event: MoveEvent) => void) => this.onMapIdle(callback, { disableMapQuerying: false, listenOnce: false }),
            onMouseMove: (callback: (event: MoveEvent) => void) => this.onMouseMove(callback),
            onDeleteClick: (callback: () => void) => this.onClick(callback, coordinateDeleteLayerId),
            setGeometry: (collection) => this.setCoordinateDeletePosition(collection),
            getPosition: getRelativePosition(this.project.bind(this), this.unproject.bind(this)),
        })
            .on(CoordinateDeleteEvent.DELETE, () => {
                if (!checkModeIsPresent<DrawingStates>(this.getDrawingMachineState(), "hoverOverDelete")) {
                    this.getDrawingMachine().send({ type: machineEventTypes.enterDelete });
                }
                this.getDrawingMachine().send({ type: machineEventTypes.clickDelete, payload: { coordinate } });
            })
            .on(CoordinateDeleteEvent.ENTER, () => {
                this.getDrawingMachine().send(machineEventTypes.enterDelete);
            })
            .on(CoordinateDeleteEvent.LEAVE, () => {
                this.getDrawingMachine().send(machineEventTypes.leaveDelete);
            });
    }

    public removeCoordinateDelete() {
        if (this.coordinateDelete === undefined) {
            return;
        }
        this.removeCoordinateDeleteLayer();
        this.coordinateDelete.destroy();
        this.coordinateDelete = undefined;
        this.currentCoordinateDelete = undefined;
    }

    protected setCoordinateDeletePosition(position: GeoJSON.FeatureCollection<GeoJSON.Point>) {
        this.reflectSource(coordinateDeleteSourceId, position);
        this.addCoordinateDeleteLayer();
    }

    protected deleteCoordinate(coordinate: GeoJSON.Position) {
        const currentComposition = this.getCurrentComposition(true);
        currentComposition.geojson.geometry = deleteCoordinate(currentComposition.geojson.geometry, coordinate);
        this.setGeometryInStore([
            {
                layerId: currentComposition.layerId,
                objectId: currentComposition.objectId,
                geometry: JSON.parse(JSON.stringify(currentComposition.geojson.geometry)),
                properties: JSON.parse(JSON.stringify(currentComposition.geojson.properties)),
                waypoints: null,
            },
        ]);
    }

    /*
        MIDPOINT HANDLE BEHAVIOUR
    */

    protected async showMidpointHandles(feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, layerId: string, isComposing = false) {
        this.removeMidpointHandles();

        if (!this.getLayerControlValue(layerId, LayerDrawingControl.MID_POINT_HANDLE)) {
            return;
        }
        if (feature.properties.fixedShape === FixedShape.Rectangle && !checkModeIsPresent(this.getMode(), "composition")) {
            this.showMidpointHandlesFixedShape(feature as GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>);
        } else {
            this.showMidpointHandlesNormal(feature, isComposing);
        }
    }

    private showMidpointHandlesNormal(feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, isComposing = false) {
        const coordinates = getLineGeometryForMidpoints(feature, isComposing);
        this.midpointHandles = new MidpointHandles(coordinates, {
            onMouseMove: (callback) =>
                this.onMouseMove((event) => {
                    callback(event);
                }),
            onContentUnderMouseChanged: (callback) => this.onMapIdle((event) => callback(event), { disableMapQuerying: false, listenOnce: false }),
            onMouseDownHandle: (callback) =>
                this.onMouseDown(
                    (event: ClickEvent) => {
                        this.disableDragPan();
                        const midPointObject = event.objects.find((object) => object.layerId === midpointHandleSpriteName);

                        // Cast the type so typescript doesn't complain
                        // Make sure to make this explicit when adding additional features
                        const geometry = midPointObject.geometry as GeoJSON.Point;

                        callback(point(geometry.coordinates, { indexBefore: midPointObject.properties.indexBefore }));
                    },
                    [midpointHandleLayerId]
                ),
            setHandleGeometry: async (geometry) => {
                this.reflectSource(midpointHandleSourceId, geometry);
                this.addMidpointHandleLayer();
            },
        })
            .on(MidpointHandleEvent.ENTER, () => {
                this.getDrawingMachine().send(machineEventTypes.enterMidpoint);
            })
            .on(MidpointHandleEvent.LEAVE, () => {
                this.getDrawingMachine().send(machineEventTypes.leaveMidpoint);
            })
            .on(MidpointHandleEvent.DRAG, (point: Midpoint) => {
                this.getDrawingMachine().send({ type: machineEventTypes.dragMidpoint, payload: { point, createNode: true } });
            })
            .on(MidpointHandleEvent.CLICK, (point: Midpoint) => {
                this.getDrawingMachine().send({ type: machineEventTypes.clickMidpoint, payload: { point, object: this.getCurrentComposition(true).geojson } });
            });
    }

    private showMidpointHandlesFixedShape(feature: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>) {
        this.fixedShapeMidpointHandles = new FixedShapeMidpointHandles(feature, {
            onMouseMove: (callback) =>
                this.onMouseMove((event) => {
                    callback(event);
                }),
            onContentUnderMouseChanged: (callback) => this.onMapIdle((event) => callback(event), { disableMapQuerying: false, listenOnce: false }),
            onMouseDownHandle: (callback) =>
                this.onMouseDown(
                    (event: ClickEvent) => {
                        this.disableDragPan();
                        const { properties } = event.objects[0];

                        // Cast the type so typescript doesn't complain
                        // Make sure to make this explicit when adding additional features
                        const geometry = event.objects[0].geometry as GeoJSON.Point;

                        callback(point(geometry.coordinates, { indexBefore: properties.indexBefore, fixedShape: properties.fixedShape }));
                    },
                    [midpointHandleLayerId]
                ),
            onMouseUp: (callback) => this.onMouseUp((event) => callback({ lng: event.lng, lat: event.lat })),
            setHandleGeometry: async (geometry) => {
                this.reflectSource(midpointHandleSourceId, geometry);
                this.addMidpointHandleLayer();
            },
        })
            .on(FixedShapeMidpointHandleEvent.ENTER, async (cursorBearing: number) => {
                const cursor = await this.getDirectionalCursor(cursorBearing);
                this.getDrawingMachine().send({ type: machineEventTypes.enterMidpoint, payload: { cursor } });
            })
            .on(FixedShapeMidpointHandleEvent.LEAVE, () => {
                this.getDrawingMachine().send(machineEventTypes.leaveMidpoint);
            })
            .on(FixedShapeMidpointHandleEvent.START_DRAG, async (point: Midpoint, cursorBearing: number) => {
                this.addTextboxLayer(measurementLayerId, measurementSourceId);
                const drawingMode = this.getDrawingMachineState();
                if (drawingMode !== "hoverOverMidpoint") {
                    const cursor = await this.getDirectionalCursor(cursorBearing);
                    this.getDrawingMachine().send({ type: machineEventTypes.enterMidpoint, payload: { cursor } });
                }
                this.getDrawingMachine().send({ type: machineEventTypes.dragMidpoint, payload: { point, createNode: false } });
            })
            .on(FixedShapeMidpointHandleEvent.DRAG, (transformed: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>, _, midpointIndex) => {
                const coords = getCoordinateList(transformed.geometry);

                // We want to display the measurement label along the moving edge
                // When the midpointIndex is 0 or 2, it should always appear along the moving edge. index of 1
                // When the midpointIndex is 1 or 3, edge is index of 0
                const indexToDisplayMeasurement = midpointIndex === 0 || midpointIndex === 2 ? 1 : 0;
                const corner = coords[indexToDisplayMeasurement];

                this.setMeasurementLabel(transformed, { lng: corner[0], lat: corner[1] });
                this.setEphemeralGeometry({
                    // The properties of the object in the coordinate handle may sometimes be out of date, so we use current composition to restore those properties
                    // Nodes only appear when editing single objects, so we can assume the current composition is of length 1
                    geojson: { ...transformed, properties: this.currentComposition[0].geojson.properties },
                    layerId: feature.properties.layerid,
                    objectId: feature.properties.id,
                    waypoints: undefined,
                });
            })
            .on(FixedShapeMidpointHandleEvent.RELEASE, (payload: { transformation: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>; coordinate: GeoJSON.Position }) => {
                this.setEmptySource(measurementSourceId);
                const currentComposition = this.getCurrentComposition(true);
                const updatedMapObject: Feature<AnySupportedGeometry, MapObjectProperties> = {
                    ...payload.transformation,
                    properties: { ...currentComposition.geojson.properties },
                };
                this.getDrawingMachine().send({
                    type: machineEventTypes.dragEnd,
                    payload: { transformed: updatedMapObject, coordinate: payload.coordinate, object: updatedMapObject },
                });
            });
    }

    public removeMidpointHandles() {
        if (this.midpointHandles !== undefined) {
            this.removeMidpointHandleLayer(); // Can be removed once layer ordering is implemented.
            this.midpointHandles.destroy();
            this.midpointHandles = undefined;
        }
        if (this.fixedShapeMidpointHandles !== undefined) {
            this.removeMidpointHandleLayer(); // Fixed shape midpoints use the same layer
            this.fixedShapeMidpointHandles.destroy();
            this.fixedShapeMidpointHandles = undefined;
        }
    }

    protected addMidpointCoordinate(index: number) {
        const currentComposition = this.getCurrentComposition(true);

        currentComposition.geojson.geometry = addMidpointCoordinate(currentComposition.geojson.geometry, index);
        this.setGeometryInStore([
            {
                layerId: currentComposition.layerId,
                objectId: currentComposition.objectId,
                geometry: JSON.parse(JSON.stringify(currentComposition.geojson.geometry)),
                properties: JSON.parse(JSON.stringify(currentComposition.geojson.properties)),
                waypoints: null,
            },
        ]);
    }

    /*
        COORDINATE HANDLE BEHAVIOUR
    */

    /**
     * Display the coordiate handles (the draggable corners for objects)
     * @param object The object to display them over
     * @param layerId The layer ID for that object
     * @param objectId The ID for the object
     * @param draggingPoint If provided signals that we are already dragging that point with the mouse down from the get-go
     */
    protected async showCoordinateHandles(object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, layerId: string, objectId: string, draggingPoint?: Midpoint) {
        if (this.coordinateHandles) {
            this.removeCoordinateHandles();
        }

        if (!this.getLayerControlValue(layerId, LayerDrawingControl.COORDINATE_HANDLE)) {
            return;
        }

        // Cannot have highlights while coordinates show
        this.removeHighlights();

        const mouseLeaveListener = this.onMouseMove(
            (e) => {
                const nearCoordinateHandle = e.objects.find((object) => object.properties.layerid === coordinateHandleLayerId);
                if (!nearCoordinateHandle) {
                    this.getDrawingMachine().send(machineEventTypes.moveAway);
                }
            },
            { queryRadius: 20 }
        );

        const isMeasurable = measurableGeometries.includes(object.geometry.type);

        this.coordinateHandles = new CoordinateHandles(
            JSON.parse(JSON.stringify(object)),
            {
                onMouseMove: (callback) =>
                    this.onMouseMove((event) => {
                        callback(event);
                    }),
                onContentUnderMouseChanged: (callback) => this.onMapIdle((event) => callback(event), { disableMapQuerying: false, listenOnce: false }),
                onMouseMoveNearHandle: (callback) =>
                    this.onMouseMove(
                        (e) => {
                            const nearCoordinateHandle = e.objects.filter((object) => object.properties.layerid === coordinateHandleLayerId);
                            if (nearCoordinateHandle.length > 0) {
                                // Find the closest node for the delete handle clamp to
                                const points = featureCollection(nearCoordinateHandle) as FeatureCollection<GeoJSON.Point>;
                                const nearestHandle = nearestPoint({ type: "Point", coordinates: [e.lng, e.lat] }, points);
                                callback(nearestHandle.geometry as GeoJSON.Point);
                            }
                        },
                        { queryRadius: 20 }
                    ),
                onMouseDownHandle: (callback) =>
                    this.onMouseDown(
                        (event: ClickEvent) => {
                            this.disableDragPan();
                            callback(event.objects[0].geometry as GeoJSON.Point);
                        },
                        [coordinateHandleLayerId]
                    ),
                onMouseUp: (callback) => this.onMouseUp((event) => callback({ lng: event.lng, lat: event.lat })),
                setHandleGeometry: async (geometry) => {
                    this.reflectSource(coordinateHandleSourceId, geometry);
                    this.addCoordinateHandleLayer();
                },
            },
            this.getCurrentState().drawingModifier,
            { snapping: () => new ObjectSnapping(undefined, this.objectSnappingFunctions) },
            draggingPoint
        )
            .on(CoordinateHandleEvent.START_DRAG, async (point: GeoJSON.Point, cursorBearing?: number) => {
                const drawingMode = this.getDrawingMachineState();
                let cursor: MapCursor;
                if (cursorBearing) {
                    cursor = await this.getDirectionalCursor(cursorBearing);
                }
                if (drawingMode !== "closeToNode.hoverOverNode") {
                    this.getDrawingMachine().send({ type: machineEventTypes.enterNode, payload: { coordinate: point.coordinates, object, cursor } });
                }
                this.getDrawingMachine().send({ type: machineEventTypes.nodeDragStart, payload: { feature: object, cursor } });
            })
            .on(CoordinateHandleEvent.DRAG, (transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, newCoordinate: { lng: number; lat: number }) => {
                if (isMeasurable) {
                    this.setMeasurementLabel(transformed, newCoordinate);
                }
                this.setEphemeralGeometry({
                    // The properties of the object in the coordinate handle may sometimes be out of date, so we use current composition to restore those properties
                    // Nodes only appear when editing single objects, so we can assume the current composition is of length 1
                    geojson: { ...transformed, properties: this.currentComposition[0].geojson.properties },
                    layerId,
                    objectId,
                    waypoints: undefined,
                });
            })
            .on(CoordinateHandleEvent.ENTER, async (point: GeoJSON.Point, cursorBearing: number) => {
                let cursor: MapCursor;
                if (cursorBearing) {
                    cursor = await this.getDirectionalCursor(cursorBearing);
                }
                this.getDrawingMachine().send({ type: machineEventTypes.enterNode, payload: { coordinate: point.coordinates, object, cursor } });
            })
            .on(CoordinateHandleEvent.LEAVE, () => {
                this.getDrawingMachine().send(machineEventTypes.leaveNode);
            })
            .on(
                CoordinateHandleEvent.RELEASE,
                async (payload: {
                    transformation: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
                    coordinate: GeoJSON.Position;
                    cursorBearing?: number;
                    isDragging?: boolean;
                }) => {
                    this.setSnapIndicatorSource(undefined);
                    if (payload.isDragging) {
                        if (isMeasurable) {
                            this.setEmptySource(measurementSourceId);
                        }
                        // Since we duplicated the object on the way in, its properties may be out of date. Use the latest values instead.
                        const body = {
                            transformed: { ...payload.transformation, properties: this.currentComposition[0].geojson.properties },
                            coordinate: payload.coordinate,
                            object: payload.transformation,
                            cursor: undefined,
                        };
                        if (payload.cursorBearing) {
                            body.cursor = await this.getDirectionalCursor(payload.cursorBearing);
                        }
                        this.getDrawingMachine().send({
                            type: machineEventTypes.dragEnd,
                            payload: body,
                        });
                    } else {
                        this.getDrawingMachine().send({
                            type: machineEventTypes.nodeClicked,
                            payload: {
                                feature: payload.transformation,
                                point: payload.coordinate,
                            },
                        });
                    }
                    // If we are in composition mode, push to the composition undo stack
                    if (checkModeIsPresent(this.getMode(), "composition")) {
                        this.compositionUndoRedo.push([{ objectId, layerId, geojson: payload.transformation, waypoints: undefined }]);
                    }
                }
            )
            .on(CoordinateHandleEvent.NEAR_HANDLE, (point) => {
                this.getDrawingMachine().send({ type: machineEventTypes.moveCloseToNode, payload: { coordinate: (point as GeoJSON.Point).coordinates, object } });
            })
            .on(CoordinateHandleEvent.DESTROY, () => {
                mouseLeaveListener.remove();
            });
    }

    public removeCoordinateHandles() {
        if (this.coordinateHandles === undefined) {
            return;
        }
        this.coordinateHandles.destroy();
        this.removeCoordinateHandleLayer(); // Can be removed once layer ordering is implemented.
        this.coordinateHandles = undefined;
    }

    /*
        ROUTE GENERATOR BEHAVIOUR
    */
    public showRouteControls(object: CompositionMapObject | DrawingObject, initialWaypoints: RouteWaypoint[]) {
        // ToDo: Handle for both compsoition and normal undo/redo
        this.sendEventToStore({ eventType: MapStoreEventType.SET_EDITING_ROUTE, payload: true, object: { ...object, waypoints: initialWaypoints } });
        this.removeObjectDragListeners();
        this.removeDrawingControls();
        if (object.geojson.geometry.type !== "LineString") {
            throw new Error("You can only generate routes from a line string");
        }
        this.routeControls = new RouteControls(object.geojson as GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>, initialWaypoints, this.routeApiFunctions.maxWaypoints, {
            onMouseMove: (callback) => this.onMouseMove((event) => callback(event)),
            onContentUnderMouseChanged: (callback) => this.onMapIdle((event) => callback(event), { disableMapQuerying: false, listenOnce: false }),
            onMouseDown: (callback) =>
                this.onMouseDown(
                    (event: ClickEvent) => {
                        this.disableDragPan();
                        callback(event.objects[0].geometry as GeoJSON.Point | GeoJSON.LineString, [event.lng, event.lat]);
                    },
                    // We use the local layer id because the object is local while editing
                    [waypointLayerId, object.geojson.properties.layerid, `${object.geojson.properties.layerid}_${localSuffix}`]
                ),
            onMouseUp: (callback) => this.onMouseUp((event) => callback({ lng: event.lng, lat: event.lat })),
            onClick: (callback) => this.onClick(callback),
            onRightClick: (callback) => this.onRightClick(callback),
            setWaypointGeometry: (points) => {
                this.reflectSource(
                    waypointSourceId,
                    featureCollection(
                        points.reduce<Feature<GeoJSON.Point>[]>(
                            (acc, p, index) => (p.coordinates == null ? acc : [...acc, point(p.coordinates, { layerid: waypointLayerId, waypointLetter: alphabet[index] })]),
                            []
                        )
                    )
                );
                this.addWaypointsLayer();
            },
            routeFinder: this.routeApiFunctions.routeFinder,
            zoomToRoute: async (transformation) => {
                if (transformation.geometry.coordinates.length > 1) {
                    // Get our current bounds
                    const { bounds } = await this.getBounds();

                    // If our feature is not within the bounds of our current zoom
                    if (!booleanContains(polygon(bounds), transformation)) {
                        // Calculate the bounds of the feature and zoom to it
                        this.setBoundsWithBounds(bbox(transformation) as [number, number, number, number]);
                    }
                }
            },
            getDurationProperty: async () => this.getRouteDurationProperty(),
        })
            .on(RouteControlsEvent.START_DRAG, () => {
                this.routeControls.removeWaypoints();
                // if we are in composition mode
                if (checkModeIsPresent(this.getMode(), "composition")) {
                    this.nodeDragStartInComposition();
                } else {
                    this.handlesDragStartInEdit();
                }
            })
            .on(RouteControlsEvent.DRAG, (transformed: GeoJSON.Feature<GeoJSON.LineString>) => {
                // The properties of the object in the coordinate handle may sometimes be out of date, so we use current composition to restore those properties
                // Waypoints only appear when editing single objects, so we can assume the current composition is of length 1
                const { properties } = this.currentComposition[0].geojson;
                this.setEphemeralGeometry({
                    geojson: { ...transformed, properties },
                    layerId: properties.layerid,
                    objectId: properties.id,
                    waypoints: undefined,
                });
            })
            .on(RouteControlsEvent.CLEAR_GEOMETRY, () => {
                const { geojson } = this.currentComposition[0];
                this.setEphemeralGeometry({
                    geojson: { ...geojson, geometry: { type: GeometryType.LineString, coordinates: [] } },
                    layerId: geojson.properties.layerid,
                    objectId: geojson.properties.id,
                    waypoints: undefined,
                });
            })
            .on(RouteControlsEvent.RELEASE, (transformation: Parameters<RouteControlListeners[RouteControlsEvent.RELEASE][0]>[0], updateStore = true) => {
                const currentComposition = this.getCurrentComposition(true);
                currentComposition.waypoints = transformation.waypoints;
                const durationProperty = this.getRouteDurationProperty();
                currentComposition.geojson.properties = {
                    ...currentComposition.geojson.properties,
                    modeOfTransport: transformation.feature.properties.modeOfTransport,
                    ...(durationProperty != null && { [durationProperty]: transformation.feature.properties[durationProperty] }),
                };
                const resultingTransformation = {
                    ...transformation.feature,
                    properties: currentComposition.geojson.properties,
                };
                this.nodeDragFinish(resultingTransformation, transformation.waypoints, updateStore);
                this.addReadBehaviour();
                this.addLastMouseRetention();
                if (transformation.waypoints?.length >= 2) {
                    this.machine.send({ type: machineEventTypes.finish });
                }
            })
            .on(RouteControlsEvent.REGISTER_WAYPOINTS, (waypoints: RouteWaypoint[]) => {
                const currentComposition = this.getCurrentComposition(true);
                currentComposition.waypoints = waypoints;
                this.sendEventToStore({ eventType: MapStoreEventType.UPDATE_ROUTE_WAYPOINTS, payload: { waypoints, objectId: currentComposition.objectId } });
            })
            .on(RouteControlsEvent.ENTER_WAYPOINT, () => {
                this.setCursor(MapCursor.MOVE);
            })
            .on(RouteControlsEvent.ENTER_LINE, () => {
                this.setCursor(MapCursor.COMPOSITION);
            })
            .on(RouteControlsEvent.PLACING_WAYPOINT, () => {
                this.setCursor(MapCursor.COMPOSITION);
                this.removeReadBehaviour();
                this.addLastMouseRetention();
            })
            .on(RouteControlsEvent.LEAVE, () => {
                this.setCursor(this.getDefaultCursor());
            })
            .on(RouteControlsEvent.DESTROY, () => {
                this.addObjectDragListeners();
            });
    }

    private getRouteDurationProperty() {
        return getMapObjectRouteTimeSystemDataField(this.getProjectDataFields())?.id;
    }

    /** Updates the route externally (waypoints, mode of transport, etc) */
    public updateRouteExternal(waypoints: RouteWaypoint[], modeOfTransport: ModeOfTransport, updateWaypointsExternal: boolean) {
        const currentComposition = this.getCurrentComposition(true);
        if (this.routeControls != null) {
            currentComposition.waypoints = waypoints;
            this.routeControls?.updateWaypointsExternal(waypoints, modeOfTransport, updateWaypointsExternal);
        }
    }

    private getDefaultCursor() {
        if (this.routeControls != null && this.routeControls.getcurrentState() === "placingWaypoint") {
            return MapCursor.COMPOSITION;
        }
        const mode = this.getMode();
        if (this.commentsHandler != null || checkModeIsPresent(mode, "addComment")) {
            return this.commentsHandler.getCursor(undefined, undefined);
        }
        return defaultCursor[mode];
    }

    private removeRouteControls() {
        this.routeControls?.destroy();
        this.routeControls = undefined;
    }

    /*
        COMMENTS BEHAVIOUR
    */
    public spawnCommentsMachine(comment: SelectableComment | undefined) {
        this.destroyCommentsMachine();
        let selectedComment: SelectedMapComment | undefined;
        if (comment) {
            const canvasCoordinates = this.project(comment.position);
            selectedComment = { commentId: comment.commentId, userId: comment.userId, canvasCoordinates };
            this.sendEventToStore({ eventType: MapStoreEventType.SELECT_MAP_COMMENT, payload: selectedComment });
        } else {
            selectedComment = this.getCurrentState().commentsSelected.value?.[0];
        }
        this.commentsHandler = new CommentsDrawing(
            {
                onClick: this.onClick.bind(this),
                onRightClick: this.onRightClick.bind(this),
                onMouseMove: this.onMouseMove.bind(this),
                onPanStart: (cb) => {
                    const pan = this.onPanStart(cb);
                    const zoom = this.onZoomStart(cb);
                    const rotate = this.onMapRotateStart(cb);
                    return {
                        remove: () => {
                            pan.remove?.();
                            zoom.remove?.();
                            rotate.remove?.();
                        },
                    };
                },
                onPanEnd: (cb) => {
                    const pan = this.onPanEnd(cb);
                    const zoom = this.onZoomEnd(cb);
                    const rotate = this.onMapRotateEnd(cb);
                    return {
                        remove: () => {
                            pan.remove?.();
                            zoom.remove?.();
                            rotate.remove?.();
                        },
                    };
                },
                onMouseDown: (callback) =>
                    this.onMouseDown(
                        (event) => {
                            this.disableDragPan();
                            callback(event);
                        },
                        [getLocalLayerID(commentsLayerId)]
                    ),
                onMouseUp: (callback) =>
                    this.onMouseUp((event) => {
                        callback(event);
                        this.enableDragPan();
                    }),
                onContentUnderMouseChanged: (callback) => this.onMapIdle(callback, { disableMapQuerying: false, listenOnce: false }),
                updateCommentsDrawingMode: (mode) => this.sendEventToStore({ eventType: MapStoreEventType.UPDATE_COMMENTS_DRAWING_MODE, mode }),
                isInvalidCommentPosition: (lat: number, lng: number) => this.areGeometriesOutsideCadBounds([{ type: "Point", coordinates: [lng, lat] }]),
                userPlacedCommentInvalidPosition: () => this.userHasDrawnOutsideCad(),
            },
            this.user,
            this.isExternalUser,
            this.project.bind(this),
            selectedComment
        )
            .on(CommentDrawingEvent.APPEND, (point, id) => {
                this.sendEventToStore({
                    eventType: MapStoreEventType.SET_COMMENT_GEOMETRY,
                    payload: {
                        type: "Feature",
                        geometry: point,
                        properties: {
                            id,
                            layerid: commentsLayerId,
                            level: this.getCurrentState().currentLevel ?? 0,
                            rotation: undefined,
                            userId: DEFAULT_COMMENT_ICON_VALUE,
                            imageId: DEFAULT_COMMENT_ICON_VALUE,
                            order: 1,
                        },
                    },
                });
                const canvasCoordinates = this.project(point.coordinates);
                this.sendEventToStore({
                    eventType: MapStoreEventType.SELECT_MAP_COMMENT,
                    payload: { commentId: id, userId: this.user.id, canvasCoordinates, isNewComment: true },
                    isNewComment: true,
                });
                this.setCursor(MapCursor.MOVE);
            })
            .on(CommentDrawingEvent.DRAG_START, (commentId) => {
                this.hideLocalObjects(commentsLayerId, [commentId]);
                this.removeReadMouseMove();
                this.setCursor(MapCursor.COMMENT);
            })
            .on(CommentDrawingEvent.DRAG_END, (point, commentId, isValid) => {
                const existingFeature = this.getCurrentState().geoJSON.value?.[commentsLayerId]?.find((comment) => comment.feature.properties.id === commentId)?.feature;
                if (isValid) {
                    this.sendEventToStore({
                        eventType: MapStoreEventType.SET_COMMENT_GEOMETRY,
                        payload: {
                            ...existingFeature,
                            geometry: point,
                            properties: {
                                ...existingFeature?.properties,
                                userId: (existingFeature?.properties.userId as string) ?? DEFAULT_COMMENT_ICON_VALUE,
                                imageId: (existingFeature?.properties.imageId as string) ?? DEFAULT_COMMENT_ICON_VALUE,
                                level: this.getCurrentState().currentLevel ?? 0,
                            },
                        },
                    });
                } else {
                    this.userHasDrawnOutsideCad();
                }

                this.unhideLocalObjects(commentsLayerId, [commentId]);
                this.setCursor(MapCursor.MOVE);
                const canvasPosition = this.project(isValid ? point.coordinates : (existingFeature?.geometry.coordinates as [number, number]));
                this.sendEventToStore({ eventType: MapStoreEventType.UPDATE_SELECTED_COMMENT_POSITION, payload: { canvasPosition } });
                this.addReadMouseMove();
            })
            .on(CommentDrawingEvent.PAN_START, (commentId) => {
                this.hideLocalObjects(commentsLayerId, [commentId]);
            })
            .on(CommentDrawingEvent.PAN_END, (commentId) => {
                const localCommentFeatures = this.getCurrentState().geoJSON.value?.[commentsLayerId];
                const existingFeature = localCommentFeatures?.find((comment) => comment.feature.properties.id === commentId)?.feature;
                if (this.user.isMobileUser) {
                    this.getMapCentre((position) => {
                        const newFeature = {
                            ...point(position),
                            properties: {
                                ...existingFeature?.properties,
                                userId: (existingFeature?.properties.userId as string) ?? DEFAULT_COMMENT_ICON_VALUE,
                                imageId: (existingFeature?.properties.imageId as string) ?? DEFAULT_COMMENT_ICON_VALUE,
                                level: this.getCurrentState().currentLevel ?? 0,
                            },
                        } as const;
                        // Set the changes immediately so we don't have to wait for the store to update
                        this.reflectSource(commentsLayerId, {
                            type: "FeatureCollection",
                            features: localCommentFeatures.reduce((acc, object) => [...acc, object.feature.properties.id === commentId ? newFeature : object.feature], []),
                        });
                        this.unhideLocalObjects(commentsLayerId, [commentId]);
                        // Then tell the store
                        this.sendEventToStore({
                            eventType: MapStoreEventType.SET_COMMENT_GEOMETRY,
                            payload: newFeature,
                        });
                    }, false);
                    this.sendEventToStore({ eventType: MapStoreEventType.UPDATE_SELECTED_COMMENT_POSITION });
                } else {
                    // On desktop, if they pan away from the comment, we must deselect it
                    this.getBounds().then(({ bounds }) => {
                        const isWithin = booleanContains(polygon(bounds), existingFeature);
                        if (!isWithin) {
                            this.selectMapCommentExternally(undefined);
                        }
                    });
                }
            })
            .on(CommentDrawingEvent.SELECT_COMMENT, (oldCommentId, newComment) => {
                const isDeselecting = newComment?.commentId == null;
                if (!this.isExternalUser && !isDeselecting) {
                    this.setCursor(MapCursor.MOVE);
                }
                this.sendEventToStore({ eventType: MapStoreEventType.SELECT_MAP_COMMENT, payload: newComment });
                this.sendEventToStore({ eventType: MapStoreEventType.CANCEL_COMMENT, selectedCommentId: oldCommentId });
                // If we are deselecting, ensure the comments machine is destroyed and we are not in add comments mode
                if (isDeselecting) {
                    this.machine.send({ type: machineEventTypes.exitComments, keepCommentsMachineAlive: false });
                    this.destroyCommentsMachine();
                }
            })
            .on(CommentDrawingEvent.DELETE_COMMENT, (commentId) => {
                // Tell store to delete the comment
                this.sendEventToStore({ eventType: MapStoreEventType.DELETE_MAP_COMMENT, commentId });
                // Tell the machine we're exiting comments
                this.machine.send({ type: machineEventTypes.exitComments, keepCommentsMachineAlive: false });
                // Destroy the comments machine
                this.destroyCommentsMachine();
            })
            .on(CommentDrawingEvent.DESTROY, (selectedComment) => {
                this.sendEventToStore({ eventType: MapStoreEventType.SELECT_MAP_COMMENT, payload: undefined });
                if (selectedComment != null) {
                    this.unhideLocalObjects(commentsLayerId, [selectedComment.commentId]);
                }
            })
            .on(CommentDrawingEvent.EXIT_COMPOSITION, (keepCommentsMachineAlive) => {
                this.machine.send({ type: machineEventTypes.exitComments, keepCommentsMachineAlive });
            });
        if (!this.isExternalUser && selectedComment?.commentId != null) {
            this.setCursor(MapCursor.MOVE);
        }
    }

    public addCommentInMobile() {
        if (this.user.isMobileUser) {
            this.getMapCentre(([lng, lat]) => {
                this.commentsHandler?.createCommentExternally(lng, lat);
            });
        }
    }

    /** Cancel the latest position change of the comment */
    public cancelCommentMove() {
        const selectedCommentId = this.commentsHandler?.getSelectedComment()?.commentId;
        if (selectedCommentId) {
            this.unhideLocalObjects(commentsLayerId, [selectedCommentId]);
        }
        this.commentsHandler?.cancel();
    }

    /** Accept the latest position change of the comment */
    public confirmCommentMove() {
        this.commentsHandler?.confirmCommentMove();
    }

    public selectMapCommentExternally(comment: SelectableComment | undefined) {
        // If the comments handler is not yet spawned, we need to spawn it first
        if (this.commentsHandler == null) {
            this.spawnCommentsMachine(comment);
            return;
        }
        this.commentsHandler?.selectCommentExternally(comment == null ? undefined : { ...comment, canvasCoordinates: this.project(comment.position) });
    }

    public deleteComment(commentId: string) {
        this.commentsHandler?.deleteComment(commentId);
    }

    public destroyCommentsMachine() {
        if (this.commentsHandler == null) {
            return;
        }
        const selectedCommentId = this.commentsHandler?.getSelectedComment()?.commentId;
        if (selectedCommentId) {
            this.sendEventToStore({ eventType: MapStoreEventType.CANCEL_COMMENT, selectedCommentId });
        }
        this.sendEventToStore({ eventType: MapStoreEventType.SELECT_MAP_COMMENT, payload: undefined });
        this.sendEventToStore({ eventType: MapStoreEventType.UPDATE_COMMENTS_DRAWING_MODE, mode: undefined });
        this.commentsHandler?.destroy();
        this.commentsHandler = undefined;
        this.enableDoubleClickZoom();
    }

    public enterCommentsMode() {
        this.removeDrawingControls();
        this.addReadBehaviour(false);
        this.addLastMouseRetention();
        this.spawnCommentsMachine(undefined);
        this.sendEventToStore({ eventType: MapStoreEventType.ENTER_COMMENTS_MODE });
        this.disableDoubleClickZoom();
    }

    public leaveCommentsMode(keepCommentsMachineAlive: boolean) {
        if (!keepCommentsMachineAlive) this.destroyCommentsMachine();
        this.removeLastMouseRetention();
        this.removeReadBehaviour();
    }

    /*
        ROTATION HANDLE BEHAVIOUR
    */

    /**
     * Show the rotation handle above the provided features (the button for dragging to rotate)
     * @param selectedFeatures The features for which to display the handle
     * @param layerId The layer id for that object
     * @param objectId The if for the rotating object
     * Will NOT show for single points
     */
    public showRotationHandle(unvalidatedObjects: CompositionMapObject[]) {
        this.removeRotationHandle();

        if (unvalidatedObjects.length === 0) {
            return;
        }

        if (unvalidatedObjects.length === 1 && !this.getLayerControlValue(unvalidatedObjects[0].layerId, LayerDrawingControl.ROTATION_HANDLE)) {
            return;
        }

        // We dont want to show rotation control if object is on a different level
        if (unvalidatedObjects.length === 1 && unvalidatedObjects[0].geojson.properties.level !== this.getCurrentState().currentLevel) {
            return;
        }

        // We don show rotation handles for routes
        if (isSingleRoute(this.getCurrentComposition(false))) {
            return;
        }

        const objects = unvalidatedObjects.filter((object) => object.geojson.geometry.coordinates.length);

        // If there are no objects with valid coordinates, do not show rotation handle
        if (!objects.length) {
            return;
        }

        this.rotator = new Rotator(objects, {
            project: this.project.bind(this),
            setRotatePosition: this.setRotatePosition.bind(this),
            onRotateMouseDown: (callback: () => void) =>
                this.onMouseDown(() => {
                    this.disableDragPan();
                    callback();
                }, [rotateHandleLayerId]),
            onMouseMove: (callback) =>
                this.onMouseMove((...args) => {
                    callback(...args);
                }),
            onMouseClick: (callback) =>
                this.onClick((...args) => {
                    callback(...args);
                }),
        })
            .on(RotatorEvent.START, () => {
                this.getDrawingMachine().send({ type: machineEventTypes.rotatorDragStart, payload: objects.map(({ objectId, layerId }) => ({ objectId, layerId })) });
            })
            .on(RotatorEvent.DRAG, (objects: CompositionMapObject[]) => {
                this.setEphemeralGeometry(mergeCompositionMapObjectProperties(objects, this.currentComposition));
            })
            .on(RotatorEvent.RELEASE, (objects: CompositionMapObject[]) => {
                // Get latest object properties since the rotator created a duplicate internally, it may be out of date
                this.getDrawingMachine().send({ type: machineEventTypes.dragEnd, payload: mergeCompositionMapObjectProperties(objects, this.currentComposition) });
            })
            .on(RotatorEvent.ENTER, () => {
                this.getDrawingMachine().send(machineEventTypes.enterRotator);
            })
            .on(RotatorEvent.LEAVE, () => {
                this.getDrawingMachine().send(machineEventTypes.leaveRotator);
            });
    }

    public removeRotationHandle() {
        if (!this.rotator) {
            return;
        }

        this.rotator.destroy();
        this.rotator = undefined;
    }

    protected setRotatePosition(x: number, y: number) {
        if (x === undefined && y === undefined) {
            this.reflectSource(rotateHandleSourceId, {
                type: "FeatureCollection",
                features: [],
            });
            return;
        }

        const position = this.unproject([x, y]);

        this.reflectSource(rotateHandleSourceId, {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Point",
                        coordinates: position,
                    },
                },
            ],
        });

        this.addRotateHandleLayer();
    }

    /*
        COMPOSITION BEHAVIOUR
    */

    /**
     * Begin composition (geometry editing) for the provided
     * @param object object id and layer id to edit
     */
    public beginCompositionBehaviour(objects: CompositionMapObject[], duplication?: boolean) {
        this.addLastMouseRetention();
        if (this.composition) {
            this.removeCompositionBehaviour();
        }
        this.removeReadBehaviour();

        let layer: MapModuleLayer;

        objects.forEach((object) => {
            layer = this.getStoredLayer(object.layerId);
            this.ensureLayerInMap(tileLayerToLocalLayer(layer));
        });

        if (duplication) {
            // If duplicating, initialise duplication behaviour
            this.beginCompositionBehaviourDuplication(objects);
        } else if (objects[0].geojson?.properties?.fixedShape != null) {
            this.createEphemeralObjects(objects);
            // If object is a fixed shape, initialise fixed shape behaviour
            this.beginCompositionBehaviourFixedShape(objects);
        } else if (isSingleRoute(objects)) {
            this.createEphemeralObjects(objects);
            // If object is a fixed shape, initialise fixed shape behaviour
            this.beginRouteCompositionBehaviour(objects);
        } else {
            this.createEphemeralObjects(objects);
            // Else, Initialise normal composition behaviour
            this.beginCompositionBehaviourNormal(objects, layer);
        }
    }

    private endDrawingOnPoint() {
        const currentComposition = this.getCurrentComposition(true);
        if (currentComposition.geojson.geometry.type === "Point") {
            this.machine.send(machineEventTypes.finish);
        }
    }

    /** Normal composition behaviour */
    private beginCompositionBehaviourNormal(objects: CompositionMapObject[] | [DrawingObject], layer: MapModuleLayer) {
        const areaStyle = layer.styleType === StyleType.Area && layer.areaStyle;
        if (areaStyle) {
            // adds the Area Trace Line Layer
            this.setEmptySource(traceLineSourceId);
            this.addTraceLineLayer();
        }

        const isMeasurable = isMeasurableLayer(layer);
        if (isMeasurable) {
            this.addTextboxLayer(measurementLayerId, measurementSourceId, false);
        }

        this.composition = new Composition(
            this.getCurrentComposition(true).geojson,
            {
                onMouseMove: (callback) => this.onMouseMove((event) => callback({ lng: event.lng, lat: event.lat }), { disableQuerying: true }),
                onClick: (callback) => this.onClick((event) => callback({ lng: event.lng, lat: event.lat })),
                onDoubleClick: (callback) => this.onDoubleClick(() => callback()),
                onEnterKeyPress: (callback) =>
                    this.onKeyUp((event) => {
                        this.endDrawingOnPoint();
                        callback(event);
                    }),
                onRightClick: (callback) =>
                    this.onRightClick((event) => {
                        this.endDrawingOnPoint();
                        callback({ lng: event.lng, lat: event.lat });
                    }),
            },
            this.getCurrentState().drawingModifier,
            { snapping: () => new ObjectSnapping(undefined, this.objectSnappingFunctions) }
        )
            .on(CompositionEvent.APPEND, (feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>) => {
                this.getDrawingMachine().send({ type: machineEventTypes.append, payload: { feature, waypoints: undefined } });
                this.compositionUndoRedo.push([{ objectId: objects[0]?.objectId, layerId: layer.id, geojson: feature, waypoints: undefined }]);
            })
            .on(CompositionEvent.MOVE, (feature: Feature<AnySupportedGeometry, MapObjectProperties>, newCoordinate) => {
                this.setEphemeralGeometry({ geojson: feature, layerId: objects[0].layerId, objectId: objects[0].objectId, waypoints: undefined });
                if (areaStyle) {
                    this.setShapeTraceLine(feature, areaStyle);
                }
                if (isMeasurable) {
                    this.setMeasurementLabel(feature, newCoordinate);
                }
            })
            .on(CompositionEvent.FINISH, async () => {
                this.setSnapIndicatorSource(undefined);
                this.machine.send(machineEventTypes.finish);
            });

        if (layer.styleType === StyleType.Point || layer.styleType === StyleType.Icon) {
            this.sendEventToStore({ eventType: MapStoreEventType.OBJECT_PLACEMENT_STARTED });
        }
    }

    /** Route composition behaviour */
    private beginRouteCompositionBehaviour(objects: CompositionMapObject[] | [DrawingObject]) {
        this.showRouteControls(objects[0], []);
    }

    /** Fixed shape composition behaviour */
    private beginCompositionBehaviourFixedShape(objects: CompositionMapObject[] | [DrawingObject]) {
        const { geojson } = this.getCurrentComposition(true);
        if (geojson.properties.fixedShape != null) {
            this.fixedShape = new CompositionFixedShape(
                geojson,
                {
                    onMouseMove: (callback) => this.onMouseMove((event) => callback({ lng: event.lng, lat: event.lat }), { disableQuerying: true }),
                    onClick: (callback) => this.onClick((event) => callback({ lng: event.lng, lat: event.lat })),
                    onRotateEnd: (callback) => this.onMapRotateEnd((e) => callback(e)),
                    onEnterKeyPress: (callback) => this.onKeyUp((event) => callback(event)),
                    onRightClick: (callback) => this.onRightClick((event) => callback({ lng: event.lng, lat: event.lat })),
                },
                async () => this.getPosition().then((res) => res.bearing),
                this.getCurrentState().drawingModifier,
                { snapping: () => new ObjectSnapping(undefined, this.objectSnappingFunctions) }
            )
                .on(FixedShapeEvent.START, (feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>) => {
                    this.addTextboxLayer(measurementLayerId, measurementSourceId);
                    this.getDrawingMachine().send({ type: machineEventTypes.append, payload: { feature, waypoints: null } });
                    this.compositionUndoRedo?.push([{ objectId: objects[0]?.objectId, layerId: objects[0].layerId, geojson: feature, waypoints: undefined }]);
                })
                .on(FixedShapeEvent.MOVE, (feature: Feature<AnySupportedGeometry, MapObjectProperties>, newCoordinate) => {
                    this.setEphemeralGeometry({ geojson: feature, layerId: objects[0].layerId, objectId: objects[0].objectId, waypoints: undefined });
                    this.setMeasurementLabel(feature, newCoordinate);
                })
                .on(FixedShapeEvent.FINISH, async (feature: Feature<FixedShapeSupportedGeometry, MapObjectProperties>) => {
                    this.getDrawingMachine().send({ type: machineEventTypes.append, payload: { feature, waypoints: null } });
                    this.machine.send(machineEventTypes.finish);
                    this.setSnapIndicatorSource(undefined);
                });
        }
    }

    /** Duplication composition behaviour */
    private beginCompositionBehaviourDuplication(objects: CompositionMapObject[]) {
        const centerPoint = center(featureCollection(objects.map((object: CompositionMapObject) => object.geojson))).geometry.coordinates;
        let preventPlacementOnPanStart: Listener;
        // We can cast here because we know if objects length is greater than 1, it must be of type CompositionMapObject (typescript can't read ">" operator)
        this.objectDrag = new ObjectDrag(
            objects as CompositionMapObject[],
            { lng: centerPoint[0], lat: centerPoint[1] },
            {
                onMouseMove: this.onMouseMove.bind(this),
                onMouseUp: this.onMouseUp.bind(this),
                placementListeners: [(callback) => this.onClick(callback), (callback) => this.onKeyPaste(callback)],
                onRightClick: (callback) =>
                    this.onRightClick((event) => {
                        this.endDrawingOnPoint();
                        callback({ lng: event.lng, lat: event.lat });
                    }),
                isInvalidPlacement: (mapObjects) => this.areCompositionObjectsOutsideCadBounds(mapObjects),
                userDraggedToInvalidPosition: () => this.userHasDrawnOutsideCad(),
            },
            this.lastMouse,
            // Set initial translation
            this.createEphemeralObjects.bind(this),
            this.getCurrentState().drawingModifier,
            {
                snapping: () =>
                    new ObjectSnapping(
                        objects.map(({ geojson }) => geojson),
                        this.objectSnappingFunctions
                    ),
            }
        )
            .onTranslate((translated) => {
                this.setEphemeralGeometry(translated);
            })
            .onRelease((translated) => {
                // Get the current level the user is on, we want to copy to the new level
                const level = this.getCurrentState().currentLevel;
                const objectsWithLevel = translated.map((object) => ({ ...object, geojson: { ...object.geojson, properties: { ...object.geojson.properties, level } } }));
                this.setCurrentComposition(objectsWithLevel);
                this.setGeometryInStore(
                    objectsWithLevel.map(({ objectId, layerId, geojson }) => ({
                        objectId,
                        layerId,
                        geometry: geojson.geometry,
                        properties: geojson.properties,
                        waypoints: null,
                    }))
                );
                this.machine.send(machineEventTypes.finish);
                this.endDraggingBehaviour(objectsWithLevel, false);
                preventPlacementOnPanStart.remove();
                this.setSnapIndicatorSource(undefined);
            });

        preventPlacementOnPanStart = this.onPanStart(() => {
            this.objectDrag.abortMouseUpPlacement();
            const mouseUp = this.onMouseUp(() => {
                this.objectDrag.resumeMouseUpPlacement();
                mouseUp.remove();
            });
        });

        // Remove text labels on polygons to improve performance while dragging
        const areaObjects = objects.filter(({ layerId }) => this.getStoredLayer(layerId).styleType === StyleType.Area);
        this.removeTextLabels(areaObjects);

        this.sendEventToStore({ eventType: MapStoreEventType.OBJECT_PLACEMENT_STARTED });
    }

    protected setShapeTraceLine(feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, shapeStyle: AreaStyle) {
        const coordinates = (feature as GeoJSON.Feature<GeoJSON.Polygon>).geometry.coordinates[0];
        // if it's less than 3 coordinates means there are only the 2 identical coordinates of the first plotted point so don't bother;
        // if it's more than 3 means we are past the first edge of polygon so don't bother anymore as we only want the first one to show up;
        if (coordinates.length === 3) {
            const traceLineCoordinates = [coordinates[1], coordinates[0]];
            this.reflectSource(traceLineSourceId, {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {
                            traceLineColour: shapeStyle.colour,
                        },
                        geometry: {
                            type: "LineString",
                            coordinates: traceLineCoordinates,
                        },
                    },
                ],
            });
        } else {
            this.setEmptySource(traceLineSourceId);
        }
    }

    protected setMeasurementLabel(feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, newCoordinate: { lng: number; lat: number }) {
        if (feature.properties.measurementScope === MeasurementScope.NEVER) {
            return;
        }
        const isFixedShape = feature.properties.fixedShape != null;
        if (isFixedShape && getCoordinateList(feature.geometry as FixedShapeSupportedGeometry).length !== 5) {
            return;
        }
        const pointGeometry = geometryToPointGeometry(feature);
        const numberOfPoints = pointGeometry.features.length;
        if (numberOfPoints < minimumNumberOfPointsToShowMeasurement(feature.geometry.type)) {
            return;
        }
        const lnglat = [newCoordinate.lng, newCoordinate.lat];
        const indexOfMatch = feature.geometry.coordinates.findIndex((f) => numbersEqualWithin(f, lnglat));

        const measurmentFeatures = [];

        // If the active node is at the end of a linestring, we only measure the one segment
        if (
            (indexOfMatch === 0 || indexOfMatch === numberOfPoints - 1 || feature.properties.measurementScope === MeasurementScope.WHOLE) &&
            feature.geometry.type !== "Polygon" &&
            feature.properties.fixedShape == null
        ) {
            const position = getRelativePosition(this.project.bind(this), this.unproject.bind(this))(pointGeometry, lnglat, MEASUREMENT_PADDING, false);
            if (!position) {
                return;
            }
            const nextCoord = (indexOfMatch === 0 ? feature.geometry.coordinates[1] : feature.geometry.coordinates[numberOfPoints - 2]) as GeoJSON.Position;
            measurmentFeatures.push({
                type: "Feature",
                geometry: { type: "Point", coordinates: position },
                properties: {
                    text: getLengthInAppropriateUnits(
                        feature.properties.measurementScope === MeasurementScope.WHOLE ? (feature as GeoJSON.Feature<GeoJSON.LineString>) : lineString([lnglat, nextCoord]),
                        this.getUnitOfMeasurement()
                    ),
                },
            });
        } else {
            const getPositions =
                // If we're dragging a rectangle midpoint, we only need to label one edge
                feature.properties.fixedShape === FixedShape.Rectangle && this.getDrawingMachineState() === "midpointDragging"
                    ? getClosestVectorMidpoint
                    : getClosestVectorMidpoints;
            const positions = getPositions(pointGeometry, lnglat, MEASUREMENT_PADDING, this.project.bind(this), this.unproject.bind(this));

            // Only measure length if the whole geometry is more than just identical coordinates (which gives a length of 0)
            if (
                !(
                    positions.length === 2 &&
                    coordinatesEqual([positions[0].coordinate[0], positions[0].coordinate[1]], [positions[1].coordinate[0], positions[1].coordinate[1]]) &&
                    numbersEqualWithin(positions[0].coordinate, [newCoordinate.lng, newCoordinate.lat])
                )
            ) {
                positions.forEach((position) =>
                    measurmentFeatures.push({
                        type: "Feature",
                        geometry: { type: "Point", coordinates: position.midpoint },
                        properties: { text: getLengthInAppropriateUnits(lineString([lnglat, position.coordinate]), this.getUnitOfMeasurement()) },
                    })
                );
            }
        }
        if ((feature.geometry.type === "Polygon" || feature.properties.fixedShape != null) && numberOfPoints >= 3) {
            // Fixed shapes can only either be linestring or polygon, so we can safely cast here
            const text = getAreaInAppropriateUnits(feature as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.LineString>, this.getUnitOfMeasurement());
            measurmentFeatures.push(this.createFeatureCenterTextLabel(feature.geometry.type === "LineString" ? polygon([feature.geometry.coordinates]) : feature, text));
        }
        this.reflectSource(measurementSourceId, featureCollection(measurmentFeatures));
    }

    /** Adds long lasting measurement label for a feature */
    protected setLongLastingMeasurementLabels(feature: Feature<AnySupportedGeometry, MapObjectProperties>) {
        if (!analysisLayerIds.includes(feature.properties.layerid)) {
            return;
        }

        this.removeLongLastingMeasurementLabels([feature.properties.id]);

        let measurement: string;

        if (feature.properties.layerid === analysisLineLayerId) {
            measurement = getLengthInAppropriateUnits(feature as Feature<GeoJSON.LineString>, this.getUnitOfMeasurement());
        }

        if (feature.properties.layerid === analysisPolygonLayerId) {
            measurement = getAreaInAppropriateUnits(feature as GeoJSON.Feature<GeoJSON.Polygon>, this.getUnitOfMeasurement());
        }

        const centreTextLabel = this.createFeatureCenterTextLabel(feature, measurement);
        this.longLastingMeasurementLabels.features.push(centreTextLabel);
        this.reflectSource(longLastingMeasurementSourceId, this.longLastingMeasurementLabels);
    }

    /** Removes long lasting measurement label for an array of mapObjects */
    protected removeLongLastingMeasurementLabels(mapObjectIds: string[]) {
        this.longLastingMeasurementLabels.features = this.longLastingMeasurementLabels.features.filter((feature) => !mapObjectIds.includes(feature.properties.mapObjectId));
        this.reflectSource(longLastingMeasurementSourceId, this.longLastingMeasurementLabels);
    }

    protected unitOfMeasurementChange() {
        const measurementLabelIds = this.longLastingMeasurementLabels.features.map((feature) => feature.properties.mapObjectId);
        this.removeLongLastingMeasurementLabels(measurementLabelIds);
        const anaylsisObjects = this.getAnalysisLayerGeometry(false);
        anaylsisObjects.forEach((object) => this.setLongLastingMeasurementLabels(object.feature));
        // When unit of measurement is changed, change the style of each text content for lines and areas to use the new unit of measurement
        this.getMapLayers().forEach((layer) => {
            if (layer.areaStyle?.textContent != null) {
                const change = ({ styleProperty: "textContent", value: layer.areaStyle.textContent } as unknown) as StylePropertyToValueMap;
                this.updateStyle(layer.id, layer.styleType, [change]);
            }
            if (layer.lineStyle?.textContent != null) {
                const change = ({ styleProperty: "textContent", value: layer.lineStyle.textContent } as unknown) as StylePropertyToValueMap;
                this.updateStyle(layer.id, layer.styleType, [change]);
            }
        });
    }

    private createFeatureCenterTextLabel(
        feature: Feature<AnySupportedGeometry, MapObjectProperties>,
        text: string
    ): Feature<AnySupportedGeometry, { text: string; mapObjectId: string }> {
        const midPointFeature = feature.geometry.type === "LineString" ? getMidPointOfLineString(feature.geometry) : centroid(feature);

        return {
            type: "Feature",
            geometry: { type: "Point", coordinates: midPointFeature.geometry.coordinates },
            properties: { text, mapObjectId: feature.properties.id },
        };
    }

    protected setEmptySource(sourceId: string) {
        this.reflectSource(sourceId, {
            type: "FeatureCollection",
            features: [],
        });
    }

    public removeCompositionBehaviour() {
        this.removeLastMouseRetention();
        if (this.composition !== undefined) {
            const object = this.getCurrentComposition(true);
            this.setEmptySource(traceLineSourceId);
            this.setEmptySource(measurementSourceId);

            // Remove mouse follow point from empheral object
            if (object) {
                this.setEphemeralGeometry(object);
            }

            this.composition.destroy();
            this.composition = undefined;
        }
        if (this.objectDrag !== undefined) {
            this.objectDrag.destroy();
        }
        if (this.fixedShape !== undefined) {
            this.setEmptySource(measurementSourceId);
            this.fixedShape.destroy();
            this.fixedShape = undefined;
        }
    }

    protected implementStyleChanges(lastStateLayers: MapModuleLayer[], layers: MapModuleLayer[]) {
        lastStateLayers.forEach(async (layer) => {
            const changedLayer = layers.find((lastStateLayer) => lastStateLayer.stamp !== layer.stamp && layer.id === lastStateLayer.id);

            if (changedLayer != null) {
                const differingStyles = findDifferingStyleProperties(getLayerStyle(changedLayer), getLayerStyle(layer));
                this.updateStyle(changedLayer.id, changedLayer.styleType, differingStyles);
            }
        });
    }

    protected createHighlightLayer(layer: MapModuleLayer): MapModuleLayer {
        switch (layer.styleType) {
            case StyleType.Line:
                return this.createLineHighlightLayer(layer);
            case StyleType.Area:
                return this.createLineHighlightLayer(layer);
            case StyleType.Point:
                return this.createPointHighlightLayer(layer);
            case StyleType.Icon:
                return this.createPointHighlightLayer(layer);
            case StyleType.LineModel:
                return this.createLineHighlightLayer(layer);
            default:
                throw new Error(`No case exists for highlight layers of type ${layer.styleType}`);
        }
    }

    protected createLineHighlightLayer(layer: MapModuleLayer): MapModuleLayer {
        return {
            ...createDefaultIventisLayer(),
            id: `${layer.id}_${highlightInfix}`,
            storageScope: layer.storageScope,
            source: layer.source,
            stamp: "",
            selected: false,
            name: layer.name,
            visible: layer.visible,
            styleType: StyleType.Line,
            areaStyle: null,
            lineStyle: {
                ...defaultLineStyle,
                styleType: StyleType.Line,
                colour: createStaticStyleValue("yellow"),
                width: createStaticStyleValue(this.calculateHighlightWidth(layer)),
                opacity: createStaticStyleValue(1),
                offset: createStaticStyleValue(this.calculateHighlightLayerOffset(layer)),
                blur: createStaticStyleValue(5),
            },
            drawingControls: null,
        };
    }

    protected calculateHighlightWidth(layer: MapModuleLayer) {
        switch (layer.styleType) {
            case StyleType.Line:
                const lineWidth = getStaticStyleValue(layer.lineStyle.width);
                const outlineWidth = getStaticStyleValue(layer.lineStyle.outline) ? getStaticStyleValue(layer.lineStyle.outlineWidth) : 0;
                return lineWidth + outlineWidth + 20;
            default:
                return 20;
        }
    }

    protected calculateHighlightLayerOffset(layer: MapModuleLayer) {
        switch (layer.styleType) {
            case StyleType.Area:
                return getStaticStyleValue(layer.areaStyle.outline) === true ? getStaticStyleValue(layer.areaStyle.outlineWidth) * -1 : 0;
            case StyleType.Line:
                return getStaticStyleValue(layer.lineStyle.offset);
            default:
                return 0;
        }
    }

    protected createPointHighlightLayer(layer: MapModuleLayer): MapModuleLayer {
        return {
            ...createDefaultIventisLayer(),
            id: `${layer.id}_${highlightInfix}`,
            storageScope: layer.storageScope,
            source: layer.source,
            selected: false,
            name: layer.name,
            visible: layer.visible,
            styleType: StyleType.Point,
            pointStyle: {
                ...defaultPointStyle,
                colour: createStaticStyleValue("yellow"),
                outline: createStaticStyleValue(false),
                blur: createStaticStyleValue(0.3),
                radius:
                    layer.styleType === StyleType.Point
                        ? modifyStyleValueFundamentalValues<number>(layer.pointStyle.radius, (value) => {
                              const outlineOffset = getStaticStyleValue(layer.pointStyle.outline) ? getStaticStyleValue(layer.pointStyle.outlineWidth) : 0;
                              return getHighlightCircleRadius(value + outlineOffset);
                          })
                        : modifyStyleValueFundamentalValues(layer.iconStyle.size, (value) => {
                              // The base size of the highlight area for icons, gets scaled by the icon's scale
                              const baseIconHighlightSizePx = 30;
                              return baseIconHighlightSizePx * value;
                          }),
            },
            drawingControls: null,
        };
    }

    private getSelectedCommentPosition() {
        const state = this.getCurrentState();
        const [selectedComment] = state.commentsSelected.value;
        // If a comment is selected
        if (selectedComment != null) {
            // Find the selected comment
            const commentsGeojson = state.geoJSON.value[commentsLayerId];
            const commentFeature = commentsGeojson.find((feature) => feature.objectId === selectedComment.commentId);
            if (commentFeature.feature.geometry.type !== "Point") return null;
            const { coordinates } = commentFeature.feature.geometry;
            // Get new canvas coordinates
            return { canvas: this.project(coordinates), map: coordinates };
        }
        return null;
    }

    /**
     * Given a list of selected map objects that exist in the map, differentiate between whether they are part of a remote or local source.
     */
    private getLocalAndRemoteSelectedMapObjects(selectedMapObjects: SelectedMapObject[]): { localMapObjectsSelected: string[]; remoteMapObjectsSelected: string[] } {
        const layers = this.store.change.value.layers.value;

        const localRemoteObjectIdMap = this.getLocalRemoteObjectIdMap();

        // If an object exists in this mapping, it is assumed that its remote object's ID is already filtered
        const filteredRemoteMapObjects = Object.values(localRemoteObjectIdMap);

        // Find which objects are local only, meaning that their layer's storage scope is local only
        const localOnlyObjects = selectedMapObjects.filter(({ layerId }) => layers.find((l) => l.id === layerId)?.storageScope === LayerStorageScope.LocalOnly);

        // Figure out which local map objects have remote counterparts.
        const localMapObjectsWhichHaveRemoteCounterparts = selectedMapObjects.filter(({ objectId }) =>
            Object.keys(localRemoteObjectIdMap).some((localobjectId) => objectId === localobjectId)
        );

        const remoteMapObjectsSelected = selectedMapObjects
            .filter((remoteMapObject) => !filteredRemoteMapObjects.includes(remoteMapObject.objectId))
            .map(({ objectId }) => objectId);

        return { localMapObjectsSelected: [...localOnlyObjects, ...localMapObjectsWhichHaveRemoteCounterparts].map(({ objectId }) => objectId), remoteMapObjectsSelected };
    }

    protected getSelectedMapObjectIdsWithLayerIds() {
        return this.getCurrentState().mapObjectsSelected.value;
    }

    protected getLocalGeometries(objects: { layerId: string; objectId: string }[]) {
        const localGeometries = this.getCurrentState().geoJSON.value;
        // Replace with layer ID list
        const layerIds = objects.reduce((cumulative, { layerId }) => [...cumulative.filter((lid) => lid !== layerId), layerId], [] as string[]);

        const objectsWithGeometries = layerIds.reduce<CompositionMapObject[]>((cumulative, layerId) => {
            // Get the objects from the store which coresspond to those passed into the function
            const layerObjects = localGeometries[layerId].filter(({ objectId: objectIdFromStore }) =>
                objects.some(({ objectId: inputObjectId }) => inputObjectId === objectIdFromStore)
            );
            return [...cumulative, ...layerObjects.map((object) => ({ objectId: object.objectId, layerId, geojson: object.feature, waypoints: object.waypoints }))];
        }, []);

        return objectsWithGeometries;
    }

    protected isLocalGeometryBeingRequested(layerId: string, objectId: string): boolean {
        const pendingObjects = this.getCurrentState().pendingGeoJSON.value;
        const pendingObjectsForLayer = pendingObjects[layerId];
        // If layer is "all" it means all map objects are being requested
        if (pendingObjectsForLayer != null && (pendingObjectsForLayer === "all" || pendingObjectsForLayer.includes(objectId))) {
            return true;
        }
        return false;
    }

    protected async importLayers(lastStateLayers: MapModuleLayer[], layers: MapModuleLayer[]) {
        // find the layers which have been added and removed from the previous state
        const layerDifference = findDifferingLayers(layers, lastStateLayers);
        // for each layer in the map
        layers.forEach(async (layer) => {
            // check if the map has been added into the map
            if (layerDifference.addedIds.includes(layer.id)) {
                if (!layer.remote) {
                    this.setEmptySource(layer.source);
                }
                // add layer to map
                await this.addLayer(layer, true);
            }
        });
    }

    private forEachLayer(lastStateLayers: MapModuleLayer[], layers: MapModuleLayer[], func: (previousLayer: MapModuleLayer, latestLayer: MapModuleLayer) => void) {
        layers.forEach((latestLayer) => {
            lastStateLayers.forEach((previousLayer) => {
                if (latestLayer.id === previousLayer.id) {
                    func(previousLayer, latestLayer);
                }
            });
        });
    }

    protected updateLayerVisibility(lastStateLayers: MapModuleLayer[], layers: MapModuleLayer[]) {
        const update = (previousLayer: MapModuleLayer, latestLayer: MapModuleLayer) => {
            if (previousLayer.visible !== latestLayer.visible) {
                if (latestLayer.visible) {
                    this.showLayer(latestLayer.id);
                } else {
                    this.hideLayer(latestLayer.id);
                    this.clickOnEmptySpace();
                }
            }
        };
        this.forEachLayer(lastStateLayers, layers, update);
    }

    // given a layer id returns true or false if the layer is selected
    protected checkIfLayerIsSelected(layerId: string) {
        const selectedLayers = this.getSelectedLayers().map((layer) => layer.id);
        return selectedLayers.includes(layerId);
    }

    // filter selected map objects by the layers which are selected
    protected getFilteredMapObjects(mapObjectId: string, layerId: string): { id: string; layerId: string }[] {
        const baseLayerId = removeLayerIdSuffixes(layerId);
        const filteredMapObjects = this.checkIfLayerIsSelected(baseLayerId)
            ? this.getSelectedMapObjectsIds().map((mapObject) => ({ id: mapObject.objectId, layerId: baseLayerId }))
            : [];
        if (filteredMapObjects.some((mapObject) => mapObjectId === mapObject.id)) {
            return filteredMapObjects.filter((mapObject) => mapObjectId !== mapObject.id);
        }
        return [...filteredMapObjects, { id: mapObjectId, layerId: baseLayerId }];
    }

    protected async createEditableObject(selectedMapObjectIds: SelectedMapObject[]) {
        if (!selectedMapObjectIds.length) {
            return;
        }
        await this.ensureGeometryExistsLocally(selectedMapObjectIds);
        // We only show manipulation controls for a single object at a time
        const { objectId, layerId } = selectedMapObjectIds[0];
        this.renderDrawingControls(objectId);
        this.createEphemeralObjects([{ layerId, objectId }]);
    }

    protected async createEditableObjectForDrag(selectedMapObjectIds: SelectedMapObject[]) {
        const localSelectedMapOjects = await this.ensureGeometryExistsLocally(selectedMapObjectIds);
        if (typeof localSelectedMapOjects === "undefined") {
            this.removeDrawingControls();
            return;
        }
        // We only show manipulation controls for a single object at a time
        const { objectId, layerId } = localSelectedMapOjects[0];
        this.renderDrawingControls(objectId);
        this.createEphemeralObjects([{ layerId, objectId }]);
    }

    protected clickOnComment(comment: SelectableComment) {
        // If the comments handler is not already spawned, spawn it
        if (this.commentsHandler == null) {
            this.spawnCommentsMachine(comment);
        }
    }

    protected clickOnMapObject(selectedMapObject: SelectedMapObject) {
        this.machine.send({ type: machineEventTypes.selectObjects, payload: { selectedMapObjectIds: [selectedMapObject], from: FROM_MAP_ENGINE, updateStore: true } });
    }

    protected clickOnEmptySpace() {
        this.machine.send({ type: machineEventTypes.selectObjects, payload: { selectedMapObjectIds: [], from: FROM_MAP_ENGINE, updateStore: true } });
    }

    /** Sets the new composition and runs any side effects of the change */
    private setCurrentComposition(composition: CompositionMapObject[]) {
        const isCurrentCompositionARoute = isSingleRoute(this.getCurrentComposition(false));
        const isNewCompositionARoute = isSingleRoute(composition);
        if (isCurrentCompositionARoute && !isNewCompositionARoute) {
            this.sendEventToStore({ eventType: MapStoreEventType.SET_EDITING_ROUTE, payload: false, object: undefined });
        }
        if (isNewCompositionARoute && !isCurrentCompositionARoute) {
            this.sendEventToStore({ eventType: MapStoreEventType.SET_EDITING_ROUTE, payload: true, object: composition[0] });
        }
        this.currentComposition = composition;
    }

    protected removeComposition() {
        this.setCurrentComposition([]);
        this.compositionLayersRemainingSource = [];
    }

    /*
        PUBLIC ACCESSOR FUNCTIONS FOR MODE-MACHINE
    */

    public async addDrawingControls() {
        const selectedObjects = this.getSelectedMapObjectsIds();
        if (selectedObjects.length === 1) {
            await this.ensureGeometryExistsLocally(selectedObjects);
            this.renderDrawingControls(selectedObjects[0].objectId);
        }
    }

    public ensureLocalObjectsUpToDate() {
        this.reflectLocalObjects({ allowTextOnSelectedObjects: this.isCompositionMode() });
        this.hideRemoteObjects();
    }

    public highlightSelectedMapObjects() {
        this.renderHighlights(this.getSelectedMapObjectsIds());
    }

    public enterAnalysisMode() {
        analysisLayerIds.forEach((id) => this.showLayer(id));
        this.sendEventToStore({ eventType: MapStoreEventType.ENTER_ANALYSIS_MODE });
    }

    public exitAnalysisMode() {
        this.deselectLayers();
        this.sendEventToStore({ eventType: MapStoreEventType.EXIT_ANALYSIS_MODE });
        analysisLayerIds.forEach((id) => this.hideLayer(id));
    }

    // COMPOSITION

    public returnToCompositionBehaviour() {
        const currentComposition = this.getCurrentComposition(false);
        this.beginCompositionBehaviour(currentComposition);
    }

    public createDrawingObjects(undefinedObjects: OptionalExceptFor<DrawingObject, "layerId">[]): CompositionMapObject[] {
        const isAnalysis = isAnalysisLayer(undefinedObjects[0]?.layerId);
        if (isAnalysis) {
            return this.createAnalysisObjects(undefinedObjects);
        }
        const state = this.getCurrentState();
        this.removeDrawingControls();
        const newObjects: CompositionMapObject[] = [];
        undefinedObjects.forEach(({ layerId, objectId: originalObjectId, geojson: originalGeoJson }) => {
            const objectId = uuid();
            const layers = this.getCurrentState().layers.value;
            const layer = layers.find((l) => l.id === layerId);
            const systemDataFieldProperties = addSystemDataFieldsToMapObject(layer.styleType, originalGeoJson.properties, this.getCurrentState().projectDataFields);
            // If undefinedObject has an object Id means it is a copy and therefore don't want to get default attribute values
            const layerDataFieldsWithDefaultValues = originalObjectId == null ? getDefaultAttributeValues(layer) : {};
            const what3wordsDataField = layer.dataFields?.find((df) => df.type === DataFieldType.What3Words);
            const geojson: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> = {
                id: objectId,
                type: "Feature",
                properties: {
                    level: state.currentLevel,
                    ...layerDataFieldsWithDefaultValues,
                    ...originalGeoJson?.properties,
                    id: objectId,
                    layerid: layerId,
                    rotation: originalGeoJson?.properties?.rotation || { x: 0, y: 0, z: 0 },
                    ...systemDataFieldProperties,
                },
                geometry: originalGeoJson?.geometry || {
                    type: styleTypeToGeoJSONGeometryType(layer.styleType),
                    coordinates: [],
                },
            };
            // Ensure we remove any what3words data fields from the geojson properties
            if (what3wordsDataField != null) {
                delete geojson.properties[what3wordsDataField.id];
            }
            newObjects.push({ objectId, layerId, geojson, waypoints: undefined });
        });

        this.sendEventToStore({ eventType: MapStoreEventType.COMPOSE_NEW_OBJECTS, payload: { objectIds: newObjects.map(({ objectId }) => objectId) } });
        return newObjects;
    }

    public createAnalysisObjects(newAnalysisObjects: OptionalExceptFor<DrawingObject, "layerId">[]): CompositionMapObject[] {
        this.removeDrawingControls();

        // There should only be one analysis object
        if (newAnalysisObjects.length > 1) {
            return [];
        }

        const newObjects: CompositionMapObject[] = [];
        const objectId = uuid();
        const analysisObject = newAnalysisObjects[0];

        const { currentLevel: level } = this.getCurrentState();

        let geojson: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;

        switch (analysisObject.layerId) {
            case analysisLineLayerId:
                geojson = {
                    id: objectId,
                    type: "Feature",
                    properties: {
                        id: objectId,
                        layerid: analysisLineLayerId,
                        rotation: { x: 0, y: 0, z: 0 },
                        measurementScope: MeasurementScope.WHOLE,
                        selected: "selected",
                        level,
                    },
                    geometry: {
                        type: "LineString",
                        coordinates: [],
                    },
                };
                newObjects.push({ objectId, layerId: analysisLineLayerId, geojson, waypoints: undefined });
                break;
            case analysisPolygonLayerId:
                geojson = polygon(
                    [],
                    {
                        name: "Analysis object",
                        id: objectId,
                        layerid: analysisPolygonLayerId,
                        rotation: { x: 0, y: 0, z: 0 },
                        measurementScope: MeasurementScope.WHOLE,
                        selected: "selected",
                        level,
                    },
                    { id: objectId }
                );
                newObjects.push({ objectId, layerId: analysisPolygonLayerId, geojson, waypoints: undefined });
                break;
            default:
                break;
        }

        this.sendEventToStore({ eventType: MapStoreEventType.COMPOSE_NEW_OBJECTS, payload: { objectIds: newObjects.map(({ objectId }) => objectId) } });
        return newObjects;
    }

    public editSetFeatures(features: TypedFeature[], updateStore = true) {
        const currentCompositions = this.getCurrentComposition(false);
        currentCompositions.forEach((compositionFeature) => {
            const newFeature = features.find((f) => [f.id, f.properties?.id].includes(compositionFeature.objectId));
            if (newFeature) {
                // eslint-disable-next-line no-param-reassign
                compositionFeature.geojson = newFeature;
            }
        });

        if (updateStore) {
            this.setGeometryInStore(
                currentCompositions.map((currentComposition) => ({
                    layerId: currentComposition.layerId,
                    objectId: currentComposition.objectId,
                    geometry: JSON.parse(JSON.stringify(currentComposition.geojson.geometry)),
                    properties: JSON.parse(JSON.stringify(currentComposition.geojson.properties)),
                    waypoints: currentComposition.waypoints != null ? JSON.parse(JSON.stringify(currentComposition.waypoints)) : null,
                }))
            );
        }

        if (currentCompositions.length === 1) {
            const currentComposition = currentCompositions[0];
            const feature = features.find((f) => [f.id, f.properties?.id].includes(currentComposition.objectId));

            this.showCoordinateHandles(feature, currentComposition.layerId, currentComposition.objectId);
            this.showMidpointHandles(feature, currentComposition.layerId, true);
        }

        const selectedObjects = this.getSelectedMapObjectIdsWithLayerIds();
        const selectedFeatureEdited = features.filter((f) => selectedObjects.some(({ objectId }) => [f.id, f.properties?.id].includes(objectId)));
        if (selectedFeatureEdited.length) {
            this.showRotationHandle(
                selectedFeatureEdited.map((feature) => ({ geojson: feature, objectId: feature.properties.id, layerId: feature.properties.layerid, waypoints: undefined }))
            );
        }
    }

    public compositionSetFeature({ feature, waypoints }: { feature: TypedFeature; waypoints: RouteWaypoint[] | null }, updateStore = true) {
        const currentComposition = this.getCurrentComposition(true);
        currentComposition.geojson = feature;
        currentComposition.waypoints = waypoints;

        if (feature.properties.fixedShape != null) {
            this.fixedShape.setObject(currentComposition.geojson as GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties>);
        } else if (feature.properties.modeOfTransport != null) {
            // Route controls handles all this so no need to do anything here
            return;
        } else {
            this.composition.setObject(currentComposition.geojson);
        }

        if (updateStore) {
            this.setGeometryInStore([
                {
                    layerId: currentComposition.layerId,
                    objectId: currentComposition.objectId,
                    geometry: JSON.parse(JSON.stringify(currentComposition.geojson.geometry)),
                    properties: JSON.parse(JSON.stringify(currentComposition.geojson.properties)),
                    waypoints: currentComposition.waypoints != null ? JSON.parse(JSON.stringify(currentComposition.waypoints)) : null,
                },
            ]);
        }

        // If we are drawing a route, we don't want to show the coordinate handles
        if (feature.properties.modeOfTransport != null) {
            return;
        }

        this.showCoordinateHandles(feature, currentComposition.layerId, currentComposition.objectId);
        this.showMidpointHandles(feature, currentComposition.layerId, true);
        this.showRotationHandle([currentComposition]);
    }

    public getInitialComposition() {
        return this.initialComposition;
    }

    public startComposition(drawingObjects: CompositionMapObject[], isDuplication: boolean, fromCoordinate: GeoJSON.Position | undefined) {
        this.removeReadBehaviour();
        this.removeObjectDragListeners();
        // If composition has started form a coordinate, it means there is an initial composition (continue drawing)
        if (fromCoordinate != null) {
            this.initialComposition = drawingObjects;
        }
        this.compositionUndoRedo.startComposition(drawingObjects);
        this.selectMapObjectsInCompositionMode(drawingObjects, isDuplication);
        this.beginCompositionBehaviour(drawingObjects, isDuplication);
    }

    public finishComposition() {
        this.removeCompositionBehaviour();
        // Remove the initial composition as it no longer applies
        this.initialComposition = undefined;
        // We just need to clear the undo/redo stack so that when we enter composition mode again we start from scratch
        this.compositionUndoRedo?.clear();
        // Allow text while drawing is true because the
        this.reflectLocalObjects({ allowTextOnSelectedObjects: true });
        this.hideRemoteObjects();
        this.storeEvents.next({ eventType: MapStoreEventType.OBJECT_PLACEMENT_FINISHED });
    }

    public completeDrawing() {
        const features = this.getCurrentComposition(false);
        const isAnalysisObject = isAnalysisLayer(features[0]?.layerId);
        this.sendEventToStore({
            eventType: MapStoreEventType.DRAWING_FINISHED,
            features,
            initialFeatures: this.initialComposition,
            isAnalysisObject,
        });

        if (features?.length === 1 && isAnalysisObject) {
            this.placeAnalysisMeasurement();
        }
    }

    public cancelDrawing(updateStore = true, explicitlyDeleteObject = false) {
        // If for example we were to cancel while continue drawing, we want to revert to its initial geometry
        const initialComposition = this.getInitialComposition()?.[0];
        const fallback = isCompositionValid(initialComposition?.geojson?.geometry) ? initialComposition : undefined;

        this.removeDrawingControls();
        const currentComposition = this.getCurrentComposition(false);
        const selection = currentComposition?.length > 0 ? currentComposition : this.getSelectedMapObjectIdsWithLayerIds();
        // If selection is an empty array means that the composition is not valid
        if (selection.length > 0) {
            const [{ layerId, objectId }] = selection;
            const object = this.getCurrentState().geoJSON.value[layerId]?.find((object) => object.objectId === objectId);
            if (object !== undefined && fallback && !explicitlyDeleteObject) {
                this.setGeometryInStore([{ objectId, layerId, properties: object.feature.properties, geometry: fallback?.geojson?.geometry, waypoints: fallback?.waypoints }]);
            } else {
                this.deleteCurrentComposition(updateStore, fallback);
            }
        }

        if (fallback && !explicitlyDeleteObject && currentComposition?.length === 1 && isAnalysisLayer(currentComposition[0].layerId)) {
            this.placeAnalysisMeasurement();
        }

        // Reset the current composition
        this.removeComposition();
    }

    public placeAnalysisMeasurement() {
        this.setEmptySource(measurementSourceId);
        this.addTextboxLayer(longLastingMeasurementLayerId, longLastingMeasurementSourceId, true, analysisMeasuringLabelLayerName);
        if (isCompositionValid(this.getCurrentComposition(true).geojson.geometry)) {
            this.setLongLastingMeasurementLabels(this.getCurrentComposition(true).geojson);
        }
        this.storeEvents.next({ eventType: MapStoreEventType.OBJECT_PLACEMENT_FINISHED });
    }

    public finishAreaSelect(cancelled: boolean) {
        this.removeCompositionBehaviour();
        const { layerId, objectId, geojson } = this.getCurrentComposition(true);
        this.sendEventToStore({
            eventType: MapStoreEventType.AREA_SELECT_FINISHED,
            payload: { object: { layerId, objectId, geometry: geojson.geometry }, cancelled },
        });
        this.removeDrawingControls();
        this.setEmptySource(measurementSourceId);
        this.removeLayer(selectedLayerAreaSelectId);
    }

    public duplicateObjects(): CompositionMapObject[] {
        const {
            geoJSON: { value: localGeoJson },
            mapObjectsSelected: { value: objectsToCopy },
        } = this.getCurrentState();
        return objectsToCopy.map(({ objectId, layerId }) => {
            const localObject = localGeoJson[layerId].find((obj) => obj.objectId === objectId);
            return {
                objectId,
                layerId,
                geojson: localObject?.feature,
                waypoints: localObject?.waypoints,
            };
        });
    }

    // SHARED DRAWING PUBLIC ACCESSORS

    public tellStoreDraggingHasStarted() {
        this.sendEventToStore({ eventType: MapStoreEventType.DRAG_START });
    }

    public tellStoreDraggingHasEnded() {
        this.sendEventToStore({ eventType: MapStoreEventType.DRAG_END });
    }

    // FEATURE DRAGGING

    public async beginDraggingBehaviour(origin: { lng: number; lat: number }) {
        if (this.objectDrag !== undefined) {
            this.objectDrag.destroy();
        }
        await this.selectMapObjectsInEditMode(this.getSelectedMapObjectsIds(), { updateStore: false, isFromDrag: true, from: FROM_MAP_ENGINE });
        this.removeDrawingControls();
        this.removeReadMouseMove();
        await this.ensureGeometryExistsLocally(this.getSelectedMapObjectsIds());
        this.removeHighlights();

        const objects = this.getLocalGeometries(this.getSelectedMapObjectIdsWithLayerIds().map(({ objectId, layerId }) => ({ objectId, layerId })));
        this.removeLongLastingMeasurementLabels(objects.map((object) => object.objectId));
        this.createEphemeralObjects(objects);
        this.objectDrag = new ObjectDrag(
            objects,
            origin,
            {
                onMouseMove: this.onMouseMove.bind(this),
                onMouseUp: this.onMouseUp.bind(this),
                placementListeners: [(callback) => this.onClick(callback)],
                // Checking if drag is valid is handled in the mode machine, this is needed for duplication
                isInvalidPlacement: () => false,
                userDraggedToInvalidPosition: () => this.userHasDrawnOutsideCad(),
            },
            this.lastMouse,
            // Set initial translation
            this.setEphemeralGeometry.bind(this),
            this.getCurrentState().drawingModifier,
            {
                snapping: () =>
                    new ObjectSnapping(
                        objects.map(({ geojson }) => geojson),
                        this.objectSnappingFunctions
                    ),
            }
        )
            .onTranslate((translated) => {
                this.setEphemeralGeometry(translated);
            })
            .onRelease((translated) => {
                this.setSnapIndicatorSource(undefined);
                this.getDrawingMachine().send({ type: machineEventTypes.objectDragEnd, payload: translated });
            });

        // Remove text labels on polygons to improve performance while dragging
        const areaObjects = objects.filter(({ layerId }) => this.getStoredLayer(layerId).styleType === StyleType.Area);
        this.removeTextLabels(areaObjects);
        // Remove measurement labels
        this.setEmptySource(measurementSourceId);
    }

    public endDraggingBehaviour(result: CompositionMapObject[] | undefined, setInStore = true) {
        const definedResult: CompositionMapObject[] = result == null ? this.objectDrag.latestTranslation ?? this.currentComposition : result;
        const validResult = this.areCompositionObjectsOutsideCadBounds(definedResult) ? this.currentComposition : definedResult;
        this.addDrawingControls();
        this.addReadMouseMove();
        this.addObjectDragListeners();
        this.objectDrag.destroy();
        this.objectDrag = undefined;
        if (setInStore) {
            this.setGeometryInStore(
                validResult.map(({ layerId, objectId, geojson, waypoints }) => ({
                    layerId,
                    objectId,
                    geometry: geojson.geometry,
                    properties: geojson.properties,
                    waypoints,
                }))
            );
        }

        this.highlightSelectedMapObjects();

        this.showRotationHandle(validResult);

        if (validResult.length === 1) {
            // If 1 is selected, we need to update the composition because it is still used due to showing the coordinate handles etc
            const currentComposition = this.getCurrentComposition(true);
            if (currentComposition != null) {
                currentComposition.geojson.geometry = validResult[0].geojson.geometry;
                this.composition?.setObject(currentComposition.geojson);
            }
        } else {
            // Otherwise, we no longer need to use the current composition since nothing else is currently relying on it
            this.removeComposition();
        }
    }

    public endAnalysisDraggingBehaviour(result: CompositionMapObject[], setInStore = true) {
        this.endDraggingBehaviour(result, setInStore);
        result.forEach((object) => {
            this.setLongLastingMeasurementLabels(object.geojson);
        });
    }

    // NODE DRAGGING

    public nodeDragStartInComposition() {
        this.removeCompositionBehaviour();
        this.removeRotationHandle();
        this.removeMidpointHandles();
        this.removeObjectDragListeners();
    }

    /** Start dragging in edit mode for both midpoint and coordinate handles */
    public handlesDragStartInEdit() {
        const currentComposition = this.getCurrentComposition(true);
        const { objectId, layerId } = currentComposition;
        this.removeRotationHandle();
        this.removeObjectDragListeners();
        this.removeReadMouseMove();
        const layer = this.getStoredLayer(layerId);
        if (layer.styleType === StyleType.Area) {
            this.removeTextLabels([{ objectId, layerId }]);
        }
    }

    public nodeDragStartInEdit(object: Feature<AnySupportedGeometry, MapObjectProperties>) {
        if (measurableGeometries.includes(object?.geometry?.type)) {
            this.addTextboxLayer(measurementLayerId, measurementSourceId);
            this.removeLongLastingMeasurementLabels([object.properties.id]);
        }
        this.handlesDragStartInEdit();
        this.removeMidpointHandles();
    }

    public midpointDragStartInEdit() {
        this.handlesDragStartInEdit();
        this.removeCoordinateHandles();
    }

    // finish node dragging behaviours shared between modes
    protected nodeDragFinish(transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>, waypoints: RouteWaypoint[] | null, updateStore = true) {
        this.enableDragPan();
        const currentComposition = this.getCurrentComposition(true);
        currentComposition.geojson = transformed;
        currentComposition.waypoints = waypoints;
        if (updateStore) {
            this.setGeometryInStore([
                {
                    layerId: currentComposition.layerId,
                    objectId: currentComposition.objectId,
                    geometry: JSON.parse(JSON.stringify(currentComposition.geojson.geometry)),
                    properties: JSON.parse(JSON.stringify(currentComposition.geojson.properties)),
                    waypoints: currentComposition.waypoints != null ? JSON.parse(JSON.stringify(currentComposition.waypoints)) : null,
                },
            ]);
        }
        this.setLongLastingMeasurementLabels(transformed);
    }

    // finish node dragging behaviours unique to composition
    public nodeDragFinishInComposition(
        transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>,
        waypoints: RouteWaypoint[] | null,
        wasNodeDraggedOutsideCadBounds: boolean
    ) {
        // If node was dragged outside Cad bounds, revert to previous geometry of before drag began
        const validTransformation: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> = wasNodeDraggedOutsideCadBounds
            ? this.getCurrentComposition(true)?.geojson
            : transformed;

        this.nodeDragFinish(validTransformation, waypoints);
        const currentComposition = this.getCurrentComposition(true);
        this.showRotationHandle([{ geojson: validTransformation, layerId: currentComposition.layerId, objectId: currentComposition.objectId, waypoints: undefined }]);
        if (!isSingleRoute([currentComposition])) {
            this.renderDrawingControls(validTransformation.properties.id, true);
        }
        this.returnToCompositionBehaviour();
    }

    // finish node dragging behaviours unique to edit
    public nodeDragFinishInEdit(
        transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>,
        waypoints: RouteWaypoint[] | null,
        wasNodeDraggedOutsideCadBounds: boolean
    ) {
        const currentComposition = this.getCurrentComposition(true);
        // If node was dragged outside Cad bounds, revert to previous geometry of before drag began
        const validTransformation: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> = wasNodeDraggedOutsideCadBounds ? currentComposition.geojson : transformed;
        if (!isSingleRoute([currentComposition])) {
            this.addDrawingControls();
        }
        this.addReadMouseMove();
        this.nodeDragFinish(validTransformation, waypoints);
        this.addObjectDragListeners();
        this.showRotationHandle([{ geojson: validTransformation, layerId: currentComposition.layerId, objectId: currentComposition.objectId, waypoints: undefined }]);
    }

    // ROTATING
    public rotationDragStartInComposition(selectedMapObjectIds: SelectedMapObject) {
        this.removeCoordinateHandles();
        this.removeMidpointHandles();
        this.removeCoordinateDelete();
        this.removeCompositionBehaviour();
        this.createEphemeralObjects([{ layerId: selectedMapObjectIds.layerId, objectId: selectedMapObjectIds.objectId }]);
    }

    public rotationDragStartInEdit(selectedMapObjectIds: SelectedMapObject[]) {
        this.removeCoordinateHandles();
        this.removeMidpointHandles();
        this.removeReadMouseMove();
        this.removeCoordinateDelete();
        this.createEphemeralObjects(selectedMapObjectIds);

        // Remove text labels on polygons to improve performance while dragging
        const areaObjects = selectedMapObjectIds.filter(({ layerId }) => this.getStoredLayer(layerId).styleType === StyleType.Area);
        this.removeTextLabels(areaObjects);

        // Remove long lasting measurement labels while rotating
        this.removeLongLastingMeasurementLabels(selectedMapObjectIds.map(({ objectId }) => objectId));
    }

    // finish rotation drag behaviours shared between modes
    protected rotationDragFinish(objects: CompositionMapObject[]) {
        const validObjects = this.areCompositionObjectsOutsideCadBounds(objects) ? this.currentComposition : objects;
        if (validObjects.length === 1) {
            // If 1 is selected, we need to update the composition because it is still used due to showing the coordinate handles etc
            const currentComposition = this.getCurrentComposition(true);
            currentComposition.geojson.geometry = validObjects[0].geojson.geometry;
            this.composition?.setObject(currentComposition.geojson);
        } else {
            // Otherwise, we no longer need to use the current composition since nothing else is currently relying on it
            this.removeComposition();
        }

        this.setGeometryInStore(
            validObjects.map(({ objectId, layerId, geojson, waypoints }) => ({ objectId, layerId, geometry: geojson.geometry, properties: geojson.properties, waypoints }))
        );
        this.showRotationHandle(validObjects);
    }

    // finish rotation dragging behaviours unique to composition
    public rotationDragFinishInComposition(objects: CompositionMapObject[]) {
        this.rotationDragFinish(objects);
        this.returnToCompositionBehaviour();
        this.renderDrawingControls(this.getCurrentComposition(true).objectId, true);
    }

    public rotationDragFinishInAnalysisComposition(objects: CompositionMapObject[]) {
        this.rotationDragFinishInComposition(objects);
        objects.forEach((object) => {
            this.setLongLastingMeasurementLabels(object.geojson);
        });
    }

    // finish rotation dragging behaviours unique to edit
    public rotationDragFinishInEdit(objects: CompositionMapObject[]) {
        this.rotationDragFinish(objects);
        this.addReadMouseMove();
        this.addDrawingControls();
    }

    public rotationDragFinishInAnalysisEdit(objects: CompositionMapObject[]) {
        this.rotationDragFinishInEdit(objects);
        objects.forEach((object) => {
            this.setLongLastingMeasurementLabels(object.geojson);
        });
    }

    // SELECTION

    public async selectMapObjectsInCompositionMode(selectedMapObjects: CompositionMapObject[], duplication = false) {
        // Don't want to update selection if we are duplicating or creating a new map object
        if (!duplication && selectedMapObjects.every((object) => isCompositionValid(object.geojson.geometry))) {
            await this.setSelectedMapObjectsInStore(selectedMapObjects, FROM_MAP_ENGINE);
        } else if (!isAnalysisLayer(selectedMapObjects[0]?.layerId)) {
            await this.setSelectedMapObjectsInStore([], FROM_MAP_ENGINE);
        }
        // We should not render the drawing controls while duplicating map objects. This is because we are not actually drawing, we are dragging when duplicating.
        // Although they can still be classed as selected which is why they have been set as selected above.
        // Same too with routes (any object with a modeOfTransport), the drawing controls are expected to already be rendered
        if (this.continueDrawingHandler != null) {
            this.renderDrawingControls(selectedMapObjects[0].objectId, true);
        }
    }

    /**
     * Get the resulting selection given some inputted toggled object IDs.
     * Objects already existing in the selection will be omitted if toggled.
     * Objects that do not already exist will be appended to the result.
     * @param toggledMapObjectIds The objects to toggle selection for
     * @returns The resulting selection, well yes
     */
    public getResultingSelection(toggledMapObjectIds: SelectedMapObject[], options?: { allowToggle?: boolean; ignoreModifier?: boolean }) {
        let selectedObjects: SelectedMapObject[];
        const existingSelections = this.getSelectedMapObjectIdsWithLayerIds();
        const isAnalysisObject = existingSelections.some((selectedObject) => isAnalysisLayer(selectedObject.layerId));
        if (options?.allowToggle && !options.ignoreModifier && this.isModifierPressed() && !isAnalysisObject) {
            // If a modifier is pressed, this means we are wanting to append to a multi-selection
            const selectionsAdded: SelectedMapObject[] = [];
            const selectionsRemoved: string[] = [];
            toggledMapObjectIds.forEach((inputtedSelection) => {
                if (existingSelections.find((existingSelection) => existingSelection.objectId === inputtedSelection.objectId)) {
                    // When clicking an existing selection, it is removed from the selection
                    selectionsRemoved.push(inputtedSelection.objectId);
                } else {
                    // When clicking a new selection that doesn't already exist, add it to the selection
                    selectionsAdded.push({ objectId: inputtedSelection.objectId, layerId: inputtedSelection.layerId });
                }
            });

            selectedObjects = [
                ...existingSelections.filter((selection) => !selectionsRemoved.some((removedSelectionId) => removedSelectionId === selection.objectId)),
                ...selectionsAdded,
            ];
        } else {
            // If a modifier is not pressed, the selections just become those provided
            selectedObjects = toggledMapObjectIds.map(({ objectId: id, layerId }) => ({ objectId: id, layerId }));
        }
        return selectedObjects;
    }

    public async selectMapObjectsInEditMode(toggledMapOjectIds: SelectedMapObject[], options: { updateStore: boolean; from: string; isFromDrag?: boolean; allowToggle?: boolean }) {
        const defaultOptions = { isFromDrag: false, allowToggle: true, updateStore: true };
        const { isFromDrag, allowToggle, updateStore, from } = { ...defaultOptions, ...options };

        this.removeDrawingControls();

        // If we aren't updating the store, it means the selection has been instigated form the outside, so the given selection is the true selection value (no need for modifyiers)
        const ignoreModifier = !options?.updateStore || from !== FROM_MAP_ENGINE;

        const selectedObjects = this.getResultingSelection(toggledMapOjectIds, { allowToggle, ignoreModifier });

        if (selectedObjects.length !== 1) {
            // Since we have no selected objects, we aren't rendering any current composition.
            // Clean up these members as letting them persist can make other executing code believe a composition is still there.
            this.removeComposition();
        }

        await Promise.all([
            ...(updateStore ? [this.setSelectedMapObjectsInStore(selectedObjects, from)] : []),
            // Download the geometry. This is required to render our rotator, coordinate handles etc
            this.ensureGeometryExistsLocally(selectedObjects.map(({ objectId, layerId }) => ({ layerId, objectId }))),
        ]);

        const layer = this.getSelectedLayers()?.find((l) => l.id === selectedObjects[0]?.layerId);

        // Show highlights for icon objects when in edit mode
        if (selectedObjects.length <= 1 && !(layer?.styleType === StyleType.Icon || layer?.styleType === StyleType.Point)) {
            this.removeHighlights();
            // A single selection means we are editing a single object, which means coordinate handles need to be shown
            if (isFromDrag) {
                await this.createEditableObjectForDrag(selectedObjects.map(({ objectId, layerId }) => ({ objectId, layerId })));
            } else {
                await this.createEditableObject(selectedObjects.map(({ objectId, layerId }) => ({ objectId, layerId })));
            }
        } else {
            // With multiple selections, just highlight them
            this.renderHighlights(selectedObjects.map(({ objectId, layerId }) => ({ layerId, objectId })));
        }

        // We need to use the local geometries to decide where to place the rotation handle
        const objects = this.getLocalGeometries(selectedObjects.map(({ objectId, layerId }) => ({ objectId, layerId })));
        this.showRotationHandle(objects);

        // Ensure we are showing the text labels if needed
        this.reflectLocalObjects({ allowTextOnSelectedObjects: this.isCompositionMode() });
    }

    public async selectMapObjectsInReadMode(selectedMapObjectIds: SelectedMapObject[], options: { updateStore: boolean; from: string }) {
        // If we aren't updating the store, it means the selection has been instigated form the outside, so the given selection is the true selection value (no need for modifyiers)
        const ignoreModifier = !options.updateStore;
        const allSelectedMapObjects = this.getResultingSelection(selectedMapObjectIds, { ignoreModifier });
        if (options.updateStore) {
            await this.setSelectedMapObjectsInStore(allSelectedMapObjects, options.from);
        }

        // Download our selected object
        await this.ensureGeometryExistsLocally(allSelectedMapObjects.map(({ objectId, layerId }) => ({ layerId, objectId })));

        this.renderHighlights(allSelectedMapObjects);
    }

    // DELETING

    protected deleteNodeFromMachine(coordinate: GeoJSON.Position, isComposing = false) {
        this.deleteCoordinate(coordinate);
        const currentComposition = this.getCurrentComposition(true);
        this.showMidpointHandles(currentComposition.geojson, currentComposition.layerId, isComposing);
        this.showCoordinateHandles(currentComposition.geojson, currentComposition.layerId, currentComposition.objectId);
        this.setLongLastingMeasurementLabels(currentComposition.geojson);
    }

    public deleteNodeWhileInEdit(coordinate: GeoJSON.Position): CompositionMapObject {
        this.deleteNodeFromMachine(coordinate);
        this.setCursor(MapCursor.READ);
        return this.getCurrentComposition(true);
    }

    public deleteNodeWhileInComposition(coordinate: GeoJSON.Position): CompositionMapObject {
        this.deleteNodeFromMachine(coordinate, true);
        this.setCursor(MapCursor.COMPOSITION);
        return this.getCurrentComposition(true);
    }

    public deleteCurrentComposition(deleteInStore = true, initialCompositionObject?: CompositionMapObject) {
        // Remove source while keeping any existing local geometry
        const currentComposition = this.getCurrentComposition(false);
        let selection: SelectedMapObject[] = currentComposition;
        if (!(selection?.length > 0)) {
            selection = this.getSelectedMapObjectIdsWithLayerIds();
        }
        const layers = selection?.reduce<MapModuleLayer[]>(
            (acc, composition) => (acc.some((l) => l.id === composition.layerId) ? acc : [...acc, this.getStoredLayer(composition.layerId)]),
            []
        );
        layers?.forEach((layer) => {
            const remaining = this.compositionLayersRemainingSource.find((g) => g.layerId === layer.id)?.geojson || [];
            this.reflectSource(layer.storageScope === LayerStorageScope.LocalOnly ? layer.id : getLocalLayerID(layer.id), featureCollection(remaining));
        });
        this.reflectLocalObjects({ allowTextOnSelectedObjects: this.isCompositionMode() });
        if (deleteInStore) {
            this.storeEvents.next({
                eventType: MapStoreEventType.DELETE_COMPOSITION,
                // If there is an initial and current composition, we can set the payload to the current composition and populate the initial composition object
                ...(currentComposition?.length > 0 && initialCompositionObject ? { payload: currentComposition, initialCompositionObject } : { payload: selection }),
            });
        }
        this.removeLongLastingMeasurementLabels(selection?.map((object) => object.objectId));
        // Deselect the map objects which we have just removed
        this.selectMapObjectsInEditMode([], { updateStore: deleteInStore, from: FROM_MAP_ENGINE });
    }

    // MIDPOINTS

    protected addMidpoint(point: Midpoint, dragging = false) {
        this.addMidpointCoordinate(point.properties.indexBefore);
        const currentComposition = this.getCurrentComposition(true);
        this.showCoordinateHandles(currentComposition.geojson, currentComposition.layerId, currentComposition.objectId, dragging ? point : undefined);
        this.showMidpointHandles(currentComposition.geojson, currentComposition.layerId, false);
    }

    public addMidpointWhileInEdit(point: Midpoint, dragging = false) {
        if (!this.currentComposition) {
            return;
        }
        this.addMidpoint(point, dragging);
        this.setCursor(MapCursor.READ);
    }

    public addMidpointWhileInComposition(point: Midpoint, dragging = false) {
        this.removeCompositionBehaviour();
        this.removeMidpointHandles();
        this.removeCoordinateDelete();
        this.addMidpoint(point, dragging);
        this.setCursor(MapCursor.READ);
    }

    public isCameraMovementLocked() {
        return this.getCurrentState().isCameraMovementLocked.value;
    }

    // PREVIEW

    public isPreview() {
        return this.preview;
    }

    // OTHER

    public disableMapCameraMovement() {
        this.disableDragPan();
        this.disableMapRotation();
    }

    public enableMapCameraMovement() {
        this.enableDragPan();
        this.enableMapRotation();
    }

    public async getDirectionalCursor(cursorBearing: number) {
        if (cursorBearing == null) {
            throw new Error("Cannot call getDirectionalCursor with an undefined bearing");
        }
        const mapBearing = (await this.getPosition()).bearing;
        return getOneDirectionalCursor(cursorBearing, ensure360Bearing(mapBearing));
    }

    public removeAllLayers() {
        const { layers } = this.getCurrentState();
        layers.value.forEach((layer) => {
            this.removeLayer(layer.id);
        });
    }

    public addAllLayers(bustCache = false) {
        const { layers } = this.getCurrentState();
        orderDomainLayers(layers.value).forEach((layer) => {
            this.addLayer(layer, bustCache);
        });
    }

    /** If a list item is updated, we want to make sure that any references to it's contents are updated */
    public refreshAttributeListItems(attributeId: string, layerId: string) {
        const layer = this.getStoredLayer(layerId);
        const style = getLayerStyle(layer);
        // Only trigger an update if the textContent on the layer uses the given attribute
        if (
            "textContent" in style &&
            style.textContent.extractionMethod === StyleValueExtractionMethod.Multi &&
            style.textContent.multiValues?.some((v) => v.dataFieldId === attributeId)
        ) {
            // By calling update style, the text content will refetch the list items and apply the appropriate text content
            this.updateStyle<typeof style>(layerId, style.styleType, [{ styleProperty: "textContent", value: style.textContent }]);
        }

        if ("height" in style && style.height.extractionMethod === StyleValueExtractionMethod.MappedProperty && style.height.dataFieldId === attributeId) {
            this.updateStyle<typeof style>(layerId, style.styleType, [{ styleProperty: "height", value: style.height }]);
        }
    }

    /** Updates drawing modifier (i.e. feature "snapping") */
    public setDrawingModifier(modifier: DrawingModifier) {
        this.sendEventToStore({ eventType: MapStoreEventType.SET_DRAWING_MODIFIER, payload: modifier });
        this.coordinateHandles?.setDrawingModifier(modifier);
        this.composition?.setDrawingModifier(modifier);
        this.objectDrag?.setDrawingModifier(modifier);
        this.fixedShape?.setDrawingModifier(modifier);
        if (modifier === "snapping") {
            this.addSnapIndicatorLayer();
        } else {
            this.removeSnapIndicatorLayer();
        }
    }

    public destroy() {
        this.removeMidpointHandles();
        this.removeCoordinateDelete();
        this.removeContinueDrawingHandle();
        this.removeCompositionBehaviour();
        this.removeReadBehaviour();
        this.removeRotationHandle();
        this.removeRouteControls();
        this.removeOnMapLoadedListener();
        this.eventStream.destroy();
        this.machine.stop();
        this.storeEvents.complete();
        this.compositionUndoRedo.destroy();
        this.onDestroy.next(undefined);
        this.onDestroy.complete();
    }
}

export function getRemoteLayerId(layerId: string) {
    return layerId.replace(`_${localSuffix}`, "");
}

export function getCentroidLayerID(layerId: string) {
    if (layerId.includes(`_${localSuffix}`)) {
        return `${layerId.replace(`_${localSuffix}`, "")}_centroid_${localSuffix}`;
    }
    return `${layerId}_centroid`;
}

export function removeAdditionalLayerSuffixes(layerId: string) {
    return layerId.replace("_centroid", "");
}

export function hasLocalSuffix(id: string) {
    return id.includes("_local");
}

export function removeLayerIdSuffixes(layerId: string): string {
    return layerId.replace(`_${localSuffix}`, "").replace(`_${highlightInfix}`, "");
}

export function doesRequireHighlight(layer: MapModuleLayer) {
    return layer.styleType !== StyleType.Model;
}

/**
 * Given the previous map state layers and the current map state layers the function will work out what layers have been added and removed
 * @param {MapModuleLayer[]} newLayers - the map layers in the current state
 * @param {MapModuleLayer[]} previousLayers - the map layers from the previous state
 * @returns { addedIds: string[]; removedIds: string[] } - an array of layer ids which have been added and an array of layer ids which have been removed
 */
export function findDifferingLayers(newLayers: MapModuleLayer[], previousLayers: MapModuleLayer[]): { addedIds: string[]; removedIds: string[] } {
    // get layer ids for both states
    const newLayerIds = newLayers.map((layer) => layer.id);
    const oldLayerIds = previousLayers.map((layer) => layer.id);

    // get layer which are present from both states
    const lastingLayerIds = newLayerIds.filter((l) => oldLayerIds.includes(l));

    // compare lasting layer ids to the two current states of layer ids
    const removedIds = oldLayerIds.filter((l) => !lastingLayerIds.includes(l));
    const addedIds = newLayerIds.filter((l) => !lastingLayerIds.includes(l));
    return { addedIds, removedIds };
}

/*
 * Create a template for a layer. IMPORTANT: Override storage scope
 * @returns The layer
 */
export function createDefaultIventisLayer(): MapModuleLayer {
    return {
        id: null,
        name: null,
        // Storage scope should be overwritten by calling code
        storageScope: undefined,
        visible: null,
        styleType: null,
        areaStyle: null,
        lineStyle: null,
        pointStyle: null,
        iconStyle: null,
        modelStyle: null,
        lineModelStyle: null,
        dataFields: null,
        stamp: "",
        selected: null,
        source: null,
        drawingControls: null,
        locked: false,
    };
}
