import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";

export function isStyleValueMappedProperty<T>(styleValue: StyleValue<T>): boolean {
    if (styleValue == null) {
        return false;
    }

    return styleValue.extractionMethod === StyleValueExtractionMethod.MappedProperty;
}
