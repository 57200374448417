import type { BehaviorSubject } from "rxjs";
import type { AnyLayerStyle, StylePropertyToValueMap } from "../../types/style-types";
import type { MapState, MapboxEngineData } from "../../types/store-schema";

export abstract class Layer<TSource, TStyle extends AnyLayerStyle> {
    protected abstract layerId: string;

    protected abstract sourceId: string;

    protected readonly state$: BehaviorSubject<MapState<MapboxEngineData>>;

    protected removed = false;

    constructor(state$: BehaviorSubject<MapState<MapboxEngineData>>) {
        this.state$ = state$;
    }

    protected abstract addLayer(): void;

    protected abstract removeLayer(): void;

    protected abstract addSource(): void;

    protected abstract updateSource(source: TSource): void;

    protected abstract removeSource(): void;

    protected abstract updateStyle(changes: StylePropertyToValueMap<TStyle>[]): void;

    protected abstract hideLayer(): void;

    protected abstract showLayer(): void;

    protected abstract updateMapOrder(aboveLayerId: string): void;

    protected abstract updateMapLevel(level: number): void;

    public getIventisLayer() {
        return this.state$.value.layers.value.find((layer) => layer.id === this.layerId);
    }

    public getGeoJson(): TSource | null {
        return this.state$.value.geoJSON.value[this.layerId]?.map(({ feature }) => feature) as TSource;
    }

    public remove() {
        this.removed = true;
        this.removeLayer();
        this.removeSource();
    }
}
