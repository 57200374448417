import { Color, MeshStandardMaterial, TextureLoader } from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/Addons.js";
import { hexToRgb } from "@iventis/utilities/src/svg/svg";

/**
 * Loads a model using ThreeJS GLTF Loader
 * Useful when we want to customise the model on the fly
 */
export async function loadModel(modelUrl: string) {
    try {
        const loader = new GLTFLoader();
        const model = await loader.loadAsync(modelUrl);
        return model;
    } catch {
        return null;
    }
}

/** Takes an array buffer and parses it to a ThreeJS Model */
export async function parseModelArrayBuffer(modelArrayBuffer: ArrayBuffer) {
    try {
        const loader = new GLTFLoader();
        const model = await loader.parseAsync(modelArrayBuffer, "");
        return model;
    } catch {
        return null;
    }
}

/**
 * Loads an image as a texture which can be added to a model
 */
export async function loadImageAsTexture(imageUrl: string) {
    const loader = new TextureLoader();
    const loadedImageTexture = await loader.loadAsync(imageUrl);
    // Prevent upside-down texture issue
    loadedImageTexture.flipY = false;
    loadedImageTexture.needsUpdate = true;
    return loadedImageTexture;
}

/**
 * Converts a ThreeJS model to a url which can be used in Mapbox's addModel function to load into a map
 */
export async function convertThreeJSModelToBlob(model: GLTF) {
    const exporter = new GLTFExporter();
    const parsedModel = await exporter.parseAsync(model.scene, {
        binary: true,
    });

    if (!(parsedModel instanceof ArrayBuffer)) {
        throw new Error("Parsed model was not an instance of Array Buffer, can't convert.");
    }

    return new Blob([parsedModel]);
}

/** Creates a mesh from a given colour */
export function createMeshFromColour(colour: string) {
    const mesh = new MeshStandardMaterial();
    const rgbColour = hexToRgb(colour);
    mesh.color = rgbColour ? new Color().setRGB(rgbColour.r / 255, rgbColour.g / 255, rgbColour.b / 255) : new Color(1, 1, 1);
    return mesh;
}
