import { DataField } from "@iventis/domain-model/model/dataField";
import {
    AssetPickerComponent,
    AssetPreviewButton,
    AutocompleteWithLoading,
    CursorTooltip,
    CustomDialog,
    FormButtonsComponent,
    InfoTooltipHoverComponent,
} from "@iventis/components";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { DialogActions as DialogActionsMUI, DialogContent as DialogContentMUI, DialogTitle as DialogTitleMUI, Divider, Switch } from "@mui/material";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import React, { PropsWithChildren, useContext, useMemo, useState, useRef, useEffect } from "react";
import { ModelDimensionHeaders, ModelSizingComponent } from "@iventis/components/src/model-sizing-component";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { Body1, Body2, Header3, formPadding, muiInputFormsCSS, sectionalMargin, styled, Header4 } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { dimensionValidation } from "@iventis/components/src/model-sizing-component-state";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { getModelQueryKey, useAssetSignatureCache } from "@iventis/utilities";
import { Asset } from "@iventis/domain-model/model/asset";
import { useQuery } from "@tanstack/react-query";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { Model } from "@iventis/domain-model/model/model";
import { ModelLayerStyle } from "@iventis/map-engine";
import { getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { isStyleValueMappedProperty } from "@iventis/layer-style-helpers/src/style-value-mapped-property-helpers";
import {
    canModelDimensionsBeStyledByProperties,
    getDataFieldListItemPropertyForModelDimension,
    getDataFieldListItemPropertyValueForModelDimension,
} from "@iventis/datafields/src/datafield-property-based-styling";
import { AttributeSelector } from "./create-formula";
import {
    ListItemToModelDimension,
    convertListItemsToModelDimensions,
    convertModelDimensionsToStyleValues,
    getListItemsUsedInStylesDimensions,
    STATIC_LIST_ITEM_KEY,
} from "./model-attribute-style-helpers";
import { EditStyleContext } from "./edit-style-context";
import { ModelWithThumbnail } from "./model-edit-style";
import { MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED, useListItemsForFormula } from "./data-driven-styles.helpers";

export type ModelAttributeBasedModalStatus = "dimensions" | "model" | "hidden";

export const ModelAttributeStyles = ({
    dataFields,
    onClose,
    onConfirm,
    style,
    selectedDataFieldId,
    modelsWithThumbnails,
    type,
    getModel,
}: {
    dataFields: DataField[];
    onClose: () => void;
    onConfirm: (values: { height: StyleValue<number>; length: StyleValue<number>; width: StyleValue<number> }) => void;
    style: ModelLayerStyle;
    selectedDataFieldId?: string;
    modelsWithThumbnails: ModelWithThumbnail[];
    type: ModelAttributeBasedModalStatus;
    getModel?: (modelId: string) => Promise<Model>;
}) => {
    const translate = useIventisTranslate();

    const [selectedDataField, setSelectedDataField] = useState<DataField | null>(dataFields.find((df) => df.id === selectedDataFieldId));
    // Contains datafield list item id to a width, height and length combination
    const [dataFieldIdToDimensionMap, setDataFieldIdToDimensionMap] = useState<ListItemToModelDimension>({});

    const [styleByProperty, setStyleByProperty] = useState(isStyleValueMappedProperty(style.height));

    const listOnlyDataFields = useMemo(() => dataFields.filter((df) => df.type === DataFieldType.List), [dataFields]);

    const selectedDataFieldChange = (dataField: DataField) => {
        setSelectedDataField(dataField);
        setStyleByProperty(false);
    };

    const [showModelSelector, setShowModelSelector] = useState<string>(null);

    const handleOnConfirm = () => {
        onConfirm(convertModelDimensionsToStyleValues(dataFieldIdToDimensionMap, selectedDataField, type === "model", styleByProperty));
    };

    const handleUpdatedModel = (model: ModelWithThumbnail, listItemId: string) => {
        const listItem = listItems.find((value) => value.id === listItemId);
        const height = styleByProperty ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "height", model.height) : model.height;
        const width = styleByProperty ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "width", model.width) : model.width;
        const length = styleByProperty ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "length", model.length) : model.length;

        setDataFieldIdToDimensionMap({
            ...dataFieldIdToDimensionMap,
            [listItemId]: {
                modelId: model.id,
                modelName: model.name,
                thumbnailUrl: model.thumbnailUrl,
                dimensions: !(model.height === 0 || model.height == null),
                height: height ?? 0,
                width: width ?? 0,
                length: length ?? 0,
            },
        });
        setShowModelSelector(null);
    };

    /** Once list items are loaded we can populate the user interface with then selected list items */
    const onListItemsLoaded = (listItems: DataFieldListItem[]) => {
        if (listItems != null) {
            // Get the list items we are doing data driven styling with
            const isPropertyBasedStyling = isStyleValueMappedProperty(style.height);
            const useAllListItems = listItems.length < MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED;
            if (useAllListItems) {
                setDataFieldIdToDimensionMap(convertListItemsToModelDimensions(style, modelsWithThumbnails, type === "model", isPropertyBasedStyling, listItems));
            } else {
                const listItemIds = isPropertyBasedStyling ? style.height.mappedPropertyListItemIds : getListItemsUsedInStylesDimensions(style);
                const listItemsToUse = listItems.filter((listItem) => listItemIds.includes(listItem.id));
                setDataFieldIdToDimensionMap(convertListItemsToModelDimensions(style, modelsWithThumbnails, type === "model", isPropertyBasedStyling, listItemsToUse));
            }
        }
    };

    // Given an array of list items, if the list item has no mapped value present, it will add the default static value as its' mapped value ready for the user to go and edit
    const addListItemsToStyleMapping = (listItems: DataFieldListItem[]) => {
        const modelId = getStaticStyleValueFromMapped(style.model);
        const model = modelsWithThumbnails.find((model) => model.id === modelId);
        setDataFieldIdToDimensionMap(
            listItems.reduce((updatedListItemWithModels, listItem) => {
                const doesExist = updatedListItemWithModels[listItem.id] != null;
                // If it does not exist we know we are adding a new list item
                if (!doesExist) {
                    // Get dimensions, if property value is null then use the model dimensions as fallback
                    const height = styleByProperty
                        ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "height", model.height)
                        : getStaticStyleValueFromMapped(style.height);
                    const width = styleByProperty
                        ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "width", model.width)
                        : getStaticStyleValueFromMapped(style.width);
                    const length = styleByProperty
                        ? getDataFieldListItemPropertyValueForModelDimension(selectedDataField, listItem, "length", model.length)
                        : getStaticStyleValueFromMapped(style.length);
                    return {
                        ...updatedListItemWithModels,
                        [listItem.id]: {
                            modelId,
                            height,
                            width,
                            length,
                            modelName: model.name,
                            thumbnailUrl: model.thumbnailUrl,
                            dimensions: !(model?.height == null || model?.height === 0),
                        },
                    };
                }
                return updatedListItemWithModels;
            }, dataFieldIdToDimensionMap)
        );
    };

    const handleStyleByPropertyChange = () => {
        const updatedValue = !styleByProperty;
        setStyleByProperty(updatedValue);
        // If we are syncing the dimensions with the property values
        if (updatedValue) {
            const heightProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "height");
            const widthProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "width");
            const lengthProperty = getDataFieldListItemPropertyForModelDimension(selectedDataField, "length");
            const updatedMap = { ...dataFieldIdToDimensionMap };
            Object.entries(dataFieldIdToDimensionMap).forEach(([listItemId, listItemModelData]) => {
                const listItem = listItems.find((value) => value.id === listItemId);
                if (listItem) {
                    // If the property value for a dimension is null then use the model dimension value
                    const heightValue = listItem.propertyValues.find((value) => value.propertyId === heightProperty.id)?.number ?? listItemModelData.height;
                    const widthValue = listItem.propertyValues.find((value) => value.propertyId === widthProperty.id)?.number ?? listItemModelData.width;
                    const lengthValue = listItem.propertyValues.find((value) => value.propertyId === lengthProperty.id)?.number ?? listItemModelData.length;
                    updatedMap[listItemId] = {
                        ...listItemModelData,
                        height: heightValue,
                        width: widthValue,
                        length: lengthValue,
                    };
                }
            });
            setDataFieldIdToDimensionMap(updatedMap);
        }
    };

    const { data: listItems, isLoading: isLoadingListItems } = useListItemsForFormula(selectedDataField, onListItemsLoaded, Infinity);

    const canStyleDimensionsByProperties = useMemo(() => canModelDimensionsBeStyledByProperties(selectedDataField), [selectedDataField]);

    return (
        <StyledCustomDialog open maxWidth="xl" onClose={onClose}>
            {showModelSelector != null ? (
                <ModelAttributePicker
                    close={() => setShowModelSelector(null)}
                    selectedModelId={modelsWithThumbnails[0]?.thumbnailAssetId}
                    handleModelUpdate={(model) => handleUpdatedModel(model, showModelSelector)}
                    getModel={getModel}
                />
            ) : (
                <ModelAttributeStyleValues
                    dataFieldIdToDimensionMap={dataFieldIdToDimensionMap}
                    setDataFieldIdToDimensionMap={setDataFieldIdToDimensionMap}
                    type={type}
                    handleConfirm={handleOnConfirm}
                    onClose={onClose}
                    setShowModelSelector={(listItemId) => setShowModelSelector(listItemId)}
                    loading={isLoadingListItems}
                    selectedDataField={selectedDataField}
                    listItems={listItems}
                    disableModelDimensions={styleByProperty}
                >
                    <AttributeSelector
                        dataFields={listOnlyDataFields}
                        selectedDataField={selectedDataField}
                        setDataField={selectedDataFieldChange}
                        label={type === "model" ? translate(Content.map6.styles.models.determineModel) : translate(Content.map6.styles.models.determineDimensions)}
                    />
                    {!isLoadingListItems && listItems.length > MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED && (
                        <AutocompleteWithLoading
                            placeholder={translate(Content.map.data_fields.data_driven_formula.searchListItem)}
                            label={translate(Content.map.data_fields.data_driven_formula.addListItem)}
                            loadingOptions={false}
                            onChange={(listItem) => addListItemsToStyleMapping([listItem])}
                            options={listItems != null ? listItems.filter((l) => dataFieldIdToDimensionMap[l.id] == null) : []}
                            value={null}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            className="listItemSearch"
                        />
                    )}
                    <StyleByPropertySwitchContainer>
                        <Switch
                            data-testid="style-model-dimensions-by-properties"
                            disabled={isLoadingListItems || !canStyleDimensionsByProperties}
                            checked={styleByProperty}
                            onChange={handleStyleByPropertyChange}
                        />
                        <StyleByPropertySwitchText disabled={!canStyleDimensionsByProperties} className="switch-text">
                            {translate(Content.map10.styleByProperty)}
                        </StyleByPropertySwitchText>
                        <InfoTooltipHoverComponent
                            className="tooltip-button"
                            placement="right"
                            component={
                                <Tooltip>
                                    <Body2>
                                        {translate(Content.map10.styleByPropertyTooltip)}{" "}
                                        <a target="_blank" rel="noreferrer" href="https://support.iventis.com/use-your-list-attribute-to-set-the-size/dimensions-of-3d-models">
                                            {translate(Content.map10.styleByPropertyTooltipLink)}
                                        </a>
                                    </Body2>
                                </Tooltip>
                            }
                        />
                    </StyleByPropertySwitchContainer>
                </ModelAttributeStyleValues>
            )}
        </StyledCustomDialog>
    );
};

const ModelAttributePicker = ({
    close,
    selectedModelId,
    handleModelUpdate,
    getModel,
}: {
    close: () => void;
    selectedModelId: string;
    handleModelUpdate: (asset: ModelWithThumbnail) => void;
    getModel?: (modelId: string) => Promise<Model>;
}) => {
    const imageGetter = useAssetSignatureCache();
    const { assetService, getModels } = useContext(EditStyleContext);
    const translate = useIventisTranslate();
    const { data: models } = useQuery([getModelQueryKey], () => getModels());

    const onAssetSelected = async (modelThumbnail: Asset) => {
        const model = models.find((model) => model.thumbnailAssetId === modelThumbnail.id);
        handleModelUpdate({ ...model, thumbnailUrl: imageGetter(modelThumbnail.assetUrl, modelThumbnail.authoritySignature) });
    };

    return (
        <AssetPickerComponent
            assetType={AssetType.Model}
            currentAssetId={selectedModelId}
            onAssetSelected={onAssetSelected}
            imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
            selectorDescription={translate(Content.map2.styles2.choose_model)}
            close={() => close()}
            getAsset={assetService.getAsset}
            getAssetTags={assetService.getAssetTags}
            getAssetsByType={assetService.getAssetsByType}
            suppressInfiniteFetching={!assetService.assetPagination}
            postIcon={assetService.postIcon}
            patchIcon={assetService.patchIcon}
            postModel={assetService.postModel}
            canEditAsset={assetService.canEditAsset}
            deleteAsset={assetService.deleteAsset}
            hideAssetActions={assetService.hideAssetSelectorActions}
            getModel={getModel}
            updateModel={assetService.updateModel}
        />
    );
};

export const ModelAttributeStyleValues = ({
    dataFieldIdToDimensionMap,
    setDataFieldIdToDimensionMap,
    type,
    handleConfirm,
    onClose,
    setShowModelSelector,
    loading,
    selectedDataField,
    children,
    listItems,
    disableModelDimensions,
}: PropsWithChildren<{
    dataFieldIdToDimensionMap: ListItemToModelDimension;
    setDataFieldIdToDimensionMap: (value: ListItemToModelDimension) => void;
    selectedDataField: DataField;
    type: ModelAttributeBasedModalStatus;
    onClose: () => void;
    handleConfirm: () => void;
    setShowModelSelector: (listItemId: string) => void;
    loading: boolean;
    listItems: DataFieldListItem[];
    disableModelDimensions: boolean;
}>) => {
    const translate = useIventisTranslate();

    const handleValueChanged = (value: number, listItemId: string, property: "height" | "width" | "length") => {
        setDataFieldIdToDimensionMap({ ...dataFieldIdToDimensionMap, [listItemId]: { ...dataFieldIdToDimensionMap[listItemId], [property]: value } });
    };

    const isValid = useMemo(
        () =>
            Object.values(dataFieldIdToDimensionMap).every((value) =>
                value.dimensions ? dimensionValidation({ height: value.height, length: value.length, width: value.width }) : true
            ) && selectedDataField != null,
        [dataFieldIdToDimensionMap, selectedDataField]
    );

    return (
        <>
            {/* Component is a non-typed prop for MUI DialogTitle - https://stackoverflow.com/questions/70441304/how-do-i-make-the-mui-dialog-title-an-h1-element-so-the-modal-is-accessible */}
            <DialogTitle {...{ component: "div" }}>
                <Header3>{translate(Content.map.data_fields.data_driven_formula.title)}</Header3>
                {type === "model" ? (
                    <Body2>{translate(Content.map6.styles.models.attributeModel)}</Body2>
                ) : (
                    <Body2>{translate(Content.map6.styles.models.attributeDimensions)}</Body2>
                )}
            </DialogTitle>
            <Divider />
            <ModelAttributeBasedDimensionContainer type={type}>
                {children}
                {/* List all list items with a set of dimensions and at the bottom have the default value */}
                {selectedDataField != null && !loading && (
                    <ListItemContainer type={type}>
                        <div className="row">
                            <Header4 className="item header">{translate(Content.map.data_fields.list_item)}</Header4>
                            {type === "model" && <Header4 className="item center-text header">{translate(Content.map2.styles2.model)}</Header4>}
                            <ModelDimensionHeaders className="item center-text header" />
                        </div>
                        {Object.entries(dataFieldIdToDimensionMap)
                            .sort(([listItemId]) => (listItemId === STATIC_LIST_ITEM_KEY ? 1 : -1))
                            .reduce((acc, [listItemId, value]) => {
                                const listItem =
                                    listItemId === STATIC_LIST_ITEM_KEY
                                        ? ({ id: listItemId, name: translate(Content.map2.data_driven.any_others) } as DataFieldListItem)
                                        : listItems.find((value) => value.id === listItemId);
                                if (listItem == null) {
                                    return acc;
                                }
                                const hasDimensions = type === "dimensions" || value?.dimensions;
                                return [
                                    ...acc,
                                    <div className="row" data-testid="model-attribute-row" key={listItemId}>
                                        <ListItemNameComponent listItemName={listItem.name} />
                                        {type === "model" && (
                                            <AssetPreviewButton
                                                className="item"
                                                img={value.thumbnailUrl}
                                                name={value.modelName}
                                                onClick={() => setShowModelSelector(listItemId)}
                                                selectorDescription={translate(Content.map2.styles2.choose_model)}
                                                testId={`${listItem.name}-model-selector`}
                                            />
                                        )}
                                        {hasDimensions && (
                                            <ModelSizingComponent
                                                selectorContainerClassName="dimension-input"
                                                values={value}
                                                onValueChanged={(property, value) => handleValueChanged(value, listItemId, property)}
                                                // Allow for "Any others" to be changed all the time
                                                disabled={disableModelDimensions && listItemId !== STATIC_LIST_ITEM_KEY}
                                            />
                                        )}
                                    </div>,
                                ];
                            }, [])}
                    </ListItemContainer>
                )}
            </ModelAttributeBasedDimensionContainer>
            <Divider />
            <DialogActions>
                <FormButtonsComponent
                    dataTestId="submit-data-driven-model-changes"
                    handleSubmit={handleConfirm}
                    submitButtonText={<span data-testid="formula-submit-button">{translate(Content.common.buttons.submit)}</span>}
                    secondaryButtons={[{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: onClose }]}
                    disableSubmit={() => !isValid}
                />
            </DialogActions>
        </>
    );
};

const ListItemNameComponent = ({ listItemName }: { listItemName: string }) => {
    const nameContainerRef = useRef<HTMLDivElement>();
    const [showNameTooltip, setShowNameTooltip] = useState(false);
    useEffect(() => {
        // Work out on mount if the name is too long to fit in the container
        setShowNameTooltip(nameContainerRef.current?.clientWidth < nameContainerRef.current?.scrollWidth);
    }, []);

    return (
        <CursorTooltip text={listItemName} disabled={!showNameTooltip}>
            <Body1 className="item list-item-name" ref={nameContainerRef}>
                {listItemName}
            </Body1>
        </CursorTooltip>
    );
};

const StyledCustomDialog = styled(CustomDialog)`
    .MuiPaper-root {
        overflow: hidden;
    }
`;

export const DialogTitle = styled(DialogTitleMUI)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .subtitle {
        ${({ theme }: { theme: Theme }) => theme.shades.two}
    }
`;

const ModelAttributeBasedDimensionContainer = styled(DialogContentMUI)<{ type: ModelAttributeBasedModalStatus }>`
    ${muiInputFormsCSS};
    scrollbar-gutter: stable both-edges;
    display: flex;
    flex-direction: column;
    width: ${({ type }) => (type === "model" ? "900px" : "700px")};
    height: 500px;
    padding: ${formPadding};
    // By default MUI dialog has a padding override, so need to explicitly set padding for top
    padding-top: ${formPadding};
    gap: ${sectionalMargin};
`;

const DialogActions = styled(DialogActionsMUI)`
    margin: 20px;
`;

const ListItemContainer = styled.div<{ type: ModelAttributeBasedModalStatus }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 100%;

    .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .item {
        width: ${({ type }) => (type === "model" ? "calc(20% - 10px)" : "calc(25% - 10px)")};
    }

    .list-item-name {
        height: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dimension-input {
        display: flex;
        justify-content: center;
        width: ${({ type }) => (type === "model" ? "calc(20% - 10px)" : "calc(25% - 10px)")};
        position: relative;
    }

    .center-text {
        text-align: center;
    }

    .header {
        font-weight: 500;
    }
`;

const StyleByPropertySwitchContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    position: relative;

    // Can't align the switch very easily due to Mui, therefore make position absolute and make left the same as the modal padding
    .MuiSwitch-root {
        position: absolute;
        left: -10px;
    }

    .tooltip-button {
        margin-top: 1px;
    }
`;

const StyleByPropertySwitchText = styled.span<{ disabled: boolean }>`
    // As switch is positioned by absolute we have to add a margin to position the element
    margin-left: 50px;
    position: relative;
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const Tooltip = styled.div`
    font-weight: 400;
    padding: 10px;
    max-width: 400px;

    a {
        font-weight: 400;
        color: ${({ theme }) => theme.typographyColors.blank};
        text-decoration: underline;
    }
`;
