import { FunctionComponent } from "react";
import { useIventisTranslate, UseIventisTranslate } from "./use-iventis-translate";

let translateHookResult: UseIventisTranslate;

export const TranslationsComponent: FunctionComponent = () => {
    // Set translateHookResult to this hook so it can be accessed outside of react components, for example an api interceptor
    translateHookResult = useIventisTranslate();
    return null;
};

export const translate: UseIventisTranslate = (...params) => {
    if (typeof translateHookResult !== "function") {
        // eslint-disable-next-line no-console
        console.error("Could not find translation hook. Was TranslationsComponent initialised? Returning input.");
        return params[0];
    }
    return translateHookResult<typeof params[0]>(...params);
};
