import React, { forwardRef, FunctionComponent, ReactNode, useContext, useImperativeHandle, useState } from "react";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { EMPTY_GUID, isValidUuid } from "@iventis/utilities";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Status } from "@iventis/domain-model/model/status";
import { RemoteUploadContext } from "@iventis/remote-upload";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { useMutation } from "@tanstack/react-query";
import { FormWizardTemplate } from "@iventis/components/src/form-wizard-template";
import { ConfirmationDialogComponent } from "@iventis/components/src/confirmation-dialog";
import { CustomDialog, StyledCustomDialog } from "@iventis/components/src/custom-dialog";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { SitemapVersionWizard } from "./sitemap-version-form";
import { SitemapForm } from "./sitemap-form";
import { SitemapContext } from "./sitemap-context";
import { TileUploader } from "./sitemap-tile-uploader";
import { SitemapVersionLevelWithFiles } from "./sitemap-types";
import { hasSitemapChanged } from "./sitemap-helpers";
import { LevelReassignmentForm, SitemapVersionLevelReassignment } from "./sitemap-version-level-reassignment-form";

const emptySitemap: Sitemap = {
    id: EMPTY_GUID,
    tag: "",
    name: "",
    colour: "pink",
    versions: [],
    projectId: undefined,
    status: Status.Active,
};

enum SitemapWizardStage {
    SitemapForm = 0,
    SitemapVersionForm = 1,
    UploadingTiles = 2,
}

type SitemapWizardState = {
    stage: SitemapWizardStage;
    sitemapOnServer: Sitemap;
    sitemap: Sitemap;
    sitemapVersion?: SitemapVersion;
    levelsWithTilesToUpload?: SitemapVersionLevelCreateResponse[];
    deleteConfirmation?: { type: "version"; name: string; id: string; isDeleting: boolean; levelsWithNoMatchingIndexes: SitemapVersionLevel[] };
};

type SitemapWizardEvents =
    | { type: "ADD_VERSION_REQUESTED" }
    | { type: "EDIT_VERSION_REQUESTED"; version: SitemapVersion }
    | { type: "DELETE_VERSION_REQUESTED"; version: SitemapVersion }
    | { type: "DELETE_VERSION"; id: string; levelReassignments: SitemapVersionLevelReassignment[] }
    | { type: "BACK" }
    | { type: "SITEMAP_VERSION_SAVED"; version: SitemapVersion }
    | { type: "SITEMAP_VERSION_LEVELS_SAVED"; version: SitemapVersion; tileUploads?: (SitemapVersionLevelCreateResponse & SitemapVersionLevelWithFiles)[] }
    | { type: "FINISH_UPLOADS" }
    | { type: "SET_SITEMAP"; payload: Sitemap | ((sitemap: Sitemap) => Sitemap) }
    | { type: "SET_SITEMAP_ON_SERVER"; payload: Sitemap }
    | { type: "CANCEL_DELETION" };

export type SitemapFormRef = { isUploadingTiles(): boolean; getSitemapVersions(): SitemapVersion[] };

/** Create or edit a sitemap */
export const SitemapFormWizard = forwardRef<SitemapFormRef, { sitemap?: Sitemap; saveAndClose(sitemap: Sitemap): void; save?: (sitemap: Sitemap) => void; close: () => void }>(
    ({ sitemap: _existingSitemap, saveAndClose, save, close }, ref) => {
        const translate = useIventisTranslate();
        const sitemapServices = useContext(SitemapContext);
        const [{ stage, sitemap, ...state }, send] = useSitemapFormWizardState(_existingSitemap ?? emptySitemap);
        // If we know a sitemap exists on the server, we are in edit mode
        const action = state.sitemapOnServer == null ? "create" : "edit";

        const hasSitemapUpdated = hasSitemapChanged(state.sitemapOnServer, sitemap);

        const { isLoading: isUpsertingSitemap, mutateAsync: upsertSitemap } = useMutation({
            mutationFn: async (sitemap: Sitemap) => {
                let remoteSitemap = { ...sitemap };
                if (action === "edit") {
                    await sitemapServices.updateSitemap(sitemap);
                    // If we are editing, just close it after saving
                    saveAndClose({ ...sitemap, id: remoteSitemap.id });
                } else {
                    remoteSitemap = await sitemapServices.createSitemap(sitemap);
                    // Do not close because they probably want to add versions after creating a sitemap
                    save(remoteSitemap);
                    // Set the latest sitemap as it has now been assigned an id from the server
                    send({ type: "SET_SITEMAP", payload: remoteSitemap });
                    // Set the sitemapOnServer so we know a sitemap exists on the server and we are now in edit mode
                    send({ type: "SET_SITEMAP_ON_SERVER", payload: remoteSitemap });
                }
            },
        });

        const finish = () => {
            if (hasSitemapUpdated) {
                upsertSitemap(sitemap);
            } else {
                saveAndClose(sitemap);
            }
        };

        useImperativeHandle(ref, () => ({
            isUploadingTiles() {
                return stage === SitemapWizardStage.UploadingTiles;
            },
            getSitemapVersions() {
                return sitemap.versions;
            },
        }));

        return (
            <FormWizardTemplate
                currentStage={stage}
                stages={[
                    {
                        primaryButtonText: translate(Content.common.buttons[hasSitemapUpdated ? "save" : "finish"]),
                        primaryButtonCallback: finish,
                        showLoadingSpinner: isUpsertingSitemap,
                        secondaryButtons: [{ buttonText: translate(Content.common.buttons.close), onButtonPressed: close }],
                        Component: (
                            <>
                                <SitemapForm
                                    allowVersions={action === "edit"}
                                    sitemap={sitemap}
                                    setSitemap={(s) => send({ type: "SET_SITEMAP", payload: s })}
                                    addVersion={() => send({ type: "ADD_VERSION_REQUESTED" })}
                                    editVersion={(sitemapVersion) => send({ type: "EDIT_VERSION_REQUESTED", version: sitemapVersion })}
                                    deleteVersion={(version) => send({ type: "DELETE_VERSION_REQUESTED", version })}
                                />
                                {/* If there are levels which need re-assigned, show the reassignment dialog before deletion */}
                                <StyledCustomDialog
                                    maxWidth="xs"
                                    open={state.deleteConfirmation?.type === "version" && state.deleteConfirmation?.levelsWithNoMatchingIndexes?.length > 0}
                                    onClose={() => send({ type: "CANCEL_DELETION" })}
                                >
                                    <LevelReassignmentForm
                                        availableSitemapVersions={sitemap.versions.filter((v) => v.id !== state.deleteConfirmation?.id)}
                                        existingLevelsRemoved={state.deleteConfirmation?.levelsWithNoMatchingIndexes ?? []}
                                        confirm={(levelReassignments) => send({ type: "DELETE_VERSION", id: state.deleteConfirmation?.id, levelReassignments })}
                                        close={() => send({ type: "CANCEL_DELETION" })}
                                    />
                                </StyledCustomDialog>
                                {/* Else, just show confirmation dialog before deletion */}
                                <ConfirmationDialogComponent
                                    title={`${translate(Content.common.buttons.delete)} ${state.deleteConfirmation?.name}`}
                                    confirmText={translate(Content.common.buttons.confirm)}
                                    cancelText={translate(Content.common.buttons.cancel)}
                                    message={translate(Content.common.delete_confirmation_message, { entity: state.deleteConfirmation?.name })}
                                    handleCancel={() => send({ type: "CANCEL_DELETION" })}
                                    handleConfirm={() => send({ type: "DELETE_VERSION", id: state.deleteConfirmation?.id, levelReassignments: [] })}
                                    show={state.deleteConfirmation?.type === "version" && !state.deleteConfirmation?.levelsWithNoMatchingIndexes?.length}
                                    isSubmitting={state.deleteConfirmation?.isDeleting}
                                />
                            </>
                        ),
                        title: translate(Content.sitemaps.sitemap.title[action]),
                    },
                    {
                        // Sitemap version form has it's own wizard template
                        replaceTemplateWithComponent: true,
                        Component: (
                            <SitemapVersionWizard
                                key={state.sitemapVersion?.id}
                                sitemapId={sitemap.id}
                                existingSitemapVersion={state.sitemapVersion}
                                cancel={() => send({ type: "BACK" })}
                                onSitemapVersionUpserted={(sitemapVersion) => send({ type: "SITEMAP_VERSION_SAVED", version: sitemapVersion })}
                                onSitemapVersionLevelsUpdated={(sitemapVersion, tileUploads) =>
                                    send({ type: "SITEMAP_VERSION_LEVELS_SAVED", version: sitemapVersion, tileUploads })
                                }
                            />
                        ),
                    },
                    {
                        primaryButtonCallback: () => null,
                        title: translate(Content.sitemaps.sitemapVersion.uploading_tiles, { level: translate(Content.sitemaps.sitemapVersion.tiles)?.toLocaleLowerCase() }),
                        showLoadingSpinner: true,
                        secondaryButtons: [],
                        Component: <TileUploader uploadRequests={state.levelsWithTilesToUpload} finish={() => send({ type: "FINISH_UPLOADS" })} />,
                    },
                ]}
            />
        );
    }
);

/** Standard modal with consistent style for sitemap form. Pass the sitemap form as a child */
export const SitemapModal: FunctionComponent<{ open: boolean; onClose: () => void; children: ReactNode }> = ({ open, onClose, children }) => (
    <CustomDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        {children}
    </CustomDialog>
);

/** This hook manages the state in our sitemap form wizard (such as which stage we're on, the sitemap we're working on etc). Returms the state, and a send function which lets the outside update the state through events */
const useSitemapFormWizardState = (initialSitemap: Sitemap) => {
    const remoteUploadContext = useContext(RemoteUploadContext);
    const sitemapServices = useContext(SitemapContext);
    const [state, setState] = useState<SitemapWizardState>({
        stage: SitemapWizardStage.SitemapForm,
        // If the id is
        sitemapOnServer: isValidUuid(initialSitemap?.id) && initialSitemap.id !== EMPTY_GUID ? initialSitemap : null,
        sitemap: initialSitemap,
        sitemapVersion: undefined,
        levelsWithTilesToUpload: undefined,
        deleteConfirmation: null,
    });

    /** Update the sitemap versions on the sitemap */
    const upsertSitemapVersion = (sitemapVersion: SitemapVersion, overwriteLevels: boolean) => {
        const versionLevelsOnServer = overwriteLevels ? sitemapVersion.sitemapVersionLevels : state.sitemapVersion?.sitemapVersionLevels;
        const versions = [...state.sitemap.versions];
        const index = state.sitemap.versions.findIndex((version) => version.id === sitemapVersion.id);
        if (index === -1) {
            versions.push(sitemapVersion);
        } else {
            versions[index] = sitemapVersion;
        }
        setState((s) => ({
            ...s,
            sitemap: { ...s.sitemap, versions },
            sitemapVersion: { ...sitemapVersion, sitemapVersionLevels: versionLevelsOnServer ?? [] },
        }));
    };

    /** This allows the outside to update the state inside this hook. Confining it to specific events means we can be more deterministic with our state updates */
    const send = async (event: SitemapWizardEvents) => {
        switch (event.type) {
            case "ADD_VERSION_REQUESTED": {
                setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.SitemapVersionForm }));
                break;
            }
            case "EDIT_VERSION_REQUESTED": {
                setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.SitemapVersionForm, sitemapVersion: event.version }));
                break;
            }
            case "DELETE_VERSION_REQUESTED": {
                // If there are any levels on the version we are about to delete which do not have any matching levels in any other version,
                // then we need to reassign the objects that are on that level
                const remainingVersions = state.sitemap.versions.filter((v) => v.id !== event.version.id);
                const levelsWithNoMatchingIndexes = event.version.sitemapVersionLevels.filter(
                    (level) => !remainingVersions.flatMap((v) => v.sitemapVersionLevels).some((l) => l.levelIndex === level.levelIndex)
                );
                setState((s) => ({
                    ...s,
                    deleteConfirmation: { type: "version", id: event.version.id, name: event.version.name, isDeleting: false, levelsWithNoMatchingIndexes },
                }));
                break;
            }
            case "DELETE_VERSION": {
                setState((s) => ({ ...s, deleteConfirmation: { ...s.deleteConfirmation, isDeleting: true } }));
                // First, we need to reassign any removed levels
                if (event.levelReassignments?.length > 0) {
                    await sitemapServices.reassignSitemapLevels(
                        event.levelReassignments.map((reassignment) => ({
                            sitemapId: state.sitemap.id,
                            oldLevel: reassignment.removedLevel.levelIndex,
                            newLevel: reassignment.fallbackLevel.levelIndex,
                            reassignAllObjects: false,
                        }))
                    );
                }
                await sitemapServices.deleteSitemapVersion(state.deleteConfirmation?.id, state.sitemap.id);
                setState((s) => ({ ...s, deleteConfirmation: null, sitemap: { ...s.sitemap, versions: s.sitemap.versions.filter((v) => v.id !== event.id) } }));
                break;
            }
            case "SITEMAP_VERSION_SAVED": {
                const { version: sitemapVersion } = event;
                upsertSitemapVersion(sitemapVersion, false);
                break;
            }
            case "SITEMAP_VERSION_LEVELS_SAVED": {
                const { version: sitemapVersion, tileUploads } = event;
                upsertSitemapVersion(sitemapVersion, true);
                if (tileUploads?.length > 0) {
                    setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.UploadingTiles, levelsWithTilesToUpload: tileUploads }));
                    tileUploads.forEach((level) => {
                        remoteUploadContext.upload(level.id, level.tileUploadUrl, level.mbtiles, async () => {
                            await sitemapServices.updateSitemapVersionLevel({ ...level, status: Status.Active, sitemapVersionId: sitemapVersion.id }, state.sitemap.id);
                        });
                    });
                } else {
                    setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.SitemapForm }));
                }
                break;
            }
            case "BACK": {
                // Send us back to sitemap editor
                if (state.stage === SitemapWizardStage.SitemapVersionForm) {
                    setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.SitemapForm }));
                }
                break;
            }
            case "FINISH_UPLOADS": {
                // Clean up the remoteUploadContext context
                state.levelsWithTilesToUpload?.forEach(({ id }) => {
                    remoteUploadContext.remove(id);
                });
                // Send us back to sitemap editor
                setState(({ sitemap, sitemapOnServer }) => ({ sitemap, sitemapOnServer, stage: SitemapWizardStage.SitemapForm }));
                break;
            }
            case "SET_SITEMAP": {
                // if payload is sitemap, set the sitemap, else, we know its a function so set the sitemap using the payload function
                setState(({ sitemap, ...s }) => ({ ...s, sitemap: "id" in event.payload ? event.payload : event.payload(sitemap) }));
                break;
            }
            case "SET_SITEMAP_ON_SERVER": {
                setState((s) => ({ ...s, sitemapOnServer: event.payload }));
                break;
            }
            case "CANCEL_DELETION": {
                setState((s) => ({ ...s, deleteConfirmation: null }));
                break;
            }
            default: {
                // @ts-expect-error If we add a new event and forget to add a case for it, typescript will give us an error at compile time
                throw new Error(event.type);
            }
        }
    };
    return [state, send] as const;
};
