export const tileUploader = async (setTileUploadProgress: (progress: number) => void, url: string, file: File) =>
    new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentage = Math.round((event.loaded * 100) / event.total);
                setTileUploadProgress(percentage);
            }
        });

        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", "application/zip");
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve();
            } else {
                reject();
            }
        };

        xhr.onerror = (error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            reject();
        };

        xhr.send(file);
    });
