import type { FillLayerSpecification, SymbolLayerSpecification, CircleLayerSpecification, LineLayerSpecification, FillExtrusionLayerSpecification } from "@iventis/mapbox-gl";
import { StyleType } from "@iventis/domain-model/model/styleType";
import type { MapboxModelLayer } from "../mapbox-layers/mapbox-model-layer";
import type { MapboxLineModelLayer } from "../mapbox-layers/mapbox-line-model-layer";

export enum SubLayerType {
    "BASE" = "BASE",
    "EXTRUSION" = "EXTRUSION",
    "POLYGON_TEXT" = "POLYGON_TEXT",
    "LINE_ICON" = "LINE_ICON",
    "LINE_TEXT" = "LINE_TEXT",
    "LINE_OUTLINE" = "LINE_OUTLINE",
    "POLYGON_OUTLINE" = "POLYGON_OUTLINE",
    "POINT_TEXT" = "POINT_TEXT",
    "LINE_MODEL" = "LINE_MODEL",
}

interface SublayerSignatureBase {
    id: string;
    type: SubLayerType;
    styleType: StyleType;
}

interface SublayerSignatureModel extends SublayerSignatureBase {
    styleType: StyleType.Model;
    layerClass: MapboxModelLayer;
}

interface SublayerSignatureLineModel extends SublayerSignatureBase {
    styleType: StyleType.LineModel;
    layerClass: MapboxLineModelLayer;
}

interface SublayerSignatureArea extends SublayerSignatureBase {
    styleType: StyleType.Area;
}

interface SublayerSignatureLine extends SublayerSignatureBase {
    styleType: StyleType.Line;
}

interface SublayerSignaturePoint extends SublayerSignatureBase {
    styleType: StyleType.Point;
}

interface SublayerSignatureIcon extends SublayerSignatureBase {
    styleType: StyleType.Icon;
}

export type SublayerSignature =
    | SublayerSignatureArea
    | SublayerSignatureLine
    | SublayerSignaturePoint
    | SublayerSignatureIcon
    | SublayerSignatureModel
    | SublayerSignatureLineModel;

export interface AggregateLayers {
    [layerId: string]: SublayerSignature[];
}

export type SupportedMapboxLayer = FillLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification | LineLayerSpecification | FillExtrusionLayerSpecification;

export type MapboxlayerWithSublayerType = SublayerSignature & {
    layer: SupportedMapboxLayer;
};

export enum LayerSourceLocation {
    Local = "Local",
    Remote = "Remote",
}
