import React, { useMemo, ReactNode, FunctionComponent, useState, useEffect } from "react";
import { styled, Body2, sectionalMargin, StyledFieldLabel } from "@iventis/styles";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { useRemoteUpload, RemoteUploadEvents, RemoteUploadState } from "@iventis/remote-upload";

import { Box, Button, LinearProgress } from "@mui/material";
import { useActor } from "@xstate/react";
import { Interpreter, InterpreterStatus } from "xstate";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { SitemapVersionLevelWithFiles } from "./sitemap-types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoteUploadRef = Interpreter<RemoteUploadState, any, RemoteUploadEvents, any, any>;

export const useTileUploader = (uploadRequests: (SitemapVersionLevelCreateResponse & SitemapVersionLevelWithFiles)[]) => {
    const remoteUpload = useRemoteUpload();
    const pendingUploads = useMemo(() => {
        const uploads: { level: SitemapVersionLevel; upload: RemoteUploadRef }[] = [];
        remoteUpload.uploads.forEach((upload) => {
            // If the upload has stopped, ignore
            if (upload.status === InterpreterStatus.Stopped) {
                return;
            }
            // If a level matches the upload, add it to the pending uploads
            const level = uploadRequests.find((level) => level.id === upload.id);
            if (level) {
                uploads.push({ level, upload });
            }
        });
        return uploads;
    }, [remoteUpload.uploads.size]);
    return pendingUploads;
};

export const TileUploader: FunctionComponent<{ uploadRequests: (SitemapVersionLevelCreateResponse & SitemapVersionLevelWithFiles)[]; finish: () => void }> = ({
    uploadRequests,
    finish,
}) => {
    const pendingUploads = useTileUploader(uploadRequests);
    const [completeUploads, setCompleteUploads] = useState<string[]>([]);
    useEffect(() => {
        if (completeUploads?.length === uploadRequests.length) {
            finish();
        }
    }, [completeUploads]);

    return (
        <StyledContainer>
            {pendingUploads.length > 0 && (
                <StyledProgressContainer>
                    {pendingUploads.reduce(
                        (acc, { upload, level }) =>
                            upload.status === InterpreterStatus.Running
                                ? [
                                      ...acc,
                                      <TileUpload
                                          key={level.id}
                                          remoteUploader={upload}
                                          level={level}
                                          registerUploadComplete={() => setCompleteUploads((acc) => (acc.includes(level.id) ? acc : [...acc, level.id]))}
                                          hasUploadCompleteBeenRegistered={completeUploads.includes(level.id)}
                                      />,
                                  ]
                                : acc,
                        [] as ReactNode[]
                    )}
                </StyledProgressContainer>
            )}
        </StyledContainer>
    );
};

export const TileUpload: FunctionComponent<{
    remoteUploader: RemoteUploadRef;
    level: SitemapVersionLevel;
    registerUploadComplete: () => void;
    hasUploadCompleteBeenRegistered: boolean;
}> = ({ remoteUploader, level, registerUploadComplete, hasUploadCompleteBeenRegistered }) => {
    const translate = useIventisTranslate();
    const [state, send] = useActor(remoteUploader);
    const isUploadComplete = state.matches("complete");

    useEffect(() => {
        if (isUploadComplete && !hasUploadCompleteBeenRegistered) {
            registerUploadComplete();
        }
    }, [state.value]);

    const progress = isUploadComplete ? 100 : state.context.progress ?? 0;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", columnGap: "10px" }}>
                <StyledFieldLabel>{translate(Content.sitemaps.sitemapVersion.uploading_tiles, { level: level.levelName })}</StyledFieldLabel>
                {state.matches("failed") && <Button onClick={() => send("RETRY")}>{translate(Content.sitemaps.sitemapVersion.retry)}</Button>}
                {!state.matches("failed") && <Body2>{`${Math.round(progress)}%`}</Body2>}
            </Box>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        </Box>
    );
};

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 40vh;
    row-gap: ${sectionalMargin};
`;

const StyledProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${sectionalMargin};
    width: 70%;
`;
