/* eslint-disable react/jsx-props-no-spreading */
import { createContext } from "react";

export type DialogActions = {
    close?: () => void;
    toggleFullScreen?: () => void;
};

export interface IDialogContext {
    isFullScreen: boolean;
    actions: DialogActions;
}

export const DialogContext = createContext<IDialogContext | undefined>(undefined);
