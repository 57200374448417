import { Terrain } from "@iventis/domain-model/model/terrain";
import { TreeBrowserNode, TreeBrowserStateInterface, UnionOfNodes } from "@iventis/tree-browser";
import React from "react";

export interface TerrainTreeBrowserNode extends TreeBrowserNode {
    type: TerrainNodeTypes;
}

export interface TerrainRootNode extends TerrainTreeBrowserNode {
    type: TerrainNodeTypes.Root;
    childNodes: TerrainNode[];
}

export interface TerrainNode extends TerrainTreeBrowserNode {
    type: TerrainNodeTypes.Terrain;
    terrain: Terrain;
}

export enum TerrainNodeTypes {
    Terrain = "Terrain",
    Root = "Root",
}

export type AnyTerrainTreeNode = UnionOfNodes<TerrainRootNode>;

export const TerrainTreeBrowserContext = React.createContext<TreeBrowserStateInterface<TerrainRootNode>>(null);
