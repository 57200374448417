/* eslint-disable no-param-reassign */
import bearing from "@turf/bearing";
import lineChunk from "@turf/line-chunk";
import length from "@turf/length";
import { Position, featureCollection } from "@turf/helpers";
import { v4 as uuid } from "uuid";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import GeoJSON, { FeatureCollection, Point } from "geojson";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { MapObjectProperties } from "@iventis/map-types";
import { offsetLineByDistance } from "../../../utilities/geojson-helpers";
import { defaultLineModelStyle } from "../../../utilities/default-style-values";

/** Create a number of point features a long a line with rotation and spacing applied */
export function createFeaturesOnLine(
    layerId: string,
    features: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>[],
    spacingStyleValue: StyleValue<number>,
    rotationStyleValue: StyleValue<number>,
    offsetStyleValue: StyleValue<number>
): FeatureCollection<Point, MapObjectProperties> {
    if (features == null) {
        return featureCollection([]);
    }

    // Get all style values
    const rotation = getStaticStyleValue(rotationStyleValue ?? defaultLineModelStyle.rotation);
    const mappedSpacingValues = getLineModelStyleValue(spacingStyleValue ?? defaultLineModelStyle.spacing);
    const mappedOffsetValues = getLineModelStyleValue(offsetStyleValue ?? defaultLineModelStyle.modelOffset);

    // Create the features along each of the line
    const newFeatures = features
        // When creating a new line model it has only one coordinates so filter it
        .filter((f) => f.geometry.coordinates.length > 1)
        .flatMap((feature) => {
            // Get the list item value, if not data driven use the default value
            const spacing = getActualStyleValue(feature.properties, spacingStyleValue ?? defaultLineModelStyle.spacing, mappedSpacingValues);
            const offset = getActualStyleValue(feature.properties, offsetStyleValue ?? defaultLineModelStyle.modelOffset, mappedOffsetValues);

            // Offset the line if an value has been specified
            const updatedFeature = offset !== 0 ? offsetLineByDistance(feature, offset) : feature;

            // Break line into chunks based on spacing
            const lineChunks = lineChunk(updatedFeature, spacing, { units: "meters" });

            let validChunks = lineChunks.features;
            // If the offset is not 0, check whether the last chunk is needed
            if (offset !== 0) {
                // Only show last model if the last line chunk if half the length of spacing
                const lengthOfLastLineChunk = length(lineChunks.features[lineChunks.features.length - 1], { units: "meters" });
                const showLastChunk = lengthOfLastLineChunk > spacing / 2;
                // Remove the last chunk if it's not needed
                validChunks = showLastChunk ? lineChunks.features : lineChunks.features.slice(0, -1);
            }

            // Get first coordinate of each chunk to use as placement for models along the line
            const coordinatesAlongLine = validChunks.map((f) => {
                const lineChunkBearing = bearing(f.geometry.coordinates[0], f.geometry.coordinates[1]);
                // Ensure all layers are rotated relative to the line's bearing
                const modelsOnLineRotation = 360 - lineChunkBearing + rotation;
                return { coordinates: f.geometry.coordinates[0], rotation: modelsOnLineRotation };
            });
            // Create features with the coordinates along the line
            return coordinatesAlongLine.map(({ coordinates, rotation }) => createModelFeatureForLineModel(feature.properties, layerId, coordinates, rotation));
        });

    return featureCollection(newFeatures);
}

/** Creates a point feature with properties for line models  */
export function createModelFeatureForLineModel(
    properties: MapObjectProperties,
    layerId: string,
    coordinates: Position = [],
    rotation = 0
): GeoJSON.Feature<GeoJSON.Point, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: { coordinates, type: "Point" },
        properties: {
            ...properties,
            layerid: layerId,
            // Map object Id this model is placed on
            baseObjectId: properties.id,
            id: uuid(),
            rotation: { x: 0, y: 0, z: rotation },
        },
    };
}

export function getLineModelStyleValue(value: StyleValue<number>): { [attributeId: string]: number } {
    if (value == null) {
        return null;
    }

    const defaultValue = value.staticValue.staticValue;
    switch (value.extractionMethod) {
        case StyleValueExtractionMethod.Static: {
            return { default: defaultValue };
        }
        case StyleValueExtractionMethod.Mapped: {
            const mappedStaticStyleValues = Object.entries(value.mappedValues).reduce((mappedValues, [valueKey, staticValue]) => {
                mappedValues[valueKey] = staticValue.staticValue;
                return mappedValues;
            }, {});
            return { ...mappedStaticStyleValues, default: defaultValue };
        }
        default:
            throw new Error(`Unsupported style value extraction method ${value.extractionMethod}`);
    }
}

export function getActualStyleValue<TStyleValue>(
    listItemValues: MapObjectProperties,
    styleValue: StyleValue<TStyleValue>,
    mappedValues: { [listItemId: string]: TStyleValue }
): TStyleValue {
    // Get the list item value, if not attribute driven use the default value
    const attributeValue = listItemValues[styleValue.dataFieldId];
    return mappedValues[attributeValue as string] ?? mappedValues.default;
}
