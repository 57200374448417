import { UncheckedCheckBox, CheckedCheckBox, Body2, InteractiveElement } from "@iventis/styles";
import React from "react";
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadModelData, CustomImageValue } from "@iventis/types/model-types";
import { useFunctionality, CUSTOM_IMAGE_MODEL_SUPPORT_URL } from "@iventis/utilities";
import { ModelImageAspectRatioComponent } from "./model-image-aspect-ratio-component";
import { Checkbox, ModelVariableSizeContainer } from "./model-input-shared-styles";

export const UploadModelCustomImageComponent = ({
    onChange,
    values,
}: {
    onChange: <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => void;
    values: CustomImageValue;
}) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();
    const functionality = useFunctionality();

    const handleCustomImageToggle = () => {
        const updatedValue = !values.customImage;
        onChange("customImage", updatedValue);
        if (!updatedValue) {
            onChange("aspectRatio", { width: 0, height: 0 });
        }
    };

    return (
        <ModelVariableSizeContainer>
            <div className="checkbox-container">
                {functionality?.imagesOnModels ? (
                    <Checkbox
                        icon={<UncheckedCheckBox size={15} borderStyle="1px solid rgba(0,0,0,0.23)" backgroundColour={theme.secondaryColors.blank} />}
                        checkedIcon={<CheckedCheckBox size={15} tickColour="white" borderStyle="1px solid transparent" backgroundColour={theme.primaryColors.focus} />}
                        checked={values.customImage}
                        onChange={() => handleCustomImageToggle()}
                        size="small"
                        data-testid="custom-image-toggle"
                    />
                ) : (
                    <StyledInteractiveElement className="upgrade-button" onClick={() => functionality.open?.("imagesOnModels")}>
                        <FontAwesomeIcon icon={["far", "circle-up"]} color={theme.primaryColors.focus} className="icon" />
                    </StyledInteractiveElement>
                )}
                <Body2>
                    {translate(Content.map5.styles.customImage)}{" "}
                    <a href={CUSTOM_IMAGE_MODEL_SUPPORT_URL} target="_blank" rel="noreferrer" style={{ fontWeight: "bold", color: theme.primaryColors.focus }}>
                        {translate(Content.common.learnMore)}
                    </a>
                    .
                </Body2>
            </div>
            <ModelImageAspectRatioComponent
                onValueChanged={(property, value) => onChange("aspectRatio", { ...values.aspectRatio, [property]: value })}
                values={values.aspectRatio}
                disabled={!values.customImage}
            />
        </ModelVariableSizeContainer>
    );
};

const StyledInteractiveElement = styled(InteractiveElement)`
    padding: 0px 10px 0px 0px;
`;
