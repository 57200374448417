import { css } from "@emotion/react";
import { styled, DataTableTheme, main } from "@iventis/styles";

import { fontSizes, zIndexes } from "@iventis/styles/src/atomic-rules";
import { TextField } from "@mui/material";

/*
    DEFAULT THEME
*/

const checkBoxBorder = `1.5px solid ${main.primaryColors.subdued70}`;

export const defaultTheme: DataTableTheme = {
    dataTableTheme: {
        typography: {
            core: main.typographyColors.core,
            subdued: main.typographyColors.subdued,
            moreSubdued: main.typographyColors.evenMoreSubdued,
            lessSubdued: main.typographyColors.lessSubdued,
        },
        background: main.secondaryColors.blank,
        root: {
            border: "1px solid #1f1f1f",
        },
        header: {
            background: "none",
            bottomBorder: "solid 1px #1f1f1f",
        },
        selected: {
            rowBackgroundColour: main.secondaryColors.light50,
            rowBorder: `1px solid ${main.primaryColors.focusAccent}`,
            cellBackgroundColour: main.secondaryColors.light50,
            cellBorderColour: main.primaryColors.focusAccent,
            cellRangeBackgroundColour: main.secondaryColors.light50,
        },
        unSelected: {
            borderColor: "#dde2eb",
        },
        buttons: {
            primary: {
                background: main.primaryColors.focusAccent,
                text: main.typographyColors.blank,
            },
            secondary: {
                background: main.typographyColors.blank,
                text: main.primaryColors.focusAccent,
            },
        },
        checkBox: {
            checked: {
                tick: { color: main.typographyColors.blank, weight: "fas" },
                background: main.primaryColors.subdued70,
                border: checkBoxBorder,
            },
            unchecked: {
                background: main.typographyColors.blank,
                border: checkBoxBorder,
            },
            cell: {
                colour: main.primaryColors.focus,
            },
        },
        popover: {
            boxShadow: `0 0 5px 1px ${main.typographyColors.subdued}`,
            borderRadius: "4px",
        },
        validation: {
            invalidCellColour: main.primaryColors.focus,
        },
        icons: {
            color: main.primaryColors.subdued70,
        },
        chips: {
            background: main.secondaryColors.lightGrey,
        },
    },
};

/*
    THEME GETTERS
*/

export const getRootBorder = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.root.border;
export const getHeaderBackgroundColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.header.background;
export const getHeaderBottomBorder = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.header.bottomBorder;
export const getUnSelectedBorderColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.unSelected.borderColor;
export const getSelectedRowBackgroundColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.selected.rowBackgroundColour;
export const getSelectedRowBorder = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.selected.rowBorder;
export const getSelectedCellBackgroundColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.selected.cellBackgroundColour;
export const getSelectedCellBorderColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.selected.cellBorderColour;
export const getCellRangeBackgroundColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.selected.cellBackgroundColour;
export const getAlternateRowBackgroundColours = ({ theme }: { theme: DataTableTheme }) =>
    theme.dataTableTheme.alternateRows ? theme.dataTableTheme.alternateRows.backgroundColour : null;
export const getPopoverBoxShadow = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.popover.boxShadow;
export const getInvalidCellColour = ({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.validation.invalidCellColour;

/*
    STYLED COMPONENTS
*/

export const StyledTextCell = styled.p`
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 100%;
    font-size: ${fontSizes.small};
    .truncate-cell-contents & {
        box-sizing: border-box;
        padding: 7px 12px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledFilter = styled.div`
    padding: 10px 0;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    > * {
        padding: 0 15px;
    }
    .title {
        padding-bottom: 10px;
    }
`;

export const StyledInputField = styled(TextField)`
    margin: 5px 0 15px 0;
    .MuiOutlinedInput-input {
        padding: 10px 15px;
    }
`;

// TODO: Does this need to be used elsewhere?

export const cellEditorBase = ({ theme }: { theme: DataTableTheme }) => css`
    background-color: ${theme.dataTableTheme.background};
    box-shadow: ${theme.dataTableTheme.popover.boxShadow};
    border-radius: 4px;
`;

/*
    AG-GRID STYLE OVERRIDES
*/

export const StyledTable = styled.div<{ $pinnedRowHeight?: number; $canAddColumn?: boolean; $rowGroupingEnabled?: boolean; theme: DataTableTheme }>`
    overflow-x: auto;
    overflow-y: hidden;

    --ag-header-cell-hover-background-color: ${getHeaderBackgroundColour};

    max-width: ${(props) => (props.$canAddColumn ? "calc(100% - 44px)" : "100%")};

    .ag-root-wrapper {
        height: 100%;
    }

    // Provides a border around the grid
    .ag-root-wrapper-body {
        border: ${getRootBorder};
        max-height: ${(props) => (props.$rowGroupingEnabled ? `calc(100% - 44px)` : `100%`)};
    }

    // Needed to remove weird gap for when we have no rows shown and an "add row" row
    .ag-layout-auto-height .ag-center-cols-viewport,
    .ag-layout-auto-height .ag-center-cols-container,
    .ag-layout-print .ag-center-cols-viewport,
    .ag-layout-print .ag-center-cols-container {
        min-height: 0;
    }

    .ag-overlay-no-rows-wrapper {
        visibility: hidden;
    }

    .ag-body-viewport.ag-layout-auto-height {
        max-height: calc(
            100cqh - 45px - ${(props) => props.$pinnedRowHeight}px - 5px
        ); // The scrollable viewport is the (height of the whole container) - (height of the column header) - (height of a pinned row) - (5px for the horizontal scroll bar because ag-grid are annoying)

        overflow-y: auto;
        scrollbar-width: auto;

        ::-webkit-scrollbar {
            display: block;
        }
    }

    // Styles the container for custom react components
    .ag-react-container {
        height: 100%;
        width: 100%; // Allows for custom components to access entire cell (full width)
    }

    // Full header
    .ag-header {
        border-bottom: ${getHeaderBottomBorder};
    }

    // Filter popups and right click popups etc
    .ag-menu-body {
        background-color: white;
    }
    .ag-menu {
        background-color: white;
        padding: 10px;
        border-radius: 4px;
        box-shadow: ${getPopoverBoxShadow};
        z-index: ${zIndexes.dataTable.menu};
    }

    // Each header cell
    .ag-header-cell {
        background-color: ${getHeaderBackgroundColour};
        box-sizing: border-box;
    }

    .ag-header-cell-resize {
        width: 10px;
        right: 0px;
        background: linear-gradient(to right, rgb(208, 208, 208, 0.7), rgb(184, 184, 184, 0.7));
        border: 3px solid ${getHeaderBackgroundColour};
        border-radius: 10px;
    }

    // Each row
    .ag-row {
        border-bottom: solid 1px #dde2eb;
        box-sizing: border-box;
    }

    .ag-row-even:not(.ag-row-selected) {
        background-color: ${(props) => getAlternateRowBackgroundColours(props)?.firstRow};
    }
    .ag-row-odd:not(.ag-row-selected) {
        background-color: ${(props) => getAlternateRowBackgroundColours(props)?.secondRow};
    }

    // Each cell
    .ag-cell {
        padding: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
    }
    .ag-cell-focus:not(.index-cell) {
        /* index-cell only allows row selection (not cell selection) */
        background-color: ${getSelectedCellBackgroundColour};
        border: 1px solid ${getSelectedCellBorderColour};
        box-sizing: border-box;
    }
    .ag-cell p {
        width: 100%;
    }

    // Each cell has a fill-handle which is used for excel drag and copy to edit cells
    .ag-fill-handle {
        background-color: ${getSelectedCellBackgroundColour};
        cursor: cell;
        position: absolute;
        width: 6px;
        height: 6px;
        bottom: -1px;
        right: -1px;
    }

    .index-cell {
        background-color: ${getHeaderBackgroundColour};
        border: none;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.typography.core};
        }
        :hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.05);
        }
        > .ag-fill-handle {
            /* index-cell does not allow editing */
            display: none;
        }

        .ag-cell-value button {
            padding: 0 4px;
        }
    }

    .ag-floating-bottom {
        .index-cell {
            svg {
                color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.typography.moreSubdued};
            }
        }
    }

    .ag-row-pinned {
        .index-cell {
            :hover {
                cursor: default;
                background: ${getHeaderBackgroundColour};
            }
        }
    }

    .ag-row-selected {
        border-top: ${getSelectedRowBorder};
        border-bottom: ${getSelectedRowBorder};
        background-color: ${getSelectedRowBackgroundColour};

        z-index: ${zIndexes.dataTable.selectedRow};

        .ag-cell {
            background-color: transparent;
        }
        .ag-cell:last-child:not(.index-cell) {
            border-right: ${getSelectedRowBorder};
        }
        // index-cell border is used to provide a left border for row selection
        .index-cell {
            border-left: ${getSelectedRowBorder};
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    // Nuances with cell selection
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell, .index-cell) {
        border: solid 1px transparent;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell, .index-cell).ag-cell-range-right {
        border-right: 1px solid ${getSelectedCellBorderColour};
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell, .index-cell).ag-cell-range-left {
        border-left: 1px solid ${getSelectedCellBorderColour};
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell, .index-cell).ag-cell-range-bottom {
        border-bottom: 1px solid ${getSelectedCellBorderColour};
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell, .index-cell).ag-cell-range-top {
        border-top: 1px solid ${getSelectedCellBorderColour};
    }
    .ag-selection-fill-right:not(.index-cell) {
        border-right: 1px dashed ${getSelectedCellBorderColour};
    }
    .ag-selection-fill-left:not(.index-cell) {
        border-left: 1px dashed ${getSelectedCellBorderColour};
    }
    .ag-selection-fill-bottom:not(.index-cell) {
        border-bottom: 1px dashed ${getSelectedCellBorderColour};
    }
    .ag-selection-fill-top:not(.index-cell) {
        border-top: 1px dashed ${getSelectedCellBorderColour};
    }
    .ag-row-selected .ag-cell:last-child:not(.index-cell) {
        border-right: 1px solid ${getSelectedCellBorderColour};
    }
    .ag-cell-range-selected-1:not(.ag-cell-focus, .index-cell) {
        background-color: ${getCellRangeBackgroundColour};
    }
    .ag-cell-range-single-cell:not(.index-cell),
    .ag-cell-range-single-cell:not(.index-cell) .ag-cell-range-handle,
    .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected, .index-cell),
    .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group {
        border: 1px solid ${getSelectedCellBorderColour};
        box-sizing: border-box;
    }
    .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
    .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
    .ag-ltr .ag-cell-range-single-cell,
    .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
    .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
    .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
    .ag-rtl .ag-cell-range-single-cell,
    .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
        border: 1px solid ${getSelectedCellBorderColour};
    }

    .ag-cell-inline-editing {
        height: 100%;
        /* Required so that cell editors can render list options which appear outside the cell bounds */
        overflow: visible;
        border: 0;
    }

    .invalid {
        border: 1px solid ${getInvalidCellColour};
        box-sizing: border-box;
    }

    .ag-header-cell-resize {
        z-index: ${zIndexes.dataTable.columnResize};
    }

    .ag-row-drag {
        font-size: ${fontSizes.xSmall};
        padding: 0 2px;
        color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.icons.color};
    }

    .ag-overlay-no-rows-center {
        margin-bottom: 24px;
    }

    .ag-row-group {
        padding: 0px 8px 0px 8px;
        gap: 8px;
    }

    .ag-column-drop {
        height: 44px;
        padding: 0px 10px 0px 10px;
        gap: 10px;
    }

    .ag-column-drop-title-bar {
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ag-column-drop-cell {
        background-color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.chips.background};
        padding: 5px 10px 5px 10px;
        border-radius: 25px;
        color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.typography.subdued};
        gap: 5px;
        height: 26px;
    }

    .ag-column-drop-cell-text {
        font-size: ${fontSizes.small};
    }

    .ag-column-drop-horizontal-cell-separator {
        margin: 0 5px;
    }

    .ag-column-drop-empty-message {
        font-size: ${fontSizes.small};
        color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.typography.moreSubdued};
    }

    .ag-dnd-ghost.ag-unselectable {
        background-color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.chips.background};
        padding: 5px 10px 5px 10px;
        border-radius: 25px;
        color: ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.typography.subdued};
        gap: 5px;
        height: 26px;
        border: none;
    }

    .ag-root.ag-layout-auto-height {
        width: auto;
    }

    ${({ theme }: { theme: DataTableTheme }) => theme.dataTableTheme.additionalCss};
`;
