import { LayerSpecification } from "@iventis/mapbox-gl";

export const zAxisLayerSpec: LayerSpecification = {
    id: "z-axis",
    type: "fill-extrusion",
    source: "z-axis",
    paint: {
        // Get `fill-extrusion-height` from the source `height` property.
        "fill-extrusion-height": 10000,

        // Get `fill-extrusion-base` from the source `base_height` property.
        "fill-extrusion-base": 0,

        // Make extrusions slightly opaque to see through indoor walls.
        "fill-extrusion-opacity": 1,
        "fill-extrusion-color": ["get", "colour"],
    },
};

export const xyAxisLayerSpec: LayerSpecification = {
    id: "xy-axis",
    type: "line",
    source: "xy-axis",
    paint: {
        "line-color": ["get", "colour"],
        "line-width": 2,
    },
};
