import { Body2, fontSizes, InteractiveElement, styled } from "@iventis/styles";
import React, { FunctionComponent, ReactNode, useRef, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import { CUSTOM_COLOUR_MODEL_SUPPORT_URL, CUSTOM_IMAGE_MODEL_SUPPORT_URL, fileToBase64, useConstant } from "@iventis/utilities";
import { useQuery } from "@tanstack/react-query";
import { Model } from "@iventis/domain-model/model/model";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { ModelPreviewComponent, ModelPreviewRef, validateModelCanHaveCustomisation } from "@iventis/model-utilities";
import { DisabledOverlay, FileUploadComponent, FormWizardTemplate, InputWithChipsCss } from "@iventis/components";
import { StyledUploadDialog, StyledThumbnailUploadContainer, StyledUploadContainer } from "@iventis/components/src/upload-shared-styles";
import { UploadModelVariableSizeComponent } from "@iventis/components/src/upload-model-variable-size";
import { dimensionValidation } from "@iventis/components/src/model-sizing-component-state";
import { FromGapComponent } from "@iventis/components/src/form-gap";
import { UploadModelCustomImageComponent } from "@iventis/components/src/upload-model-custom-image";
import { UploadModelCustomColourComponent } from "@iventis/components/src/upload-model-custom-colour";
import { ModelThumbnailPreview } from "@iventis/components/src/model-thumbnail";
import { UploadModelData, ModelRenderStatus } from "@iventis/types/model-types";

export const acceptedModelFileTypes = ["glb", "gltf"];

/** Returns true for all accepted 3D model file types */
export const isModelFileType = (fileName) => acceptedModelFileTypes.includes(fileName.substring(fileName.lastIndexOf(".") + 1));

export const UploadAssetModal: FunctionComponent<{
    open: boolean;
    close: () => void;
    children: ReactNode;
    minModalDimensions?: { width: string; height: string };
}> = ({ open, close, children, minModalDimensions }) => (
    <StyledUploadDialog open={open} onClose={close} $minModalDimensions={minModalDimensions}>
        {open && children}
    </StyledUploadDialog>
);

export const UploadModelForm: FunctionComponent<{
    close: () => void;
    uploadRemotely: (updated: UploadModelData, original: UploadModelData) => Promise<Model>;
    categories?: string[];
    existingThumbnailId?: string;
    getExistingModelByThumbnailId?: (thumbnailId: string) => Promise<UploadModelData>;
    setSelectedId?: (id: string) => void;
}> = ({ close, uploadRemotely, existingThumbnailId, getExistingModelByThumbnailId, categories, setSelectedId }) => {
    const translate = useIventisTranslate();
    const [state, setState] = useState<"idle" | "loading" | "error" | "saving">(existingThumbnailId ? "loading" : "idle");
    const [renderModelState, setRenderModelState] = useState<ModelRenderStatus>(ModelRenderStatus.None);
    const defaultData = useConstant<UploadModelData>(() => ({
        name: null,
        thumbnail: { fileName: null, imageUrl: null },
        categories: [],
        model: { fileName: null, modelUrl: null },
        variableSizeModel: false,
        size: { height: 0, width: 0, length: 0 },
        customImage: false,
        aspectRatio: { height: 0, width: 0 },
        customColour: false,
    }));
    // Form data
    const [showThumbnailFileSelector, setShowThumbnailFileSelector] = useState(false);

    const [data, _setData] = useState<UploadModelData>(defaultData);

    const [existingData, setExistingData] = useState<UploadModelData | null>();

    const [customiseModelValidation, customiseModelModelValidation] = useState<{ colour: boolean; image: boolean }>({ colour: false, image: false });

    const modelPreviewRef = useRef<ModelPreviewRef>(null);
    // Use the ref
    const renderModel = (modelUrl: string): Promise<ModelRenderStatus> => modelPreviewRef.current?.renderModel(modelUrl);

    // Programtically we want to render a thumbnail when a new model is uploaded
    const renderThumbnail = async () => {
        const pic = await modelPreviewRef.current?.renderThumbnail();
        setData("thumbnail", { fileName: "Preview generated", imageUrl: pic, changed: true });
        setState("idle");
    };

    // When the camera icon is clicked, we want to update the thumbnail with the latest data url
    const onCameraClicked = (dataUrl: string) => {
        setData("thumbnail", { fileName: "Preview generated", imageUrl: dataUrl, changed: true });
        setState("idle");
    };

    const { isFetching } = useQuery(
        ["get-existing-asset", existingThumbnailId],
        async () => (existingThumbnailId ? getExistingModelByThumbnailId?.(existingThumbnailId) : Promise.resolve(defaultData)),
        {
            onSuccess: async (model) => {
                // If values are null (never been set) then set them to 0
                const height = model.size.height ?? 0;
                const width = model.size.width ?? 0;
                const length = model.size.length ?? 0;
                const aspectRatioWidth = model?.aspectRatio?.width ?? 0;
                const aspectRatioHeight = model?.aspectRatio?.height ?? 0;

                const updatedModel = {
                    ...model,
                    variableSizeModel: height !== 0,
                    size: { height, width, length },
                    aspectRatio: { height: aspectRatioHeight, width: aspectRatioWidth },
                };
                const canHaveCustomImage = await validateModelCanHaveCustomisation(updatedModel.model.modelUrl, "image");
                const canHaveCustomColour = await validateModelCanHaveCustomisation(updatedModel.model.modelUrl, "colour");
                setExistingData(updatedModel);
                _setData(updatedModel);
                customiseModelModelValidation({ colour: canHaveCustomColour, image: canHaveCustomImage });
                if (updatedModel?.model?.modelUrl != null) {
                    setRenderModelState(ModelRenderStatus.Proccessing);
                    const renderResult = await renderModel(updatedModel.model.modelUrl);
                    setRenderModelState(renderResult);
                }
                setState("idle");
            },
        }
    );

    // Update form data
    const setData = <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => _setData((d) => ({ ...d, [field]: value }));

    // Upload image to local state
    const uploadThumbnailLocally = async (file: File) => {
        const { withHeader } = await fileToBase64(file);
        setData("thumbnail", { fileName: file.name, imageUrl: withHeader, changed: true });
        setState("idle");
    };

    // Upload image to local state
    const uploadModelLocally = async (file: File) => {
        if (!isModelFileType(file.name)) {
            setData("model", { fileName: null, modelUrl: null, error: `Must be of type: ${acceptedModelFileTypes.join(", ")}` });
            return;
        }
        const { withHeader } = await fileToBase64(file);
        setRenderModelState(ModelRenderStatus.Proccessing);
        const renderResult = await renderModel(URL.createObjectURL(file));
        setRenderModelState(renderResult);
        if (renderResult === ModelRenderStatus.Visible) {
            await renderThumbnail();
        }
        setData("model", { fileName: file.name, modelUrl: withHeader });
        const localUrl = URL.createObjectURL(file);
        const canHaveCustomImage = await validateModelCanHaveCustomisation(localUrl, "image");
        const canHaveCustomColour = await validateModelCanHaveCustomisation(localUrl, "colour");

        customiseModelModelValidation({ colour: canHaveCustomColour, image: canHaveCustomImage });

        setState("idle");
    };

    // Upload model to remote storage
    const uploadModelRemotely = async () => {
        setState("saving");
        let err = false;
        try {
            const res = await uploadRemotely(data, existingData);
            if (setSelectedId) setSelectedId(res?.thumbnailAssetId);
        } catch {
            err = true;
            setState("error");
        }
        if (!err) {
            close();
        }
    };

    const validationCheck = () => {
        const isColourValid = data.customColour ? customiseModelValidation.colour : true;
        const isImageValid = data.customImage ? customiseModelValidation.image : true;

        // Check that all the properties are populated
        const isValid =
            data.name?.length > 0 &&
            data.thumbnail.fileName?.length > 0 &&
            data.thumbnail.imageUrl?.length > 0 &&
            data.model.fileName?.length > 0 &&
            isColourValid &&
            isImageValid &&
            (data.variableSizeModel ? dimensionValidation(data.size) : true) &&
            ModelRenderStatus.Visible === renderModelState;

        // If editing then check that updated data is different to existing
        if (existingThumbnailId) {
            return isValid && JSON.stringify(data) !== JSON.stringify(existingData);
        }

        // If not editing ensure model is populated
        return isValid && data.model.modelUrl?.length > 0;
    };

    return (
        <FormWizardTemplate
            stages={[
                {
                    isValid: validationCheck(),
                    primaryButtonText: translate(Content.common.buttons.confirm),
                    primaryButtonCallback: uploadModelRemotely,
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: close }],
                    submitButtonDataCy: "confirm-model-button",
                },
            ]}
            isSubmitting={state === "saving"}
            currentStage={0}
            title={existingThumbnailId == null ? translate(Content.map8.upload_asset.upload_model) : translate(Content.map8.upload_asset.update_model)}
        >
            {(isFetching || state === "loading") && <LinearProgress style={{ position: "absolute", width: "100%", top: "0px", left: "0px" }} />}
            {(isFetching || state === "loading") && <DisabledOverlay testId="upload-model-disabled-overlay" />}
            <StyledFieldLabel id="name">{translate(Content.map8.upload_asset.name, { assetType: translate(Content.map8.upload_asset.model) })}</StyledFieldLabel>
            <TextField
                type="text"
                aria-labelledby="name"
                name="name"
                variant="outlined"
                value={data.name || ""}
                onChange={(e) => setData("name", e.target.value)}
                data-testid="upload-asset-name"
            />
            <FromGapComponent />
            {categories && (
                <>
                    <StyledFieldLabel id="name">{translate(Content.map8.upload_asset.categories)}</StyledFieldLabel>
                    <InputWithChipsCss>
                        <Autocomplete<string, true>
                            multiple
                            options={categories}
                            onChange={(_, value) => setData("categories", value)}
                            value={data.categories}
                            renderInput={(params) => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    placeholder={translate(Content.map8.upload_asset.search_for_categories)}
                                />
                            )}
                            data-testid="asset-categories-selector"
                        />
                    </InputWithChipsCss>
                    <FromGapComponent />
                </>
            )}
            <StyledThumbnailTitle>
                <StyledFieldLabel id="select-model">
                    {translate(Content.map8.upload_asset.select_asset, { assetType: translate(Content.map8.upload_asset.model) })}
                </StyledFieldLabel>
            </StyledThumbnailTitle>
            <StyledUploadContainer>
                <FileUploadComponent
                    PreviewComponent={() => null}
                    removeRequested={() => null}
                    loading={renderModelState === ModelRenderStatus.Proccessing}
                    fileName={data.model.fileName}
                    fileThumbnailUrl={null}
                    uploadFile={uploadModelLocally}
                    uploadButtonText={translate("Select file")}
                    removeImageText={null}
                    className="file-upload"
                    persistFileSelectInput
                    ariaLabelledBy="select-model"
                    maxFileSize={3}
                    dataTestIdButton="upload-model-button"
                    dataTestIdInput="upload-model-input"
                />
            </StyledUploadContainer>
            {renderModelState === ModelRenderStatus.Error && (
                <>
                    <ErrorText data-testid="model-unsupported-error">{translate(Content.map10.modelPreview.model_not_supported_error)}</ErrorText>
                    <FromGapComponent />
                </>
            )}
            {data.model.error?.length > 0 && (
                <>
                    <ErrorText>
                        {`${translate(Content.map8.upload_asset.file_end)} ${acceptedModelFileTypes.map((t) => `.${t}`).join(` ${translate(Content.map8.upload_asset.or)} `)}`}
                    </ErrorText>
                    <FromGapComponent />
                </>
            )}
            {customiseModelValidation.image === false && data?.customImage && (
                <>
                    <ErrorText>
                        {translate(Content.map8.upload_asset.not_support_custom_image)}{" "}
                        <a href={CUSTOM_IMAGE_MODEL_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                            {translate(Content.common.here)}
                        </a>
                        .
                    </ErrorText>
                    <FromGapComponent />
                </>
            )}

            {customiseModelValidation.colour === false && data?.customColour && (
                <>
                    <ErrorText>
                        {translate(Content.map10.not_support_custom_colour)}{" "}
                        <a href={CUSTOM_COLOUR_MODEL_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                            {translate(Content.common.here)}
                        </a>
                        .
                    </ErrorText>
                    <FromGapComponent />
                </>
            )}
            <StyledModelPreviewContainer>
                <ModelPreviewComponent ref={modelPreviewRef} onCameraClicked={onCameraClicked} />
            </StyledModelPreviewContainer>
            <FromGapComponent />
            <StyledInfoBox>
                <Body2>{translate(Content.map10.modelPreview.preview_info_heading)}</Body2>
                <StyledBulletPoint className="point">
                    <Body2 className="bullet">&#8226;</Body2>
                    <Body2>{translate(Content.map10.modelPreview.preview_info_model_appears)}</Body2>
                </StyledBulletPoint>
                <StyledBulletPoint className="point">
                    <Body2 className="bullet">&#8226;</Body2>
                    <Body2>{translate(Content.map10.modelPreview.preview_info_centered)}</Body2>
                </StyledBulletPoint>
                <StyledBulletPoint className="point">
                    <Body2 className="bullet">&#8226;</Body2>
                    <Body2>{translate(Content.map10.modelPreview.preview_info_orientation)}</Body2>
                </StyledBulletPoint>
            </StyledInfoBox>
            <FromGapComponent />
            <StyledThumbnailTitle>
                <StyledFieldLabel id="select-thumbnail">{translate(Content.map8.upload_asset.select_thumbnail)}</StyledFieldLabel>
                <StyledSetAttributeButton
                    data-testid="show-model-thumbnail-file-upload-button"
                    onClick={() => {
                        setShowThumbnailFileSelector(true);
                    }}
                >
                    <Body2>{translate(Content.map10.modelShowThumbnailUploader)}</Body2>
                </StyledSetAttributeButton>
            </StyledThumbnailTitle>
            <StyledThumbnailUploadContainer>
                {showThumbnailFileSelector && (
                    <FileUploadComponent
                        PreviewComponent={null}
                        removeRequested={() => null}
                        loading={false}
                        fileName={data.thumbnail.fileName}
                        fileThumbnailUrl={data.thumbnail.imageUrl}
                        uploadFile={uploadThumbnailLocally}
                        uploadButtonText={translate(Content.map8.upload_asset.select_file)}
                        removeImageText={null}
                        inputAccept="image/*"
                        className="file-upload"
                        persistFileSelectInput
                        ariaLabelledBy="select-thumbnail"
                        maxFileSize={1}
                        dataTestIdButton="upload-model-thumbnail-button"
                        dataTestIdInput="upload-model-thumbnail-input"
                    />
                )}
            </StyledThumbnailUploadContainer>
            <StyledModelThumbnail>
                <ModelThumbnailPreview fileThumbnailUrl={data.thumbnail.imageUrl} />
            </StyledModelThumbnail>
            <FromGapComponent />
            <UploadModelVariableSizeComponent onChange={setData} values={data} />
            <FromGapComponent />
            <UploadModelCustomImageComponent onChange={setData} values={data} />
            <UploadModelCustomColourComponent onChange={setData} value={data} />
            {state === "error" && (
                <>
                    <FromGapComponent />
                    <Body2 style={{ color: "red" }}>{translate(Content.map8.upload_asset.something_went_wrong)}</Body2>
                </>
            )}
        </FormWizardTemplate>
    );
};

const StyledModelThumbnail = styled.div`
    width: 200px;
    height: 200px;
    // align in center
    margin-left: auto;
    margin-right: auto;
`;

const StyledModelPreviewContainer = styled.div`
    width: 200px;
    height: 200px;
    .mapboxgl-ctrl-top-right {
        display: block !important;
    }
    // align in center
    margin-left: auto;
    margin-right: auto;
`;

const StyledSetAttributeButton = styled(InteractiveElement)`
    text-decoration: underline;
    // align on the right
    margin-left: auto;
    margin-right: 0;
    padding-bottom: 6.4px;
`;

const StyledThumbnailTitle = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
`;

const StyledInfoBox = styled.div`
    border-radius: 4px;
    background-color: ${(props) => props.theme.shades.libraryHover};
    color: ${(props) => props.theme.primaryColors.subduedMono};
    font-size: ${fontSizes.small};
    padding: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    .point {
        padding-left: 3px;
    }
`;

const StyledBulletPoint = styled.div`
    display: flex;
    .bullet {
        padding-right: 3px;
    }
`;

const ErrorText = styled(Body2)`
    color: ${(props) => props.theme.secondaryColors.error};
    margin-top: 5px;
`;
