import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";

/** Utility class to help get list item values */
export class DataFieldListItemStore {
    private readonly apiCallToGetListItems: (dataField: string) => Promise<DataFieldListItem[]>;

    private dataFieldListItems: Record<string, DataFieldListItem[]> = {};

    private dataFieldRequestQueue: Record<string, Promise<DataFieldListItem[]>> = {};

    constructor(apiCallToGetListItems: (dataField: string) => Promise<DataFieldListItem[]>) {
        this.apiCallToGetListItems = apiCallToGetListItems;
    }

    private makeRequest(dataFieldId: string): Promise<DataFieldListItem[]> {
        // Check if we have a request for this data field already
        const request = this.dataFieldRequestQueue[dataFieldId];
        // If we have return the request
        if (request) {
            return request;
        }
        // Else make the request and store it
        this.dataFieldRequestQueue[dataFieldId] = this.apiCallToGetListItems(dataFieldId);
        return this.dataFieldRequestQueue[dataFieldId];
    }

    public async getDataFieldListItems(dataFieldId: string): Promise<DataFieldListItem[]> {
        // Check if we have the list items locally
        const listItems = this.dataFieldListItems[dataFieldId];
        if (listItems) {
            return listItems;
        }
        const listItemResponse = await this.makeRequest(dataFieldId);
        // Save response
        this.dataFieldListItems[dataFieldId] = listItemResponse;
        // Clear any requests which were being made as now it is complete
        this.dataFieldRequestQueue[dataFieldId] = undefined;
        return listItemResponse;
    }

    public async clearDataFieldListItems(dataFieldId: string): Promise<void> {
        delete this.dataFieldListItems[dataFieldId];
    }
}
