import { Button, DialogContent } from "@mui/material";
import { Theme } from "@emotion/react";
import React, { Children, FunctionComponent, MutableRefObject, ReactNode, useCallback, useContext } from "react";
import { styled, media, formButton, FillSpace, iconButtonSize, StyledIconButton } from "@iventis/styles";
import { ButtonText, Header4 } from "@iventis/styles/src/components/texts";

import { muiInputFormsCSS } from "@iventis/styles/src/mui-input-forms";

import { OptionalExceptFor } from "@iventis/types/useful.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { LoadingComponent } from "./loading";
import { FormButtonsComponent, FormButtonsProps } from "./form-buttons";
import { DialogActions, DialogContext } from "./custom-dialog-context";

export const formWizardConfirmClassName = "form-wizard-confirm";

type DialogActionsProp = { [Key in keyof DialogActions]?: true };

export type Stage = {
    primaryButtonText?: string | React.ReactNode;
    primaryButtonCallback?: () => void;
    submittingText?: string | React.ReactNode;
    submittedText?: string | React.ReactNode;
    disabledSubmitButtonText?: string;
    secondaryButtons: FormButtonsProps["secondaryButtons"];
    isValid?: boolean;
    Component?: ReactNode;
    tertiaryButtonCallback?: () => void;
    tertiaryButtonText?: string;
    tertiaryButtonDisabled?: boolean;
    tertiaryButtonDisabledCallback?: () => void;
    tertiaryButtonSpinning?: { is: boolean; text: string };
    tertiaryTestId?: string;
    showLoadingSpinner?: boolean;
    isSubmitted?: boolean;
    title?: string | ReactNode;
    dividers?: boolean;
    /** (REQUIRES Component prop) This means the whole template is ignored and we just show the given component */
    replaceTemplateWithComponent?: boolean;
    /** (REQUIRES Component prop) This means the bottom section of the form wizard is rendered by the component given at this stage */
    overrideFormButtons?: boolean;
    submitButtonDataCy?: string;
    dialogActions?: DialogActionsProp;
    submitButtonDataTestId?: string;
};

export type Stages = (
    | Stage
    | (OptionalExceptFor<Stage, "Component"> & { replaceTemplateWithComponent: true })
    | (OptionalExceptFor<Stage, "Component"> & { overrideFormButtons: true })
)[];

export const FormWizardTemplate: FunctionComponent<{
    title?: string | ReactNode; // The title of the Wizard
    currentStage: number; // The current stage of the wizard.
    stages: Stages;
    isSubmitting?: boolean;
    isDeleting?: boolean;
    className?: string;
    classNameContent?: string;
    children?: ReactNode;
    dividers?: boolean;
    footer?: ReactNode;
    contentRef?: MutableRefObject<HTMLDivElement>;
    modalTestId?: string;
    dialogActions?: DialogActionsProp;
    toggleFullScreenDataTestId?: string;
}> = ({
    title: _title,
    stages,
    currentStage,
    children,
    isSubmitting = false,
    isDeleting,
    className,
    classNameContent,
    dividers: _dividers = true,
    footer,
    contentRef,
    modalTestId,
    dialogActions: dialogActionsAllStages = {},
    toggleFullScreenDataTestId,
}) => {
    const {
        primaryButtonText,
        primaryButtonCallback,
        submittedText,
        submittingText,
        disabledSubmitButtonText = "",
        isSubmitted,
        secondaryButtons,
        isValid = true,
        tertiaryButtonText,
        tertiaryButtonCallback,
        tertiaryButtonDisabled = false,
        // called when the button is pressed and is disabled
        tertiaryButtonDisabledCallback,
        Component,
        showLoadingSpinner = isSubmitting,
        title = _title,
        dividers = _dividers,
        replaceTemplateWithComponent,
        tertiaryButtonSpinning,
        tertiaryTestId,
        overrideFormButtons,
        submitButtonDataCy,
        dialogActions: dialogActionsStageOnly = {},
        submitButtonDataTestId,
    } = stages[currentStage];

    const translate = useIventisTranslate();

    const header = useCallback(() => {
        if (title === undefined) {
            return <></>;
        }
        if (typeof title === "string") {
            return (
                <Header4 className={className} style={{ margin: "20px" }}>
                    {title}
                </Header4>
            );
        }
        return title;
    }, [title]);

    // If the form wizard lies within a dialog and the instance wishes to use the dialog actions, we need to include them in the form wizard
    const dialogContext = useContext(DialogContext);
    const dialogActions: DialogActions =
        dialogContext != null
            ? Object.entries({ ...dialogActionsAllStages, ...dialogActionsStageOnly }).reduce<DialogActions>(
                  (acc, [key, value]) => (value === true && typeof dialogContext.actions[key] === "function" ? { ...acc, [key]: dialogContext.actions[key] } : acc),
                  {}
              )
            : {};

    const content = Component ?? children;

    if (replaceTemplateWithComponent) {
        return <>{Component}</>;
    }

    return (
        <>
            {Object.keys(dialogActions).length > 0 ? (
                <StyledHeader>
                    {header()}
                    <FillSpace />
                    {typeof dialogActions.toggleFullScreen === "function" && (
                        <StyledIconButton
                            data-testid={toggleFullScreenDataTestId}
                            type="button"
                            onClick={() => dialogActions.toggleFullScreen()}
                            sx={{ height: iconButtonSize, width: iconButtonSize }}
                        >
                            <FontAwesomeIcon
                                icon={["far", dialogContext.isFullScreen ? "arrow-down-left-and-arrow-up-right-to-center" : "arrow-up-right-and-arrow-down-left-from-center"]}
                                className="dialog-action-icon"
                            />
                            <span className="hidden">{translate(Content.common.buttons[dialogContext.isFullScreen ? "minimise" : "maximise"])}</span>
                        </StyledIconButton>
                    )}
                    {typeof dialogActions.close === "function" && (
                        <StyledIconButton type="button" onClick={() => dialogActions.close()} sx={{ height: iconButtonSize, width: iconButtonSize }}>
                            <FontAwesomeIcon icon={["far", "xmark"]} className="dialog-action-icon" />
                            <span className="hidden">{translate(Content.common.buttons.close)}</span>
                        </StyledIconButton>
                    )}
                </StyledHeader>
            ) : (
                header()
            )}
            <StyledContent ref={contentRef} data-testid={modalTestId} className={classNameContent} dividers={dividers}>
                {/** If the content isn't contained in one element, we must wrap it in a div, otherwise the content could get squished */}
                {Children.count(content) > 1 ? <ContentContainer>{content}</ContentContainer> : content}
            </StyledContent>
            {footer}
            {/* Buttons */}
            {!overrideFormButtons && (
                <StyledButtons className={className}>
                    {tertiaryButtonCallback && (
                        <TertiaryButton
                            disabled={!tertiaryButtonDisabledCallback && tertiaryButtonDisabled}
                            $styledDisabled={tertiaryButtonDisabledCallback && tertiaryButtonDisabled}
                            className="delete-button"
                            style={{ height: formButton.height, width: formButton.width, marginRight: "10px" }}
                            onClick={() => (tertiaryButtonDisabled ? tertiaryButtonDisabledCallback() : tertiaryButtonCallback())}
                            data-testid={tertiaryTestId}
                        >
                            {tertiaryButtonSpinning == null && (isDeleting ? <LoadingComponent /> : tertiaryButtonText)}
                            {tertiaryButtonSpinning != null &&
                                (tertiaryButtonSpinning?.is ? (
                                    <ButtonText>
                                        {tertiaryButtonSpinning.text}{" "}
                                        <span>
                                            <FontAwesomeIcon icon="circle-notch" spin />
                                        </span>
                                    </ButtonText>
                                ) : (
                                    <span>{tertiaryButtonText}</span>
                                ))}
                        </TertiaryButton>
                    )}
                    <FormButtonsComponent
                        submitButtonText={primaryButtonText}
                        secondaryButtons={secondaryButtons}
                        handleSubmit={primaryButtonCallback}
                        disableSubmit={() => !isValid}
                        disableSubmitTooltip={disabledSubmitButtonText}
                        isSubmitting={showLoadingSpinner}
                        className={formWizardConfirmClassName}
                        submittedText={submittedText}
                        submittingText={submittingText}
                        isSubmitted={isSubmitted}
                        dataCy={submitButtonDataCy}
                        dataTestId={submitButtonDataTestId}
                    />
                </StyledButtons>
            )}
        </>
    );
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledContent = styled(DialogContent)`
    padding: 30px 24px;
    position: relative;
    display: flex;
    flex-direction: column;

    .space-within-content {
        margin-bottom: 10px;
    }
    .space-between-content {
        margin-bottom: 30px;
    }

    ${muiInputFormsCSS}

    ${media.extraSmall} {
        padding: 1rem 1rem;
    }
`;

const StyledButtons = styled.div`
    margin: 16px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const TertiaryButton = styled(Button)<{ $styledDisabled: boolean }>`
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    :hover {
        background-color: ${({ theme }: { theme: Theme }) => theme.otherColors.hover};
    }
    opacity: ${({ $styledDisabled: styledDisabled }) => (styledDisabled ? "50%" : "100%")};
    cursor: ${({ $styledDisabled: styledDisabled }) => (styledDisabled ? "default" : "pointer")};
`;

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
    .dialog-action-icon {
        width: 18px;
        height: 18px;
        margin-right: 0;
    }
`;
