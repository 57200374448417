import { StyleType } from "@iventis/domain-model/model/styleType";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { ValueSelectorComponentCreator, assetPickerComponentCreator, AssetInputProps, LoadingComponent } from "@iventis/components";
import React, { useMemo, useCallback, useContext } from "react";
import { Model } from "@iventis/domain-model/model/model";
import { Content } from "@iventis/translations/content/typed-content";
import { UnionOfStyles, UnionOfStyleProperties } from "@iventis/map-engine";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useQuery } from "@tanstack/react-query";
import { getModelQueryKey, useAssetSignatureCache } from "@iventis/utilities";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { DataField } from "@iventis/domain-model/model/dataField";
import { styled } from "@iventis/styles";
import { getStaticAndMappedValues } from "@iventis/map-engine/src/utilities/style-helpers";
import { ModelWithThumbnail } from "./model-edit-style";
import { EditStyleContext } from "./edit-style-context";
import { FormulaSummaryComponent } from "./edit-style-formula-summary";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const enumToStringArray = (enumValue: any) => Object.values(enumValue).map((value) => value.toString());

export type ChangeStyleValue<Style extends UnionOfStyles = UnionOfStyles> = <
    T extends Style,
    P extends StyleType extends T["styleType"] ? [UnionOfStyleProperties, unknown] : [keyof T, T[keyof T]]
>(
    ...args: P
) => void;

export const useModelSelector = (
    layerStyle: { model: StyleValue<string> },
    updateSelection: (id: string, model?: Model) => void,
    getModels: () => Promise<Model[]>,
    dataFields: DataField[],
    removeAttributeBasedStyling: () => void,
    editAttributeBasedStyling: () => void
) => {
    const translate = useIventisTranslate();

    const { data: models, status } = useQuery([getModelQueryKey], getModels);

    const isChosenModelBasedOnAttributes = layerStyle.model.extractionMethod === StyleValueExtractionMethod.Mapped;

    const { assetService } = useContext(EditStyleContext);

    const imageGetter = useAssetSignatureCache();

    const allModelIds = useMemo(() => getStaticAndMappedValues(layerStyle.model), [layerStyle.model]);

    const { data: selectedModels, isLoading: isGettingSelectedModels } = useQuery<ModelWithThumbnail[]>(
        [allModelIds],
        async () => {
            const selectedModels = models.filter((m) => allModelIds.includes(m.id));
            const thumbnailAssets = await assetService.getAssetsById(selectedModels.map((m) => m.thumbnailAssetId));
            return selectedModels.map((model) => {
                const thumbnailAsset = thumbnailAssets.find((asset) => asset.id === model.thumbnailAssetId);
                return { ...model, thumbnailUrl: imageGetter(thumbnailAsset.assetUrl, thumbnailAsset.authoritySignature), metadata: thumbnailAsset?.metaData };
            });
        },
        {
            enabled: models != null,
        }
    );

    const onModelSelected = (id: string) => {
        const selectedModel = models.find(({ thumbnailAssetId }) => thumbnailAssetId === id);
        updateSelection(selectedModel.id, selectedModel);
    };
    const thumbnailAssetIds = useMemo(() => models?.map(({ thumbnailAssetId }) => thumbnailAssetId), [models]);

    const PreviewComponent = ({ value }: { value: string }) => {
        const model = selectedModels.find((m) => m.id === value);
        return model == null ? <LoadingComponent /> : <AssetImagePreview src={model.thumbnailUrl} alt={model.name} />;
    };

    const modelSelector = useCallback<ValueSelectorComponentCreator<AssetInputProps>>(
        (additionalProps) => {
            switch (status) {
                case "loading":
                    return () => <LoadingComponent />;
                case "success":
                    return isChosenModelBasedOnAttributes
                        ? () => (
                              <FormulaSummaryComponent
                                  dataFields={dataFields}
                                  setFormulaDialogOpen={editAttributeBasedStyling}
                                  removeFormula={removeAttributeBasedStyling}
                                  PreviewComponent={PreviewComponent}
                                  value={layerStyle.model}
                                  dataTestId="model"
                              />
                          )
                        : assetPickerComponentCreator(additionalProps);
                case "error":
                    return () => <span>{translate(Content.map2.styles2.error_loading_models)}</span>;
                default:
                    throw new Error("Response for model selector was not recognised");
            }
        },
        [status, selectedModels, isChosenModelBasedOnAttributes, layerStyle]
    );

    // For now, we must use treat the thumbnail as the style value until we introduce data driven styling to models
    const changeModelThumbnail: ChangeStyleValue<ModelStyle> = (_, value) => onModelSelected((value as StyleValue<string>).staticValue.staticValue);

    return { selectedModels, thumbnailAssetIds, modelSelector, changeModelThumbnail, isGettingSelectedModels };
};

export const AssetImagePreview = styled.img`
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
`;
