/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-console */
import { translate } from "@iventis/translations/translation";
import { Content } from "@iventis/translations";
import mapboxgl, { GeoJSONSourceSpecification, Map } from "@iventis/mapbox-gl";
import { ModelRenderStatus } from "@iventis/types/model-types";
import { mapboxKey } from "../constants/mapbox-constants";
import { ThreeDView, frontView, cameraSvg } from "../constants/mapbox-control-svgs";
import { modelGeoJson, xyAxisGeoJsonLines, zAxisGeoJsonPolygon } from "../constants/mapbox-geojson";
import { xyAxisLayerSpec, zAxisLayerSpec } from "../constants/mapbox-layer-specs";
import { MapboxCustomControl } from "./mapbox-custom-control";
import "@iventis/mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = mapboxKey;

type Model = {
    aabb: {
        max: [number, number, number];
        min: [number, number, number];
    };
};

export class MapboxModelPreview {
    constructor(onCameraClicked: (base64Url) => void) {
        this.onCameraClicked = onCameraClicked;
    }

    public map: Map;

    private resizeMapObserver: ResizeObserver;

    public onCameraClicked: (base64Url) => void;

    /** Creates a map and returns a promise when complete */
    async createMap(element: HTMLDivElement) {
        return new Promise<void>((resolve) => {
            this.map = new Map({
                container: element,
                center: [0, 0],
                zoom: 22,
                pitch: 60,
                hash: false,
                preserveDrawingBuffer: true,
                style: { version: 8, sources: {}, layers: [{ id: "background", type: "background", paint: { "background-color": "#D3D3D3" } }] },
            });

            this.map.once("idle", () => {
                this.onResizeMap(element);
                this.AddControls();
                this.AddAxes();
                this.FitToFrontLeftView();
                this.AddSky();
                this.map.addSource("model", modelGeoJson as GeoJSONSourceSpecification);
                resolve();
                this.map.on("remove", () => {
                    this.remove();
                });
            });
        });
    }

    private AddControls() {
        this.map.addControl(new mapboxgl.FullscreenControl(), "top-right");
        this.map.addControl(new MapboxCustomControl(translate(Content.map10.modelPreview.front_left_view), this.FitToFrontLeftView, ThreeDView), "top-right");
        this.map.addControl(new MapboxCustomControl(translate(Content.map10.modelPreview.front_view), this.FitToFrontView, frontView, "0 0 448 512"), "top-right");
        this.map.addControl(
            new MapboxCustomControl(translate(Content.map10.modelPreview.capture_thumbnail), () => this.generateThumbnail().then((url) => this.onCameraClicked(url)), cameraSvg),
            "top-right"
        );
    }

    private AddAxes() {
        this.map.addSource("xy-axis", xyAxisGeoJsonLines as GeoJSONSourceSpecification);
        this.map.addSource("z-axis", zAxisGeoJsonPolygon as GeoJSONSourceSpecification);
        this.map.addLayer(zAxisLayerSpec);
        this.map.addLayer(xyAxisLayerSpec);
    }

    private AddSky() {
        this.map.addLayer({
            id: "sky",
            type: "sky",
        });
    }

    private FitToFrontLeftView() {
        this.map.fitBounds(
            [
                [0.000004, 0.000004],
                [0.00005, 0.00005],
            ],
            { pitch: 70, bearing: 45, duration: 0 }
        );
    }

    private FitToFrontView() {
        this.map.fitBounds(
            [
                [-0.00002, -0.00002],
                [0.00002, 0.0004],
            ],
            { pitch: 90, bearing: 0, duration: 0 }
        );
    }

    public getMap() {
        return this.map;
    }

    public async renderModel(modelUrl: string): Promise<ModelRenderStatus> {
        return new Promise<ModelRenderStatus>(async (resolve) => {
            this.FitToFrontLeftView();
            // Remove any existing model or layer
            if (this.map.style.getLayer("modellayer") != null) {
                this.map.removeLayer("modellayer");
            }
            if (this.map.style.modelManager.getModel("model", "") != null) {
                this.map.removeModel("model");
            }

            const model = await this.addModel(modelUrl);
            if (model == null) {
                return resolve(ModelRenderStatus.Error);
            }
            const modelScaleFactor = this.calculateModelScaleFactor(model);
            this.map.addLayer({
                id: "modellayer",
                type: "model",
                source: "model",
                layout: {
                    "model-id": "model",
                },
                paint: {
                    "model-scale": [modelScaleFactor, modelScaleFactor, modelScaleFactor],
                },
            });
            const found = await this.queryRenderedModel();
            if (found) {
                return resolve(ModelRenderStatus.Visible);
            }

            return resolve(ModelRenderStatus.Error);
        });
    }

    public generateThumbnail(): Promise<string> {
        return new Promise<string>((resolve) => {
            this.map.setLayoutProperty("z-axis", "visibility", "none");
            this.map.setLayoutProperty("xy-axis", "visibility", "none");
            this.map.setLayoutProperty("sky", "visibility", "none");
            this.map.setPaintProperty("background", "background-color", "white");

            setTimeout(() => {
                const thumbnail = this.map.getCanvas().toDataURL();
                this.map.setLayoutProperty("z-axis", "visibility", "visible");
                this.map.setLayoutProperty("xy-axis", "visibility", "visible");
                this.map.setLayoutProperty("sky", "visibility", "visible");
                this.map.setPaintProperty("background", "background-color", "#D3D3D3");
                return resolve(thumbnail);
            }, 400);
        });
    }

    /**
     * Adds a model to the map and returns the model or null if it is not loaded within 20 seconds.
     *
     * @param modelUrl - The URL of the model to be added.
     * @returns A promise that resolves to the model if it is loaded within 20 seconds, or null otherwise.
     *
     * @throws Will log an error and resolve to null if there is an issue adding the model.
     */
    private async addModel(modelUrl: string): Promise<any> {
        const interval: NodeJS.Timer | null = null;
        const timeout: NodeJS.Timer | null = null;
        return new Promise<Model | null>((resolve) => {
            try {
                this.map.addModel("model", modelUrl);

                const getModel = () => {
                    const model = this.map?.style?.modelManager?.models?.[""]?.model?.model;
                    if (model == null) {
                        return;
                    }
                    clearInterval(interval);
                    clearTimeout(timeout);
                    return resolve(model as Model);
                };

                const interval = setInterval(() => getModel(), 500);

                const timeout = setTimeout(() => {
                    clearInterval(interval);
                    return resolve(null);
                }, 20000);
            } catch (error) {
                if (interval != null) {
                    clearInterval(interval);
                }

                if (timeout != null) {
                    clearTimeout(timeout);
                }
                return resolve(null);
            }
        });
    }

    private async queryRenderedModel(): Promise<boolean> {
        let interval: NodeJS.Timer;
        let timeout: NodeJS.Timer;
        return new Promise<boolean>((resolve) => {
            try {
                const getFeatures = () => {
                    const features = this.map.queryRenderedFeatures();
                    if (features.length > 0 && features.filter((f) => f.properties?.id === "gc842f12-071f-5537-a665-bace79d0d5b3").length > 0) {
                        clearInterval(interval);
                        clearTimeout(timeout);
                        return resolve(true);
                    }
                };
                interval = setInterval(() => getFeatures(), 500);
                timeout = setTimeout(() => {
                    clearInterval(interval);
                    return resolve(false);
                }, 5000);
            } catch (error) {
                console.error(error);
                if (interval != null) {
                    clearInterval(interval);
                }
                if (timeout != null) {
                    clearTimeout(timeout);
                }
                return resolve(false);
            }
        });
    }

    private calculateModelScaleFactor(model: Model): number {
        // Determine which axis size is the largest over 4, or which axis size is the cloest to 4.
        const xSize = model.aabb.max[0] - model.aabb.min[0];
        const ySize = model.aabb.max[1] - model.aabb.min[1];
        const zSize = model.aabb.max[2] - model.aabb.min[2];
        const size = Math.max(xSize, ySize, zSize);
        const sf = 4 / size;
        return sf;
    }

    private onResizeMap(mapElement: HTMLDivElement) {
        this.resizeMapObserver = new ResizeObserver(() => {
            this.map.resize();
        });
        this.resizeMapObserver.observe(mapElement);
    }

    remove() {
        this.resizeMapObserver.disconnect();
    }
}
