import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataFieldListItemProperty } from "@iventis/domain-model/model/dataFieldListItemProperty";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { DataFieldListItemPropertyValue } from "@iventis/domain-model/model/dataFieldListItemPropertyValue";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import {
    PROPERTY_BASED_STYLING_MODEL_DIMENSION_HEIGHT_NAME,
    PROPERTY_BASED_STYLING_MODEL_DIMENSION_WIDTH_NAME,
    PROPERTY_BASED_STYLING_MODEL_DIMENSION_LENGTH_NAME,
    PropertyBasedStylingModelDimensionPropertyNames,
} from "./datafield-property-based-styling-constants";

/**
 * Validates if a list item is valid for property based styling for model dimensions (width, height, length)
 */
export function canModelDimensionsBeStyledByProperties(dataField: DataField) {
    if (dataField == null || dataField.type !== DataFieldType.List) {
        return false;
    }

    let hasWidth = false;
    let hasHeight = false;
    let hasLength = false;

    dataField.listItemProperties.forEach((property) => {
        const lowerCasePropertyName = property.name?.toLowerCase();
        const isPropertyValueNumber = property.type === DataFieldListItemPropertyType.Number;
        switch (true) {
            case lowerCasePropertyName === PROPERTY_BASED_STYLING_MODEL_DIMENSION_HEIGHT_NAME && isPropertyValueNumber:
                hasHeight = true;
                break;
            case lowerCasePropertyName === PROPERTY_BASED_STYLING_MODEL_DIMENSION_WIDTH_NAME && isPropertyValueNumber:
                hasWidth = true;
                break;
            case lowerCasePropertyName === PROPERTY_BASED_STYLING_MODEL_DIMENSION_LENGTH_NAME && isPropertyValueNumber:
                hasLength = true;
                break;
            default:
            // Do nothing
        }
    });
    return hasWidth && hasHeight && hasLength;
}

/**
 * Finds a property id from a datafield given a property name for model dimensions (width, height, length)
 */
export function getDataFieldListItemPropertyForModelDimension(dataField: DataField, propertyName: PropertyBasedStylingModelDimensionPropertyNames): DataFieldListItemProperty {
    if (dataField == null || dataField.type !== DataFieldType.List) {
        return null;
    }

    return dataField.listItemProperties?.find((property) => property.name?.toLowerCase() === propertyName) ?? null;
}

/**
 * Gets a property value from a list item for a given property name for model dimensions (width, height, length)
 */
export function getDataFieldListItemPropertyValueForModelDimension(
    dataField: DataField,
    listItem: DataFieldListItem,
    propertyName: PropertyBasedStylingModelDimensionPropertyNames,
    fallbackValue?: number
) {
    const propertyId = getDataFieldListItemPropertyForModelDimension(dataField, propertyName)?.id;
    const listItemProperty = listItem?.propertyValues.find((value) => value.propertyId === propertyId);
    return listItemProperty?.number ?? fallbackValue;
}

/**
 * Finds the property value (either number or text) from a given style value
 */
export function getDataFieldListItemPropertyValueFromStyleValue<T>(
    styleValue: StyleValue<T>,
    listItem: DataFieldListItem,
    valueProperty: keyof Pick<DataFieldListItemPropertyValue, "number" | "text">,
    fallbackValue?: T
): T {
    const propertyId = styleValue?.dataFieldListItemPropertyId;
    const property = listItem?.propertyValues.find((value) => value.propertyId === propertyId);
    return (property?.[valueProperty] as T) ?? fallbackValue;
}
