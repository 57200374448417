import { StyleType } from "@iventis/domain-model/model/styleType";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { MapImportLayer } from "./map-json-types";

/** Ensures that all necessary style values are populated */
export function ensureLayerStylesAreValid(layers: MapImportLayer[]): MapImportLayer[] {
    return layers.map((layer) => {
        switch (layer.styleType) {
            case StyleType.Model:
                return {
                    ...layer,
                    style: {
                        ...layer.style,
                        height: layer.style.height ?? defaultHeightStyleValue,
                        width: layer.style.width ?? defaultWidthStyleValue,
                        length: layer.style.length ?? defaultLengthStyleValue,
                        scale: layer.style.scale ?? defaultScaleStyleValue,
                    },
                };
            case StyleType.LineModel:
                return {
                    ...layer,
                    style: {
                        ...layer.style,
                        height: layer.style.height ?? defaultHeightStyleValue,
                        width: layer.style.width ?? defaultWidthStyleValue,
                        length: layer.style.length ?? defaultLengthStyleValue,
                        scale: layer.style.scale ?? defaultScaleStyleValue,
                    },
                };
            case StyleType.Area: {
                // Ensure 3D areas don't have text enabled
                if (layer.style.dimension?.staticValue.staticValue === AreaDimension.Three && layer.style.text?.staticValue.staticValue === true) {
                    return {
                        ...layer,
                        style: {
                            ...layer.style,
                            text: createStaticStyleValue(false),
                        },
                    };
                }
                return layer;
            }
            default:
                return layer;
        }
    });
}

const defaultHeightStyleValue: ModelStyle["height"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    multiValues: [],
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
    mappedPropertyListItemIds: [],
};

const defaultWidthStyleValue: ModelStyle["width"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    multiValues: [],
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
    mappedPropertyListItemIds: [],
};

const defaultLengthStyleValue: ModelStyle["length"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    multiValues: [],
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
    mappedPropertyListItemIds: [],
};

const defaultScaleStyleValue: ModelStyle["scale"] = {
    extractionMethod: StyleValueExtractionMethod.Static,
    mappedValues: {},
    multiValues: [],
    staticValue: { extractionMethod: ZoomableValueExtractionMethod.Static, staticValue: 1, mappedZoomValues: {}, unitType: Units.None },
    mappedPropertyListItemIds: [],
};
