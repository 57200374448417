import React from "react";
import { styled, StyledFieldLabel } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { CustomImageValue } from "@iventis/types/model-types";
import { IncrementalValueComponent } from "./selectors/incremental-value-input";
import { ModelInputContainer } from "./model-input-shared-styles";
import { DisabledOverlay } from "./disabled-overlay";

export const MAX_SIZE_IMAGE = 1000;
export const MIN_SIZE_IMAGE = 1;

export const ModelImageAspectRatioComponent = ({
    values,
    disabled,
    onValueChanged,
}: {
    onValueChanged: (property: keyof CustomImageValue["aspectRatio"], value: number) => void;
    values: CustomImageValue["aspectRatio"];
    disabled?: boolean;
}) => {
    const translate = useIventisTranslate();

    const handleValueChange = (value: number, property: "height" | "width") => {
        onValueChanged(property, value);
    };

    return (
        <AspectRatioContainer>
            <div className="selector-container">
                <div>
                    <StyledFieldLabel> {`${translate(Content.map5.fixed_shape.width)}`}</StyledFieldLabel>
                    <IncrementalValueComponent
                        changeValue={(value) => handleValueChange(value, "width")}
                        minValue={MIN_SIZE_IMAGE}
                        maxValue={MAX_SIZE_IMAGE}
                        increment={1}
                        value={values?.width}
                        decimals={0}
                        disabled={disabled}
                        hideError
                        alwaysEmitValue
                    />
                </div>
                <div>
                    <StyledFieldLabel> {`${translate(Content.map2.styles.height)}`} </StyledFieldLabel>
                    <IncrementalValueComponent
                        changeValue={(value) => handleValueChange(value, "height")}
                        minValue={MIN_SIZE_IMAGE}
                        maxValue={MAX_SIZE_IMAGE}
                        increment={1}
                        value={values?.height}
                        decimals={0}
                        disabled={disabled}
                        hideError
                        alwaysEmitValue
                    />
                </div>
            </div>
            {disabled && <DisabledOverlay />}
        </AspectRatioContainer>
    );
};

const AspectRatioContainer = styled(ModelInputContainer)`
    .selector-container {
        justify-content: flex-start;
        align-items: center;
    }
    position: relative;
`;
