import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DisabledOverlay, SimpleTooltip, StyledPopover } from "@iventis/components";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Divider, IconButton, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { styled, Body2, InteractiveElement } from "@iventis/styles";
import { Theme } from "@emotion/react";

import { InsertRowBelowComponent } from "./insert-row-below";

export const TableControls: React.FC<{
    addNewRow: () => void;
    deleteRow: () => void;
    uploadData?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    downloadData?: () => void;
    updateOptionToOverride?: (bool: boolean) => void;
    rowSelected: boolean;
    canDelete?: boolean;
}> = ({ deleteRow, uploadData, downloadData, updateOptionToOverride, rowSelected, addNewRow, canDelete }) => {
    const translate = useIventisTranslate();
    const inputRef = useRef(null);
    const importButtonRef = useRef(null);
    const theme = useTheme<Theme>();
    const [popover, setPopover] = useState<"import" | null>(null);
    const deleteDisabled = !rowSelected || !canDelete;
    return (
        <>
            <Divider />
            <StyledContainer>
                <SimpleTooltip text={translate(Content.table.addRow)} placement="bottom">
                    <IconButton data-testid="add-new-row" onClick={() => addNewRow()}>
                        <InsertRowBelowComponent />
                    </IconButton>
                </SimpleTooltip>
                {deleteRow && (
                    <SimpleTooltip text={translate(Content.table.deleteRow)} placement="bottom">
                        <span style={{ position: "relative", display: "flex" }}>
                            {deleteDisabled && <DisabledOverlay testId="delete-list-item-button-disabled" />}
                            <IconButton onClick={() => deleteRow()} data-testid="delete-list-item-button">
                                <FontAwesomeIcon icon={["far", "trash"]} size="xs" color={!deleteDisabled ? theme.primaryColors.subdued70 : theme.shades.four} />
                            </IconButton>
                        </span>
                    </SimpleTooltip>
                )}
                {uploadData && (
                    <>
                        <SimpleTooltip text={translate(Content.table.uploadData)} placement="bottom">
                            <IconButton ref={importButtonRef} onClick={() => setPopover("import")}>
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={(e) => {
                                        uploadData(e);
                                        setPopover(null);
                                    }}
                                    hidden
                                    ref={inputRef}
                                />
                                <FontAwesomeIcon icon={["far", "upload"]} size="xs" color={theme.primaryColors.subdued70} />
                            </IconButton>
                        </SimpleTooltip>
                        <StyledPopover
                            space={44}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={popover === "import"}
                            anchorEl={importButtonRef.current}
                            onClose={() => setPopover(null)}
                        >
                            {["deleteExisting", "keepExisting"].map((choice) => (
                                <InteractiveElement
                                    key={choice}
                                    className="button"
                                    type="button"
                                    onClick={() => {
                                        setPopover(null);
                                        updateOptionToOverride(choice !== "keepExisting");
                                        inputRef.current.click();
                                    }}
                                >
                                    <Body2>{translate(Content.table[choice])}</Body2>
                                </InteractiveElement>
                            ))}
                        </StyledPopover>
                    </>
                )}
                {downloadData && (
                    <SimpleTooltip text={translate(Content.table.downloadData)} placement="bottom">
                        <IconButton data-testid="export-list-table-button" onClick={() => downloadData()}>
                            <FontAwesomeIcon icon={["far", "download"]} size="xs" color={theme.primaryColors.subdued70} />
                        </IconButton>
                    </SimpleTooltip>
                )}
            </StyledContainer>
        </>
    );
};

const StyledContainer = styled.div`
    height: 46px;
    display: flex;
    justify-content: flex-end;

    button {
        width: 46px;
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    }
`;
