import { AssetType } from "@iventis/domain-model/model/assetType";
import { LineEnd } from "@iventis/domain-model/model/lineEnd";
import { LineJoin } from "@iventis/domain-model/model/lineJoin";
import { LineType } from "@iventis/domain-model/model/lineType";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { DigitalTwinCameraMode } from "@iventis/domain-model/model/digitalTwinCameraMode";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import {
    COMMENTS_OBJECT_SORT_PROPERTY,
    COMMENT_ICON_DATA_URL,
    COMMENT_VECTOR_SOURCE_ID,
    analysisLineLayerId,
    analysisLineSourceId,
    analysisPolygonLayerId,
    analysisPolygonSourceId,
    commentsLayerId,
    selectedLayerAreaSelectId,
} from "../bridge/constants";
import { LayerDrawingControl, LayerStorageScope, MapboxEngineData, MapModuleLayer, MapState, Source } from "../types/store-schema";
import { defaultAreaStyle, defaultIconStyle, outlineDefaultValues, textDefaultValues } from "../utilities/default-style-values";

export const analysisLine: MapModuleLayer = {
    id: analysisLineLayerId,
    name: "analysis-line",
    areaStyle: null,
    dataFields: [],
    iconStyle: null,
    modelStyle: null,
    pointStyle: null,
    lineModelStyle: null,
    lineStyle: {
        ...textDefaultValues,
        ...outlineDefaultValues,
        styleType: StyleType.Line,
        type: createStaticStyleValue(LineType.Solid),
        colour: {
            extractionMethod: StyleValueExtractionMethod.Mapped,
            dataFieldId: "selected",
            mappedValues: {
                selected: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    staticValue: "#454aa8",
                    unitType: Units.None,
                    mappedZoomValues: undefined,
                },
            },
            staticValue: {
                extractionMethod: ZoomableValueExtractionMethod.Static,
                staticValue: "grey",
                unitType: Units.None,
                mappedZoomValues: undefined,
            },
            multiValues: [],
            mappedPropertyListItemIds: [],
        },
        width: createStaticStyleValue(5),
        opacity: createStaticStyleValue(1),
        offset: createStaticStyleValue(0),
        end: createStaticStyleValue(LineEnd.Round),
        join: createStaticStyleValue(LineJoin.Round),
        blur: createStaticStyleValue(0),
        dash: createStaticStyleValue(0),
        arrows: createStaticStyleValue(false),
        arrowColour: null,
        arrowColourMatchesLine: null,
        arrowOpacity: null,
        arrowSize: null,
        arrowSpacing: null,
        isModel: createStaticStyleValue(false),
        model: null,
        iconPlacement: null,
    },
    selected: false,
    source: analysisLineSourceId,
    stamp: "",
    storageScope: LayerStorageScope.LocalOnly,
    styleType: StyleType.Line,
    visible: true,
    locked: false,
    mapOrder: Infinity,
    drawingControls: {
        [LayerDrawingControl.ROTATION_HANDLE]: true,
        [LayerDrawingControl.MID_POINT_HANDLE]: true,
        [LayerDrawingControl.COORDINATE_HANDLE]: true,
    },
    remote: false,
};

export const analysisPolygon: MapModuleLayer = {
    id: analysisPolygonLayerId,
    name: "analysis-area",
    areaStyle: {
        ...defaultAreaStyle,
        styleType: StyleType.Area,
        colour: {
            extractionMethod: StyleValueExtractionMethod.Mapped,
            dataFieldId: "selected",
            mappedValues: {
                selected: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    staticValue: "#454aa8",
                    unitType: Units.None,
                    mappedZoomValues: undefined,
                },
            },
            staticValue: {
                extractionMethod: ZoomableValueExtractionMethod.Static,
                staticValue: "grey",
                unitType: Units.None,
                mappedZoomValues: undefined,
            },
            multiValues: [],
            mappedPropertyListItemIds: [],
        },
        fill: createStaticStyleValue(true),
        opacity: createStaticStyleValue(0.5),
        outline: createStaticStyleValue(true),
        outlineColour: {
            extractionMethod: StyleValueExtractionMethod.Mapped,
            dataFieldId: "selected",
            mappedValues: {
                selected: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    staticValue: "#454aa8",
                    unitType: Units.None,
                    mappedZoomValues: undefined,
                },
            },
            staticValue: {
                extractionMethod: ZoomableValueExtractionMethod.Static,
                staticValue: "grey",
                unitType: Units.None,
                mappedZoomValues: undefined,
            },
            multiValues: [],
            mappedPropertyListItemIds: [],
        },
    },
    dataFields: [],
    iconStyle: null,
    modelStyle: null,
    pointStyle: null,
    lineModelStyle: null,
    lineStyle: null,
    selected: false,
    source: analysisPolygonSourceId,
    stamp: "",
    storageScope: LayerStorageScope.LocalOnly,
    styleType: StyleType.Area,
    visible: true,
    locked: false,
    mapOrder: Infinity,
    drawingControls: {
        [LayerDrawingControl.ROTATION_HANDLE]: true,
        [LayerDrawingControl.MID_POINT_HANDLE]: true,
        [LayerDrawingControl.COORDINATE_HANDLE]: true,
    },
    remote: false,
};

const commentsLayer: MapModuleLayer = {
    id: commentsLayerId,
    name: "comments",
    iconStyle: {
        ...defaultIconStyle,
        size: createStaticStyleValue(1),
        iconImage: {
            extractionMethod: StyleValueExtractionMethod.Literal,
            dataFieldId: "imageId",
            staticValue: {
                staticValue: COMMENT_ICON_DATA_URL,
                extractionMethod: ZoomableValueExtractionMethod.Static,
                unitType: Units.Meters,
                mappedZoomValues: {},
            },
            mappedValues: {},
            multiValues: [],
            mappedPropertyListItemIds: [],
        },
        objectOrder: {
            extractionMethod: StyleValueExtractionMethod.Literal,
            dataFieldId: COMMENTS_OBJECT_SORT_PROPERTY,
            staticValue: {
                staticValue: 0,
                extractionMethod: ZoomableValueExtractionMethod.Static,
                unitType: Units.Meters,
                mappedZoomValues: {},
            },
            mappedValues: {},
            multiValues: [],
            mappedPropertyListItemIds: [],
        },
    },
    dataFields: [],
    pointStyle: null,
    modelStyle: null,
    areaStyle: null,
    lineModelStyle: null,
    lineStyle: null,
    selected: false,
    source: COMMENT_VECTOR_SOURCE_ID,
    stamp: "",
    // ToDo: Change this to LocalAndRemote when the backend is ready
    storageScope: LayerStorageScope.LocalAndTiles,
    styleType: StyleType.Icon,
    visible: true,
    locked: false,
    mapOrder: Infinity,
    drawingControls: {
        [LayerDrawingControl.ROTATION_HANDLE]: false,
        [LayerDrawingControl.MID_POINT_HANDLE]: false,
        [LayerDrawingControl.COORDINATE_HANDLE]: false,
    },
    remote: false,
};

export const areaSelectLayer = {
    name: "Area select",
    lineStyle: null,
    pointStyle: null,
    iconStyle: null,
    modelStyle: null,
    lineModelStyle: null,
    dataFields: [],
    locked: false,
    id: selectedLayerAreaSelectId,
    source: "selectedArea",
    visible: true,
    selected: true,
    styleType: StyleType.Area,
    storageScope: LayerStorageScope.LocalOnly,
    stamp: "",
    remote: false,
    areaStyle: {
        ...defaultAreaStyle,
        colour: createStaticStyleValue("blue"),
        opacity: createStaticStyleValue(0.2),
    },
    drawingControls: {
        [LayerDrawingControl.ROTATION_HANDLE]: false,
        [LayerDrawingControl.MID_POINT_HANDLE]: true,
        [LayerDrawingControl.COORDINATE_HANDLE]: true,
    },
};

export const mapInitialState: MapState<MapboxEngineData> = {
    position: {
        value: {
            lng: 28.38,
            lat: 28.22,
            zoom: 2.23,
            bearing: 0,
            pitch: 0,
            source: Source.HOST,
        },
        stamp: "",
    },
    engineSpecificData: {
        styles: {
            value: {
                [AssetType.MapBackground]: {
                    version: 8,
                    name: "Preview Map",
                    sources: {},
                    layers: [
                        {
                            type: "background",
                            id: "preview-background",
                            paint: {
                                "background-color": "#FFFFFF",
                            },
                        },
                    ],
                    sprite: "",
                },
                [AssetType.SiteMap]: [],
            },
            stamp: "",
        },
    },
    mode: {
        value: "loading",
        stamp: "",
    },
    terrain3D: {
        value: {
            enabled: false,
            exaggeration: 1,
            customTerrains: [],
        },
        stamp: "",
    },
    globe: {
        value: true,
        stamp: "",
    },
    buildings3D: { value: true, stamp: "" },
    streetNames: { value: true, stamp: "" },
    layers: {
        value: [analysisLine, analysisPolygon, commentsLayer, areaSelectLayer],
        stamp: "",
    },
    analysisLayers: {
        value: [analysisLine, analysisPolygon, commentsLayer],
        stamp: "",
    },
    // Collection of gemotries from the back end, but with local object ids.
    geoJSON: {
        value: {},
        stamp: "",
    },
    pendingGeoJSON: {
        value: {},
        stamp: "",
    },
    // Maps the local id of a map object to its remote id.
    // Required because the remote id may not exist for new objects.
    // Store Object Ids if you are selecting or manipulating.
    localToRemoteMapObjectIdsMap: {
        value: {},
        stamp: "",
    },
    mapObjectsSelected: {
        value: [],
        stamp: "",
    },
    commentsSelected: {
        value: [],
        stamp: "",
    },
    onResize: "",
    bounds: {
        value: { bounds: [], source: Source.MAP },
        stamp: "",
    },
    unitOfMeasurement: {
        value: UnitOfMeasurement.Metric,
        stamp: "",
    },
    isCameraMovementLocked: {
        value: false,
        stamp: "",
    },
    overrideCursor: {
        value: undefined,
        stamp: "",
    },
    // Tile request url
    tileSources: {
        value: { comments: { bounds: undefined, tiles: [] }, objects: { bounds: undefined, tiles: [] } },
        stamp: "",
    },
    datesFilter: {
        value: { filter: false, day: null, time: null },
        stamp: "",
    },
    projectDataFields: [],
    mapId: null,
    currentLevel: 0,
    hideComments: false,
    drawingModifier: "none",
    digitalTwinEngineData: {
        cameraMode: {
            value: DigitalTwinCameraMode.Orbit,
            stamp: "",
        },
        status: null,
    },
};
