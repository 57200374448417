import type { Map } from "@iventis/mapbox-gl";
import type { Feature, LineString } from "geojson";
import type { MapObjectProperties } from "@iventis/map-types/src/map-object-properties";
import type { BehaviorSubject } from "rxjs";
import type { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import type { ModelDataStore } from "../../data-store/model-data-store";
import type { DataFieldListItemStore } from "../../data-store/data-field-list-item-store";
import type { MapboxEngineData, MapModuleLayer, MapState } from "../../types/store-schema";
import type { StylePropertyToValueMap } from "../../types/internal";
import { MapboxModelLayer } from "./mapbox-model-layer";
import { createFeaturesOnLine } from "../mapbox/3d-engine/3d-engine-helpers";
import { MapboxLineModelGuideLineLayer } from "./mapbox-line-model-guide-line-layer";
import { getOnlyNeededStyleChanges } from "../mapbox/model-helpers/model-style-helpers";

export class MapboxLineModelLayer extends MapboxModelLayer<Feature<LineString, MapObjectProperties>[], LineModelStyle> {
    private guideLineLayer: MapboxLineModelGuideLineLayer;

    private readonly styleChangesAffectingPositioning: (keyof LineModelStyle)[] = ["rotation", "spacing", "modelOffset"];

    constructor(
        map: Map,
        layer: MapModuleLayer,
        state$: BehaviorSubject<MapState<MapboxEngineData>>,
        modelDataStore: ModelDataStore,
        dataFieldListItemDataStore: DataFieldListItemStore,
        events: {
            updateGuideLine: (callback: (show: boolean, mapObjectIds: string[]) => void, layerId: string) => void;
            getAboveLayerId: (layerId: string) => string;
        }
    ) {
        super(map, layer, state$, modelDataStore, dataFieldListItemDataStore, events);
        events.updateGuideLine(this.updateGuideLine.bind(this), this.layerId);
    }

    protected async initaliseLayer(layer: MapModuleLayer): Promise<void> {
        await super.initaliseLayer(layer);
        this.guideLineLayer = new MapboxLineModelGuideLineLayer(this.state$, this.layerId, this.map);
    }

    public updateMapOrder(aboveLayerId: string): void {
        super.updateMapOrder(aboveLayerId);
        this.guideLineLayer.updateMapOrder();
    }

    public updateGuideLine(show: boolean, mapObjectIds: string[]) {
        if (show) {
            this.guideLineLayer?.showGuideLinesFor(mapObjectIds);
        } else {
            this.guideLineLayer?.removeGuideLines();
        }
    }

    public updateSource(source: Feature<LineString, MapObjectProperties>[]) {
        const layer = this.state$.value.layers.value.find((layer) => layer.id === this.layerId);
        const features = createFeaturesOnLine(this.layerId, source, layer.lineModelStyle.spacing, layer.lineModelStyle.rotation, layer.lineModelStyle.modelOffset);
        let layerSource = this.map.getSource(this.sourceId);

        if (layerSource == null) {
            this.addSource();
            layerSource = this.map.getSource(this.sourceId);
        }

        if (layerSource != null && layerSource.type === "geojson") {
            layerSource.setData(features);
        }

        this.guideLineLayer?.updateSource(source);
    }

    public async updateStyle(styleChanges: StylePropertyToValueMap<LineModelStyle>[]): Promise<void> {
        // Rotation, spacing and model offset all change the position of the models on the map and therefore we only need one of the changes
        const onlyNeededStyleChanges = getOnlyNeededStyleChanges(styleChanges, this.styleChangesAffectingPositioning);

        // Spilt the changes into two groups, one for this class to deal with and another for MapboxModelClass to deal with
        const modelStyleChanges = onlyNeededStyleChanges.filter(
            (change) => !this.styleChangesAffectingPositioning.some((positionalChange) => positionalChange === change.styleProperty)
        );
        const lineModelStyleChanges = onlyNeededStyleChanges.filter((change) =>
            this.styleChangesAffectingPositioning.some((positionalChange) => positionalChange === change.styleProperty)
        );

        await super.updateStyle(modelStyleChanges);

        for (const change of lineModelStyleChanges) {
            const { styleProperty } = change;
            switch (styleProperty) {
                case "spacing":
                case "modelOffset":
                case "rotation":
                    {
                        // All of the above styles change the positioning of the models along the lines, so just redraw them
                        const geojson = this.state$.value.geoJSON.value[this.layerId]?.map(({ feature }) => feature as Feature<LineString, MapObjectProperties>);
                        this.updateSource(geojson);
                    }
                    break;
                default:
                    throw new Error(`Style property ${styleProperty} is not handled`);
            }
        }
    }
}
