import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { FilterSpecification, Layer } from "@iventis/mapbox-gl";
import { MapModuleLayer } from "../../../types/store-schema";

/** Creates a date filter expression for a given layer with the current filter parameters */
export function createDateFilterExpression(layer: MapModuleLayer, day: number, time: number, amount: number): FilterSpecification {
    // Get all data fields that are of type RepeatedTimeRanges
    const repeatedTimeRangeDataFields = layer.dataFields.filter((df) => df.type === DataFieldType.RepeatedTimeRanges);

    // If there are no repeated time range data fields, return null
    if (repeatedTimeRangeDataFields.length === 0) {
        return null;
    }

    const dateRangeFilters: Layer["filter"] = [];
    const noDateFilterAppliedCheck: Layer["filter"] = ["all"];
    // For each repeated time range data field, create a filter expression
    repeatedTimeRangeDataFields.forEach((df) => {
        const dataFieldId = df.id;
        // There an be a maximum of number time ranges on each object so therefore we need to create a case for each one
        for (let index = 1; index <= amount; index += 1) {
            dateRangeFilters.push([
                "all",
                // Checks if the date range has been set for that map object
                ["has", `${dataFieldId}-start-date-${index}`],
                // Checks if the current date is within the selected time and day
                ["<=", ["get", `${dataFieldId}-start-date-${index}`], day],
                [">=", ["get", `${dataFieldId}-end-date-${index}`], day],
                ["<=", ["get", `${dataFieldId}-start-time-${index}`], time],
                [">=", ["get", `${dataFieldId}-end-time-${index}`], time],
            ]);
        }
        // If no time range has been added to the map object we should show it
        noDateFilterAppliedCheck.push(["!", ["has", `${dataFieldId}-start-date-1`]]);
    });
    return ["any", noDateFilterAppliedCheck, ...dateRangeFilters];
}

/** Creates a date filter expression for a local layer */
export function createDateFilterExpressionForLocalLayer(): FilterSpecification {
    return ["==", ["get", "dateFiltered"], false];
}
