/* eslint-disable no-param-reassign */
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import {
    ColumnProps,
    DataTable,
    DataTableCallbackRefs,
    DataTableOperations,
    GridMachineContext,
    IventisRowNode,
    OnCellValueChangeEvent,
    QueueDelete,
    QueueInsert,
    RowNetworkStatus,
    TableControls,
} from "@iventis/data-table";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { useTheme } from "@mui/material";
import { greyTheme, styled } from "@iventis/styles";
import { v4 as uuid } from "uuid";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { DataField } from "@iventis/domain-model/model/dataField";
import { generateNumberColumn, generateListColumn, generateTextColumn, generateRadioButtonColumn } from "@iventis/data-table/helpers/column-generators";
import { useMachine } from "@xstate/react";
import { LoadingComponent } from "@iventis/components";
import { toast } from "@iventis/toasts/src/toast";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { Theme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { AddDatafieldListItemMachine } from "./data-field-list-item-table.machine";
import {
    DataFieldListItemRowData,
    DataFieldListItemRowDataArray,
    getListItemFromRow,
    getRowFromListItem,
    parseDataFieldListItemsToRowData,
} from "./data-field-list-item-table.helpers";
import { useDataFieldListItemTableService } from "./data-field-list-item-table-services";
import { useDataFieldConfig } from "./data-fields-services";
import { RelationshipListItemCellEditor } from "./data-field-list-item-components";

const defaultColumnOptions = { resizable: true, maxWidth: 200 };

export interface DataFieldListItemTableRef {
    uploadCSV: () => void;
}

export const DataFieldListItemTable: React.FC<{
    selectedDataField: DataField;
    isDataChangedRef: MutableRefObject<boolean>;
    onNetworkRequestError: (params, refreshTable) => void;
    /**
     * On add column button click
     */
    onInsertColumn?: () => void;
    /**
     * Can add column/show add column button
     */
    canAddColumn?: boolean;
    /**
     * Can upload data via csv
     */
    allowDataUpload?: boolean;
    /**
     * Can download data via csv
     */
    allowDataDownload?: boolean;
    disabled?: boolean;
}> = ({ selectedDataField, isDataChangedRef, onNetworkRequestError, onInsertColumn, canAddColumn = false, allowDataUpload = false, allowDataDownload = false, disabled }) => {
    const services = useDataFieldListItemTableService();
    const { resourceId, allowSetDefaultValue, validateListItem: validateRow } = useDataFieldConfig();
    // Needed to update table
    const [tableKey, setTableKey] = useState("0");

    const theme = useTheme<Theme>();

    const ref = useRef<DataTableCallbackRefs>();

    const [selectedRow, setSelectedRow] = useState<IventisRowNode>(undefined);

    const [state, send] = useMachine(AddDatafieldListItemMachine, { context: { resourceId, tableApiRef: ref, hasChangedRef: isDataChangedRef, services } });
    const translate = useIventisTranslate();
    const refreshLocalCells = () => setTableKey(`${tableKey}0`);
    const refreshTable = () => {
        send("UPDATE_DATAFIELD", { payload: selectedDataField });
        refreshLocalCells();
    };

    // This stores what fields are in the current dataField's metadata/property keys
    const [dataKeys, setDataKeys] = useState<Array<string>>([]);

    useEffect(() => {
        setSelectedRow(null);
        if (selectedDataField != null) {
            send("UPDATE_DATAFIELD", { payload: selectedDataField });
            const propertyItems = selectedDataField.listItemProperties?.map((p) => p.name);
            setDataKeys([...(selectedDataField?.allowedMetadataProperties ?? []), ...(propertyItems ?? [])]);
        }
    }, [selectedDataField?.id]);

    const lastSetDefaultListItemId = useRef<string>(selectedDataField?.defaultValue?.listItemId);

    /** When the default value of a list item changes */
    const handleDefaultValueChange = (event: OnCellValueChangeEvent) => {
        const previousNodeId = lastSetDefaultListItemId.current;
        // Sets data for the row
        event.node.setDataValue("default", event.data.default);
        // If default is set to true or last set default list item is toggled again update
        if (event.data.default || event.data.id === lastSetDefaultListItemId.current) {
            lastSetDefaultListItemId.current = event.data.id;
            // Send request to update the datafield with new default value
            services.updateDefaultValue?.({
                id: selectedDataField.id,
                defaultValue: { ...selectedDataField.defaultValue, listItemId: event.data.default ? event.data.id : null },
            });
        }

        // If default value is set to true then update previous default value to false
        if (lastSetDefaultListItemId.current != null && event.data.default && previousNodeId != null) {
            const node = event.api.getRowNode(previousNodeId);
            node.setDataValue("default", false);
        }
    };

    const columnsMemoised: ColumnProps[] = useMemo(() => {
        const nameColumn = generateTextColumn({ field: "name", headerName: "Name" }, { ...defaultColumnOptions, editable: !disabled, suppressMovable: true });
        const defaultColumn = generateRadioButtonColumn(
            { field: "default", headerName: "Default" },
            { ...defaultColumnOptions, suppressMovable: true, onCellValueChanged: handleDefaultValueChange }
        );
        const propertyColumns = dataKeys.map((x) => {
            const type = selectedDataField.listItemProperties?.find((listItemProperty) => x === listItemProperty.name)?.type;
            return type === DataFieldListItemPropertyType.Number
                ? generateNumberColumn({ field: x, headerName: x }, { ...defaultColumnOptions, editable: !disabled, suppressMovable: true })
                : generateTextColumn({ field: x, headerName: x }, { ...defaultColumnOptions, editable: !disabled, suppressMovable: true });
        });
        const relationshipColumns =
            selectedDataField.listItemRelationships
                // hide the relationship when the related to data field cannot be found. This occurs when 2 project dfs relate, but only one project df has been added to the resource (Eg Layer)
                // In that case, we ignore the relationship
                ?.filter((r) => state.context?.relatedDataFieldsListItems?.[r.relatedToDataFieldId] != null)
                .map((r) =>
                    generateListColumn(
                        {
                            componentParams: {
                                listItems:
                                    state.context?.relatedDataFieldsListItems?.[r.relatedToDataFieldId] != null
                                        ? [...state.context?.relatedDataFieldsListItems?.[r.relatedToDataFieldId]]
                                        : [],
                            },
                        },
                        { field: r.id, headerName: state.context.relatedDataFields?.find((d) => d.id === r.relatedToDataFieldId)?.name || "" },
                        {
                            ...defaultColumnOptions,
                            valueGetter: (params) => {
                                const relationshipId = params.column.getColId();
                                return params.data[relationshipId];
                            },
                            valueSetter: (params) => {
                                const relationshipId = params.column.getColId();
                                params.data[relationshipId] = params.newValue;
                                return true;
                            },
                            editable: !disabled,
                            suppressMovable: true,
                            // Sorting and filtering is currently broken for this column, disabled for now
                            sortable: false,
                            filter: false,
                            cellEditor: RelationshipListItemCellEditor,
                        },
                        null
                    )
                ) || [];
        return [nameColumn, ...(!allowSetDefaultValue || services.updateDefaultValue == null ? [] : [defaultColumn]), ...propertyColumns, ...relationshipColumns];
    }, [dataKeys, state.context.relatedDataFields, state.context.relatedDataFieldsListItems]);

    const rowsMemoised: DataFieldListItemRowDataArray = useMemo(() => {
        const formattedItems: DataFieldListItemRowDataArray = parseDataFieldListItemsToRowData(state.context.listItems, selectedDataField);
        return formattedItems;
    }, [state.context.listItems]);

    useEffect(() => {
        refreshLocalCells();
    }, [columnsMemoised]);

    const addRow = async () => {
        ref.current.startEditingNewRow();
    };

    const deleteRow = () => {
        ref.current?.deleteRequest(true);
    };

    const loading = state.matches("getSelectedDataFieldTableContext") && columnsMemoised.length === 0;

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = Array.from(e.target.files);
        send("UPLOAD", { payload: files[0] });
    };

    const downloadCsv = () => {
        send("DOWNLOAD", { payload: undefined });
    };

    const handleRowOrderUpdate = async (dataFieldListItem: DataFieldListItemRowData, dataFieldListItemIdBefore: string, dataFieldListItemIdAfter: string) => {
        const dataFieldId = selectedDataField.id;
        // if an item exists before, get BE order of previous list item and pass it in
        let order = 0;
        if (dataFieldListItemIdBefore != null) {
            order = (await services.getListItem(dataFieldId, dataFieldListItemIdBefore)).order + 1;
        }
        services.updateListItemOrder(dataFieldId, { id: dataFieldListItem.id, listItemIdAfter: dataFieldListItemIdAfter, order });
    };

    const reorderingEnabled = typeof services.updateListItemOrder === "function";

    const { data, isInitialLoading } = useQuery(
        ["selected-datafield-list-item-usage", selectedDataField.id, selectedRow?.data?.id],
        () => services.isListItemBeingUsed(selectedDataField.id, selectedRow?.data?.id),
        {
            enabled: selectedRow != null && services.isListItemBeingUsed != null,
        }
    );

    const allowDelete = !data && !isInitialLoading && (selectedDataField.projectId != null || selectedRow?.data?.networkStatus === RowNetworkStatus.ExistsOnServer);

    return (
        <>
            {loading && <LoadingComponent />}
            {!loading && (
                <>
                    {!disabled && (
                        <TableControls
                            addNewRow={addRow}
                            deleteRow={services.deleteListItem == null ? undefined : deleteRow}
                            canDelete={allowDelete}
                            downloadData={allowDataDownload && services.exportListItems ? downloadCsv : null}
                            uploadData={allowDataUpload && services.importListItems ? handleFileSelected : null}
                            rowSelected={selectedRow != null}
                            updateOptionToOverride={(deleteExisting) => send({ type: "UPDATE_OPTION_TO_OVERRIDE", payload: deleteExisting })}
                        />
                    )}

                    <TableContainer>
                        <DataTable
                            draggableRows={reorderingEnabled}
                            key={tableKey}
                            onRowSelected={(rows) => setSelectedRow(rows[0])}
                            gridOptions={{
                                rowData: rowsMemoised,
                                headerHeight: 44,
                                rowHeight: 36,
                                suppressColumnVirtualisation: true,
                                stopEditingWhenCellsLoseFocus: true,
                                context: { translate, toast },
                                getRowId: (row) => row.data.id,
                                rowDragManaged: reorderingEnabled,
                                animateRows: true,
                            }}
                            ref={ref}
                            theme={greyTheme(theme)}
                            columns={columnsMemoised}
                            editRowAsync={async (context: GridMachineContext) => {
                                const queue = context.queue[0];
                                const { node } = queue.operation === DataTableOperations.Edit && queue;
                                const data = node.data as DataFieldListItemRowData;
                                const listItem = getListItemFromRow(data, selectedDataField, state.context.listItems, dataKeys);
                                await services.updateListItem(selectedDataField.id, { ...listItem, order: undefined });
                                isDataChangedRef.current = true;
                            }}
                            deleteRowAsync={async (context) => {
                                const queue = context.queue[0] as QueueDelete;
                                const { row } = queue;
                                const { id } = row;

                                await services.deleteListItem(selectedDataField.id, id);
                                send("DELETE_ITEM", { payload: id });
                                isDataChangedRef.current = true;
                                return row;
                            }}
                            onNetworkRequestSuccess={(context, event) => {
                                switch (context.operation) {
                                    case DataTableOperations.Insert: {
                                        const data = event.data as DataFieldListItem;
                                        const row = getRowFromListItem(data);
                                        ref.current.getApi().getRowNode(context.node.id).setData(row);
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            }}
                            onNetworkRequestError={(params) => onNetworkRequestError(params, refreshTable)}
                            insertRowAsync={async (context) => {
                                const { node } = context.queue[0] as QueueInsert;
                                const data = node.data as DataFieldListItemRowData;
                                const listItem = getListItemFromRow(data, selectedDataField, state.context.listItems, dataKeys);
                                send({ type: "UPDATE_LIST_ITEMS", payload: [...state.context.listItems, listItem] });
                                // Only update if the list items length is 1 or more, otherwise order of 0 is just fine
                                if (state.context.listItems.length > 0) {
                                    // Get the last list item's order
                                    const { order } = await services.getListItem(selectedDataField.id, state.context.listItems[state.context.listItems.length - 1].id);
                                    // Update list item order to the last's order + 1
                                    listItem.order = order + 1;
                                }
                                const response = await services.addListItem(selectedDataField.id, listItem);
                                isDataChangedRef.current = true;
                                ref.current.getApi().ensureNodeVisible(node, "bottom");
                                return response;
                            }}
                            onInsertColumn={onInsertColumn}
                            canAddColumn={canAddColumn && !disabled}
                            enableEmbeddedAddRow={!disabled}
                            requiredFields={["name", ...(selectedDataField.allowedMetadataProperties ?? [])]}
                            createTemplateRow={() => {
                                const customerIdName = selectedDataField.customerIdentifierName;
                                const data = {};
                                const customerIdentifier = data[customerIdName];
                                const listItem: DataFieldListItem = {
                                    id: uuid(),
                                    name: "",
                                    metaData: data,
                                    customerIdentifier,
                                    order: 0,
                                    propertyValues: [],
                                    uniqueId: undefined,
                                    dataFieldId: selectedDataField?.id,
                                    relationshipValues: [],
                                };
                                return { ...getRowFromListItem(listItem), networkStatus: RowNetworkStatus.Template };
                            }}
                            handleRowOrderUpdate={handleRowOrderUpdate}
                            validateRow={(row) => validateRow(selectedDataField, row.data)}
                        />
                    </TableContainer>
                </>
            )}
        </>
    );
};

const TableContainer = styled.div`
    display: flex;
    height: 100%;
    flex-grow: 1;
`;
