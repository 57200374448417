import { UncheckedCheckBox, CheckedCheckBox, Body2, fontSizes } from "@iventis/styles";
import React from "react";
import { Theme } from "@emotion/react";
import { useTheme } from "@mui/material";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import styled from "@emotion/styled";
import { UploadModelData, ModelVariableSize } from "@iventis/types/model-types";
import { ModelDimensionHeaders, ModelSizingComponent } from "./model-sizing-component";
import { Checkbox, ModelVariableSizeContainer } from "./model-input-shared-styles";

export const UploadModelVariableSizeComponent = ({
    onChange,
    values,
}: {
    onChange: <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => void;
    values: ModelVariableSize;
}) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const handleVariableSizeModelToggleChange = () => {
        const updatedValue = !values.variableSizeModel;
        onChange("variableSizeModel", updatedValue);
        if (!updatedValue) {
            onChange("size", { height: 0, width: 0, length: 0 });
        }
    };

    return (
        <ModelVariableSizeContainer>
            <div className="checkbox-container">
                <Checkbox
                    data-testid="variable-sized-model-checkbox"
                    icon={<UncheckedCheckBox size={15} borderStyle="1px solid rgba(0,0,0,0.23)" backgroundColour={theme.secondaryColors.blank} />}
                    checkedIcon={<CheckedCheckBox size={15} tickColour="white" borderStyle="1px solid transparent" backgroundColour={theme.primaryColors.focus} />}
                    checked={values.variableSizeModel}
                    onChange={() => handleVariableSizeModelToggleChange()}
                    size="small"
                />
                <Body2>{translate(Content.map5.styles.customDimensions)}</Body2>
            </div>
            <ModelDimensionContainer>
                <ModelDimensionHeaders className="dimension-headers" />
                <ModelSizingComponent
                    onValueChanged={(property, value) => onChange("size", { ...values.size, [property]: value })}
                    values={values.size}
                    disabled={!values.variableSizeModel}
                />
            </ModelDimensionContainer>
        </ModelVariableSizeContainer>
    );
};

const ModelDimensionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    position: relative;
    margin-bottom: 10px;

    .dimension-headers {
        font-size: ${fontSizes.small};
        font-weight: 500;
    }
`;
