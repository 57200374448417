/* eslint-disable no-param-reassign */
import { Mesh } from "three";
import { convertThreeJSModelToBlob, createMeshFromColour, parseModelArrayBuffer } from "./customise-model-helpers";
import { CUSTOM_COLOUR_MATERIAL_SLOT_NAME } from "./model-material-name-constants";

/** Given a model and a colour value, change the customisable materials to the given colour */
export async function changeModelColour(modelBlob: Blob, colour: string) {
    const modelArrayBuffer = await modelBlob.arrayBuffer();
    const threeJSModel = await parseModelArrayBuffer(modelArrayBuffer);

    if (threeJSModel == null) {
        throw new Error("Failed to load model from array buffer using threejs");
    }

    const meshWithColour = createMeshFromColour(colour);

    threeJSModel.scene.traverse((child) => {
        if (child instanceof Mesh) {
            if (child.material.name === CUSTOM_COLOUR_MATERIAL_SLOT_NAME) {
                child.material = meshWithColour;
            }
        }
    });

    return convertThreeJSModelToBlob(threeJSModel);
}
