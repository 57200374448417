import React, { FunctionComponent } from "react";
import { Body2, formGap, muiInputFormsCSS, StyledFieldLabel, styled } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { Box, LinearProgress, TextField } from "@mui/material";
import { FileUploadComponent } from "@iventis/components";
import { TerrainFromValues } from "./terrain-form-values";

export const TerrainForm: FunctionComponent<{
    terrainFormValues: TerrainFromValues;
    setTerrainFormValues: (values: TerrainFromValues) => void;
    terrainUploadProgress?: number;
    disabled?: boolean;
}> = ({ terrainFormValues, setTerrainFormValues, terrainUploadProgress, disabled }) => {
    const translate = useIventisTranslate();

    return (
        <StyledForm>
            <div className="terrain-name terrain-input-with-label">
                <StyledFieldLabel>{translate(Content.terrain.name)}</StyledFieldLabel>
                <TextField
                    disabled={disabled}
                    inputProps={{ "data-testid": "terrain-name-input" }}
                    variant="outlined"
                    value={terrainFormValues.name}
                    onChange={(event) => {
                        setTerrainFormValues({ ...terrainFormValues, name: event.target.value });
                    }}
                />
            </div>
            <div className="terrain-bounds terrain-input-with-label">
                <StyledFieldLabel>{translate(Content.terrain.bounds)}</StyledFieldLabel>
                <TextField
                    disabled={disabled}
                    inputProps={{ "data-testid": "terrain-bounds-input" }}
                    variant="outlined"
                    className="terrain-input"
                    value={terrainFormValues.bounds}
                    onChange={(event) => {
                        setTerrainFormValues({ ...terrainFormValues, bounds: event.target.value });
                    }}
                />
            </div>
            <div>
                <StyledFieldLabel>{translate(Content.terrain.tiles)}</StyledFieldLabel>
                <div className="tiles">
                    <div>
                        <FileUploadComponent
                            disabled={disabled}
                            dataTestIdButton="terrain-level-tiles-button"
                            dataTestIdInput="terrain-level-tiles-input"
                            uploadFile={(file: File) => {
                                setTerrainFormValues({ ...terrainFormValues, tileFile: file, tileFileName: file.name });
                            }}
                            uploadButtonText={translate(Content.terrain.tiles)}
                            removeRequested={() => {
                                setTerrainFormValues({ ...terrainFormValues, tileFile: null, tileFileName: null });
                            }}
                            loading={false}
                            removeImageText={translate(Content.terrain.remove_tiles)}
                            persistFileSelectInput
                            fileName={terrainFormValues.tileFileName}
                        />
                    </div>
                    {disabled && terrainUploadProgress != null && (
                        <div>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", columnGap: "10px" }}>
                                    <StyledFieldLabel>{translate(Content.terrain.uploading_tiles)}</StyledFieldLabel>
                                    <Body2>{`${Math.round(terrainUploadProgress)}%`}</Body2>
                                </Box>
                                <Box sx={{ width: "100%", mr: 1 }}>
                                    <LinearProgress variant="determinate" value={terrainUploadProgress} />
                                </Box>
                            </Box>
                        </div>
                    )}
                </div>
            </div>
        </StyledForm>
    );
};

const StyledForm = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    flex-direction: column;
    gap: ${formGap};
    .terrain-input-with-label {
        display: flex;
        flex-direction: column;
    }
    .tiles {
        gap: 10px;
        display: flex;
        flex-direction: row;
    }
`;
