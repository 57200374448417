import { ColDef, Column, GridApi, GridReadyEvent, IRowNode } from "@ag-grid-community/core";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { FilterType } from "@iventis/types";
import { v4 as uuid } from "uuid";
import { SortBy } from "../components/sort";
import { INDEX_COL_ID, KEY_BACKSPACE, KEY_F2 } from "./constants";
import { ColumnDataType, IventisCellEditorParams, IventisFilterModel, IventisTableData, RowNetworkStatus } from "../types/data-table.types";

export const autoSizeColumns = (params: GridReadyEvent): void => {
    const { columnApi } = params;
    const allColumnIds = [];
    columnApi.getColumns().forEach((column) => {
        const colId = column.getColId();
        const hasInitWidth = typeof column.getColDef().initialWidth === "number";

        if (colId !== INDEX_COL_ID && !hasInitWidth) {
            allColumnIds.push(column.getColId());
        }
    });
    columnApi.autoSizeColumns(allColumnIds, false);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFilterModel = (column: Column, filterType: ColumnDataType, operator: IventisFilterOperator, values: any, apiFilterType?: FilterType): IventisFilterModel =>
    column.isFilterActive()
        ? {
              filterType,
              fieldName: column.getColId(),
              operator,
              values,
              apiFilterType,
          }
        : null;

export const doesFilterPass = (valueInFilter: string | number, valueInRow: string | number, operator: IventisFilterOperator) => {
    switch (operator) {
        case IventisFilterOperator.Ct:
            if (typeof valueInRow !== "string" || typeof valueInFilter !== "string") {
                return false;
            }
            return valueInRow.includes(valueInFilter);
        case IventisFilterOperator.Eq:
            return typeof valueInFilter === "number" ? valueInRow === Number(valueInFilter) : valueInRow === valueInFilter;
        case IventisFilterOperator.Lt:
            return validateNumber(valueInFilter, valueInRow, valueInRow < valueInFilter);
        case IventisFilterOperator.Lte:
            return validateNumber(valueInFilter, valueInRow, valueInRow <= valueInFilter);
        case IventisFilterOperator.Gt:
            return validateNumber(valueInFilter, valueInRow, valueInRow > valueInFilter);
        case IventisFilterOperator.Gte:
            return validateNumber(valueInFilter, valueInRow, valueInRow >= valueInFilter);
        default:
            return true;
    }
};

// eslint-disable-next-line consistent-return
const validateNumber = (valueInFilter: string | number, valueInRow: string | number, equalityCheck: boolean): boolean => {
    if (typeof valueInRow !== "number" || typeof Number(valueInFilter) !== "number") {
        return false;
    }
    return equalityCheck;
};
export const nextSortBy = (prevSortBy) => {
    switch (prevSortBy) {
        case SortBy.ASC: {
            return SortBy.DESC; // If previously sorting by ascending, we switch to descending
        }
        case SortBy.DESC: {
            return null; // If previously sorting by ascending, we switch to no sort
        }
        default: {
            return SortBy.ASC; // If previously no sort, we sort by ascending
        }
    }
};

// An array of strings to represent the hours in a day in 24h format (00 to 23)
export const hourStrings = Array.from(Array(24), (v, key) => `${`0${23 - key}`.slice(-2)}`).reverse();

// An array of strings to represent the minutes (00 to 59)
export const minuteStrings = Array.from(Array(60), (v, key) => `${`0${59 - key}`.slice(-2)}`).reverse();

/** Creates the initial input value and focus based on what key was pressed (if any) to begin editing the cell */
export const createInitialState = (props: IventisCellEditorParams) => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = "";
    } else if (props.eventKey) {
        if (props.eventKey === "Enter") {
            startValue = props.value;
            highlightAllOnFocus = true;
        } else {
            // if a letter was pressed, we start with the letter
            startValue = props.eventKey;
            highlightAllOnFocus = false;
        }
    } else {
        // otherwise we start with the current value
        startValue = props.value;
        if (props.eventKey === KEY_F2) {
            highlightAllOnFocus = false;
        }
    }

    return {
        value: startValue,
        highlightAllOnFocus,
    };
};

/** Array of single digit numbers as strings */
const numbersAsText = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

/** Creates the initial number input value and focus based on what key was pressed (if any) to begin editing the cell */
export const createInitialNumberState = <TData extends IventisTableData = IventisTableData>(props: IventisCellEditorParams<TData>) => {
    const { value, highlightAllOnFocus } = createInitialState(props);
    if (numbersAsText.includes(props.eventKey)) {
        return { value, highlightAllOnFocus };
    }
    return { value: props.value, highlightAllOnFocus };
};

export const selectSingleCell = (column: Column, row: IRowNode, api: GridApi) => {
    api?.setFocusedCell(row.rowIndex, column.getColId());
};

export const clearAllSelection = (api: GridApi) => {
    api.clearFocusedCell();
    api.deselectAll();
};

export const createBasicTemplateRow = (): IventisTableData => ({ id: uuid(), canEdit: true, networkStatus: RowNetworkStatus.Template });

/** Comparator which takes into consideration our internal static rows such as template rows that always sit at the bottom */
export const iventisComparator = (fallbackComparator?: ColDef<IventisTableData>["comparator"]): ColDef<IventisTableData>["comparator"] => (
    valueA,
    valueB,
    nodeA,
    nodeB,
    isDescending
) =>
    nodeA.data?.networkStatus === RowNetworkStatus.Template && !isDescending
        ? 1
        : nodeA.data?.networkStatus === RowNetworkStatus.Template && isDescending
        ? -1
        : fallbackComparator?.(valueA, valueB, nodeA, nodeB, isDescending) ?? (JSON.stringify([valueA, valueB].sort()[0]) === JSON.stringify(valueA) && !isDescending ? -1 : 1);
