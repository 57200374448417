import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { TextStyle } from "@iventis/domain-model/model/textStyle";
import { getStaticStyleValue } from "./create-style-value-helpers";
import { getLayerStyle } from "./get-layer-style-helpers";

/** Gets the text style from a layer, will return undefined if the layer is not a style type which supports text */
export function getLayerStyleTextProperties<TLayer extends Pick<MapLayer, "styleType" | "areaStyle" | "lineStyle" | "pointStyle" | "iconStyle" | "modelStyle" | "lineModelStyle">>(
    layer: TLayer
): Omit<TextStyle, "styleType" | "objectOrder"> | undefined {
    if (layer == null || layer.styleType == null || getLayerStyle(layer) == null) {
        return undefined;
    }

    // Ignore the text properties of 3D areas. This can occur when a 2D area with list attr text content is switched to a 3D area.
    if (layer.styleType === StyleType.Area && layer.areaStyle?.dimension?.staticValue.staticValue === AreaDimension.Three) {
        return undefined;
    }

    switch (layer.styleType) {
        case StyleType.Area:
            return getTextStyleValues(layer.areaStyle);
        case StyleType.Line:
            return getTextStyleValues(layer.lineStyle);
        case StyleType.Point:
            return getTextStyleValues(layer.pointStyle);
        case StyleType.Icon:
            return getTextStyleValues(layer.iconStyle);
        default:
            return undefined;
    }
}

function getTextStyleValues(style: AreaStyle | LineStyle | PointStyle | IconStyle): Omit<TextStyle, "styleType" | "objectOrder"> | undefined {
    if (style == null) {
        return undefined;
    }

    return {
        text: style.text,
        textContent: style.textContent,
        textColour: style.textColour,
        textSize: style.textSize,
        textOverlap: style.textOverlap,
        textBold: style.textBold,
        textItalic: style.textItalic,
        textUnderlined: style.textUnderlined,
        textOutlineWidth: style.textOutlineWidth,
        textOutlineColour: style.textOutlineColour,
        textOpacity: style.textOpacity,
        textPosition: style.textPosition,
        textOffset: style.textOffset,
    };
}

export function isTextContentDeterminedByListDataField(textStyle: Omit<TextStyle, "styleType" | "objectOrder">, dataFields: DataField[]) {
    if (textStyle == null || textStyle.text == null || getStaticStyleValue(textStyle.text) === false || textStyle.textContent == null) {
        return false;
    }
    const textContentDataFieldId = textStyle?.textContent?.dataFieldId;
    const foundDataField = dataFields?.find((dataField) => dataField.id === textContentDataFieldId);
    return foundDataField?.type === DataFieldType.List;
}
