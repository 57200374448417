import type { Model } from "@iventis/domain-model/model/model";

export type ModelData = Pick<Model, "length" | "height" | "width"> & {
    /** Id to be manipulated depending on if a layer is using custom colour or image */
    modelId: string;
    /** Id of the model */
    baseModelId: string;
    assetId: string;
    /** The model file stored as a Blob */
    modelFileBlob: Blob;
    /** If this model supports custom colours */
    customColour: boolean;
    /** If this model supports adding an image to it */
    customImage: boolean;
    name: string;
};

export type ModelAsset = {
    id: string;
    url: string;
    metaData?: {
        sdf?: boolean;
        customImage?: boolean;
        customColour?: boolean;
    };
};

export class ModelDataStore {
    private modelIdsToModelData: Record<string, ModelData> = {};

    private getModelDataRequests: Record<string, Promise<ModelData[]>> = {};

    // Maybe we can load all the models at once when this class is instaniated
    constructor(private getModels: (modelIds: string[]) => Promise<Model[]>, private getModelAssets: (assetIds: string[]) => Promise<ModelAsset[]>) {}

    public async getModelById(ids: string[]): Promise<ModelData[]> {
        const loadedModels: ModelData[] = [];
        const modelRequestsBeenMadeButNotComplete: Promise<ModelData>[] = [];
        const modelRequestNotBeenMade: string[] = [];

        ids.forEach((id) => {
            // Model has already been loaded
            const alreadyLoadedModel = this.modelIdsToModelData[id];
            if (alreadyLoadedModel) {
                loadedModels.push(alreadyLoadedModel);
                return;
            }

            // Model request has been made but waiting for it to be finished
            const existingModelsRequest = this.getModelDataRequests[id];
            if (existingModelsRequest) {
                // Once model request has been finshed, get the model we are looking for
                const modelRequest = existingModelsRequest.then((models) => models.find((m) => m.modelId === id));
                modelRequestsBeenMadeButNotComplete.push(modelRequest);
                return;
            }

            // Model has not been requested yet
            modelRequestNotBeenMade.push(id);
        });

        if (modelRequestNotBeenMade.length !== 0) {
            const missingModels = await this.requestModelData(modelRequestNotBeenMade);
            loadedModels.push(...missingModels);
        }

        if (modelRequestsBeenMadeButNotComplete.length !== 0) {
            const finishedLoadingModels = await Promise.all(modelRequestsBeenMadeButNotComplete);
            loadedModels.push(...finishedLoadingModels);
        }

        return loadedModels;
    }

    public async getModelImageAssetUrl(imageId: string) {
        const [asset] = await this.getModelAssets([imageId]);
        return asset.url;
    }

    public getLoadedModelData(modelId: string) {
        return this.modelIdsToModelData[modelId];
    }

    public bustModelId(modelId: string) {
        delete this.modelIdsToModelData[modelId];
    }

    public getLoadedModelDataByName(modelName: string) {
        return Object.values(this.modelIdsToModelData).find((m) => m.name === modelName);
    }

    private async requestModelData(ids: string[]): Promise<ModelData[]> {
        const request = async () => {
            const models = await this.getModels(ids);

            if (models == null) {
                throw new Error("Get models response is undefined. Probably an issue with the request");
            }

            const assetIds = models.map((model) => model.lods[0].files[0].assetId);
            // Get the model asset and the thumbnail asset, as info about custom images and colour are on the thumbnail asset
            const assets = await this.getModelAssets([...assetIds, ...models.map((model) => model.thumbnailAssetId)]);

            if (assets == null) {
                throw new Error("Get assets response is undefined. Probably an issue with the request");
            }

            const modelData: ModelData[] = [];

            for (const model of models) {
                const assetId = model.lods[0]?.files[0]?.assetId;
                const modelAsset = assets.find((asset) => asset.id === assetId);
                const thumbnailAsset = assets.find((asset) => asset.id === model.thumbnailAssetId);

                // If the asset can't be found we will just skip over it
                if (modelAsset != null) {
                    const modelGlbBlobRes = await fetch(modelAsset.url);
                    const modelGlbBlob = await modelGlbBlobRes.blob();
                    const newModelData: ModelData = {
                        baseModelId: model.id,
                        modelId: model.id,
                        assetId: modelAsset.id,
                        modelFileBlob: modelGlbBlob,
                        customColour: thumbnailAsset?.metaData?.customColour ?? false,
                        customImage: thumbnailAsset?.metaData?.customImage ?? false,
                        height: model.height,
                        width: model.width,
                        length: model.length,
                        name: model.name,
                    };

                    this.modelIdsToModelData[newModelData.modelId] = newModelData;
                    delete this.getModelDataRequests[newModelData.modelId];
                    modelData.push(newModelData);
                }
            }
            return modelData;
        };
        const requestCall = request();
        ids.forEach((id) => {
            this.getModelDataRequests[id] = requestCall;
        });

        return requestCall;
    }
}
