export const notifications = {
    list: {
        title: "Notifications",
    },
    planExport: {
        download: "Download",
        view: "View",
        completed: "completed",
        failed: "could not be completed",
        inProgress: "is in progress",
        export: "export",
        completedStatus: "Completed",
        failedStatus: "Failed",
        inProgressStatus: "In progress",
    },
    markAsRead: "Mark as read",
    noNotifications: "There are currently no notifications.",
    layersImport: {
        "in-progress": { title: "Importing layers", description: "Importing layers: {{ name }}" },
        completed: { title: "Layers import completed", description: "Layers import completed: {{ name }}" },
        failed: { title: "Failed to import layers", description: "Failed to import layers: '{{ name }}'" },
        goToMap: "Go to map",
    },
    layersCopy: {
        "in-progress": { title: "Copying layers", description: "Copying layers: {{ name }}" },
        completed: { title: "Copy layers completed", description: "Copy layers completed" },
        failed: { title: "Failed to copy layers", description: "Failed to copy layers: '{{ name }}'" },
        goToMap: "Go to map",
    },
    layersUnlink: {
        "in-progress": { title: "Unlinking layers", description: "Unlinking layers: {{ name }}" },
        completed: { title: "Layers unlink completed", description: "Layers unlink completed" },
        failed: { title: "Failed to unlink layers", description: "Failed to unlink layers: '{{ name }}'" },
        goToMap: "Go to map",
    },
    mapsCopy: {
        "in-progress": { title: "Copying plan(s)", description: "Copying to: {{ destinationName }}" },
        completed: { title: "Copy plan(s) completed", description: "Copied to: {{ destinationName }}" },
        goToLocation: "Go to {{ destinationName }}",
        teamMapLimitReached: "Team plans limit reached.",
        somePlansWereNotCopied: "Some plans were not copied.",
        somePlansFailedToCopy: "Some plans failed to copy.",
    },
} as const;
