export const sitemaps = {
    sitemap: {
        title: {
            create: "Create sitemap",
            edit: "Edit sitemap",
        },
        name: "Name",
        key: "Key code (3 letters)",
        colour_of_label: "Colour of label",
        versions: "Versions",
        there_are_no_versions: "There are no versions on this sitemap",
        add_version: "Add version",
    },
    sitemapVersion: {
        title: {
            create: "Create a sitemap version",
            edit: "Edit a sitemap version",
        },
        name: "Name",
        level_abbreviation: "Level abbreviation (max 3 characters)",
        level_index: "Index",
        tiles: "Tiles",
        upload_tiles: "Upload tiles",
        remove_tiles: "Remove tiles",
        style: "Style",
        upload_style: "Upload style",
        remove_style: "Remove style",
        levels: "Levels",
        there_are_no_levels: "There are no levels on this version",
        add_level: "Add level",
        uploading_tiles: "Uploading {{ level }}",
        retry: "Retry",
        enter_level_name: "Enter level name",
        level_index_must_be_unique: "Level index must be unique",
        Pending: "Pending",
        Active: "Active",
        Deleted: "Deleted",
        deleteVersionWarning: "A sitemap must have at least 1 version. Please create a new version before deleting",
    },
    levelsReassignmentDialog: {
        title: "Level reassignment",
        paragraph1: "You are about to remove some levels, but some map objects may exist on these levels.",
        paragraph2: "Which levels would you like to move them to?",
    },
} as const;
