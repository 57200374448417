/* eslint-disable class-methods-use-this */
import { Map } from "@iventis/mapbox-gl";
import debounce from "lodash.debounce";
import { AttributionPosition, MapboxAttribution } from "./engine-mapbox-attribution";
import "./engine-mapbox-google-attribution.css";

export class GoogleMapboxAttribution extends MapboxAttribution {
    private readonly apiKey: string;

    private readonly sessionToken: string;

    /** 5 Seconds */
    private readonly debounceTimeMs = 5000;

    /** Maximum zoom value Google map tiles return */
    private maxGoogleMapsZoom = 20;

    /** Canvas width for when minimization is used */
    private canvasWidthAttributionMinimization = 1000;

    private mapMoveEndCallback: () => void;

    private resizeCallback: () => void;

    /** Holds last attribute value (does not have to be shown) */
    private latestAttributionValue: Node;

    /** Whether the attribution is being shown or not */
    private attributionMinimized = false;

    /** Show this if minimizeAttribution is true and the map size is small enough to show  */
    private minimizedAttributionValue = "Data sources";

    /** If the attribution has been clicked */
    private toggleAttribution = false;

    /** If the attribution can be minimized */
    private allowMinimization = false;

    /** Debounce function so it can be cancelled */
    private debounceFunction: ReturnType<typeof debounce>;

    constructor(map: Map, attributionPosition: AttributionPosition, apiKey: string, sessionKey: string) {
        super(map, attributionPosition, "google-maps-data-source-container");
        this.apiKey = apiKey;
        this.sessionToken = sessionKey;
        // Set up map listener for when the map move event has ended
        this.updateAttributionValueOnMoveEnd();
        // Set up map listener for when the map changes size
        this.onMapResize();
    }

    public async setInitialAttributionValue() {
        this.map.once("idle", async () => {
            const attributionText = await this.getAttributionValue();
            const attributionElement = this.createAttributionElements(attributionText);
            this.latestAttributionValue = attributionElement;
            if (!this.attributionMinimized) {
                this.setElementAttribution(attributionElement);
            }
        });
    }

    /** On map move update the attribution value */
    private updateAttributionValueOnMoveEnd() {
        this.mapMoveEndCallback = () => {
            // Clear debounce
            this.debounceFunction?.cancel();
            this.debounceFunction = debounce(async () => {
                this.updateAttributionValue();
            }, this.debounceTimeMs);
            this.debounceFunction();
        };
        this.map.on("moveend", this.mapMoveEndCallback);
    }

    /** Update attribution value with Google api response */
    private async updateAttributionValue() {
        const attributionText = await this.getAttributionValue();
        const attributionElement = this.createAttributionElements(attributionText);
        this.latestAttributionValue = attributionElement;
        if (!this.attributionMinimized) {
            this.setElementAttribution(attributionElement);
        }
    }

    /** Get Attribution value from Google api */
    private async getAttributionValue() {
        // Get location and zoom of the map
        const bounds = this.map.getBounds();
        const north = bounds.getNorth();
        const east = bounds.getEast();
        const south = bounds.getSouth();
        const west = bounds.getWest();
        const mapZoom = this.map.getZoom();
        // Google maps only goes up to 20 in zoom level, so cap it at this
        const zoom = mapZoom > this.maxGoogleMapsZoom ? this.maxGoogleMapsZoom : Math.round(mapZoom);
        // Make request using map values and keys
        const response = await fetch(
            `https://tile.googleapis.com/tile/v1/viewport?session=${this.sessionToken}&key=${this.apiKey}&zoom=${zoom}&north=${north}&south=${south}&east=${east}&west=${west}`
        );
        // Parse results and get copyright to show on the map
        const results = (await response.json()) as { copyright: string };
        // All Google attribution needs to start with "Google"
        return ["Google", results.copyright];
    }

    /** Show attribution on mouse enter */
    public onMouseEnter(): void {
        if (this.attributionMinimized && !this.toggleAttribution && this.allowMinimization) {
            this.setElementAttribution(this.latestAttributionValue);
            this.attributionMinimized = false;
        }
    }

    /** Hide attribution on mouse leave */
    public onMouseLeave(): void {
        if (!this.attributionMinimized && !this.toggleAttribution && this.allowMinimization) {
            this.setStringAttributionValue(this.minimizedAttributionValue);
            this.attributionMinimized = true;
        }
    }

    /** Toggle attribution on click (shows attribution till clicked again) */
    public onClick(): void {
        this.toggleAttribution = !this.toggleAttribution;
        if (this.toggleAttribution && this.allowMinimization) {
            this.setElementAttribution(this.latestAttributionValue);
        }
    }

    /** When map size changes */
    private onMapResize() {
        this.resizeCallback = () => {
            // Check width of canvas and if below threshold hide attribution
            const { width } = this.map.getCanvas();
            // When map size changes, turn toggle off
            this.toggleAttribution = false;
            if (width < this.canvasWidthAttributionMinimization) {
                this.setStringAttributionValue(this.minimizedAttributionValue);
                this.attributionMinimized = true;
                this.allowMinimization = true;
            } else {
                this.attributionMinimized = false;
                this.updateAttributionValue();
                this.allowMinimization = false;
            }
        };
        // Call to set the initial value
        this.resizeCallback();
        this.map.on("resize", this.resizeCallback);
    }

    private createAttributionElements(textElements: string[]) {
        const elements = document.createElement("div");
        elements.className = "google-maps-data-sources";
        // Each text element needs to be separated by interpuncts
        textElements.forEach((value, index) => {
            const valueContainer = document.createElement("div");
            valueContainer.innerText = value;
            elements.appendChild(valueContainer);
            // If it is not the last element
            if (index !== textElements.length - 1) {
                const interpunctContainer = document.createElement("span");
                interpunctContainer.innerText = " · ";
                interpunctContainer.className = "interpunct";
                elements.appendChild(interpunctContainer);
            }
        });
        return elements;
    }

    /** Remove the map "moveend" and "resize" events */
    public destroy() {
        super.destroy();
        this.debounceFunction?.cancel();
        this.map.off("moveend", this.mapMoveEndCallback);
        this.map.off("resize", this.resizeCallback);
    }
}
