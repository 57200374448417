import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { inlineTextIconMargin, borderRadius, inputLeftPadding } from "@iventis/styles/src/atomic-rules";
import { InteractiveElement } from "@iventis/styles/src/components";
import { Theme } from "@emotion/react";
import React, { useState, FunctionComponent } from "react";
import { styled, media } from "@iventis/styles";

import { useQuery } from "@tanstack/react-query";
import { Observable } from "rxjs";
import { Model } from "@iventis/domain-model/model/model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssetPickerComponent } from "../../../layer-styles/src/asset-picker";
import { LoadingComponent } from "../loading";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";
import { IAssetService } from "./asset-selector.types";
import { CustomDialog } from "../custom-dialog";

export interface AssetInputProps extends ValueSelector<string> {
    assetType: AssetType;
    selectorDescription: string;
    imageUrlGetter: (asset: Asset) => string;
    allowedAssetIds?: string[];
    assetService: IAssetService;
    onAssetRefresh?: Observable<unknown>;
    getModel?: (modelId: string) => Promise<Model>;
}

/** Input where an asset can be selected */
export const AssetInputComponent: FunctionComponent<AssetInputProps & { assetService: IAssetService }> = ({
    value: selectedValue,
    assetType,
    changeValue: onChangeValue,
    selectorDescription,
    imageUrlGetter,
    assetService,
    onAssetRefresh,
    getModel,
}) => {
    const [selectingAsset, setSelectingAsset] = useState(false);
    const { status, data, refetch } = useQuery(
        [selectedValue],
        async () => {
            const res = await assetService.getAsset(selectedValue);

            // If the value is null it means the currently selected value is not a valid asset id
            if (res == null) {
                onChangeValue(null);
            }
            return res;
        },
        { enabled: selectedValue != null }
    );
    onAssetRefresh?.subscribe({
        async next({ payload }: { payload: { assetId: string; thumbnailAssetId: string } }) {
            if (payload.assetId === selectedValue || payload.thumbnailAssetId === selectedValue) refetch();
        },
    });

    return (
        <>
            {selectedValue == null ? (
                <EmptyAssetPreviewButton onClick={() => setSelectingAsset(true)} selectorDescription={selectorDescription} />
            ) : status === "loading" || data == null ? (
                <LoadingComponent />
            ) : (
                <AssetPreviewButton img={imageUrlGetter(data)} name={data.name} onClick={() => setSelectingAsset(true)} selectorDescription={selectorDescription} />
            )}
            <StyledAssetPickerDialog onClose={() => setSelectingAsset(false)} open={selectingAsset}>
                <AssetPickerComponent
                    assetType={assetType}
                    onAssetSelected={(image) => {
                        onChangeValue(image.id);
                        setSelectingAsset(false);
                    }}
                    imageUrlGetter={imageUrlGetter}
                    currentAssetId={data?.id}
                    selectorDescription={selectorDescription}
                    close={() => setSelectingAsset(false)}
                    getAsset={assetService.getAsset}
                    getAssetTags={assetService.getAssetTags}
                    getAssetsByType={assetService.getAssetsByType}
                    suppressInfiniteFetching={!assetService.assetPagination}
                    postIcon={assetService.postIcon}
                    patchIcon={assetService.patchIcon}
                    postModel={assetService.postModel}
                    updateModel={assetService.updateModel}
                    getModel={getModel}
                    canEditAsset={assetService.canEditAsset}
                    deleteAsset={assetService.deleteAsset}
                    hideAssetActions={assetService.hideAssetSelectorActions}
                />
            </StyledAssetPickerDialog>
        </>
    );
};

export const AssetPreviewButton = ({
    onClick,
    name,
    img,
    selectorDescription,
    className,
    testId,
}: {
    onClick: () => void;
    name: string;
    img: string;
    selectorDescription: string;
    className?: string;
    testId?: string;
}) => (
    <StyledAssetInputContainer className={className}>
        <StyledAssetInput onClick={onClick} data-testid={testId ?? "asset-selector"}>
            <img className="asset-image" src={img} alt={name} />
            <span className="asset-text" data-testid={`selected-asset-${name}`}>
                {name}
            </span>
            {/* Hidden instruction for accessibility */}
            <span className="hidden">{selectorDescription}</span>
        </StyledAssetInput>
    </StyledAssetInputContainer>
);

export const EmptyAssetPreviewButton = ({ onClick, selectorDescription, className }: { onClick: () => void; selectorDescription: string; className?: string }) => (
    <StyledAssetInputContainer className={className}>
        <StyledAssetInput onClick={onClick} data-testid="asset-selector">
            <FontAwesomeIcon icon={["fas", "image"]} />
            <span data-testid="selected-asset-empty}">{selectorDescription}</span>
            {/* Hidden instruction for accessibility */}
            <span className="hidden">{selectorDescription}</span>
        </StyledAssetInput>
    </StyledAssetInputContainer>
);

const StyledAssetInputContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const StyledAssetInput = styled(InteractiveElement)`
    display: flex;
    align-items: center;
    gap: ${inlineTextIconMargin};
    border-radius: ${borderRadius.input};
    border: ${(props: { theme: Theme }) => `1px solid ${props.theme.otherColors.inputBorder}`};
    padding: 0 ${inputLeftPadding};
    width: 100%;
    height: ${(props: { theme: Theme }) => props.theme.sizes.iconButtonWidth};
    box-sizing: border-box;
    :hover {
        background-color: ${(props: { theme: Theme }) => props.theme.otherColors.hover};
    }
    .asset-image {
        height: auto;
        width: auto;
        max-height: 70%;
        max-width: 30%;
    }

    .asset-text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
`;

export const StyledAssetPickerDialog = styled(CustomDialog)`
    ${media.small} {
        .MuiPaper-root {
            min-width: 450px;
            min-height: 80vh;
        }
    }
`;

export const assetPickerComponentCreator: ValueSelectorComponentCreator<AssetInputProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AssetInputComponent {...genericProps} {...additionalProps} />
);

export const AssetInputPreviewComponent: FunctionComponent<{
    assetId: string;
    assetServices: IAssetService;
    imageGetter: (asset: Asset) => string;
}> = ({ assetId, assetServices, imageGetter }) => {
    const { status, data } = useQuery([assetId], () => assetServices.getAsset(assetId));

    return (
        <>
            {status === "loading" && <LoadingComponent />}
            {status === "success" && <img className="asset-image" style={{ width: "100%", height: "100%" }} src={imageGetter(data)} alt={data.name} />}
        </>
    );
};

export default AssetInputComponent;
