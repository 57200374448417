/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { ExpressionSpecification } from "@iventis/mapbox-gl";
import rhumbBearing from "@turf/rhumb-bearing";
import distance from "@turf/distance";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { IconAlignment } from "@iventis/domain-model/model/iconAlignment";
import { createLiteralStyleValue, createMultiStyleValue, createStaticStyleValue } from "@iventis/layer-style-helpers";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { isValidUuid } from "@iventis/utilities";
import cloneDeep from "lodash.clonedeep";
import { Point } from "geojson";
import { IIventisTestHelpers } from "./iventis-test-helpers";
import { MapModuleLayer } from "../../../types/store-schema";
import { IventisMapboxTestLayer } from "./mapbox-test-helper-types";
import { TypedFeature } from "../../../types/internal";

type MapboxStyles = string | ExpressionSpecification | number | boolean;

export class MapboxTestHelpers implements IIventisTestHelpers<IventisMapboxTestLayer, MapboxStyles> {
    public toIventisLayer(layers: IventisMapboxTestLayer[], features: TypedFeature[] = []): Partial<MapModuleLayer> {
        if (layers == null || layers.length === 0) return null;
        const baseLayers = layers.filter((layer) => layer.metadata.type === "base");
        let baseLayer = baseLayers[0];
        // If a guideline exists, we know it's a line model layer
        const isLineModelLayer = layers.some((l) => l.id.includes("-guide"));
        if (isLineModelLayer && baseLayers.length > 1) {
            // Make the base layer the model layer _not_ the guideline
            baseLayer = baseLayers.find((l) => !l.id.includes("-guide"));
        }
        // If there's no base, it's likely because it's an area layer with outline but no fill
        if (baseLayer == null) {
            const outline = layers.find((layer) => layer.metadata.type === "outline");
            if (outline != null) {
                return this.toIventisAreaLayer(layers);
            }
            throw new Error("No base layer found");
        }
        switch (baseLayer.type) {
            case "line":
                return this.toIventisLineLayer(layers);
            case "fill":
                return this.toIventisAreaLayer(layers);
            case "fill-extrusion":
                return this.toIventisAreaLayerFromExtrusion(layers);
            case "circle":
                return this.toIventisPointLayer(layers);
            case "symbol":
                return this.toIventisIconLayer(layers);
            case "model":
                if (isLineModelLayer) {
                    return this.toIventisLineModelLayer(layers, features);
                }
                return this.toIventisModelLayer(layers);
            default:
                // @ts-expect-error
                throw new Error(`Unsupported layer type: ${baseLayer.type}`);
        }
    }

    public toIventisAreaLayer(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        const text = layers.find((layer) => layer.metadata?.type === "text");
        const outline = layers.find((layer) => layer.metadata?.type === "outline");
        const main = base ?? outline;
        return {
            id: main.id,
            styleType: StyleType.Area,
            areaStyle: {
                styleType: StyleType.Area,
                colour: this.parseStyleValue(base?.paint?.["fill-color"]),
                opacity: this.parseStyleValue(base?.paint?.["fill-opacity"]),
                dimension: createStaticStyleValue(AreaDimension.Two),
                fill: this.parseStyleValue(base != null),
                simulation: undefined,
                simulationModel: undefined,
                simulationScale: undefined,
                simulationDirection: undefined,
                simulationDisturbution: undefined,
                grid: undefined,
                gridColour: undefined,
                gridOrientation: undefined,
                gridWidth: undefined,
                gridLength: undefined,
                height: undefined,
                outline: this.parseStyleValue(outline != null),
                outlineColour: undefined,
                outlineOpacity: undefined,
                outlineWidth: undefined,
                outlineBlur: undefined,
                text: this.parseStyleValue(text != null),
                textContent: this.parseTextContent(text?.layout?.["text-field"]),
                textColour: undefined,
                textSize: this.parseStyleValue(text?.layout?.["text-size"]),
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            visible: main.layout.visibility === "visible",
            source: main.source.toString(),
        };
    }

    public toIventisAreaLayerFromExtrusion(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        return {
            id: base.id,
            styleType: StyleType.Area,
            areaStyle: {
                styleType: StyleType.Area,
                colour: undefined,
                opacity: undefined,
                dimension: createStaticStyleValue(AreaDimension.Three),
                fill: undefined,
                simulation: undefined,
                simulationModel: undefined,
                simulationScale: undefined,
                simulationDirection: undefined,
                simulationDisturbution: undefined,
                grid: undefined,
                gridColour: undefined,
                gridOrientation: undefined,
                gridWidth: undefined,
                gridLength: undefined,
                height: undefined,
                outline: undefined,
                outlineColour: undefined,
                outlineOpacity: undefined,
                outlineWidth: undefined,
                outlineBlur: undefined,
                text: undefined,
                textContent: undefined,
                textColour: undefined,
                textSize: undefined,
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            visible: base.layout.visibility === "visible",
            source: base.source.toString(),
        };
    }

    public toIventisLineLayer(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        const arrows = layers.find((layer) => layer.metadata?.type === "arrows");
        return {
            id: base.id,
            styleType: StyleType.Line,
            lineStyle: {
                styleType: StyleType.Line,
                colour: this.parseStyleValue(base.paint["line-color"]),
                type: undefined,
                width: this.parseZoomableStyleValue(base.paint["line-width"]),
                opacity: undefined,
                offset: undefined,
                end: undefined,
                join: undefined,
                blur: undefined,
                dash: undefined,
                arrows: this.parseStyleValue(arrows != null),
                arrowColour: this.parseStyleValue(arrows?.paint?.["icon-color"]),
                arrowColourMatchesLine: undefined,
                arrowOpacity: undefined,
                arrowSize: undefined,
                arrowSpacing: this.parseStyleValue(arrows?.layout?.["symbol-spacing"]),
                isModel: undefined,
                model: undefined,
                iconPlacement: undefined,
                outline: undefined,
                outlineColour: undefined,
                outlineOpacity: undefined,
                outlineWidth: undefined,
                outlineBlur: undefined,
                text: undefined,
                textContent: undefined,
                textColour: undefined,
                textSize: undefined,
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public toIventisPointLayer(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        const text = layers.find((layer) => layer.metadata?.type === "text");
        return {
            id: base.id,
            styleType: StyleType.Point,
            pointStyle: {
                styleType: StyleType.Point,
                colour: this.parseStyleValue(base.paint?.["circle-color"]),
                opacity: undefined,
                blur: undefined,
                radius: this.parseZoomableStyleValue(base.paint?.["circle-radius"]),
                outline: undefined,
                outlineColour: undefined,
                outlineWidth: undefined,
                outlineOpacity: undefined,
                pitchAlignment: undefined,
                text: this.parseStyleValue(text != null),
                textContent: undefined,
                textColour: undefined,
                textSize: undefined,
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            source: base.source.toString(),
        };
    }

    public toIventisIconLayer(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        return {
            id: base.id,
            styleType: StyleType.Icon,
            iconStyle: {
                styleType: StyleType.Icon,
                iconImage: this.parseStyleValue(base.layout?.["icon-image"]),
                opacity: this.parseStyleValue(base.paint?.["icon-opacity"]),
                size: this.parseStyleValue(base.layout?.["icon-size"]),
                rotation: this.parseStyleValue(base.layout?.["icon-rotate"]),
                orientation: undefined,
                customColour: undefined,
                colour: undefined,
                allowOverlap: undefined,
                iconTextFit: undefined,
                iconTextFitMargin: undefined,
                text: this.parseStyleValue(base.layout?.["text-field"] != null),
                textContent: this.parseTextContent(base?.layout?.["text-field"]),
                textColour: undefined,
                textSize: this.parseStyleValue(base.layout?.["text-size"]),
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
                iconAlignment: this.parseStyleValue(base.layout?.["icon-rotation-alignment"], "iconAlignment"),
            },
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public toIventisModelLayer(layers: IventisMapboxTestLayer[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");

        const modelId = base.layout?.["model-id"];
        const modelIdStyleValue = this.parseStyleValue<string>(modelId);

        const modelStyle: ModelStyle = {
            styleType: StyleType.Model,
            model: cloneDeep(modelIdStyleValue),
            scale: this.parseStyleValue(base.paint?.["model-scale"][0]),
            height: undefined,
            width: undefined,
            length: undefined,
            customImage: cloneDeep(modelIdStyleValue),
            customColour: this.parseStyleValue(false),
            colour: cloneDeep(modelIdStyleValue),
            objectOrder: undefined,
        };

        const isDataDriven = modelStyle.model.extractionMethod === StyleValueExtractionMethod.Mapped;
        if (isDataDriven) {
            Object.entries(getLiteralValuesFromModelId(modelStyle.model.staticValue.staticValue)).forEach(([key, value]) => {
                modelStyle[key].staticValue.staticValue = value;
                if (key !== "customColour") {
                    modelStyle[key].mappedValues = Object.entries(modelStyle[key].mappedValues).reduce(
                        (acc, [listItemId, mappedValue]) => ({ ...acc, [listItemId]: { staticValue: getLiteralValuesFromModelId(mappedValue as string)[key] } }),
                        {}
                    );
                }
            });
        } else {
            Object.entries(getLiteralValuesFromModelId(modelId)).forEach(([key, value]) => {
                modelStyle[key] = this.parseStyleValue(value);
            });
        }

        return {
            id: base.id,
            styleType: StyleType.Model,
            modelStyle,
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public toIventisLineModelLayer(layers: IventisMapboxTestLayer[], features: TypedFeature[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base" && !layer.id.includes("-guide"));
        if (features[0] == null || features[1] == null) {
            console.warn(`Need at least two features to calculate rotation`);
            return null;
        }
        // Get bearing of the first two models on a line
        const firstObject = features[0];
        const secondObject = features[1];
        const bearingOfObjects = rhumbBearing((firstObject.geometry as Point).coordinates, (secondObject.geometry as Point).coordinates);
        // Add the rotation of the first object to the bearing of the features and then mod 360 to get the layer rotation
        const layerRotation = Math.round((bearingOfObjects + firstObject.properties.rotation.z) % 360);
        const rotation = createStaticStyleValue(layerRotation) as StyleValue<number>;

        // Get the space between the first two models on a line
        const bearingOfObjectsInMetersRounded = Math.round(
            distance((firstObject.geometry as Point).coordinates, (secondObject.geometry as Point).coordinates, { units: "meters" })
        );
        const spacing = createStaticStyleValue(bearingOfObjectsInMetersRounded) as StyleValue<number>;

        const lineModelStyle: LineModelStyle = {
            styleType: StyleType.LineModel,
            model: this.parseStyleValue(base.layout?.["model-id"]),
            scale: this.parseStyleValue(base.paint?.["model-scale"]),
            spacing,
            rotation,
            height: undefined,
            width: undefined,
            length: undefined,
            modelOffset: undefined,
            customImage: undefined,
            customColour: undefined,
            colour: undefined,
            objectOrder: undefined,
        };
        return {
            id: base.id,
            styleType: StyleType.LineModel,
            lineModelStyle,
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public parseStyleValue<TStyleValue>(value: MapboxStyles, styleProperty?: string): StyleValue<TStyleValue> {
        if (value == null) {
            return null;
        }

        switch (styleProperty) {
            case "iconAlignment":
                if (value === "map") {
                    return createStaticStyleValue(IconAlignment.North) as StyleValue<TStyleValue>;
                }
                if (value === "viewport") {
                    return createStaticStyleValue(IconAlignment.Screen) as StyleValue<TStyleValue>;
                }
                break;
            default:
        }

        switch (typeof value) {
            case null:
                return null;
            case "string":
            case "boolean":
            case "number":
                return createStaticStyleValue<TStyleValue>(value as TStyleValue);
            default: {
                const parsedValue: StyleValue<TStyleValue> = this.parseAttributeBasedStyleValue(value as ExpressionSpecification);

                if (parsedValue != null) {
                    return (parsedValue as StyleValue<TStyleValue>) as StyleValue<TStyleValue>;
                }
                // eslint-disable-next-line no-console
                console.warn(`Unsupported style value type: ${typeof value}`);
                return null;
            }
        }
    }

    public parseTextContent(value: ExpressionSpecification): StyleValue<string> {
        if (value == null || ((value as unknown) as string) === "") {
            return null;
        }

        if (value[0] !== "concat") {
            // Unsupported text content value
            return null;
        }

        const styleValues = [];

        for (let index = 1; index < value.length; index += 1) {
            const subValue = value[index];
            // Check if subValue is an array
            if (Array.isArray(subValue)) {
                if (subValue[0] === "case") {
                    styleValues.push(this.parseAttributeBasedStyleValue(subValue as ExpressionSpecification));
                }
                if (subValue[0] === "get") {
                    styleValues.push(this.parseLiteralStyleValue<string>(subValue as ExpressionSpecification));
                }
            }
        }

        const multiValue = createMultiStyleValue<string>("");
        multiValue.multiValues = styleValues;

        return multiValue;
    }

    private parseLiteralStyleValue<TStyleValue>(value: ExpressionSpecification): StyleValue<TStyleValue> {
        if (value == null) {
            return null;
        }

        if (value[0] === "get") {
            return createLiteralStyleValue<TStyleValue>("" as TStyleValue, value[1] as string);
        }
        return null;
    }

    private parseAttributeBasedStyleValue<TStyleValue>(value: ExpressionSpecification): StyleValue<TStyleValue> {
        if (value == null) {
            return null;
        }

        if (value[0] === "case") {
            const dataFieldId = value[1][2][1];
            const defaultValue = value[value.length - 1];
            const mappedValues = {};
            value.forEach((val, index) => {
                if (index % 2 === 1 && index !== value.length - 1) {
                    const listItemId = val[1];
                    const styleValue = value[index + 1];
                    mappedValues[listItemId] = styleValue;
                }
            });

            return {
                extractionMethod: StyleValueExtractionMethod.Mapped,
                staticValue: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    mappedZoomValues: null,
                    staticValue: defaultValue,
                    unitType: Units.None,
                },
                mappedValues,
                dataFieldId,
                multiValues: [],
                mappedPropertyListItemIds: [],
            };
        }
        return null;
    }

    private parseZoomableStyleValue<TStyleValue>(value: ExpressionSpecification): StyleValue<TStyleValue> | null {
        if (value == null) {
            return null;
        }

        if (value[0] === "interpolate") {
            const values = value.slice(3);
            const mappedZoomValues = {};
            values.forEach((val, index) => {
                if (index % 2 === 0) {
                    const zoomLevel = val;
                    const styleValue = values[index + 1];
                    mappedZoomValues[zoomLevel] = styleValue;
                }
            });
            return {
                extractionMethod: StyleValueExtractionMethod.Static,
                mappedValues: {},
                staticValue: {
                    extractionMethod: ZoomableValueExtractionMethod.Continuous,
                    mappedZoomValues,
                    staticValue: null,
                    unitType: Units.None,
                },
                multiValues: [],
                mappedPropertyListItemIds: [],
            };
        }
        return null;
    }
}

/** Gets the values for model, customImage, colour and customColour based on the given model-id from the mapbox style */
const getLiteralValuesFromModelId = (modelId: string) => {
    const [modelUuid, ...rest] = modelId.split("_");
    const customImage = rest.find((p) => isValidUuid(p));
    const colour = rest.find((p) => p.startsWith("#"));
    return {
        model: modelUuid,
        customImage,
        colour,
        customColour: colour != null,
    };
};
