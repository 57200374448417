export const xyAxisGeoJsonLines = {
    type: "geojson",
    data: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: { colour: "green" },
                geometry: {
                    coordinates: [
                        [100, 0],
                        [-100, 0],
                    ],
                    type: "LineString",
                },
            },
            {
                type: "Feature",
                properties: { colour: "red" },
                geometry: {
                    coordinates: [
                        [0, -100],
                        [0, 100],
                    ],
                    type: "LineString",
                },
            },
        ],
    },
};

export const zAxisGeoJsonPolygon = {
    type: "geojson",
    data: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {
                    colour: "blue",
                },
                geometry: {
                    coordinates: [
                        [
                            [0.0000001, 0.0000001],
                            [-0.0000001, 0.0000001],
                            [-0.0000001, -0.0000001],
                            [0.0000001, -0.0000001],
                            [0.0000001, 0.0000001],
                        ],
                    ],
                    type: "Polygon",
                },
            },
        ],
    },
};

export const modelGeoJson = {
    type: "geojson",
    data: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                id: "gc842f12-071f-5537-a665-bace79d0d5b3",
                geometry: {
                    coordinates: [0, 0],
                    type: "Point",
                },

                properties: {
                    id: "gc842f12-071f-5537-a665-bace79d0d5b3",
                },
            },
        ],
    },
};
