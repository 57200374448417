import { NodeSortAction } from "@iventis/domain-model/model/nodeSortAction";
import { FieldSort } from "@iventis/types/sorting";
import React from "react";
import { BehaviorSubject } from "rxjs";
import { NodeServices, TreeBrowserNode, TreeBrowserState, UnionOfNodes } from "../types/data-types";
import { defaultTreeBrowserSelection, TreeBrowserSelection } from "./tree-browser-selection";

export abstract class TreeBrowserStateInterface<TNode extends TreeBrowserNode> {
    public state: BehaviorSubject<TreeBrowserState<TNode>>;

    protected nodeServices: NodeServices<TNode>;

    protected readonly treeBrowserSelection: TreeBrowserSelection<TNode>;

    constructor(initialState: TreeBrowserState<TNode>, nodeServices: NodeServices<TNode>, treeBrowserSelection?: Partial<TreeBrowserSelection<TNode>>) {
        this.state = new BehaviorSubject(initialState);
        this.nodeServices = nodeServices;
        this.treeBrowserSelection = { ...defaultTreeBrowserSelection<TNode>(), ...treeBrowserSelection };
    }

    public get currentState() {
        return this.state.value;
    }

    public setState(state: TreeBrowserState<TNode>) {
        this.state.next(state);
    }

    abstract initialise(
        state: TreeBrowserState<TNode>,
        nodeServices?: NodeServices<TNode>,
        middleware?: <TEvent extends unknown>(state: TreeBrowserState<TNode>, event: TEvent) => void
    ): void;

    abstract getNode(id: string): TNode;

    abstract addNode(node: UnionOfNodes<TNode>): void;

    abstract addNodesLocally(nodes: UnionOfNodes<TNode>[]): void;

    abstract deleteNodes(nodes: UnionOfNodes<TNode>[], onDone?: (nodes: UnionOfNodes<TNode>[]) => void): void;

    abstract removeNodesLocally(nodeIds: string[], options?: { from?: string }): void;

    abstract toggleExpandNode(node: UnionOfNodes<TNode>, skipGet?: boolean, forceGet?: boolean): void;

    abstract collapseNode(node: UnionOfNodes<TNode>): void;

    abstract expandNode(node: UnionOfNodes<TNode>, skipGet?: boolean): void;

    abstract toggleSelectNodes(
        nodeIds: string[] | UnionOfNodes<TNode>[],
        options?: {
            from?: string;
            ignoreExistingSelection?: boolean;
            simulateCtrlDown?: boolean;
            affectedNodeTypes?: UnionOfNodes<TNode>["type"][];
            undoable?: boolean;
        }
    ): void;

    abstract toggleSelectAll(): void;

    abstract setMainNode(node: UnionOfNodes<TNode>): void;

    abstract setTree(node: UnionOfNodes<TNode>): void;

    abstract getThumbnail(node: UnionOfNodes<TNode>): void;

    abstract setSort(sort: FieldSort<NodeSortAction>): void;

    abstract updateNode(node: UnionOfNodes<TNode>, previousNodeParentId?: string, replaceChildren?: boolean): void;

    abstract updateNodeLocally(node: UnionOfNodes<TNode>, previousNodeParentId?: string, skipExpandedCheck?: boolean, replaceChildren?: boolean): void;

    abstract updateNodesLocally(nodes: UnionOfNodes<TNode>[]): void;

    abstract moveNodes(nodes: UnionOfNodes<TNode>[], newParentId: string): void;

    abstract copyNodes(
        nodes: UnionOfNodes<TNode>[],
        copyId: string,
        newParentId: string,
        includeMapObjects: boolean,
        keepPermissions: boolean,
        onDone?: (nodes: UnionOfNodes<TNode>[], destinationId: string, includeMapObjects: boolean, keepPermissions: boolean) => void
    ): void;

    abstract toggleFavourite(node: UnionOfNodes<TNode>, favourite: boolean): void;

    abstract setWithExistingTree(newState: Pick<TreeBrowserState<TNode>, "tree" | "mainNodeId">): void;

    abstract destroy(): void;

    abstract search(search: string): void;
}

export const TreeBrowserContext = React.createContext(null);
