import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import GeoJSON from "geojson";
import { isDataFieldValueRepeatedTimeRange, isValidUuid, parseRepeatedTimeRangeDataFieldValueToNumber } from "@iventis/utilities";
import { RouteWaypoint, LocalGeoJsonObject } from "@iventis/map-types";
import { analysisLayerIdPrefix, commentsLayerId, areaSelectLayerId, selectedLayerAreaSelectId } from "../bridge/constants";
import { DrawingModifier, MapModuleLayer } from "../types/store-schema";
import { styleTypeToGeoJSONGeometryType } from "./geojson-helpers";

export const modelLayers: StyleType[] = [StyleType.Model, StyleType.LineModel];

export const isModelLayer = <T extends { styleType: StyleType }>(layer: T): layer is T & { styleType: StyleType.Model | StyleType.LineModel } =>
    modelLayers.includes(layer?.styleType);

export const getLayersByType = <T extends { styleType: StyleType } = MapModuleLayer>(layers: T[], types: StyleType[]) => layers.filter((layer) => types.includes(layer.styleType));
export const removeLayersByType = <T extends { styleType: StyleType } = MapModuleLayer>(layers: T[], types: StyleType[]) =>
    layers.filter((layer) => !types.includes(layer.styleType));

export const getModelLayers = <T extends { styleType: StyleType } = MapModuleLayer>(layers: T[]) => getLayersByType(layers, [StyleType.Model, StyleType.LineModel]);
export const removeModelLayers = <T extends { styleType: StyleType } = MapModuleLayer>(layers: T[]) => removeLayersByType(layers, [StyleType.Model, StyleType.LineModel]);

export const measurableGeometries: GeoJSON.GeoJsonTypes[] = ["Polygon", "LineString"];

export const isMeasurableLayer = <T extends { styleType: StyleType } = MapModuleLayer>(layer: T) => measurableGeometries.includes(styleTypeToGeoJSONGeometryType(layer.styleType));

export const isAnalysisLayer = (layerId: string) => layerId?.includes(analysisLayerIdPrefix);

export const isCommentsLayer = (layerId: string) => layerId === commentsLayerId;

export const isAreaSelectLayer = (layerId: string) => areaSelectLayerId === layerId || selectedLayerAreaSelectId === layerId;

export const fixedShapeGeometries: GeoJSON.GeoJsonTypes[] = ["Polygon", "LineString"];

export const convertMapObjectToLocalGeoJson = (mapObject: MapObject, waypoints: RouteWaypoint[]): LocalGeoJsonObject => {
    const dataFieldValues = Object.entries(mapObject.dataFieldValues).reduce((cum, [key, value]) => {
        if (value === null) {
            return { ...cum, [key]: value };
        }

        if (typeof value === "object" && "id" in value) {
            return { ...cum, [key]: (value as object & Record<"id", unknown>).id };
        }

        if (isDataFieldValueRepeatedTimeRange(value)) {
            return {
                ...cum,
                [key]: parseRepeatedTimeRangeDataFieldValueToNumber(value),
            };
        }

        return { ...cum, [key]: value };
    }, {});

    return {
        objectId: mapObject.id,
        feature: {
            type: "Feature" as const,
            geometry: mapObject.geoJsonFeature.geometry,
            properties: {
                id: mapObject.id,
                layerid: mapObject.layerId,
                rotation: mapObject.geoJsonFeature.properties.rotation,
                fixedShape: mapObject.fixedShape,
                modeOfTransport: mapObject.modeOfTransport ?? mapObject.geoJsonFeature?.properties?.modeOfTransport,
                level: mapObject.level,
                sitemapId: mapObject.geoJsonFeature.properties.sitemapId,
                ...dataFieldValues,
            },
        },
        waypoints,
    };
};

/** Validation on the given layers against each drawing modifier */
export const isModifierValid: Record<DrawingModifier, (layers: MapModuleLayer[]) => boolean> = {
    snapping: (layers) => layers.every((layer) => isValidUuid(layer.id) || isAnalysisLayer(layer.id) || isAreaSelectLayer(layer.id)),
    none: () => true,
};
