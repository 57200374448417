/* eslint-disable no-console */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customMediaQueryMax, muiInputFormsCSS, screenSizeBreakpoints, styled } from "@iventis/styles";
import { inlineTextIconMargin } from "@iventis/styles/src/atomic-rules";
import { TextField } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import { Content } from "@iventis/translations/content/typed-content";
import { FormWizardTemplate, Stages, FormWizardTitle } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { isValidUuid, useConstant, useFunctionality } from "@iventis/utilities";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { v4 as uuid } from "uuid";
import { useQuery } from "@tanstack/react-query";
import { ExtractRefFromComponent } from "@iventis/types/useful.types";
import { FormWizardEditableTitle } from "@iventis/components/src/form-wizard-editable-title";
import { useMachine } from "@xstate/react";
import { dataTestIds } from "@iventis/testing";
import { getProjectDataFieldsQueryKey, useDataFieldServices } from "@iventis/datafield-editor";
import { IventisErrorBoundary } from "@iventis/error-boundaries";
import { addRequiredDataFieldsAndOrder, ensureLayerHasAllStyleProperties, swapTemplateLayerDatafields } from "./edit-layer-helpers";
import { useEditLayerServices } from "./edit-layer-services";
import { LayerTemplateSelector, TemplateLayerWithPreviewUrl } from "./layer-template-selector";
import { editLayerMachine, LayerEditStages } from "./edit-layer-machine";
import { LayerTour, tourLayerNameClassName } from "./layer-tour";
import { EditStyleWizard, StyleTypeEditor } from "./edit-style-wizard";

export interface LayerEditComponentProps {
    layer?: MapLayer;
    thumbnailImage?: string;
    mapName: string;
    enlarge?: (visible: boolean) => void;
    categoryOptions?: { categories: string[]; getExistingCategories: () => Promise<string[]> };
    isTemplateSelector?: boolean;
    allowImageUpload?: boolean;
    showTour: boolean;
    finishTour: () => void;
    navigateToProjectSettings?: () => void;
    canUserEdit: boolean;
    contentRefContainerRef?: MutableRefObject<HTMLDivElement>;
    close: () => void;
    skipTemplateSelection: boolean;
    showMapObjectDataFieldSelectionForTooltip: boolean;
}

export const LayerEditComponent: React.FC<LayerEditComponentProps> = ({
    layer = {} as MapLayer,
    mapName,
    categoryOptions,
    isTemplateSelector = false,
    allowImageUpload = false,
    enlarge,
    thumbnailImage,
    showTour,
    finishTour,
    navigateToProjectSettings,
    canUserEdit,
    contentRefContainerRef,
    close,
    skipTemplateSelection,
    showMapObjectDataFieldSelectionForTooltip,
}) => {
    const translate = useIventisTranslate();
    const functionality = useFunctionality();

    const { styleService: editStyleService, layerService } = useEditLayerServices();
    const { dataFieldsService, dataFieldListItemsService } = useDataFieldServices();

    const editLayerTitleRef = useRef<ExtractRefFromComponent<typeof FormWizardEditableTitle>>(null);
    const editing = useConstant(() => isValidUuid(layer?.id));

    const initialStage = editing ? LayerEditStages.StyleEdit : isTemplateSelector ? LayerEditStages.NameInput : LayerEditStages.TemplateSelection;

    const [state, send] = useMachine(editLayerMachine, {
        context: {
            stage: initialStage,
            editLayerTitleRef,
            existing: editing,
            layerWithModifications: editing ? ensureLayerHasAllStyleProperties({ ...layer }) : { ...layer },
            templateEditor: isTemplateSelector,
            skipTemplateSelection,
        },
        actions: {
            close,
        },
        services: {
            createFromTemplate: async (context) => {
                const layerWithStyles = ensureLayerHasAllStyleProperties(context.selectedTemplateLayer);
                const deepCopyLayerWithStyles = JSON.parse(JSON.stringify(layerWithStyles));
                const layerWithCorrectDataFields = swapTemplateLayerDatafields(deepCopyLayerWithStyles, projectDataFields);
                // Reset the layer id so it doesn't use the template id
                const newLayer = { ...layerWithCorrectDataFields, id: uuid() };
                // Create the layer and all data fields and list items if present
                await layerService.postLayer(newLayer);
                const listItemRequests: Promise<void>[] = [];
                for (let i = 0; i < newLayer.dataFields.length; i += 1) {
                    const dataField = newLayer.dataFields[i];
                    if (dataField.systemDataFieldName == null) {
                        // Considered using Promise.all() here, but the map slice needs to process these one by one,
                        // whereas list items are fired and forgotten so we can do them all at once after the data fields have been created
                        await dataFieldsService.postDataField(dataField, newLayer.id);
                        dataField.listValues?.forEach(async (listItem) => {
                            listItemRequests.push(dataFieldListItemsService.postDataFieldListItem(listItem, dataField.id, newLayer.id));
                        });
                    } else {
                        // We also need to update the system data fields otherwise the order will be out of date
                        await dataFieldsService.putDataField(dataField, newLayer.id);
                    }
                }
                if (listItemRequests.length > 0) {
                    await Promise.all(listItemRequests);
                }
                return newLayer;
            },
        },
    });

    const { stage, layerWithModifications, selectedTemplateLayer } = state.context;
    const setLayerWithModifications = (layer: Partial<MapLayer>) => send("EDIT_LAYER", { layer });

    const { data: projectDataFields } = useQuery([getProjectDataFieldsQueryKey], editStyleService.getProjectDataFields);

    const isSubmitting = state.matches("choosingTemplate.addTemplateToMap");
    const contentRef = useRef<HTMLDivElement>();

    useEffect(() => {
        enlarge(stage >= LayerEditStages.StyleEdit || stage === LayerEditStages.TemplateSelection);
    }, [stage]);

    const onTemplateLayerSelected = async (templateLayer: TemplateLayerWithPreviewUrl) => send({ type: "SELECT_TEMPLATE", templateLayer });

    const onStartFromScratch = () => {
        if (!functionality.customLayer) functionality.open("customLayer");
        else send("FROM_SCRATCH");
    };

    const onNameChanged = (name: string | null) => setLayerWithModifications({ name });

    const enterStyleEditorStage = () => {
        addRequiredDataFieldsAndOrder(layerWithModifications?.dataFields, projectDataFields, layerWithModifications.styleType, setLayerWithModifications);
        send("NEXT");
    };

    const [templateError, setTemplateError] = useState<boolean>(false);

    const stages: Stages = [
        {
            // TEMPLATE SELECTION
            primaryButtonText: translate(Content.map4.edit_layer.add_to_map),
            primaryButtonCallback: () => send("CONFIRM"),
            isValid: selectedTemplateLayer !== undefined && !state.matches("choosingTemplate.customiseTemplate") && !templateError,
            secondaryButtons: [
                {
                    onButtonPressed: enterStyleEditorStage,
                    buttonText: translate(Content.map4.edit_layer.customise),
                    buttonDisabled: selectedTemplateLayer == null || projectDataFields == null || !templateError,
                    buttonSpinning: { is: state.matches("choosingTemplate.customiseTemplate"), text: translate(Content.common.creating) },
                },
            ],
            tertiaryButtonText: translate(Content.common.buttons.close),
            tertiaryButtonCallback: () => send("BACK"),
            showLoadingSpinner: state.matches("choosingTemplate.addTemplateToMap"),
            dialogActions: { close: true, toggleFullScreen: true },
        },
        {
            // LAYER NAME INPUT
            primaryButtonText: translate(Content.common.buttons.next),
            primaryButtonCallback: () => send("NEXT"),
            isValid: typeof layerWithModifications.name === "string" && layerWithModifications.name.length !== 0,
            secondaryButtons: [
                {
                    onButtonPressed: () => {
                        onNameChanged(null);
                        editLayerTitleRef.current.updateNameExternally(null);
                        send("BACK");
                    },
                    buttonText: translate(Content.common.buttons[isTemplateSelector ? "close" : "back"]),
                },
            ],
        },
        {
            // LAYER TYPE INPUT
            primaryButtonText: translate(Content.common.buttons.next),
            primaryButtonCallback: enterStyleEditorStage,
            isValid: layerWithModifications.styleType != null && projectDataFields != null,
            secondaryButtons: [
                {
                    onButtonPressed: () => {
                        onNameChanged(null);
                        editLayerTitleRef.current.updateNameExternally(null);
                        setLayerWithModifications({ styleType: null });
                        send("BACK");
                    },
                    buttonText: translate(Content.common.buttons.back),
                },
            ],
        },
        {
            // STYLE EDIT
            replaceTemplateWithComponent: true,
            Component: (
                <EditStyleWizard
                    initialLayer={layerWithModifications}
                    allowImageUpload={allowImageUpload}
                    categoryOptions={categoryOptions}
                    canUserEdit={canUserEdit}
                    navigateToProjectSettings={navigateToProjectSettings}
                    back={() => send("BACK")}
                    close={close}
                    templateImage={thumbnailImage}
                    mapName={mapName}
                    showMapObjectDataFieldSelectionForTooltip={showMapObjectDataFieldSelectionForTooltip}
                />
            ),
        },
    ];

    const [tourRunning, setTourRunning] = useState<boolean>(showTour);

    return (
        <>
            <LayerTour
                running={stage === LayerEditStages.StyleEdit && tourRunning}
                onFinish={() => {
                    setTourRunning(false);
                    finishTour();
                }}
            >
                <FormWizardTemplate
                    title={
                        <FormWizardTitle
                            header={mapName}
                            title={
                                <FormWizardEditableTitle
                                    ref={editLayerTitleRef}
                                    entityName={layerWithModifications.name}
                                    updateNameOnEntity={onNameChanged}
                                    disableNameEdits
                                    className={tourLayerNameClassName}
                                    icon={<FontAwesomeIcon style={{ marginRight: inlineTextIconMargin }} icon={["far", "layer-group"]} />}
                                    fallbackTitle={translate(Content.map.add_layer.create_a_new_layer)}
                                />
                            }
                        />
                    }
                    contentRef={contentRef}
                    stages={stages}
                    currentStage={stage}
                    isSubmitting={isSubmitting}
                    dialogActions={{ close: true }}
                >
                    <StyledContent stage={stage} ref={contentRefContainerRef}>
                        {stage === LayerEditStages.TemplateSelection && (
                            <StyledErrorBoundary onError={() => setTemplateError(true)}>
                                <LayerTemplateSelector
                                    onStartFromScratchSelected={onStartFromScratch}
                                    onTemplateSelected={onTemplateLayerSelected}
                                    selectedTemplateLayer={selectedTemplateLayer}
                                />
                            </StyledErrorBoundary>
                        )}
                        {(stage === LayerEditStages.NameInput || stage === LayerEditStages.TypeInput) && (
                            <>
                                <StyledFieldLabel>{translate(Content.map.add_layer.name_of_layer)}</StyledFieldLabel>
                                <TextField
                                    data-testid={dataTestIds.modals.nameOfLayerInput}
                                    style={{ width: "100%" }}
                                    className="input-margin-bottom"
                                    variant="outlined"
                                    value={layerWithModifications?.name || ""}
                                    onChange={(event) => {
                                        const name = event.target.value;
                                        onNameChanged(name);
                                        editLayerTitleRef.current.updateNameExternally(name);
                                    }}
                                />
                            </>
                        )}

                        {stage === LayerEditStages.TypeInput && (
                            <StyleTypeEditor layer={layerWithModifications} layerExistsOnServer={false} setStyleType={(styleType) => setLayerWithModifications({ styleType })} />
                        )}
                    </StyledContent>
                </FormWizardTemplate>
            </LayerTour>
        </>
    );
};

const StyledContent = styled.div<{ stage: LayerEditStages }>`
    display: flex;
    flex-direction: column;

    ${({ stage }) =>
        stage === LayerEditStages.TemplateSelection &&
        // These are fixed numbers as I couldn't find a way to get the scroll working properly within the template browser and category list
        css`
            height: calc(100vh - 317px);
            height: calc(100dvh - 317px);

            ${customMediaQueryMax(screenSizeBreakpoints.extraSmall)} {
                height: calc(100vh - 225px);
                height: calc(100dvh - 225px);
            }
        `}

    ${muiInputFormsCSS}
`;

const StyledErrorBoundary = styled(IventisErrorBoundary)`
    width: auto;
`;
