import { BehaviorSubject } from "rxjs";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { EMPTY_GUID } from "@iventis/utilities";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { changeModelColour } from "@iventis/model-utilities/src/change-model-colour";
import { addImageToModel } from "@iventis/model-utilities/src/add-image-to-model";
import type { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import type { MapboxEngineData, MapModuleLayer, MapState } from "../../types/store-schema";
import type { DataFieldListItemStore } from "../../data-store/data-field-list-item-store";
import { Layer } from "./layer";
import { getModelLayerStyle } from "../../utilities/layer.helpers";
import { getStaticAndMappedValues } from "../../utilities/style-helpers";
import type { ModelLayerStyle } from "../../types/models";
import type { ModelDataStore, ModelData } from "../../data-store/model-data-store";

export abstract class ModelLayer<TSource, TStyle extends ModelStyle | LineModelStyle> extends Layer<TSource, TStyle> {
    protected readonly layerId: string;

    protected readonly sourceId: string;

    protected modelData: ModelData[] = [];

    protected dataFieldListItems: Record<string, DataFieldListItem[]> = {};

    private readonly modelDataStore: ModelDataStore;

    private readonly dataFieldListItemStore: DataFieldListItemStore;

    constructor(layer: MapModuleLayer, modelDataStore: ModelDataStore, dataFieldListItemStore: DataFieldListItemStore, state$: BehaviorSubject<MapState<MapboxEngineData>>) {
        super(state$);
        this.layerId = layer.id;
        this.sourceId = layer.id;
        this.modelDataStore = modelDataStore;
        this.dataFieldListItemStore = dataFieldListItemStore;
    }

    /** Loads all the models needed for a layer */
    protected async loadModel(iventisLayer: MapModuleLayer) {
        this.modelData = [];
        const { model: modelStyleValue, customImage, customColour, colour } = getModelLayerStyle(iventisLayer);
        const modelIds = getStaticAndMappedValues(modelStyleValue);
        const imageId = getStaticStyleValue(customImage);
        const isCustomColourBeingUsed = getStaticStyleValue(customColour);
        const useCustomImage = imageId != null && imageId !== EMPTY_GUID;
        const loadedModels = await this.modelDataStore.getModelById(modelIds);

        for (const loadedModel of loadedModels) {
            let model = { ...loadedModel };

            // Model has custom image
            if (useCustomImage && loadedModel.customImage) {
                // Get image asset url
                const imageUrl = await this.modelDataStore.getModelImageAssetUrl(imageId);
                model = {
                    ...model,
                    modelId: this.createModelId(model.baseModelId, imageId, undefined, model),
                    // Add image to model
                    modelFileBlob: await addImageToModel(model.modelFileBlob, imageUrl),
                };
            }

            // Model has custom colours
            if (isCustomColourBeingUsed && loadedModel.customColour) {
                const colourValues = getStaticAndMappedValues(colour);
                // Each custom colour requires a different model to be used
                for (const colourValue of colourValues) {
                    const modelWithColour = {
                        ...model,
                        modelId: this.createModelId(model.baseModelId, imageId, colourValue, model),
                        modelFileBlob: await changeModelColour(model.modelFileBlob, colourValue),
                    };
                    this.modelData.push(modelWithColour);
                }
            } else {
                this.modelData.push(model);
            }
        }
    }

    /** Loads all the datafield list items needed for a model layer style */
    protected async loadDataFieldListItems(iventisLayer: MapModuleLayer) {
        const style = getModelLayerStyle(iventisLayer);
        if (this.isLayerDimensionsListItemPropertyBased(style)) {
            const { dataFieldId } = style.height;
            const listItems = await this.dataFieldListItemStore.getDataFieldListItems(dataFieldId);
            this.dataFieldListItems[dataFieldId] = listItems;
        }
    }

    /**
     * Generates a model id depending on if it is using a custom image or custom colour
     * As we need to load a different model for when a custom image or custom image is being used we need to generate unique ids for each customised model
     *
     * @example
     * Model - "{modelId}"
     * Model with image - "{modelId}:{customImageId}"
     * Model with colour - "{modelId}:{colourValue}"
     * Model with image and colour - "{modelId}:{customImageId}:{colourValue}"
     */
    public createModelId(modelId: string, customImageId?: string, colourValue?: string, _model?: ModelData) {
        let id = modelId;
        const model = _model ?? this.modelData.find(({ baseModelId }) => baseModelId === modelId);

        // If custom image id is EMPTY_GUID we know that it does not have a custom image
        if (customImageId != null && customImageId !== EMPTY_GUID && model.customImage) {
            id += `_${customImageId}`;
        }

        if (colourValue != null && model.customColour) {
            id += `_${colourValue}`;
        }

        return id;
    }

    public isLayerDimensionsBasedOnDataFields(modelStyle: ModelLayerStyle) {
        const { height, width, length } = modelStyle;

        if (height == null || width == null || length == null) {
            return false;
        }

        return (
            height.extractionMethod === StyleValueExtractionMethod.Mapped &&
            length.extractionMethod === StyleValueExtractionMethod.Mapped &&
            width.extractionMethod === StyleValueExtractionMethod.Mapped
        );
    }

    public isLayerDimensionsListItemPropertyBased(modelStyle: ModelLayerStyle) {
        const { height, width, length } = modelStyle;

        if (height == null || width == null || length == null) {
            return false;
        }

        return (
            height.extractionMethod === StyleValueExtractionMethod.MappedProperty &&
            length.extractionMethod === StyleValueExtractionMethod.MappedProperty &&
            width.extractionMethod === StyleValueExtractionMethod.MappedProperty
        );
    }
}
