import type { AnyLayerStyle, StylePropertyToValueMap } from "../../../types/style-types";

/** Checks style changes to see if many style changes effect the same style property
 *
 * For example: Scale, Width, Height and Length all effect mapbox style property of scale
 */
export function getOnlyNeededStyleChanges<TStyle extends AnyLayerStyle>(styleChanges: StylePropertyToValueMap<TStyle>[], stylesAffectingSameProperty: string[]) {
    let propertyFound = false;
    return styleChanges.reduce<StylePropertyToValueMap<TStyle>[]>((changes, change) => {
        if (propertyFound && stylesAffectingSameProperty.some((styleProperty) => styleProperty === change.styleProperty)) {
            return changes;
        }
        propertyFound = stylesAffectingSameProperty.some((styleProperty) => styleProperty === change.styleProperty);
        changes.push(change);
        return changes;
    }, []);
}
