import { FilterSpecification } from "@iventis/mapbox-gl";

export enum FilterType {
    Level = "Level",
    Date = "Date",
    LayerBase = "LayerBase",
    MapObject = "MapObject",
    LineModelGuideLine = "LineModelGuideLine",
}

export type SitemapLevelFilter = ["==", ["get", "level"], number] & FilterSpecification;
