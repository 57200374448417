import React, { useState } from "react";
import { FormWizardTemplate, AutocompleteWithLoading } from "@iventis/components";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { Body1, HorizontalGap, StyledFieldLabel } from "@iventis/styles";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";

export interface SitemapVersionLevelReassignment {
    removedLevel: SitemapVersionLevel;
    fallbackLevel: SitemapVersionLevel;
}

/**
 * If a level has been removed, we need to ensure any map objects that existed on that level are reassigned to a new level
 */
export const LevelReassignmentForm = ({
    availableSitemapVersions,
    existingLevelsRemoved,
    close,
    confirm,
}: {
    availableSitemapVersions: SitemapVersion[];
    existingLevelsRemoved: SitemapVersionLevel[];
    close: () => void;
    confirm: (adjustments: SitemapVersionLevelReassignment[]) => void;
}) => {
    const translate = useIventisTranslate();

    const [sitemapVersionLevelAdjustment, setSitemapVersionLevelAdjustment] = useState<{ [removedLevelId: string]: SitemapVersionLevelReassignment }>({});

    const onConfirm = () => {
        confirm(Object.values(sitemapVersionLevelAdjustment));
        close();
    };

    const isValid = existingLevelsRemoved?.every((level) => sitemapVersionLevelAdjustment[level.id]?.fallbackLevel != null);

    return (
        <FormWizardTemplate
            currentStage={0}
            stages={[
                {
                    primaryButtonText: translate(Content.common.buttons.confirm),
                    primaryButtonCallback: onConfirm,
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: close }],
                    isValid,
                    submitButtonDataTestId: "level-reassignment-confirm",
                },
            ]}
            title={translate(Content.sitemaps.levelsReassignmentDialog.title)}
        >
            <Body1>
                <span>{translate(Content.sitemaps.levelsReassignmentDialog.paragraph1)}</span> <br />
                <span>{translate(Content.sitemaps.levelsReassignmentDialog.paragraph2)}</span>
            </Body1>
            {existingLevelsRemoved.map((level) => (
                <React.Fragment key={level.id}>
                    <HorizontalGap height="15px" />
                    <StyledFieldLabel>{`${level.levelName} (${level.levelAbbreviation})`}</StyledFieldLabel>
                    <AutocompleteWithLoading
                        dataTestId={`level-reassignment-${level.levelName}`}
                        value={sitemapVersionLevelAdjustment[level.id]?.fallbackLevel ?? null}
                        options={availableSitemapVersions.flatMap((version) => version.sitemapVersionLevels)}
                        groupBy={(option) => availableSitemapVersions?.find((v) => v.id === option.sitemapVersionId)?.name}
                        onChange={(value) => setSitemapVersionLevelAdjustment((adjustments) => ({ ...adjustments, [level.id]: { removedLevel: level, fallbackLevel: value } }))}
                        getOptionLabel={(option: SitemapVersionLevel) => `${option.levelName} (${option.levelAbbreviation})`}
                        getOptionSelected={(option, selected) => option?.id === selected?.id}
                        loadingOptions={false}
                    />
                </React.Fragment>
            ))}
        </FormWizardTemplate>
    );
};
